import { Badge, Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, } from '@chakra-ui/react';
import React from 'react';
import moment from "moment";
import { numberFormat, convertFriendlyName } from "../../../../libraries/utils";
import { UserRole } from "../../../../config/constant";

const TABLE_COLUMNS = [
  {
    Header: 'OCCURRED',
    accessor: 'dateOccurred',
  },
  {
    Header: 'POLICY',
    accessor: 'policy',
  },
  {
    Header: 'TYPE',
    accessor: 'type',
  },
  {
    Header: 'CARRIER IDENTIFIER',
    accessor: 'carrierClaimIdentifier',
  },
  {
    Header: 'PAYOUT',
    accessor: 'payoutCents',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
];

export default function Claim(props) {
  const {
    claims
  } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <Box mt='32px'>
      <Text
        color={ textColor }
        fontWeight='bold'
        fontSize='lg'
      >
        Claims
      </Text>
      <Box mt='8px'>
        <Table variant="simple" color="gray.500">
          <Thead>
            <Tr>
              { TABLE_COLUMNS.map((column, index) => (
                <Th pe="10px" key={ index } borderColor={ borderColor }>
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={ {
                      sm: '10px',
                      lg: '12px',
                    } }
                    color="gray.400"
                  >
                    { column.Header }
                  </Flex>
                </Th>
              )) }
            </Tr>
          </Thead>
          <Tbody>
            {
              claims && claims.map((claim, index) => {
                return (
                  <Tr key={ index }
                      color={ textColor }
                      fontSize="sm">
                    <Td>
                      { moment(claim['dateOccurred']).format('MMM DD, YYYY') }
                    </Td>
                    <Td>
                      {
                        claim?.policy?.name || ''
                      }
                    </Td>
                    <Td>
                      { convertFriendlyName(claim?.type)}
                    </Td>
                    <Td>
                      { claim?.carrierClaimIdentifier }
                    </Td>
                    <Td>
                      { claim?.payoutCents ? `$${numberFormat(claim?.payoutCents / 100)}` : 'N/A' }
                    </Td>
                    <Td>
                      <Badge colorScheme={claim?.status === 'OPEN' ? 'orange' : 'green'}>{claim?.status}</Badge>
                    </Td>
                  </Tr>
                )
              })
            }
            { (!claims || claims.length === 0) && (
              <Tr>
                <Td colSpan={ 8 } p={ 2 }>
                  <Text textAlign="center">There is no claim!</Text>
                </Td>
              </Tr>
            ) }
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
