import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverContent,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { CirclesWithBar } from 'react-loader-spinner';
import ReactApexChart from 'react-apexcharts';
import { getSmartPortfolioInfo } from "../../../../api/fasttrack-service";
import { useAuth } from "../../../../auth-context/auth.context";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { countDecimals, currencyFormat, getAccountTypeInfo } from "../../../../libraries/utils";
import { PopoverTrigger } from "../../dataTables/components/ClientTable";
import { LockIcon } from "@chakra-ui/icons";

interface SmartPortfolioProps {
  isOpen: boolean,
  onClose: () => void,
  account: any,
  setSelectedSecurityId: (id: string) => void,
  onMarketDataOpen: () => void,
  isPremiumPlan: boolean,
}

const DEFAULT_CHARTDATA = {
  options: {
    labels: [],
    responsive: [{
      breakpoint: 380,
      options: {
        chart: {
          width: 300
        },
        legend: {
          position: 'bottom'
        }
      }
    },
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 400
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ],
  },
  series: [],
}

const DEFAULT_LINECHART = {
  options: {
    chart: {
      id: 'smartcast-line-chart',
      type: 'bar',
    },
    xaxis: {
      categories: [],
    },
  },
  series: [],
}

const tableColumns: any = [
  {
    Header: 'Symbol',
    accessor: 'tickerSymbol',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Market Value',
    accessor: 'marketValue',
    Cell: ({ value }: any) => `$${currencyFormat(value)}`,
  },
  {
    Header: 'Cost Basis',
    accessor: 'costBasis',
    Cell: ({ value }: any) => `$${currencyFormat(value)}`,
  },
  {
    Header: 'Exp Ratio',
    accessor: 'expenseRatio',
    Cell: ({ value }: any) => {
      const maxDigits = countDecimals(value);
      return value === null ? 'N/A' : `${(value * 100).toLocaleString(undefined, { maximumFractionDigits: maxDigits })}%`
    },
  },
  {
    Header: '12B-1 FEE',
    accessor: 'b12',
    Cell: ({ value }: any) => {
      const maxDigits = countDecimals(value);
      return value === null ? 'N/A' : `${(value * 100).toLocaleString(undefined, { maximumFractionDigits: maxDigits })}%`
    },
  },
  {
    Header: 'Front Load',
    accessor: 'frontLoad',
    Cell: ({ value }: any) => {
      const maxDigits = countDecimals(value);
      return value === null ? 'N/A' : `${(value * 100).toLocaleString(undefined, { maximumFractionDigits: maxDigits })}%`
    },
  },
  {
    Header: 'Fund Fee',
    accessor: 'smartFee',
    Cell: ({ value }: any) => `$${currencyFormat(value)}`,
  },
];

const SMARTCAST_YEARS = [1, 5, 10, 15, 25, 50];

const SmartPortfolioModal: React.FC<SmartPortfolioProps> = ({
                                                              isOpen,
                                                              onClose,
                                                              account,
                                                              isPremiumPlan,
                                                              setSelectedSecurityId,
                                                              onMarketDataOpen
                                                            }) => {
  // Chakra Color Mode
  const textColor = useColorModeValue("navy.700", "white");
  const tmImage = useColorModeValue(require('../../../../assets/img/TM-black.png'), require('../../../../assets/img/TM-white.png'));

  const { selectedClient, } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState<any>(DEFAULT_CHARTDATA)
  const [portfolioData, setPortfolioData] = useState(undefined);
  const [feeOfOneYear, setFeeOfOneYear] = useState(0);
  const [totalSmartFees, setTotalSmartFees] = useState(0);

  const [lineChartData, setLineChartData] = useState<any>(DEFAULT_LINECHART);

  useEffect(() => {
    if (selectedClient && account) {
      getSmartPortfolioData();
    }
  }, [selectedClient, account]);

  const getSmartPortfolioData = async () => {
    try {
      setIsLoading(true);
      const {
        data,
        feeOfOneYear,
        totalSmartFees
      } = await getSmartPortfolioInfo(selectedClient.userId, account.accountId);
      setChartData((prev: any) => ({
        ...prev,
        options: {
          ...prev.options,
          labels: data.map((item: any) => `${item.tickerSymbol}`),
          legend: {
            ...prev.options.legend,
            show: true,
            position: 'right',
            formatter: (seriesName: string, opts: any) => {
              // This will show the ticker symbol next to the percentage in the legend.
              return `${seriesName} - ${data[opts.seriesIndex].name}`;
            },
          }
        },
        series: data.map((item: any) => item.marketValue || 1),
      }));
      setPortfolioData(data);
      setFeeOfOneYear(feeOfOneYear);
      setTotalSmartFees(totalSmartFees);

      // Smartcast
      const newSeries = [{
        name: "Total Fee",
        data: SMARTCAST_YEARS.map(year => ((totalSmartFees + feeOfOneYear) * year).toFixed(2)),
      }];

      setLineChartData({
        options: {
          ...lineChartData.options,
          colors: ['#FF4560'],
          xaxis: {
            categories: SMARTCAST_YEARS.map(year => `${year}Y`),
          },
        },
        series: newSeries,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  const handleClose = () => {
    setChartData(DEFAULT_CHARTDATA);
    onClose();
  }

  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data: any = useMemo(() => {
    if (!portfolioData) return [];
    return portfolioData
  }, [portfolioData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='full'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack color={textColor}>
            <Avatar
              src={account?.icon}
              title={account?.name}
              w="30px"
              h="30px"
              me="8px"
            />
            <Text fontSize='2xl'>{account?.name} smartportfolio
              <Image
                display="inline"
                boxSize="18px"
                src={tmImage}
              />
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
            isLoading ? <Center h="100%" minH='400px' justifyContent="center" py="32px">
                <CirclesWithBar
                  height="120"
                  width="120"
                  color="#70ad45"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  outerCircleColor=""
                  innerCircleColor=""
                  barColor=""
                  ariaLabel="circles-with-bar-loading"
                />
              </Center> :
              <Box>
                <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight='800' color='brand.500'>{account?.title} {getAccountTypeInfo(account?.type)}</Text>
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={1} mb={4}
                      alignItems='start'>
                  <HStack color={textColor}>
                    <Text fontSize='xl' fontWeight='700'>Total Balance:</Text>
                    <Text fontSize={{ base: '2xl', md: '3xl' }}
                          fontWeight='800'>$ {account?.balance ? currencyFormat(account?.balance) : 0}</Text>
                  </HStack>
                  <Box>
                    <Flex direction={{ base: 'column', md: 'row' }} color={textColor}
                          align={{ base: 'start', md: 'center' }} gap={{ base: 0, md: 2 }}>
                      <Text fontSize='xl' fontWeight='700' whiteSpace="nowrap">
                        Total smartfees
                        <Image
                          display="inline"
                          boxSize="18px"
                          src={tmImage}
                        />:
                      </Text>
                      <Text fontSize={{ base: '2xl', md: '3xl' }} fontWeight='800' color='red.400'>
                        $ {currencyFormat(totalSmartFees + feeOfOneYear)} {
                        account?.balance && Number(account.balance) !== 0 ? ` (${currencyFormat(((totalSmartFees + feeOfOneYear) / account?.balance) * 100)}%)` : ''}
                      </Text>
                    </Flex>
                    <Flex gap={4}>
                      <HStack>
                        <Tooltip label='expense ratio, 12b-1, frontLoad' hasArrow placement='auto'>
                          <Text fontSize='sm' color={textColor} fontWeight='500' cursor='help'>
                            Fund fees:
                          </Text>
                        </Tooltip>
                        <Text fontSize='sm' color={textColor} fontWeight='700'>
                          $ {currencyFormat(totalSmartFees)}
                        </Text>
                      </HStack>
                      <HStack>
                        <Tooltip label='Transaction fees over a 12-month period.' hasArrow placement='auto'>
                          <Text fontSize='sm' color={textColor} fontWeight='500' cursor='help'>
                            Advisor fees:
                          </Text>
                        </Tooltip>
                        <Text fontSize='sm' color={textColor} fontWeight='700'>
                          $ {currencyFormat(feeOfOneYear)}
                        </Text>
                      </HStack>
                    </Flex>
                  </Box>
                </Grid>
                <VStack>
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="pie"
                    width={600} />
                </VStack>
                <Box overflowX="auto" overflowY="auto" maxH="400px" mt={4}>
                  <Table {...getTableProps()} variant="striped" color="gray.500">
                    <Thead>
                      {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                          {headerGroup.headers.map((column, index) => (
                            <Th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              key={index}
                              borderColor="transparent">
                              <Flex
                                justify="space-between"
                                align="center"
                                color="gray.400">
                                {column.render('Header')}
                              </Flex>
                            </Th>
                          ))}
                        </Tr>
                      ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                      {isLoading ? <>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                          <Tr key={item}>
                            <Td colSpan={6} p={2}>
                              <Skeleton h="20px" />
                            </Td>
                          </Tr>
                        ))}
                      </> : rows.map((row, index) => {
                        prepareRow(row);
                        return (
                          <Tr {...row.getRowProps()} key={index}>
                            {row.cells.map((cell: any, index) => {
                              if (cell.column.Header === 'Symbol') {
                                return (
                                  <Td
                                    {...cell.getCellProps()}
                                    key={index}
                                    fontSize='md'
                                    py="8px"
                                    borderColor="transparent">
                                    {!isPremiumPlan && (
                                      <Popover placement='top-start'>
                                        <PopoverTrigger>
                                          <Button size='sm' variant='link' rightIcon={<LockIcon />}>
                                            <Tooltip label={cell.row.original.name} hasArrow placement="top">
                                              <Text
                                                color={textColor}
                                                fontSize="lg"
                                                fontWeight="500">
                                                {cell.value}
                                              </Text>
                                            </Tooltip>
                                          </Button>
                                        </PopoverTrigger>
                                        <PopoverContent>
                                          <PopoverArrow />
                                          <Alert status='error' gap={1}>
                                            <HStack>
                                              <AlertIcon />
                                              <AlertTitle color='red.500'>
                                                Upgrade to Smartdata+ to access market data
                                              </AlertTitle>
                                            </HStack>
                                            <Button
                                              colorScheme='red'
                                              variant='outline'
                                              borderRadius='full'
                                              minW='90px'
                                              size='sm'
                                              onClick={() => window.location.href = '/admin/settings?tab=Subscription'}>
                                              Upgrade
                                            </Button>
                                          </Alert>
                                        </PopoverContent>
                                      </Popover>
                                    )}
                                    {isPremiumPlan && (
                                      <Button size='sm' variant='link'
                                              onClick={() => {
                                                setSelectedSecurityId(cell.row.original.securityId);
                                                onMarketDataOpen();
                                              }}>
                                        <Tooltip label={cell.row.original.name} hasArrow placement="top">
                                          <Text
                                            color={textColor}
                                            fontSize="lg"
                                            fontWeight="500">
                                            {cell.value}
                                          </Text>
                                        </Tooltip>
                                      </Button>
                                    )}
                                  </Td>
                                )
                              }
                              return (
                                <Td
                                  {...cell.getCellProps()}
                                  key={index}
                                  fontSize='md'
                                  py="8px"
                                  borderColor="transparent">
                                  {cell.render('Cell')}
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })}
                    </Tbody>
                    <Tfoot
                      style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: '#E1E9F8',
                      }}>
                      <Tr>
                        {headerGroups[0].headers.map((column, index) => (
                          <Td
                            key={index}
                            borderColor="transparent"
                            fontSize="md"
                            py="8px"
                            fontWeight="bold"
                          >
                            {index === columns.length - 2 ? (
                              'Total Fund fees' // Label for the total in the first cell
                            ) : index === columns.length - 1 ? (
                              `$${totalSmartFees.toFixed(2)}`
                            ) : (
                              '' // Empty cells for the rest
                            )}
                          </Td>
                        ))}
                      </Tr>
                    </Tfoot>
                  </Table>
                </Box>
                <Divider mt={4} />
                <Box>
                  <Text color={textColor} fontSize='xl' fontWeight='700' mt={2}>
                    smartcast
                    <Image
                      display="inline"
                      boxSize="18px"
                      src={tmImage}
                    />
                  </Text>
                  <Box w={{ base: '100%', md: '50%' }} mx='auto'>
                    <ReactApexChart
                      options={lineChartData.options}
                      series={lineChartData.series}
                      type="bar"
                    />
                  </Box>
                  <Flex
                    overflowX='auto'
                    width="full"
                    py={2}
                    gap={2}
                    justify='space-between'
                    align='end'
                  >
                    <Text color={textColor} fontSize='lg' fontWeight='500' p={2} me={{ base: 8, md: 16 }}>
                      Total Fee
                    </Text>
                    {
                      SMARTCAST_YEARS.map((year: number, index: number) => (
                        <VStack key={index} flexShrink={0} p={2}>
                          <Text fontWeight={600}>{year}Y</Text>
                          <Text>${currencyFormat((totalSmartFees + feeOfOneYear) * year)}</Text>
                        </VStack>
                      ))
                    }
                  </Flex>
                </Box>
              </Box>
          }
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SmartPortfolioModal;
