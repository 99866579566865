import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Box, Flex, FormControl, FormLabel, Radio, RadioGroup, Text, useColorModeValue } from '@chakra-ui/react';
import { getFormFieldValues, updateFormFieldValues } from "../../../../../api/smart-fill-service";
import { getValueByFieldName } from "../../../../../libraries/utils";
import { CirclesWithBar } from "react-loader-spinner";
import { StepComponentProps } from "../IRASchwabApplicationModal";

const ACCOUNT_HOLDER_CHECKBOXES_A1 = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesA1[0].accountHolder[0]';
const IA_CHECKBOXES_A1 = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesA1[0].IA[0]';
const ACCOUNT_HOLDER_CHECKBOXES_A2 = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesA2[0].accountHolder[0]';
const IA_CHECKBOXES_A2 = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesA2[0].IA[0]';
const NONE_CHECKBOXES_A2 = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesA2[0].none[0]';
const ACCOUNT_HOLDER_CHECKBOXES_B1 = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesB1[0].accountHolder[0]';
const IA_CHECKBOXES_B1 = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesB1[0].IA[0]';
const ACCOUNT_HOLDER_CHECKBOXES_B2 = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesB2[0].accountHolder[0]';
const IA_CHECKBOXES_B2 = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesB2[0].IA[0]';
const NONE_CHECKBOXES_B2 = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesB2[0].none[0]';
const ACCOUNT_HOLDER_CHECKBOXES_C = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesC[0].accountHolder[0]';
const IA_CHECKBOXES_C = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesC[0].IA[0]';
const BOTH_CHECKBOXES_C = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].CheckboxesC[0].both[0]';
const OBJECTION_CHECKBOXES_YES = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].Objection[0].Checkboxes[0].yes[0]';
const OBJECTION_CHECKBOXES_NO = 'clients[0].Form[0].Section6[0].border[0].Issuer_Communications_Custodial[0].Issuer_Communications_Custodial[0].Objection[0].Checkboxes[0].no[0]';

const IssuerCommunication = forwardRef<any, StepComponentProps>(({userFormId, title, ...props}, ref) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [isLoading, setIsLoading] = useState(false);
  const [voteProxyBallot, setVoteProxyBallot] = useState('AH');
  const [nonVotingCopy, setNonVotingCopy] = useState('AH');
  const [decision, setDecision] = useState('AH');
  const [informationCopy, setInformationCopy] = useState('AH');
  const [interimMailing, setInterimMailing] = useState('AH');
  const [objectToSchwabRelease, setObjectToSchwabRelease] = useState('BOTH');

  useEffect(() => {
    fetchFormValues();
  }, []);

  const fetchFormValues = () => {
    setIsLoading(true);
    getFormFieldValues(userFormId)
      .then(({fieldValues}) => {
        const accountHolderCheckboxesA1 = getValueByFieldName(fieldValues, ACCOUNT_HOLDER_CHECKBOXES_A1) === "checked";
        const iaCheckboxesA1 = getValueByFieldName(fieldValues, IA_CHECKBOXES_A1) === "checked";

        let voteProxyBallotOption = '';
        if (accountHolderCheckboxesA1) {
          voteProxyBallotOption = 'AH';
        } else if (iaCheckboxesA1) {
          voteProxyBallotOption = 'IA';
        }
        setVoteProxyBallot(voteProxyBallotOption);

        const accountHolderCheckboxesA2 = getValueByFieldName(fieldValues, ACCOUNT_HOLDER_CHECKBOXES_A2) === "checked";
        const iaCheckboxesA2 = getValueByFieldName(fieldValues, IA_CHECKBOXES_A2) === "checked";
        const noneCheckboxesA2 = getValueByFieldName(fieldValues, NONE_CHECKBOXES_A2) === "checked";

        let nonVotingCopyOption = '';
        if (accountHolderCheckboxesA2) {
          nonVotingCopyOption = 'AH';
        } else if (iaCheckboxesA2) {
          nonVotingCopyOption = 'IA';
        } else if (noneCheckboxesA2) {
          nonVotingCopyOption = 'NONE';
        }
        setNonVotingCopy(nonVotingCopyOption);

        const accountHolderCheckboxesB1 = getValueByFieldName(fieldValues, ACCOUNT_HOLDER_CHECKBOXES_B1) === "checked";
        const iaCheckboxesB1 = getValueByFieldName(fieldValues, IA_CHECKBOXES_B1) === "checked";

        let decisionOption = '';
        if (accountHolderCheckboxesB1) {
          decisionOption = 'AH';
        } else if (iaCheckboxesB1) {
          decisionOption = 'IA';
        }
        setDecision(decisionOption);

        const accountHolderCheckboxesB2 = getValueByFieldName(fieldValues, ACCOUNT_HOLDER_CHECKBOXES_B2) === "checked";
        const iaCheckboxesB2 = getValueByFieldName(fieldValues, IA_CHECKBOXES_B2) === "checked";
        const noneCheckboxesB2 = getValueByFieldName(fieldValues, NONE_CHECKBOXES_B2) === "checked";

        let informationCopyOption = '';
        if (accountHolderCheckboxesB2) {
          informationCopyOption = 'AH';
        } else if (iaCheckboxesB2) {
          informationCopyOption = 'IA';
        } else if (noneCheckboxesB2) {
          informationCopyOption = 'NONE';
        }
        setInformationCopy(informationCopyOption);

        const accountHolderCheckboxesC = getValueByFieldName(fieldValues, ACCOUNT_HOLDER_CHECKBOXES_C) === "checked";
        const iaCheckboxesC = getValueByFieldName(fieldValues, IA_CHECKBOXES_C) === "checked";
        const bothCheckboxesC = getValueByFieldName(fieldValues, BOTH_CHECKBOXES_C) === "checked";

        let interimMailingOption = '';
        if (accountHolderCheckboxesC) {
          interimMailingOption = 'AH';
        } else if (iaCheckboxesC) {
          interimMailingOption = 'IA';
        } else if (bothCheckboxesC) {
          interimMailingOption = 'BOTH';
        }
        setInterimMailing(interimMailingOption);

        const objectionCheckboxesYes = getValueByFieldName(fieldValues, OBJECTION_CHECKBOXES_YES) === "checked";
        const objectionCheckboxesNo = getValueByFieldName(fieldValues, OBJECTION_CHECKBOXES_NO) === "checked";

        let objectToSchwabReleaseOption = '';
        if (objectionCheckboxesYes) {
          objectToSchwabReleaseOption = 'YES';
        } else if (objectionCheckboxesNo) {
          objectToSchwabReleaseOption = 'NO';
        }
        setObjectToSchwabRelease(objectToSchwabReleaseOption);

      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsLoading(false))
  }

  const updateData = async () => {
    await updateFormFieldValues([
        {
          [ACCOUNT_HOLDER_CHECKBOXES_A1]: voteProxyBallot === 'AH' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [IA_CHECKBOXES_A1]: voteProxyBallot === 'IA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [ACCOUNT_HOLDER_CHECKBOXES_A2]: nonVotingCopy === 'AH' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [IA_CHECKBOXES_A2]: nonVotingCopy === 'IA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [NONE_CHECKBOXES_A2]: nonVotingCopy === 'NONE' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [ACCOUNT_HOLDER_CHECKBOXES_B1]: decision === 'AH' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [IA_CHECKBOXES_B1]: decision === 'IA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [ACCOUNT_HOLDER_CHECKBOXES_B2]: informationCopy === 'AH' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [IA_CHECKBOXES_B2]: informationCopy === 'IA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [NONE_CHECKBOXES_B2]: informationCopy === 'NONE' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [ACCOUNT_HOLDER_CHECKBOXES_C]: interimMailing === 'AH' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [IA_CHECKBOXES_C]: interimMailing === 'IA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [BOTH_CHECKBOXES_C]: interimMailing === 'BOTH' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [OBJECTION_CHECKBOXES_YES]: objectToSchwabRelease === 'YES' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [OBJECTION_CHECKBOXES_NO]: objectToSchwabRelease === 'NO' ? 'checked' : '', // Replace 'ObjectionNo' with actual condition value
          fieldType: 'PDFCheckBox'
        }
      ], userFormId
    )
  }

  useImperativeHandle(ref, () => ({
    handleNext: async () => {
      try {
        await updateData();
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  }));

  return (
    <Box h='full'>
      <Text
        fontSize="2xl"
        fontWeight="500">
        { title }
      </Text>
      <Flex p={ {base: '0px', md: '32px'} } direction='column' align={ isLoading ? 'center' : 'start' }
            justify={ isLoading ? 'center' : 'start' } h='full' textColor={textColor}>
        {
          isLoading ? <CirclesWithBar
            height="100"
            width="100"
            wrapperClass=""
            visible={ true }
            ariaLabel="circles-with-bar-loading"
          /> : <Box w='full'>
            <Text>
              If you have granted your Investment Advisor ("IA") trading authority over your Account and your IA
              exercises investment discretion for you pursuant to an advisory contract, you can appoint your IA to be
              sent certain issuer and issuer-related communications (such as proxies, corporate reorganizations and
              other corporate actions, including tender offers, proposed mergers, rights offerings, exchange offers and
              warrants, among other things), that may require a voting decision or other action, regarding investments
              held in your Account.
            </Text>
            <Text>
              If you appoint your IA in Sections A, B and/or C below, your IA will, regarding only those voting decision
              or other action communications sent to your IA: (i) be requested to vote proxy ballots; (ii) be requested
              to provide instructions regarding corporate reorganizations and other corporate actions; and (iii) be sent
              certain prospectuses and annual reports and other communications ("interim mailings"). In these cases, you
              will be authorizing your IA to make all voting decisions and take all actions on your behalf.
            </Text>
            <Text
              fontWeight='600'
            >
              Even if you appoint your IA in any or all of the sections below, you may still be sent certain other
              issuer and issuer-related communications regarding investments held in your Account. You agree that you
              will be responsible for providing Charles Schwab & Co., Inc. ("Schwab") any applicable instructions or
              directions on those items.
            </Text>
            <Text>
              If you do not make a selection in any, or all, of Sections A, B and/or C below, Schwab will send you the
              applicable communications and deem you to have indicated that you will be responsible for providing Schwab
              any applicable instructions or directions on those items, but informational copies of those communications
              and issuer-related communications may be sent to your IA.
            </Text>
            <Text>
              Please indicate whom you authorize to receive and vote proxies, make reorganization decisions and receive
              interim mailings. Select only one option for each item.
            </Text>

            <Box>
              <Text
                fontWeight='600'
              >
                A. Proxy Voting Authorization and Mailing Preferences
              </Text>
              <FormControl mt='12px' ms='16px'>
                <FormLabel>Select only <b>one</b> who should be sent and vote proxy ballots:</FormLabel>
                <RadioGroup value={ voteProxyBallot } onChange={ (e) => setVoteProxyBallot(e) }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ {sm: 2, md: 4} }>
                    <Radio colorScheme="brandScheme" value='AH'>Account Holder</Radio>
                    <Radio colorScheme="brandScheme" value='IA'>IA</Radio>
                  </Flex>
                </RadioGroup>
              </FormControl>
              <FormControl mt='12px' ms='16px'>
                <FormLabel>Select only <b>one</b> who should be sent informational, nonvoting copies:</FormLabel>
                <RadioGroup value={ nonVotingCopy } onChange={ (e) => setNonVotingCopy(e) }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ {sm: 2, md: 4} }>
                    <Radio colorScheme="brandScheme" value='AH'>Account Holder</Radio>
                    <Radio colorScheme="brandScheme" value='IA'>IA (May choose only if Account Holder is
                      voting.)</Radio>
                    <Radio colorScheme="brandScheme" value='NONE'>None</Radio>
                  </Flex>
                </RadioGroup>
              </FormControl>
            </Box>

            <Box>
              <Text
                fontWeight='600'
              >
                B. Corporate Reorganizations and Other Corporate Actions
              </Text>
              <FormControl mt='12px' ms='16px'>
                <FormLabel>Select only <b>one</b> who should be sent response coupons and make reorganization
                  decisions:</FormLabel>
                <RadioGroup value={ decision } onChange={ (e) => setDecision(e) }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ {sm: 2, md: 4} }>
                    <Radio colorScheme="brandScheme" value='AH'>Account Holder</Radio>
                    <Radio colorScheme="brandScheme" value='IA'>IA</Radio>
                  </Flex>
                </RadioGroup>
              </FormControl>
              <FormControl mt='12px' ms='16px'>
                <FormLabel>Select only <b>one</b> who should be sent informational copies:</FormLabel>
                <RadioGroup value={ informationCopy } onChange={ (e) => setInformationCopy(e) }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ {sm: 2, md: 4} }>
                    <Radio colorScheme="brandScheme" value='AH'>Account Holder</Radio>
                    <Radio colorScheme="brandScheme" value='IA'>IA (May choose only if Account Holder is
                      voting.)</Radio>
                    <Radio colorScheme="brandScheme" value='NONE'>None</Radio>
                  </Flex>
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <Text
                fontWeight='600'
              >
                C. Interim Mailings
              </Text>
              <FormControl mt='12px' ms='16px'>
                <FormLabel>Select only <b>one</b> who should be sent interim mailings:</FormLabel>
                <RadioGroup value={ interimMailing } onChange={ (e) => setInterimMailing(e) }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ {sm: 2, md: 4} }>
                    <Radio colorScheme="brandScheme" value='AH'>Account Holder</Radio>
                    <Radio colorScheme="brandScheme" value='IA'>IA</Radio>
                    <Radio colorScheme="brandScheme" value='BOTH'>Both Account Holder and IA</Radio>
                  </Flex>
                </RadioGroup>
              </FormControl>
            </Box>
            <Box>
              <Text
                fontWeight='600'
              >
                Objection to Release of Information to Issuers
              </Text>
              <Text>
                Rule 14b-1 of the Securities Exchange Act of 1934, as amended, requires that, upon the issuer's request,
                Schwab disclose your name, address and security positions to the issuer of any security held in your
                Account unless you object to disclosure of this information.
              </Text>
            </Box>
            <FormControl>
              <RadioGroup value={ objectToSchwabRelease } onChange={ (e) => setObjectToSchwabRelease(e) }>
                <Radio colorScheme="brandScheme" value='YES'>
                  Yes, I object to Schwab's release of my name, address and securities positions to issuers whose
                  securities are held in my Account.
                </Radio>
                <Radio colorScheme="brandScheme" value='NO'>
                  No, I do not object to Schwab's release of my name, address and securities positions to issuers whose
                  securities are held in my Account.
                </Radio>
              </RadioGroup>
            </FormControl>
          </Box>
        }
      </Flex>
    </Box>
  );
});

export default IssuerCommunication;
