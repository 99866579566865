import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid, GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select, Textarea,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react';
import { AccountType, Management } from '../../../../config/constant';
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import moment from "moment";
import US_STATES from '../../../../config/us-sate.json';
import { isValidEmail, isValidUSPhone } from "../../../../libraries/utils";

interface UserAddModalProps {
  isOpen: boolean,
  onClose: () => void,
  user: any,
  onSave: (e: {
    firstName: string,
    middleName: string,
    lastName: string,
    nickName: string,
    birthday: string,
    email: string,
    phoneNumber: string,
    userRole: string,
    management: string,
    addressLine1: string,
    city: string,
    state: string,
    postalCode: string,
    companyName: string,
    note?: string,
  }) => Promise<void>
}

const UpdateClientModal: React.FC<UserAddModalProps> = ({ isOpen, onClose, onSave, user }) => {
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickName, setNickName] = useState('');
  const [birthday, setBirthday] = useState(new Date());
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userRole, setUserRole] = useState<AccountType>(AccountType.CLIENT);
  const [management, setManagement] = useState<Management>(Management.FINANCIAL);
  const [addressLine1, setAddressLine1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [note, setNote] = useState('');

  const save = async () => {
    let message = null;

    if (!firstName) {
      message = 'First name is required.';
    } else if (!lastName) {
      message = 'Last name is required.';
    } else if (!email) {
      message = 'Email is required.';
    } else if (!isValidEmail(email)) {
      message = 'Invalid email address.';
    } else if (phoneNumber && !isValidUSPhone(phoneNumber)) {
      message = 'Please input valid phone number.';
    }

    if (message) {
      setErrorMessage(message);
      toast({
        title: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } else {
      setIsSaving(true);
      const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
      await onSave({
        addressLine1,
        birthday: moment(birthday).format('YYYY-MM-DD'),
        city,
        companyName,
        email,
        firstName,
        lastName,
        management,
        middleName,
        nickName,
        phoneNumber: phoneNumberDigits ? `+1${phoneNumberDigits}` : '',
        postalCode,
        state,
        userRole,
        note
      });
      setIsSaving(false);
      handleClose();
    }
  }

  useEffect(() => {
    setDefaultUserInfo();
  }, [user]);

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let onlyNums = value.replace(/\D/g, '');
    let formattedNumber;
    if (onlyNums.length <= 3) {
      formattedNumber = onlyNums;
    } else if (onlyNums.length <= 6) {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    } else {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    }

    setPhoneNumber(formattedNumber);
    setErrorMessage(null);
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setErrorMessage(null);
  }

  const handleCompanyName = (value: string) => {
    setCompanyName(value);
    setErrorMessage(null);
  }

  const handleClose = () => {
    onClose();
    setDefaultUserInfo();
    setErrorMessage(null);
  }

  const setDefaultUserInfo = () => {
    setFirstName(user?.firstName);
    setMiddleName(user?.middleName);
    setLastName(user?.lastName);
    setNickName(user?.nickName);
    setBirthday(user?.dateOfBirth ? new Date(user.dateOfBirth) : new Date());
    setEmail(user?.email);
    setPhoneNumber(user?.phoneNumber ? user?.phoneNumber.replace(/\+1/g, '') : '');
    setCompanyName(user?.fullName);
    setAddressLine1(user?.addressLine1);
    setCity(user?.city);
    setState(user?.state);
    setPostalCode(user?.postalCode);
    setUserRole(AccountType.CLIENT);
    setManagement(Management.FINANCIAL);
  }

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Lead</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid
            templateColumns={useBreakpointValue({ base: '1fr', md: 'repeat(3, 1fr)' })}
            gap={useBreakpointValue({ base: 4, md: 2 })}
          >
            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input placeholder='John' value={firstName}
                     onChange={(e) => {
                       setFirstName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl>
              <FormLabel>Middle Name</FormLabel>
              <Input placeholder='Fortune (Optional)' value={middleName}
                     onChange={(e) => {
                       setMiddleName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input placeholder='Doe' value={lastName}
                     onChange={(e) => {
                       setLastName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
          </Grid>
          <Grid
            mt="12px"
            templateColumns={useBreakpointValue({ base: '1fr', md: 'repeat(2, 1fr)' })}
            gap={useBreakpointValue({ base: 4, md: 2 })}
          >
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input placeholder='you@example.com' type="email" colorScheme="brandScheme" value={email} readOnly
                     onChange={(e) => handleEmailChange(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input colorScheme="brandScheme" value={phoneNumber}
                     type="tel"
                     placeholder="(201)-555-0123 (Optional)"
                     onChange={(e) => handlePhoneNumberChange(e)} />
            </FormControl>
            <FormControl>
              <FormLabel>Nick Name</FormLabel>
              <Input placeholder='nickname (Optional)' value={nickName}
                     onChange={(e) => {
                       setNickName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl>
              <FormLabel>Date of Birth</FormLabel>
              <SingleDatepicker
                name="date-input"
                maxDate={new Date()}
                date={birthday}
                onDateChange={setBirthday}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Company Name</FormLabel>
              <Input placeholder='Company (Optional)' colorScheme="brandScheme" value={companyName}
                     onChange={(e) => handleCompanyName(e.target.value)} marginBottom='8px' />
            </FormControl>
            <FormControl>
              <FormLabel>Address</FormLabel>
              <Input placeholder='Address Line1 (Optional)' colorScheme="brandScheme" value={addressLine1}
                     onChange={(e) => {
                       setAddressLine1(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Input placeholder='City (Optional)' colorScheme="brandScheme" value={city}
                     onChange={(e) => {
                       setCity(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl>
              <FormLabel>State/Province</FormLabel>
              <Select
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  setErrorMessage(null);
                }}
              >
                <option key={0} value=''>
                  Select a state...
                </option>
                {US_STATES.map((usState) => (
                  <option key={usState.abbreviation} value={usState.abbreviation}>
                    {usState.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Zip/Postal Code</FormLabel>
              <Input placeholder='Postal Code (Optional)' colorScheme="brandScheme" value={postalCode}
                     maxLength={6}
                     type="number"
                     onChange={(e) => {
                       setPostalCode(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl>
              <FormLabel>Country</FormLabel>
              <Input value='United States' colorScheme="brandScheme" readOnly />
            </FormControl>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Note</FormLabel>
                <Textarea placeholder='Internal note about lead' value={note}
                          rows={3}
                          onChange={(e) => {
                            setNote(e.target.value);
                          }} />
              </FormControl>
            </GridItem>
          </Grid>
          <FormControl>
            {/*<FormLabel>Management</FormLabel>*/}
            {/*<RadioGroup value={management} onChange={(e: Management) => setManagement(e)}>*/}
            {/*  <HStack>*/}
            {/*    <Radio colorScheme="brandScheme"*/}
            {/*           value={Management.FINANCIAL}>{Management.FINANCIAL.toUpperCase()}</Radio>*/}
            {/*    <Radio colorScheme="brandScheme"*/}
            {/*           value={Management.INSURANCE}>{Management.INSURANCE.toUpperCase()}</Radio>*/}
            {/*  </HStack>*/}
            {/*</RadioGroup>*/}
            {errorMessage ? (
              <FormHelperText color='red'>
                {errorMessage}
              </FormHelperText>
            ) : null}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brandScheme" mr={3} onClick={() => save()} isLoading={isSaving} isDisabled={isSaving}
                  loadingText='Saving'>Save</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UpdateClientModal;
