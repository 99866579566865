import { API } from '@aws-amplify/api';
import { ENDPOINT } from "../config/constant";

const apiName = ENDPOINT;

const apiRequest = (method: string, url: string, params?: any): Promise<any> => {
	// @ts-ignore
	return API[method](apiName, url, {
		...params,
		response: true,
	});
};

export default apiRequest;
