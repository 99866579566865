import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import moment from "moment/moment";
import { CirclesWithBar } from "react-loader-spinner";
import { getFormFieldValues, updateFormFieldValues } from "../../../../../api/smart-fill-service";
import { getValueByFieldName, isValidUSPhone } from "../../../../../libraries/utils";
import { StepComponentProps } from "../IRASchwabApplicationModal";

const CONTACT_INFO_FIRST_NAME = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].fullName[0].firstNameTF[0]";
const CONTACT_INFO_MIDDLE_NAME = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].fullName[0].middleNameTF[0]";
const CONTACT_INFO_LAST_NAME = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].fullName[0].lastNameTF[0]";
const CONTACT_INFO_SUFFIX = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].fullName[0].SuffixTF[0]";
const CONTACT_INFO_SSNTAX_ID = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].ssnTaxIDTF[0]";
const CONTACT_INFO_DOB_TRUST_DATE = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].dobOrTrustDate[0]";
const CONTACT_INFO_ANOTHER_NAME = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].anotherName[0]";
const CONTACT_INFO_LEGAL_ADDRESS = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].legalAddressDetails[0].legalAddressTF[0]";
const CONTACT_INFO_CITY = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].legalAddressDetails[0].cityTF[0]";
const CONTACT_INFO_STATE = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].legalAddressDetails[0].stateTF[0]";
const CONTACT_INFO_ZIP_CODE = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].legalAddressDetails[0].zipCodeTF[0]";
const CONTACT_INFO_COUNTRY = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].legalAddressDetails[0].country[0]";
const CONTACT_INFO_MAILING_ADDRESS = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].mailingAddressDetails[0].mailingAddressTF[0]";
const CONTACT_INFO_MAILING_CITY = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].mailingAddressDetails[0].cityTF[0]";
const CONTACT_INFO_MAILING_STATE = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].mailingAddressDetails[0].stateTF[0]";
const CONTACT_INFO_MAILING_ZIP_CODE = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].mailingAddressDetails[0].zipCodeTF[0]";
const CONTACT_INFO_MAILING_COUNTRY = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].mailingAddressDetails[0].country[0]";
const CONTACT_INFO_HOME_PHONE = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].phoneNumbers[0].homePhoneTF[0]";
const CONTACT_INFO_MOBILE_PHONE = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].phoneNumbers[0].mobilePhoneTF[0]";
const CONTACT_INFO_WORK_PHONE = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].phoneNumbers[0].workPhoneTF[0]";
const CONTACT_INFO_EXTENSION = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].phoneNumbers[0].extensionTF[0]";
const CONTACT_INFO_EMAIL_ADDRESS = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].emailAddress[0].emailAddressTF[0]";
const CONTACT_INFO_MOTHER_MAIDEN_NAME = "clients[0].Form[0].Section2[0].border[0].ContactInfo_baseline[0].emailAddress[0].motherMaidenName[0]";
const COUNTRY_ID_CITIZENSHIP_USA = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].USA[0]";
const COUNTRY_ID_CITIZENSHIP_OTHER1 = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].other1[0]";
const COUNTRY_ID_CITIZENSHIP_COUNTRY1 = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].country1[0]";
const COUNTRY_ID_RESIDENCE_USA = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].citizenshipAndResidence[0].residence[0].checkResidence[0].USA[0]";
const COUNTRY_ID_RESIDENCE_OTHER = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].citizenshipAndResidence[0].residence[0].checkResidence[0].Other[0]";
const COUNTRY_ID_RESIDENCE_COUNTRY = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].citizenshipAndResidence[0].residence[0].checkResidence[0].Country[0]";
const PHOTO_ID_PASSPORT_NUMBER = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].photoIDInformation[0].IDNumber[0].IDNumber[0].passport[0]";
const PHOTO_ID_DRIVING_LICENSE_NUMBER = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].photoIDInformation[0].IDNumber[0].IDNumber[0].drivingLicense[0]";
const PHOTO_ID_GOVT_ID_NUMBER = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].photoIDInformation[0].IDNumber[0].IDNumber[0].govtID[0]";
const PHOTO_ID_GOVT_ID_TEXT_FIELD = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].photoIDInformation[0].govtIDTF[0]";
const PHOTO_ID_COUNTRY_OF_ISSUANCE = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].photoIDInformation[0].countryOfIssuance[0]";
const PHOTO_ID_STATE_OF_ISSUANCE_TEXT_FIELD = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].photoIDInformation[0].stateOfIssuanceTF[0]";
const PHOTO_ID_DATE_OF_ISSUE = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].photoIDInformation[0].dateOfIssue[0]";
const PHOTO_ID_DATE_OF_EXPIRY = "clients[0].Form[0].Section2[0].border[0].Country_ID_baseline[0].countryIdBaselineInfo[0].photoIDInformation[0].dateOfExpiry[0]";
const EMPLOYMENT_STATUS_EMPLOYED = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].employmentInfoCheckBoxes[0].employed[0]";
const EMPLOYMENT_STATUS_SELF_EMPLOYED = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].employmentInfoCheckBoxes[0].selfEmployed[0]";
const EMPLOYMENT_STATUS_RETIRED = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].employmentInfoCheckBoxes[0].retired[0]";
const EMPLOYMENT_STATUS_HOMEMAKER = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].employmentInfoCheckBoxes[0].homemaker[0]";
const EMPLOYMENT_STATUS_STUDENT = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].employmentInfoCheckBoxes[0].student[0]";
const EMPLOYMENT_STATUS_NOT_EMPLOYED = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].employmentInfoCheckBoxes[0].notEmployed[0]";
const OCCUPATION_BUSINESS_OWNER = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].businessOwner[0]";
const OCCUPATION_FINANCIAL_SERVICES = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].financialServices[0]";
const OCCUPATION_MILITARY = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].military[0]";
const OCCUPATION_CONSULTANT = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].consultant[0]";
const OCCUPATION_SENIOR_MGT = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].seniorMgt[0]";
const OCCUPATION_IT_PROFESSIONAL = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].ITProfessional[0]";
const OCCUPATION_EDUCATOR = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].educator[0]";
const OCCUPATION_OTHERS = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].others[0]";
const OCCUPATION_MEDICAL_PROFESSIONAL = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].medicalProfessional[0]";
const OCCUPATION_OTHER_PROFESSIONAL = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].otherProfessional[0]";
const OCCUPATION_SALES_MARKETING = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].salesMarketing[0]";
const OCCUPATION_OTHERS_INPUT = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].othersInput[0]";
const OCCUPATION_LEGAL_PROFESSIONAL = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].legalProfessional[0]";
const OCCUPATION_CLERICAL_ADMINISTRATIVE = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].clericalAdministrativeServices[0]";
const OCCUPATION_US_GOVT = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].USGovt[0]";
const OCCUPATION_ACCOUNT_PROFESSIONAL = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].accountProfessional[0]";
const OCCUPATION_FOREIGN_GOVT = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].foreignGovt[0]";
const OCCUPATION_TRADE_SERVICE = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].occupation[0].col1[0].tradeService[0]";
const BUSINESS_DETAILS_EMPLOYER_NAME = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].businessAddressDetails[0].employerName[0]";
const BUSINESS_DETAILS_STREET = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].businessAddressDetails[0].businessStreet[0]";
const BUSINESS_DETAILS_CITY = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].businessAddressDetails[0].City[0]";
const BUSINESS_DETAILS_STATE_PROVINCE = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].businessAddressDetails[0].StateorProvince[0]";
const BUSINESS_DETAILS_ZIP_POSTAL_CODE = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].businessAddressDetails[0].ZiporPostalCode[0]";
const BUSINESS_DETAILS_COUNTRY = "clients[0].Form[0].Section2[0].border[0].EmploymentOccupation[0].Occupation[0].businessAddressDetails[0].country[0]";
const FINRA_RULE_EMPLOYMENT_QUESTION_NO = "clients[0].Form[0].Section2[0].border[0].FINRARuleemploymentQues_MA_SA[0].border[0].FINRARuleEmployemntQues_MA_SA[0].Checkboxes[0].No[0]";
const FINRA_RULE_EMPLOYMENT_QUESTION_YES = "clients[0].Form[0].Section2[0].border[0].FINRARuleemploymentQues_MA_SA[0].border[0].FINRARuleEmployemntQues_MA_SA[0].Checkboxes[0].Yes[0]";
const FINRA_RULE_EMPLOYMENT_QUESTION_LIST_OF_COMPANY = "clients[0].Form[0].Section2[0].border[0].FINRARuleemploymentQues_MA_SA[0].border[0].FINRARuleEmployemntQues_MA_SA[0].Checkboxes[0].ListOfCompany[0]";
const FINRA_RULE_10_PERCENT_SHAREHOLDER_NO = "clients[0].Form[0].Section2[0].border[0].FINRA_Rule_10_percent_Shareholder[0].#subform[0].Checkboxes[0].No[0]";
const FINRA_RULE_10_PERCENT_SHAREHOLDER_YES = "clients[0].Form[0].Section2[0].border[0].FINRA_Rule_10_percent_Shareholder[0].#subform[0].Checkboxes[0].Yes[0]";
const FINRA_RULE_10_PERCENT_SHAREHOLDER_COMPANY_NAME = "clients[0].Form[0].Section2[0].border[0].FINRA_Rule_10_percent_Shareholder[0].#subform[0].companyName[0]";
const FINRA_RULE_10_PERCENT_SHAREHOLDER_TRADING_SYMBOL = "clients[0].Form[0].Section2[0].border[0].FINRA_Rule_10_percent_Shareholder[0].#subform[0].tradingSymbol[0]";
const TRUSTED_CONTACT_1_FIRST_NAME = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].fullName[0].FirstName[0]";
const TRUSTED_CONTACT_1_MIDDLE_NAME = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].fullName[0].MiddleName[0]";
const TRUSTED_CONTACT_1_LAST_NAME = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].fullName[0].LastName[0]";
const TRUSTED_CONTACT_1_SUFFIX = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].fullName[0].Suffix[0]";
const TRUSTED_CONTACT_1_RELATIONSHIP_SPOUSE = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].Relationship[0].Checkboxes[0].Spouse[0]";
const TRUSTED_CONTACT_1_RELATIONSHIP_PARTNER = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].Relationship[0].Checkboxes[0].Partner[0]";
const TRUSTED_CONTACT_1_RELATIONSHIP_CHILD = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].Relationship[0].Checkboxes[0].Child[0]";
const TRUSTED_CONTACT_1_RELATIONSHIP_PARENT = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].Relationship[0].Checkboxes[0].Parent[0]";
const TRUSTED_CONTACT_1_RELATIONSHIP_SIBLING = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].Relationship[0].Checkboxes[0].Sibling[0]";
const TRUSTED_CONTACT_1_RELATIONSHIP_FRIEND = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].Relationship[0].Checkboxes[0].Friend[0]";
const TRUSTED_CONTACT_1_RELATIONSHIP_OTHER = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].Relationship[0].Checkboxes[0].Other[0]";
const TRUSTED_CONTACT_1_MAILING_ADDRESS = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].MailingAddress[0]";
const TRUSTED_CONTACT_1_CITY = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].City[0]";
const TRUSTED_CONTACT_1_STATE = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].State[0]";
const TRUSTED_CONTACT_1_ZIP_CODE = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].ZipCode[0]";
const TRUSTED_CONTACT_1_COUNTRY = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].Country[0]";
const TRUSTED_CONTACT_1_HOME_PHONE = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].HomePhone[0]";
const TRUSTED_CONTACT_1_MOBILE_PHONE = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].MobilePhone[0]";
const TRUSTED_CONTACT_1_EMAIL = "clients[0].Form[0].Section2[0].border[0].person1[0].Trusted_Contact_Information1[0].Email[0]";
const TRUSTED_CONTACT_2_FIRST_NAME = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].fullName[0].FirstName[0]";
const TRUSTED_CONTACT_2_MIDDLE_NAME = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].fullName[0].MiddleName[0]";
const TRUSTED_CONTACT_2_LAST_NAME = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].fullName[0].LastName[0]";
const TRUSTED_CONTACT_2_SUFFIX = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].fullName[0].Suffix[0]";
const TRUSTED_CONTACT_2_RELATIONSHIP_SPOUSE = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].Relationship[0].Checkboxes[0].Spouse[0]";
const TRUSTED_CONTACT_2_RELATIONSHIP_PARTNER = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].Relationship[0].Checkboxes[0].Partner[0]";
const TRUSTED_CONTACT_2_RELATIONSHIP_CHILD = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].Relationship[0].Checkboxes[0].Child[0]";
const TRUSTED_CONTACT_2_RELATIONSHIP_PARENT = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].Relationship[0].Checkboxes[0].Parent[0]";
const TRUSTED_CONTACT_2_RELATIONSHIP_SIBLING = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].Relationship[0].Checkboxes[0].Sibling[0]";
const TRUSTED_CONTACT_2_RELATIONSHIP_FRIEND = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].Relationship[0].Checkboxes[0].Friend[0]";
const TRUSTED_CONTACT_2_RELATIONSHIP_OTHER = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].Relationship[0].Checkboxes[0].Other[0]";
const TRUSTED_CONTACT_2_MAILING_ADDRESS = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].MailingAddress[0]";
const TRUSTED_CONTACT_2_CITY = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].City[0]";
const TRUSTED_CONTACT_2_STATE = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].State[0]";
const TRUSTED_CONTACT_2_ZIP_CODE = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].ZipCode[0]";
const TRUSTED_CONTACT_2_COUNTRY = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].Country[0]";
const TRUSTED_CONTACT_2_HOME_PHONE = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].HomePhone[0]";
const TRUSTED_CONTACT_2_MOBILE_PHONE = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].MobilePhone[0]";
const TRUSTED_CONTACT_2_EMAIL = "clients[0].Form[0].Section2[0].border[0].person2[0].Trusted_Contact_Information[0].Email[0]";

const AccountHolderInformation = forwardRef<any, StepComponentProps>(({userFormId, title, ...props}, ref) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = 'gray.500';

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [suffix, setSuffix] = useState('');
  const [ssn, setSsn] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(moment().subtract(20, 'years').startOf('year').toDate());
  const [alias, setAlias] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [homeCity, setHomeCity] = useState('');
  const [homeState, setHomeState] = useState('');
  const [homePostalCode, setHomePostalCode] = useState('');
  const [homeCountry, setHomeCountry] = useState('US');
  const [mailingAddress, setMailingAddress] = useState('');
  const [mailingCity, setMailingCity] = useState('');
  const [mailingState, setMailingState] = useState('');
  const [mailingPostalCode, setMailingPostalCode] = useState('');
  const [mailingCountry, setMailingCountry] = useState('US');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [workNumber, setWorkNumber] = useState('');
  const [extension, setExtension] = useState('');
  const [email, setEmail] = useState('');
  const [motherName, setMotherName] = useState('');
  const [citizenShip, setCitizenShip] = useState('USA');
  const [otherCitizenShip, setOtherCitizenShip] = useState('');
  const [residence, setResidence] = useState('USA');
  const [otherResidence, setOtherResidence] = useState('');
  const [idType, setIdType] = useState<'PASSPORT' | 'DL' | 'GID' | ''>('');
  const [idNumber, setIdNumber] = useState('');
  const [countryIssuance, setCountryIssuance] = useState('');
  const [stateIssuance, setStateIssuance] = useState('');
  const [issueDate, setIssueDate] = useState(moment().subtract(20, 'years').startOf('year').toDate());
  const [expireDate, setExpireDate] = useState(new Date());
  const [employmentInformation, setEmploymentInformation] = useState('Employed');
  const [occupation, setOccupation] = useState('');
  const [otherOccupation, setOtherOccupation] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [businessCity, setBusinessCity] = useState('');
  const [businessState, setBusinessState] = useState('');
  const [businessPostalCode, setBusinessPostalCode] = useState('');
  const [businessCountry, setBusinessCountry] = useState('US');
  const [employedFINRA, setEmployedFINRA] = useState('NO');
  const [employedCompanyList, setEmployedCompanyList] = useState('');
  const [shareHolder10Percent, setShareHolder10Percent] = useState('NO');
  const [shareHolderCompanyName, setShareHolderCompanyName] = useState('');
  const [shareHolderTradingSymbol, setShareHolderTradingSymbol] = useState('');

  const initialContactState = {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    relationship: '',
    mailingAddress: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    telephoneNumber: '',
    mobileNumber: '',
    email: '',
  };

  const [tc1, setTc1] = useState({...initialContactState});
  const [tc2, setTc2] = useState({...initialContactState});

  const handleTcInputChange = (contactIndex: number, fieldName: string, value: string) => {
    if (contactIndex === 1) {
      setTc1((prev) => ({...prev, [fieldName]: value}));
    } else if (contactIndex === 2) {
      setTc2((prev) => ({...prev, [fieldName]: value}));
    }
  };

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    ssn: '',
    dateOfBirth: '',
    homeAddress: '',
    homeCity: '',
    homeState: '',
    homePostalCode: '',
    homeCountry: '',
    email: '',
  });


  const validateEmail = (email: string) => {
    // Add your email validation logic here
    return /^(.+)@(.+)\.(.+)$/.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: validateEmail(newEmail) ? '' : 'Invalid email address.',
    }));
  };


  useEffect(() => {
    fetchFormValues();
  }, []);

  const fetchFormValues = () => {
    setIsLoading(true);
    getFormFieldValues(userFormId)
      .then(({fieldValues}) => {
        const contactInfoFirstName = getValueByFieldName(fieldValues, CONTACT_INFO_FIRST_NAME);
        const contactInfoMiddleName = getValueByFieldName(fieldValues, CONTACT_INFO_MIDDLE_NAME);
        const contactInfoLastName = getValueByFieldName(fieldValues, CONTACT_INFO_LAST_NAME);
        const contactInfoSuffix = getValueByFieldName(fieldValues, CONTACT_INFO_SUFFIX);
        const contactInfoSSNTAXID = getValueByFieldName(fieldValues, CONTACT_INFO_SSNTAX_ID);
        const contactInfoDOBTrustDate = getValueByFieldName(fieldValues, CONTACT_INFO_DOB_TRUST_DATE);
        const contactInfoAnotherName = getValueByFieldName(fieldValues, CONTACT_INFO_ANOTHER_NAME);
        const contactInfoLegalAddress = getValueByFieldName(fieldValues, CONTACT_INFO_LEGAL_ADDRESS);
        const contactInfoCity = getValueByFieldName(fieldValues, CONTACT_INFO_CITY);
        const contactInfoState = getValueByFieldName(fieldValues, CONTACT_INFO_STATE);
        const contactInfoZipCode = getValueByFieldName(fieldValues, CONTACT_INFO_ZIP_CODE);
        const contactInfoCountry = getValueByFieldName(fieldValues, CONTACT_INFO_COUNTRY);
        const contactInfoMailingAddress = getValueByFieldName(fieldValues, CONTACT_INFO_MAILING_ADDRESS);
        const contactInfoMailingCity = getValueByFieldName(fieldValues, CONTACT_INFO_MAILING_CITY);
        const contactInfoMailingState = getValueByFieldName(fieldValues, CONTACT_INFO_MAILING_STATE);
        const contactInfoMailingZipCode = getValueByFieldName(fieldValues, CONTACT_INFO_MAILING_ZIP_CODE);
        const contactInfoMailingCountry = getValueByFieldName(fieldValues, CONTACT_INFO_MAILING_COUNTRY);
        const contactInfoHomePhone = getValueByFieldName(fieldValues, CONTACT_INFO_HOME_PHONE);
        const contactInfoMobilePhone = getValueByFieldName(fieldValues, CONTACT_INFO_MOBILE_PHONE);
        const contactInfoWorkPhone = getValueByFieldName(fieldValues, CONTACT_INFO_WORK_PHONE);
        const contactInfoExtension = getValueByFieldName(fieldValues, CONTACT_INFO_EXTENSION);
        const contactInfoEmailAddress = getValueByFieldName(fieldValues, CONTACT_INFO_EMAIL_ADDRESS);
        const contactInfoMotherMaidenName = getValueByFieldName(fieldValues, CONTACT_INFO_MOTHER_MAIDEN_NAME);

        setFirstName(contactInfoFirstName);
        setMiddleName(contactInfoMiddleName);
        setLastName(contactInfoLastName);
        setSuffix(contactInfoSuffix);
        setSsn(contactInfoSSNTAXID);
        setDateOfBirth(contactInfoDOBTrustDate ? moment(contactInfoDOBTrustDate).toDate() : moment().subtract(20, 'years').startOf('year').toDate());
        setAlias(contactInfoAnotherName);
        setHomeAddress(contactInfoLegalAddress);
        setHomeCity(contactInfoCity);
        setHomeState(contactInfoState);
        setHomePostalCode(contactInfoZipCode);
        setHomeCountry(contactInfoCountry || 'US');
        setMailingAddress(contactInfoMailingAddress);
        setMailingCity(contactInfoMailingCity);
        setMailingState(contactInfoMailingState);
        setMailingPostalCode(contactInfoMailingZipCode);
        setMailingCountry(contactInfoMailingCountry || 'US');
        setTelephoneNumber(contactInfoHomePhone);
        setMobileNumber(contactInfoMobilePhone);
        setWorkNumber(contactInfoWorkPhone);
        setExtension(contactInfoExtension);
        setEmail(contactInfoEmailAddress);
        setMotherName(contactInfoMotherMaidenName);

        const countryIdCitizenshipUsaChecked = getValueByFieldName(fieldValues, COUNTRY_ID_CITIZENSHIP_USA) === "checked";
        const countryIdCitizenshipOther1Checked = getValueByFieldName(fieldValues, COUNTRY_ID_CITIZENSHIP_OTHER1) === "checked";
        const countryIdCitizenshipCountry1 = getValueByFieldName(fieldValues, COUNTRY_ID_CITIZENSHIP_COUNTRY1);
        let citizenOption = '';
        if (countryIdCitizenshipUsaChecked) {
          citizenOption = 'USA';
        } else if (countryIdCitizenshipOther1Checked) {
          citizenOption = 'OTHER';
        }

        setCitizenShip(citizenOption);
        setOtherCitizenShip(countryIdCitizenshipCountry1);

        const countryIdResidenceUsaChecked = getValueByFieldName(fieldValues, COUNTRY_ID_RESIDENCE_USA) === "checked";
        const countryIdResidenceOtherChecked = getValueByFieldName(fieldValues, COUNTRY_ID_RESIDENCE_OTHER) === "checked";
        const countryIdResidenceCountry = getValueByFieldName(fieldValues, COUNTRY_ID_RESIDENCE_COUNTRY);
        let residenceOption = '';
        if (countryIdResidenceUsaChecked) {
          residenceOption = 'USA';
        } else if (countryIdResidenceOtherChecked) {
          residenceOption = 'OTHER';
        }
        setResidence(residenceOption);
        setOtherResidence(countryIdResidenceCountry);

        const photoIdPassportNumberChecked = getValueByFieldName(fieldValues, PHOTO_ID_PASSPORT_NUMBER) === "checked";
        const photoIdDrivingLicenseNumberChecked = getValueByFieldName(fieldValues, PHOTO_ID_DRIVING_LICENSE_NUMBER) === "checked";
        const photoIdGovtIdNumberChecked = getValueByFieldName(fieldValues, PHOTO_ID_GOVT_ID_NUMBER) === "checked";
        const photoIdGovtIdTextField = getValueByFieldName(fieldValues, PHOTO_ID_GOVT_ID_TEXT_FIELD);
        const photoIdCountryOfIssuance = getValueByFieldName(fieldValues, PHOTO_ID_COUNTRY_OF_ISSUANCE);
        const photoIdStateOfIssuanceTextField = getValueByFieldName(fieldValues, PHOTO_ID_STATE_OF_ISSUANCE_TEXT_FIELD);
        const photoIdDateOfIssue = getValueByFieldName(fieldValues, PHOTO_ID_DATE_OF_ISSUE);
        const photoIdDateOfExpiry = getValueByFieldName(fieldValues, PHOTO_ID_DATE_OF_EXPIRY);

        let idTypeOption: "" | "PASSPORT" | "DL" | "GID" = '';
        if (photoIdPassportNumberChecked) {
          idTypeOption = 'PASSPORT';
        } else if (photoIdDrivingLicenseNumberChecked) {
          idTypeOption = 'DL';
        } else if (photoIdGovtIdNumberChecked) {
          idTypeOption = 'GID';
        }
        setIdType(idTypeOption);
        setIdNumber(photoIdGovtIdTextField);
        setCountryIssuance(photoIdCountryOfIssuance);
        setStateIssuance(photoIdStateOfIssuanceTextField);
        setIssueDate(photoIdDateOfIssue ? moment(photoIdDateOfIssue).toDate() : moment().subtract(20, 'years').startOf('year').toDate());
        setExpireDate(photoIdDateOfExpiry ? moment(photoIdDateOfExpiry).toDate() : new Date());

        const employmentStatusEmployed = getValueByFieldName(fieldValues, EMPLOYMENT_STATUS_EMPLOYED) === "checked";
        const employmentStatusSelfEmployed = getValueByFieldName(fieldValues, EMPLOYMENT_STATUS_SELF_EMPLOYED) === "checked";
        const employmentStatusRetired = getValueByFieldName(fieldValues, EMPLOYMENT_STATUS_RETIRED) === "checked";
        const employmentStatusHomemaker = getValueByFieldName(fieldValues, EMPLOYMENT_STATUS_HOMEMAKER) === "checked";
        const employmentStatusStudent = getValueByFieldName(fieldValues, EMPLOYMENT_STATUS_STUDENT) === "checked";
        const employmentStatusNotEmployed = getValueByFieldName(fieldValues, EMPLOYMENT_STATUS_NOT_EMPLOYED) === "checked";

        let employmentInformationOption = '';
        if (employmentStatusEmployed) {
          employmentInformationOption = 'Employed';
        } else if (employmentStatusSelfEmployed) {
          employmentInformationOption = 'SelfEmployed';
        } else if (employmentStatusRetired) {
          employmentInformationOption = 'Retired';
        } else if (employmentStatusHomemaker) {
          employmentInformationOption = 'Homemaker';
        } else if (employmentStatusStudent) {
          employmentInformationOption = 'Student';
        } else if (employmentStatusNotEmployed) {
          employmentInformationOption = 'NotEmployed';
        }

        setEmploymentInformation(employmentInformationOption);

        const occupationBusinessOwner = getValueByFieldName(fieldValues, OCCUPATION_BUSINESS_OWNER) === "checked";
        const occupationFinancialServices = getValueByFieldName(fieldValues, OCCUPATION_FINANCIAL_SERVICES) === "checked";
        const occupationMilitary = getValueByFieldName(fieldValues, OCCUPATION_MILITARY) === "checked";
        const occupationConsultant = getValueByFieldName(fieldValues, OCCUPATION_CONSULTANT) === "checked";
        const occupationSeniorMgt = getValueByFieldName(fieldValues, OCCUPATION_SENIOR_MGT) === "checked";
        const occupationItProfessional = getValueByFieldName(fieldValues, OCCUPATION_IT_PROFESSIONAL) === "checked";
        const occupationEducator = getValueByFieldName(fieldValues, OCCUPATION_EDUCATOR) === "checked";
        const occupationMedicalProfessional = getValueByFieldName(fieldValues, OCCUPATION_MEDICAL_PROFESSIONAL) === "checked";
        const occupationOtherProfessional = getValueByFieldName(fieldValues, OCCUPATION_OTHER_PROFESSIONAL) === "checked";
        const occupationSalesMarketing = getValueByFieldName(fieldValues, OCCUPATION_SALES_MARKETING) === "checked";
        const occupationLegalProfessional = getValueByFieldName(fieldValues, OCCUPATION_LEGAL_PROFESSIONAL) === "checked";
        const occupationClericalAdministrative = getValueByFieldName(fieldValues, OCCUPATION_CLERICAL_ADMINISTRATIVE) === "checked";
        const occupationUsGovt = getValueByFieldName(fieldValues, OCCUPATION_US_GOVT) === "checked";
        const occupationAccountProfessional = getValueByFieldName(fieldValues, OCCUPATION_ACCOUNT_PROFESSIONAL) === "checked";
        const occupationForeignGovt = getValueByFieldName(fieldValues, OCCUPATION_FOREIGN_GOVT) === "checked";
        const occupationTradeService = getValueByFieldName(fieldValues, OCCUPATION_TRADE_SERVICE) === "checked";
        const occupationOthers = getValueByFieldName(fieldValues, OCCUPATION_OTHERS) === "checked";
        const occupationOthersInput = getValueByFieldName(fieldValues, OCCUPATION_OTHERS_INPUT);

        let occupationOption = '';
        if (occupationBusinessOwner) {
          occupationOption = 'BusinessOwner';
        } else if (occupationFinancialServices) {
          occupationOption = 'FinancialServices';
        } else if (occupationMilitary) {
          occupationOption = 'Military';
        } else if (occupationConsultant) {
          occupationOption = 'Consultant';
        } else if (occupationSeniorMgt) {
          occupationOption = 'SeniorMgt';
        } else if (occupationItProfessional) {
          occupationOption = 'ItProfessional';
        } else if (occupationEducator) {
          occupationOption = 'Educator';
        } else if (occupationMedicalProfessional) {
          occupationOption = 'MedicalProfessional';
        } else if (occupationOtherProfessional) {
          occupationOption = 'OtherProfessional';
        } else if (occupationSalesMarketing) {
          occupationOption = 'SalesMarketing';
        } else if (occupationLegalProfessional) {
          occupationOption = 'LegalProfessional';
        } else if (occupationClericalAdministrative) {
          occupationOption = 'ClericalAdministrative';
        } else if (occupationUsGovt) {
          occupationOption = 'UsGovt';
        } else if (occupationAccountProfessional) {
          occupationOption = 'AccountProfessional';
        } else if (occupationForeignGovt) {
          occupationOption = 'ForeignGovt';
        } else if (occupationTradeService) {
          occupationOption = 'TradeService';
        } else if (occupationOthers) {
          occupationOption = 'Other';
        }
        setOccupation(occupationOption);
        setOtherOccupation(occupationOthersInput);

        const businessDetailsEmployerName = getValueByFieldName(fieldValues, BUSINESS_DETAILS_EMPLOYER_NAME);
        const businessDetailsStreet = getValueByFieldName(fieldValues, BUSINESS_DETAILS_STREET);
        const businessDetailsCity = getValueByFieldName(fieldValues, BUSINESS_DETAILS_CITY);
        const businessDetailsStateProvince = getValueByFieldName(fieldValues, BUSINESS_DETAILS_STATE_PROVINCE);
        const businessDetailsZipPostalCode = getValueByFieldName(fieldValues, BUSINESS_DETAILS_ZIP_POSTAL_CODE);
        const businessDetailsCountry = getValueByFieldName(fieldValues, BUSINESS_DETAILS_COUNTRY);

        setBusinessName(businessDetailsEmployerName);
        setBusinessAddress(businessDetailsStreet);
        setBusinessCity(businessDetailsCity);
        setBusinessState(businessDetailsStateProvince);
        setBusinessPostalCode(businessDetailsZipPostalCode);
        setBusinessCountry(businessDetailsCountry || 'US');

        const finraRuleEmploymentQuestionNo = getValueByFieldName(fieldValues, FINRA_RULE_EMPLOYMENT_QUESTION_NO) === "checked";
        const finraRuleEmploymentQuestionYes = getValueByFieldName(fieldValues, FINRA_RULE_EMPLOYMENT_QUESTION_YES) === "checked";
        const finraRuleEmploymentQuestionListOfCompany = getValueByFieldName(fieldValues, FINRA_RULE_EMPLOYMENT_QUESTION_LIST_OF_COMPANY);
        const finraRule10PercentShareholderNo = getValueByFieldName(fieldValues, FINRA_RULE_10_PERCENT_SHAREHOLDER_NO) === "checked";
        const finraRule10PercentShareholderYes = getValueByFieldName(fieldValues, FINRA_RULE_10_PERCENT_SHAREHOLDER_YES) === "checked";
        const finraRule10PercentShareholderCompanyName = getValueByFieldName(fieldValues, FINRA_RULE_10_PERCENT_SHAREHOLDER_COMPANY_NAME);
        const finraRule10PercentShareholderTradingSymbol = getValueByFieldName(fieldValues, FINRA_RULE_10_PERCENT_SHAREHOLDER_TRADING_SYMBOL);

        let employedFINRAOption = '';
        if (finraRuleEmploymentQuestionNo) {
          employedFINRAOption = 'NO';
        } else if (finraRuleEmploymentQuestionYes) {
          employedFINRAOption = 'YES';
        }
        setEmployedFINRA(employedFINRAOption);
        setEmployedCompanyList(finraRuleEmploymentQuestionListOfCompany);
        let shareHolder10Percent = '';
        if (finraRule10PercentShareholderNo) {
          shareHolder10Percent = 'NO';
        } else if (finraRule10PercentShareholderYes) {
          shareHolder10Percent = 'YES';
        }
        setShareHolder10Percent(shareHolder10Percent);
        setShareHolderCompanyName(finraRule10PercentShareholderCompanyName);
        setShareHolderTradingSymbol(finraRule10PercentShareholderTradingSymbol);

        const trustedContact1FirstName = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_FIRST_NAME);
        const trustedContact1MiddleName = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_MIDDLE_NAME);
        const trustedContact1LastName = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_LAST_NAME);
        const trustedContact1Suffix = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_SUFFIX);
        const trustedContact1RelationshipSpouse = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_RELATIONSHIP_SPOUSE);
        const trustedContact1RelationshipPartner = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_RELATIONSHIP_PARTNER);
        const trustedContact1RelationshipChild = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_RELATIONSHIP_CHILD);
        const trustedContact1RelationshipParent = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_RELATIONSHIP_PARENT);
        const trustedContact1RelationshipSibling = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_RELATIONSHIP_SIBLING);
        const trustedContact1RelationshipFriend = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_RELATIONSHIP_FRIEND);
        const trustedContact1RelationshipOther = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_RELATIONSHIP_OTHER);
        const trustedContact1MailingAddress = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_MAILING_ADDRESS);
        const trustedContact1City = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_CITY);
        const trustedContact1State = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_STATE);
        const trustedContact1ZipCode = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_ZIP_CODE);
        const trustedContact1Country = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_COUNTRY);
        const trustedContact1HomePhone = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_HOME_PHONE);
        const trustedContact1MobilePhone = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_MOBILE_PHONE);
        const trustedContact1Email = getValueByFieldName(fieldValues, TRUSTED_CONTACT_1_EMAIL);

        let trustedContact1RelationshipOption = '';
        if (trustedContact1RelationshipSpouse) {
          trustedContact1RelationshipOption = 'Spouse';
        } else if (trustedContact1RelationshipPartner) {
          trustedContact1RelationshipOption = 'Partner';
        } else if (trustedContact1RelationshipChild) {
          trustedContact1RelationshipOption = 'Child';
        } else if (trustedContact1RelationshipParent) {
          trustedContact1RelationshipOption = 'Parent';
        } else if (trustedContact1RelationshipSibling) {
          trustedContact1RelationshipOption = 'Sibling';
        } else if (trustedContact1RelationshipFriend) {
          trustedContact1RelationshipOption = 'Friend';
        } else if (trustedContact1RelationshipOther) {
          trustedContact1RelationshipOption = 'Other';
        }

        setTc1({
          firstName: trustedContact1FirstName,
          middleName: trustedContact1MiddleName,
          lastName: trustedContact1LastName,
          suffix: trustedContact1Suffix,
          relationship: trustedContact1RelationshipOption,
          mailingAddress: trustedContact1MailingAddress,
          city: trustedContact1City,
          state: trustedContact1State,
          postalCode: trustedContact1ZipCode,
          country: trustedContact1Country || 'US',
          telephoneNumber: trustedContact1HomePhone,
          mobileNumber: trustedContact1MobilePhone,
          email: trustedContact1Email,
        })

        const trustedContact2FirstName = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_FIRST_NAME);
        const trustedContact2MiddleName = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_MIDDLE_NAME);
        const trustedContact2LastName = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_LAST_NAME);
        const trustedContact2Suffix = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_SUFFIX);
        const trustedContact2RelationshipSpouse = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_RELATIONSHIP_SPOUSE);
        const trustedContact2RelationshipPartner = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_RELATIONSHIP_PARTNER);
        const trustedContact2RelationshipChild = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_RELATIONSHIP_CHILD);
        const trustedContact2RelationshipParent = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_RELATIONSHIP_PARENT);
        const trustedContact2RelationshipSibling = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_RELATIONSHIP_SIBLING);
        const trustedContact2RelationshipFriend = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_RELATIONSHIP_FRIEND);
        const trustedContact2RelationshipOther = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_RELATIONSHIP_OTHER);
        const trustedContact2MailingAddress = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_MAILING_ADDRESS);
        const trustedContact2City = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_CITY);
        const trustedContact2State = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_STATE);
        const trustedContact2ZipCode = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_ZIP_CODE);
        const trustedContact2Country = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_COUNTRY);
        const trustedContact2HomePhone = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_HOME_PHONE);
        const trustedContact2MobilePhone = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_MOBILE_PHONE);
        const trustedContact2Email = getValueByFieldName(fieldValues, TRUSTED_CONTACT_2_EMAIL);

        let trustedContact2RelationshipOption = '';
        if (trustedContact2RelationshipSpouse) {
          trustedContact2RelationshipOption = 'Spouse';
        } else if (trustedContact2RelationshipPartner) {
          trustedContact2RelationshipOption = 'Partner';
        } else if (trustedContact2RelationshipChild) {
          trustedContact2RelationshipOption = 'Child';
        } else if (trustedContact2RelationshipParent) {
          trustedContact2RelationshipOption = 'Parent';
        } else if (trustedContact2RelationshipSibling) {
          trustedContact2RelationshipOption = 'Sibling';
        } else if (trustedContact2RelationshipFriend) {
          trustedContact2RelationshipOption = 'Friend';
        } else if (trustedContact2RelationshipOther) {
          trustedContact2RelationshipOption = 'Other';
        }

        setTc2({
          firstName: trustedContact2FirstName,
          middleName: trustedContact2MiddleName,
          lastName: trustedContact2LastName,
          suffix: trustedContact2Suffix,
          relationship: trustedContact2RelationshipOption,
          mailingAddress: trustedContact2MailingAddress,
          city: trustedContact2City,
          state: trustedContact2State,
          postalCode: trustedContact2ZipCode,
          country: trustedContact2Country || 'US',
          telephoneNumber: trustedContact2HomePhone,
          mobileNumber: trustedContact2MobilePhone,
          email: trustedContact2Email,
        })

      }).catch(err => {
      console.error(err.message);
    })
      .finally(() => setIsLoading(false))
  }

  const updateData = async () => {
    await updateFormFieldValues([
      {
        [CONTACT_INFO_FIRST_NAME]: firstName,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_MIDDLE_NAME]: middleName,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_LAST_NAME]: lastName,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_SUFFIX]: suffix,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_SSNTAX_ID]: ssn,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_DOB_TRUST_DATE]: dateOfBirth ? moment(dateOfBirth).format('MM/DD/YYYY') : '',
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_ANOTHER_NAME]: alias,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_LEGAL_ADDRESS]: homeAddress,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_CITY]: homeCity,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_STATE]: homeState,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_ZIP_CODE]: homePostalCode,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_COUNTRY]: homeCountry,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_MAILING_ADDRESS]: mailingAddress,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_MAILING_CITY]: mailingCity,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_MAILING_STATE]: mailingState,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_HOME_PHONE]: telephoneNumber,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_MOBILE_PHONE]: mobileNumber,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_WORK_PHONE]: workNumber,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_EXTENSION]: extension,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_EMAIL_ADDRESS]: email,
        fieldType: 'PDFTextField'
      },
      {
        [CONTACT_INFO_MOTHER_MAIDEN_NAME]: motherName,
        fieldType: 'PDFTextField'
      },
      {
        [COUNTRY_ID_CITIZENSHIP_USA]: citizenShip === 'USA' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [COUNTRY_ID_CITIZENSHIP_OTHER1]: citizenShip ? 'OTHER' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [COUNTRY_ID_CITIZENSHIP_COUNTRY1]: otherCitizenShip,
        fieldType: 'PDFTextField'
      },
      {
        [COUNTRY_ID_RESIDENCE_USA]: residence === 'USA' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [COUNTRY_ID_RESIDENCE_OTHER]: residence === 'OTHER' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [COUNTRY_ID_RESIDENCE_COUNTRY]: otherResidence,
        fieldType: 'PDFTextField'
      },
      {
        [PHOTO_ID_PASSPORT_NUMBER]: idType === "PASSPORT" ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [PHOTO_ID_DRIVING_LICENSE_NUMBER]: idType === "DL" ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [PHOTO_ID_GOVT_ID_NUMBER]: idType === "GID" ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [PHOTO_ID_GOVT_ID_TEXT_FIELD]: idNumber,
        fieldType: 'PDFTextField'
      },
      {
        [PHOTO_ID_COUNTRY_OF_ISSUANCE]: countryIssuance,
        fieldType: 'PDFTextField'
      },
      {
        [PHOTO_ID_STATE_OF_ISSUANCE_TEXT_FIELD]: stateIssuance,
        fieldType: 'PDFTextField'
      },
      {
        [PHOTO_ID_DATE_OF_ISSUE]: issueDate ? moment(issueDate).format('MM/DD/YYYY') : '',
        fieldType: 'PDFTextField'
      },
      {
        [PHOTO_ID_DATE_OF_EXPIRY]: expireDate ? moment(expireDate).format('MM/DD/YYYY') : '',
        fieldType: 'PDFTextField'
      },
      {
        [EMPLOYMENT_STATUS_EMPLOYED]: employmentInformation === 'Employed' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [EMPLOYMENT_STATUS_SELF_EMPLOYED]: employmentInformation === 'SelfEmployed' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [EMPLOYMENT_STATUS_RETIRED]: employmentInformation === 'Retired' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [EMPLOYMENT_STATUS_HOMEMAKER]: employmentInformation === 'Homemaker' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [EMPLOYMENT_STATUS_STUDENT]: employmentInformation === 'Student' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [EMPLOYMENT_STATUS_NOT_EMPLOYED]: employmentInformation === 'NotEmployed' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_BUSINESS_OWNER]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'BusinessOwner' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_FINANCIAL_SERVICES]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'FinancialServices' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_MILITARY]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'Military' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_CONSULTANT]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'Consultant' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_SENIOR_MGT]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'SeniorMgt' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_IT_PROFESSIONAL]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'ItProfessional' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_EDUCATOR]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'Educator' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_OTHERS]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'Other' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_MEDICAL_PROFESSIONAL]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'MedicalProfessional' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_OTHER_PROFESSIONAL]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'OtherProfessional' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_SALES_MARKETING]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'SalesMarketing' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_OTHERS_INPUT]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'Other' ? otherOccupation : '',
        fieldType: 'PDFTextField'
      },
      {
        [OCCUPATION_LEGAL_PROFESSIONAL]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'LegalProfessional' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_CLERICAL_ADMINISTRATIVE]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'ClericalAdministrative' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_US_GOVT]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'UsGovt' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_ACCOUNT_PROFESSIONAL]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'AccountProfessional' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_FOREIGN_GOVT]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'ForeignGovt' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_TRADE_SERVICE]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'TradeService' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_OTHER_PROFESSIONAL]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'OtherProfessional' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OCCUPATION_SALES_MARKETING]: (employmentInformation === 'Employed' || employmentInformation === 'SelfEmployed') && occupation === 'SalesMarketing' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [BUSINESS_DETAILS_EMPLOYER_NAME]: businessName,
        fieldType: 'PDFTextField'
      },
      {
        [BUSINESS_DETAILS_STREET]: businessAddress,
        fieldType: 'PDFTextField'
      },
      {
        [BUSINESS_DETAILS_CITY]: businessCity,
        fieldType: 'PDFTextField'
      },
      {
        [BUSINESS_DETAILS_STATE_PROVINCE]: businessState,
        fieldType: 'PDFTextField'
      },
      {
        [BUSINESS_DETAILS_ZIP_POSTAL_CODE]: businessPostalCode,
        fieldType: 'PDFTextField'
      },
      {
        [BUSINESS_DETAILS_COUNTRY]: businessCountry,
        fieldType: 'PDFTextField'
      },
      {
        [FINRA_RULE_EMPLOYMENT_QUESTION_NO]: employedFINRA === 'NO' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [FINRA_RULE_EMPLOYMENT_QUESTION_YES]: employedFINRA === 'YES' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [FINRA_RULE_EMPLOYMENT_QUESTION_LIST_OF_COMPANY]: employedCompanyList,
        fieldType: 'PDFTextField'
      },
      {
        [FINRA_RULE_10_PERCENT_SHAREHOLDER_NO]: shareHolder10Percent === 'NO' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [FINRA_RULE_10_PERCENT_SHAREHOLDER_YES]: shareHolder10Percent === 'YES' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [FINRA_RULE_10_PERCENT_SHAREHOLDER_COMPANY_NAME]: shareHolderCompanyName,
        fieldType: 'PDFTextField'
      },
      {
        [FINRA_RULE_10_PERCENT_SHAREHOLDER_TRADING_SYMBOL]: shareHolderTradingSymbol,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_FIRST_NAME]: tc1.firstName,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_MIDDLE_NAME]: tc1.middleName,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_LAST_NAME]: tc1.lastName,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_SUFFIX]: tc1.suffix,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_RELATIONSHIP_SPOUSE]: tc1.relationship === 'Spouse' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_1_RELATIONSHIP_PARTNER]: tc1.relationship === 'Partner' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_1_RELATIONSHIP_CHILD]: tc1.relationship === 'Child' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_1_RELATIONSHIP_PARENT]: tc1.relationship === 'Parent' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_1_RELATIONSHIP_SIBLING]: tc1.relationship === 'Sibling' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_1_RELATIONSHIP_FRIEND]: tc1.relationship === 'Friend' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_1_RELATIONSHIP_OTHER]: tc1.relationship === 'Other' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_1_MAILING_ADDRESS]: tc1.mailingAddress,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_CITY]: tc1.city,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_STATE]: tc1.state,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_ZIP_CODE]: tc1.postalCode,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_COUNTRY]: tc1.country,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_HOME_PHONE]: tc1.telephoneNumber,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_MOBILE_PHONE]: tc1.mobileNumber,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_1_EMAIL]: tc1.email,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_FIRST_NAME]: tc2.firstName,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_MIDDLE_NAME]: tc2.middleName,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_LAST_NAME]: tc2.lastName,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_SUFFIX]: tc2.suffix,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_RELATIONSHIP_SPOUSE]: tc2.relationship === 'Spouse' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_2_RELATIONSHIP_PARTNER]: tc2.relationship === 'Partner' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_2_RELATIONSHIP_CHILD]: tc2.relationship === 'Child' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_2_RELATIONSHIP_PARENT]: tc2.relationship === 'Parent' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_2_RELATIONSHIP_SIBLING]: tc2.relationship === 'Sibling' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_2_RELATIONSHIP_FRIEND]: tc2.relationship === 'Friend' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_2_RELATIONSHIP_OTHER]: tc2.relationship === 'Other' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUSTED_CONTACT_2_MAILING_ADDRESS]: tc2.mailingAddress,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_CITY]: tc2.city,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_STATE]: tc2.state,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_ZIP_CODE]: tc2.postalCode,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_COUNTRY]: tc2.country,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_HOME_PHONE]: tc2.telephoneNumber,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_MOBILE_PHONE]: tc2.mobileNumber,
        fieldType: 'PDFTextField'
      },
      {
        [TRUSTED_CONTACT_2_EMAIL]: tc2.email,
        fieldType: 'PDFTextField'
      },
    ], userFormId);
  }

  useImperativeHandle(ref, () => ({
    handleNext: async () => {
      if (!firstName) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: 'First Name is required.',
        }));
        return Promise.reject(new Error('First Name is required.'));
      } else if (!lastName) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: 'Last Name is required.',
        }));
        return Promise.reject(new Error('Last Name is required.'));
      } else if (!ssn) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          ssn: 'Social Security/Tax ID Number is required.',
        }));
        return Promise.reject(new Error('Social Security/Tax ID Number is required'));
      } else if (!dateOfBirth) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dateOfBirth: 'Date of Birth is required.',
        }));
        return Promise.reject(new Error('Date of Birth is required.'));
      } else if (!homeAddress) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: 'Home/Legal Street Address is required.',
        }));
        return Promise.reject(new Error('Home/Legal Street Address is required'));
      } else if (!homeCity) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: 'City is required.',
        }));
        return Promise.reject(new Error('City is required'));
      } else if (!homeState) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: 'State or Province is required.',
        }));
        return Promise.reject(new Error('State or Province is required'));
      } else if (!homePostalCode) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: 'Zip or Postal Code is required.',
        }));
        return Promise.reject(new Error('Zip or Postal Code is required'));
      } else if (!homeCountry) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firstName: 'Country is required.',
        }));
        return Promise.reject(new Error('Country is required'));
      } else if (!email) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Email is required.',
        }));
        return Promise.reject(new Error('Email is required'));
      } else if (!isValidUSPhone(email)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Invalid email address!',
        }));
        return Promise.reject(new Error('Invalid email address!'));
      }
      try {
        await updateData();
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  }));

  return (
    <Box h='full'>
      <Text
        fontSize="2xl"
        fontWeight="500">
        { title }
      </Text>
      <Flex p={ {base: '0px', md: '32px'} } direction='column' align={ isLoading ? 'center' : 'start' }
            justify={ isLoading ? 'center' : 'start' } h='full'>
        {
          isLoading ? <CirclesWithBar
            height="100"
            width="100"
            wrapperClass=""
            visible={ true }
            ariaLabel="circles-with-bar-loading"
          /> : <Box w='full'>
            <Grid
              templateColumns={ {base: '1fr', md: '2fr 2fr 2fr 1fr'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl isRequired mt='12px' isInvalid={ !!errors.firstName }>
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ firstName }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstName(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      firstName: '',
                    }));
                  } }
                />
                { errors.firstName && <FormErrorMessage>{ errors.firstName }</FormErrorMessage> }
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Middle Name</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ middleName }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setMiddleName(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl isRequired mt='12px' isInvalid={ !!errors.lastName }>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ lastName }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setLastName(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      lastName: '',
                    }));
                  } }
                />
                { errors.lastName && <FormErrorMessage>{ errors.lastName }</FormErrorMessage> }
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Suffix</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ suffix }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setSuffix(e.target.value);
                  } }
                />
              </FormControl>
            </Grid>
            <Grid
              templateColumns={ {base: '1fr', md: '1fr 1fr 2fr'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl isRequired mt='12px' isInvalid={ !!errors.ssn }>
                <FormLabel>Social Security/Tax ID Number</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ ssn }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setSsn(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      ssn: '',
                    }));
                  } }
                />
                { errors.ssn && <FormErrorMessage>{ errors.ssn }</FormErrorMessage> }
              </FormControl>
              <FormControl mt='12px' isRequired isInvalid={ !!errors.dateOfBirth }>
                <FormLabel>Date of Birth</FormLabel>
                <SingleDatepicker
                  configs={ {
                    dateFormat: 'MM/dd/yyyy'
                  } }
                  name="date-input"
                  maxDate={ new Date() }
                  date={ dateOfBirth }
                  onDateChange={ setDateOfBirth }
                />
                { errors.dateOfBirth && <FormErrorMessage>{ errors.dateOfBirth }</FormErrorMessage> }
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Preferred Name or Alias (if applicable)</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ alias }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setAlias(e.target.value);
                  } }
                />
              </FormControl>
            </Grid>
            <FormControl isRequired mt='12px' isInvalid={ !!errors.homeAddress }>
              <FormLabel>Home/Legal Street Address (no P.O. boxes)</FormLabel>
              <Input
                type="text"
                color={ textColor }
                value={ homeAddress }
                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                  setHomeAddress(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    homeAddress: '',
                  }));
                } }
              />
              { errors.homeAddress && <FormErrorMessage>{ errors.homeAddress }</FormErrorMessage> }
            </FormControl>
            <Grid
              templateColumns={ {base: '1fr', md: 'repeat(4, 1fr)'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl isRequired mt='12px' isInvalid={ !!errors.homeCity }>
                <FormLabel>City</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ homeCity }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setHomeCity(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      homeCity: '',
                    }));
                  } }
                />
                { errors.homeCity && <FormErrorMessage>{ errors.homeCity }</FormErrorMessage> }
              </FormControl>
              <FormControl isRequired mt='12px' isInvalid={ !!errors.homeState }>
                <FormLabel>State or Province</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ homeState }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setHomeState(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      homeState: '',
                    }));
                  } }
                />
                { errors.homeState && <FormErrorMessage>{ errors.homeState }</FormErrorMessage> }
              </FormControl>
              <FormControl isRequired mt='12px' isInvalid={ !!errors.homePostalCode }>
                <FormLabel>Zip or Postal Code</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ homePostalCode }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setHomePostalCode(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      homePostalCode: '',
                    }));
                  } }
                />
                { errors.homePostalCode && <FormErrorMessage>{ errors.homePostalCode }</FormErrorMessage> }
              </FormControl>
              <FormControl isRequired mt='12px' isInvalid={ !!errors.homeCountry }>
                <FormLabel>Country</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ homeCountry }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setHomeCountry(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      homeCountry: '',
                    }));
                  } }
                />
                { errors.homeCountry && <FormErrorMessage>{ errors.homeCountry }</FormErrorMessage> }
              </FormControl>
            </Grid>
            <FormControl mt='12px'>
              <FormLabel>Mailing Address (P.O. boxes may be used.)</FormLabel>
              <Input
                type="text"
                color={ textColor }
                value={ mailingAddress }
                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                  setMailingAddress(e.target.value);
                } }
              />
            </FormControl>
            <Grid
              templateColumns={ {base: '1fr', md: 'repeat(4, 1fr)'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl mt='12px'>
                <FormLabel>City</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ mailingCity }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setMailingCity(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>State or Province</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ mailingState }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setMailingState(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Zip or Postal Code</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ mailingPostalCode }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setMailingPostalCode(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Country</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ mailingCountry }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setMailingCountry(e.target.value);
                  } }
                />
              </FormControl>
            </Grid>
            <Grid
              templateColumns={ {base: '1fr', md: '2fr 2fr 2fr 1fr'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl mt='12px'>
                <FormLabel>Telephone Number</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ telephoneNumber }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setTelephoneNumber(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Mobile Number</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ mobileNumber }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setMobileNumber(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Work Number</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ workNumber }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setWorkNumber(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Extension</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ extension }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setExtension(e.target.value);
                  } }
                />
              </FormControl>
            </Grid>
            <Grid
              templateColumns={ {base: '1fr', md: '2fr 1fr'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl mt='12px' isRequired isInvalid={ !!errors.email }>
                <FormLabel>Email Address</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ email }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    handleEmailChange(e);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      email: '',
                    }));
                  } }
                />
                { errors.email && <FormErrorMessage>{ errors.email }</FormErrorMessage> }
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Mother's Maiden Name</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ motherName }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setMotherName(e.target.value);
                  } }
                />
              </FormControl>
            </Grid>
            <Text color={ textColorSecondary } mt='4px'>*By providing your email address, you consent to receiving email
              from
              Schwab. Information about opting out
              of certain email communications is provided at www.schwab.com/privacy</Text>
            <Grid
              mt="12px"
              templateColumns={ {base: '1fr', md: 'repeat(2, 1fr)'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl isRequired mt='12px'>
                <FormLabel>Country(ies) of Citizenship</FormLabel>
                <RadioGroup value={ citizenShip } onChange={ (e) => {
                  setCitizenShip(e);
                  if (citizenShip === 'USA') {
                    setOtherCitizenShip('');
                  }
                } }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                    <Radio colorScheme="brandScheme" value='USA'>USA</Radio>
                    <Radio colorScheme="brandScheme" value='OTHER'>Other:</Radio>
                    <FormControl ps='24px' hidden={ citizenShip === 'USA' }>
                      <Input
                        type="text"
                        color={ textColor }
                        w={ {base: 'full', md: 'xs'} }
                        value={ otherCitizenShip }
                        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                          setOtherCitizenShip(e.target.value);
                        } }
                      />
                    </FormControl>
                  </Flex>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired mt='12px'>
                <FormLabel>Country of Legal Residence</FormLabel>
                <RadioGroup value={ residence } onChange={ (e) => {
                  setResidence(e);
                  if (residence === 'USA') {
                    setOtherResidence('');
                  }
                } }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                    <Radio colorScheme="brandScheme" value='USA'>USA</Radio>
                    <Radio colorScheme="brandScheme" value='OTHER'>Other:</Radio>
                    <FormControl ps='24px' hidden={ residence === 'USA' }>
                      <Input
                        type="text"
                        color={ textColor }
                        w={ {base: 'full', md: 'xs'} }
                        value={ otherResidence }
                        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                          setOtherResidence(e.target.value);
                        } }
                      />
                    </FormControl>
                  </Flex>
                </RadioGroup>
              </FormControl>
            </Grid>

            <FormControl isRequired mt='12px'>
              <FormLabel>ID Number and Type</FormLabel>
              <RadioGroup value={ idType } onChange={ (e: 'PASSPORT' | 'DL' | 'GID') => setIdType(e) }>
                <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                  <Radio colorScheme="brandScheme" value='PASSPORT'>Passport</Radio>
                  <Radio colorScheme="brandScheme" value='DL'>Driver's License</Radio>
                  <Radio colorScheme="brandScheme" value='GID'>Gov't-Issued ID</Radio>
                </Flex>
              </RadioGroup>
            </FormControl>

            <Grid
              templateColumns={ {base: '1fr', md: 'repeat(5, 1fr)'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl isRequired mt='12px'>
                <FormLabel>Identification Number</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ idNumber }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setIdNumber(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl isRequired mt='12px'>
                <FormLabel>Country of Issuance</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ countryIssuance }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setCountryIssuance(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>State of Issuance (if applicable)</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  maxLength={ 2 }
                  value={ stateIssuance }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setStateIssuance(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl isRequired mt='12px'>
                <FormLabel>Issue Date</FormLabel>
                <SingleDatepicker
                  configs={ {
                    dateFormat: 'MM/dd/yyyy'
                  } }
                  name="date-input"
                  maxDate={ new Date() }
                  date={ issueDate }
                  onDateChange={ setIssueDate }
                />
              </FormControl>
              <FormControl isRequired mt='12px'>
                <FormLabel>Expiration Date</FormLabel>
                <SingleDatepicker
                  configs={ {
                    dateFormat: 'MM/dd/yyyy'
                  } }
                  name="date-input"
                  minDate={ new Date() }
                  date={ expireDate }
                  onDateChange={ setExpireDate }
                />
              </FormControl>
            </Grid>

            <FormControl isRequired mt='12px'>
              <FormLabel>Employment Information (Please select only one.)</FormLabel>
              <RadioGroup value={ employmentInformation } onChange={ (e) => setEmploymentInformation(e) }>
                <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                  <Radio colorScheme="brandScheme" value='Employed'>Employed</Radio>
                  <Radio colorScheme="brandScheme" value='SelfEmployed'>Self-Employed</Radio>
                  <Radio colorScheme="brandScheme" value='Retired'>Retired</Radio>
                  <Radio colorScheme="brandScheme" value='Homemaker'>Homemaker</Radio>
                  <Radio colorScheme="brandScheme" value='Student'>Student</Radio>
                  <Radio colorScheme="brandScheme" value='NotEmployed'>Not Employed</Radio>
                </Flex>
              </RadioGroup>
            </FormControl>

            <FormControl mt='12px'
                         hidden={ !(employmentInformation === "Employed" || employmentInformation === "SelfEmployed") }>
              <FormLabel>Occupation (If you selected "Employed" or "Self-Employed," please select one option that best
                describes your occupation.)</FormLabel>
              <RadioGroup value={ occupation } onChange={ (e) => {
                setOccupation(e);
              } }>
                <Grid
                  templateColumns={ {base: '1fr', md: 'repeat(4, 1fr)'} }
                  gap={ {base: 4, md: 2} }
                >
                  <Radio colorScheme="brandScheme" value='BusinessOwner'>Business Owner/Self-Employed</Radio>
                  <Radio colorScheme="brandScheme" value='SeniorMgt'>Executive/Senior Management</Radio>
                  <Radio colorScheme="brandScheme" value='MedicalProfessional'>Medical Professional</Radio>
                  <Radio colorScheme="brandScheme" value='LegalProfessional'>Legal Professional</Radio>
                  <Radio colorScheme="brandScheme" value='AccountProfessional'>Accounting Professional</Radio>
                  <Radio colorScheme="brandScheme" value='FinancialServices'>Financial Services/Banking
                    Professional</Radio>
                  <Radio colorScheme="brandScheme" value='ItProfessional'>Information Technology Professional</Radio>
                  <Radio colorScheme="brandScheme" value='OtherProfessional'>Other Professional</Radio>
                  <Radio colorScheme="brandScheme" value='ClericalAdministrative'>Clerical/Administrative
                    Services</Radio>
                  <Radio colorScheme="brandScheme" value='ForeignGovt'>Foreign Government Employee (Non-U.S.)</Radio>
                  <Radio colorScheme="brandScheme" value='Military'>Military</Radio>
                  <Radio colorScheme="brandScheme" value='Educator'>Educator</Radio>
                  <Radio colorScheme="brandScheme" value='SalesMarketing'>Sales/Marketing</Radio>
                  <Radio colorScheme="brandScheme" value='UsGovt'>U.S. Government Employee (Federal/State/Local)</Radio>
                  <Radio colorScheme="brandScheme" value='TradeService'>Trade/Service
                    (Labor/Manufacturing/Production)</Radio>
                  <Radio colorScheme="brandScheme" value='Consultant'>Consultant</Radio>
                  <Box>
                    <Radio colorScheme="brandScheme" value='Other'>Other (specify):</Radio>
                    <FormControl ps='24px' hidden={ occupation !== 'Other' }>
                      <Input
                        type="text"
                        color={ textColor }
                        w={ {base: 'full', md: 'xs'} }
                        value={ otherOccupation }
                        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                          setOtherOccupation(e.target.value);
                        } }
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </RadioGroup>
            </FormControl>

            <Grid
              templateColumns={ {base: '1fr', md: '1fr 3fr'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl mt='12px'>
                <FormLabel>Employer Name/Business Name</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ businessName }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setBusinessName(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Business Street Address (no P.O. boxes)</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ businessAddress }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setBusinessAddress(e.target.value);
                  } }
                />
              </FormControl>
            </Grid>
            <Grid
              templateColumns={ {base: '1fr', md: 'repeat(4, 1fr)'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl mt='12px'>
                <FormLabel>City</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ businessCity }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setBusinessCity(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>State or Province</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ businessState }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setBusinessState(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl isRequired mt='12px'>
                <FormLabel>Zip or Postal Code</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ businessPostalCode }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setBusinessPostalCode(e.target.value);
                  } }
                />
              </FormControl>
              <FormControl isRequired mt='12px'>
                <FormLabel>Country</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ businessCountry }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setBusinessCountry(e.target.value);
                  } }
                />
              </FormControl>
            </Grid>

            <Text
              color={ textColor }
              backgroundColor='gray.300'
              mt='24px'
              p='4px'
              fontWeight='500'>
              The Next Two Questions Are Required by Industry Regulations:
            </Text>

            <FormControl mt='12px'>
              <FormLabel>Are you or an immediate family member associated with or employed by a stock exchange or member
                firm
                of an exchange or FINRA, or a municipal
                securities broker-dealer?</FormLabel>
              <RadioGroup value={ employedFINRA } onChange={ (e) => setEmployedFINRA(e) }>
                <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                  <Radio colorScheme="brandScheme" value='NO'>No</Radio>
                  <Radio colorScheme="brandScheme" value='YES'>Yes</Radio>
                </Flex>
                <FormControl ps='24px' hidden={ employedFINRA === 'NO' }>
                  <FormLabel>
                    List the company name
                  </FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    w={ {base: 'full', md: 'xs'} }
                    value={ employedCompanyList }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmployedCompanyList(e.target.value);
                    } }
                  />
                </FormControl>
              </RadioGroup>

              <FormLabel mt='12px'>Are you a director, 10% shareholder or policy-making officer of a publicly held
                company?</FormLabel>
              <RadioGroup value={ shareHolder10Percent } onChange={ (e) => setShareHolder10Percent(e) }>
                <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                  <Radio colorScheme="brandScheme" value='NO'>No</Radio>
                  <Radio colorScheme="brandScheme" value='YES'>Yes</Radio>
                </Flex>
                <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                  <FormControl ps='24px' hidden={ shareHolder10Percent === 'NO' }
                               w={ {base: 'full', md: 'xs'} }>
                    <FormLabel>
                      Company name
                    </FormLabel>
                    <Input
                      type="text"
                      color={ textColor }
                      w={ {base: 'full', md: 'xs'} }
                      value={ shareHolderCompanyName }
                      onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                        setShareHolderCompanyName(e.target.value);
                      } }
                    />
                  </FormControl>
                  <FormControl ps='24px' hidden={ shareHolder10Percent === 'NO' }
                               w={ {base: 'full', md: 'xs'} }>
                    <FormLabel>
                      Trading Symbol
                    </FormLabel>
                    <Input
                      type="text"
                      color={ textColor }
                      w={ {base: 'full', md: 'xs'} }
                      value={ shareHolderTradingSymbol }
                      onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                        setShareHolderTradingSymbol(e.target.value);
                      } }
                    />
                  </FormControl>
                </Flex>
              </RadioGroup>
            </FormControl>

            <Box
              mt='24px'>
              <Text
                color={ textColor }
                backgroundColor='gray.300'
                p='4px'
                fontWeight='500'>
                Trusted Contact Designation
              </Text>
              <Text mt='8px'>
                A Trusted Contact Person ("Trusted Contact")* is a resource Schwab, and your advisor (if you have one),
                may
                contact on your behalf, if necessary, to attempt to address concerns regarding potential financial
                exploitation, or in communicating with you regarding issues related to your account(s). A Trusted
                Contact
                will
                not be able to view your account information, execute transactions in your account(s), or
                inquire about account activity, unless that person has that authority through another role on the
                account(s),
                such as a trustee or power of attorney.
                Providing Schwab with Trusted Contact information is voluntary. We encourage you to provide two Trusted
                Contacts in the event that one is not reachable in the future.
                <Text>
                  • Schwab suggests that your Trusted Contact(s) be someone other than your Financial Consultant or
                  Investment
                  Advisor.
                </Text>
                <Text>
                  • You may name up to two Trusted Contacts.
                </Text>
                <Text>
                  • The person(s) you name as Trusted Contact(s) will be the Trusted Contact(s) on all of your Schwab
                  accounts,
                  as provided for in your account agreement.
                </Text>
                <Text>
                  • For multiple-party accounts, each party can name separate Trusted Contacts.
                </Text>
                <Text>
                  • The Trusted Contact(s) must be at least 18 years old
                </Text>
              </Text>
            </Box>

            <Box
              mt='24px'>
              <Text
                fontWeight='500'>
                Trusted Contact Information
              </Text>
              <Text textColor={ textColorSecondary }>
                Trusted Contact information provided on this form will replace all Trusted Contact information currently
                on
                file.
              </Text>
            </Box>

            <Box
              mt='8px'
              borderRadius='4px'
              p='12px'
              borderWidth='1px'
            >
              <Text
                color={ textColor }
                backgroundColor='gray.300'
                p='4px'
                fontWeight='400'>
                Trusted Contact Person 1
              </Text>
              <Text
                color={ textColorSecondary }>
                If you have no changes to your existing Trusted Contact, please skip this section.
              </Text>

              <Grid
                templateColumns={ {base: '1fr', md: '2fr 2fr 2fr 1fr'} }
                gap={ {base: 4, md: 2} }
              >
                <FormControl isRequired mt='12px'>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.firstName }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'firstName', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl mt='12px'>
                  <FormLabel>Middle Name</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.middleName }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'middleName', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl isRequired mt='12px'>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.lastName }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'lastName', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl mt='12px'>
                  <FormLabel>Suffix</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.suffix }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'suffix', e.target.value);
                    } }
                  />
                </FormControl>
              </Grid>
              <FormControl isRequired mt='12px'>
                <FormLabel>Relationship (Please select only one.)</FormLabel>
                <RadioGroup value={ tc1.relationship } onChange={ (e) => handleTcInputChange(1, 'relationship', e) }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                    <Radio colorScheme="brandScheme" value='Spouse'>Spouse</Radio>
                    <Radio colorScheme="brandScheme" value='Partner'>Partner</Radio>
                    <Radio colorScheme="brandScheme" value='Child'>Child</Radio>
                    <Radio colorScheme="brandScheme" value='Parent'>Parent</Radio>
                    <Radio colorScheme="brandScheme" value='Sibling'>Sibling</Radio>
                    <Radio colorScheme="brandScheme" value='Friend'>Friend</Radio>
                    <Radio colorScheme="brandScheme" value='Other'>Other</Radio>
                  </Flex>
                </RadioGroup>
              </FormControl>
              <Grid
                templateColumns={ {base: '1fr', md: '3fr 1fr'} }
                gap={ {base: 4, md: 2} }
              >
                <FormControl isRequired mt='12px'>
                  <FormLabel>Mailing Street Address (no P.O. boxes)</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.mailingAddress }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'mailingAddress', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl isRequired mt='12px'>
                  <FormLabel>City</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.city }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'city', e.target.value);
                    } }
                  />
                </FormControl>
              </Grid>
              <Grid
                templateColumns={ {base: '1fr', md: '3fr 2fr 1fr'} }
                gap={ {base: 4, md: 2} }
              >
                <FormControl isRequired mt='12px'>
                  <FormLabel>State or Province</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.postalCode }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'postalCode', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl isRequired mt='12px'>
                  <FormLabel>Zip or Postal Code</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.postalCode }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'postalCode', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl isRequired mt='12px'>
                  <FormLabel>Country</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.country }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'country', e.target.value);
                    } }
                  />
                </FormControl>
              </Grid>
              <Grid
                templateColumns={ {base: '1fr', md: 'repeat(3, 1fr)'} }
                gap={ {base: 4, md: 2} }
              >
                <FormControl mt='12px'>
                  <FormLabel>Telephone Number</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.telephoneNumber }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'telephoneNumber', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl mt='12px'>
                  <FormLabel>Mobile Number</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.mobileNumber }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'mobileNumber', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl mt='12px'>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc1.email }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(1, 'email', e.target.value);
                    } }
                  />
                </FormControl>
              </Grid>
            </Box>

            <Box
              mt='8px'
              borderRadius='4px'
              p='12px'
              borderWidth='1px'
            >
              <Text
                color={ textColor }
                backgroundColor='gray.300'
                p='4px'
                fontWeight='400'>
                Trusted Contact Person 2
              </Text>
              <Text
                color={ textColorSecondary }>
                If you have no changes to your existing Trusted Contact, please skip this section.
              </Text>

              <Grid
                templateColumns={ {base: '1fr', md: '2fr 2fr 2fr 1fr'} }
                gap={ {base: 4, md: 2} }
              >
                <FormControl isRequired mt='12px'>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.firstName }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'firstName', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl mt='12px'>
                  <FormLabel>Middle Name</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.middleName }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'middleName', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl isRequired mt='12px'>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.lastName }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'lastName', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl mt='12px'>
                  <FormLabel>Suffix</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.suffix }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'suffix', e.target.value);
                    } }
                  />
                </FormControl>
              </Grid>
              <FormControl isRequired mt='12px'>
                <FormLabel>Relationship (Please select only one.)</FormLabel>
                <RadioGroup value={ tc2.relationship } onChange={ (e) => handleTcInputChange(2, 'relationship', e) }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                    <Radio colorScheme="brandScheme" value='Spouse'>Spouse</Radio>
                    <Radio colorScheme="brandScheme" value='Partner'>Partner</Radio>
                    <Radio colorScheme="brandScheme" value='Child'>Child</Radio>
                    <Radio colorScheme="brandScheme" value='Parent'>Parent</Radio>
                    <Radio colorScheme="brandScheme" value='Sibling'>Sibling</Radio>
                    <Radio colorScheme="brandScheme" value='Friend'>Friend</Radio>
                    <Radio colorScheme="brandScheme" value='Other'>Other</Radio>
                  </Flex>
                </RadioGroup>
              </FormControl>
              <Grid
                templateColumns={ {base: '1fr', md: '3fr 1fr'} }
                gap={ {base: 4, md: 2} }
              >
                <FormControl isRequired mt='12px'>
                  <FormLabel>Mailing Street Address (no P.O. boxes)</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.mailingAddress }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'mailingAddress', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl isRequired mt='12px'>
                  <FormLabel>City</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.city }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'city', e.target.value);
                    } }
                  />
                </FormControl>
              </Grid>
              <Grid
                templateColumns={ {base: '1fr', md: '3fr 2fr 1fr'} }
                gap={ {base: 4, md: 2} }
              >
                <FormControl isRequired mt='12px'>
                  <FormLabel>State or Province</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.postalCode }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'postalCode', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl isRequired mt='12px'>
                  <FormLabel>Zip or Postal Code</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.postalCode }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'postalCode', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl isRequired mt='12px'>
                  <FormLabel>Country</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.country }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'country', e.target.value);
                    } }
                  />
                </FormControl>
              </Grid>
              <Grid
                templateColumns={ {base: '1fr', md: 'repeat(3, 1fr)'} }
                gap={ {base: 4, md: 2} }
              >
                <FormControl mt='12px'>
                  <FormLabel>Telephone Number</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.telephoneNumber }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'telephoneNumber', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl mt='12px'>
                  <FormLabel>Mobile Number</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.mobileNumber }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'mobileNumber', e.target.value);
                    } }
                  />
                </FormControl>
                <FormControl mt='12px'>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ tc2.email }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTcInputChange(2, 'email', e.target.value);
                    } }
                  />
                </FormControl>
              </Grid>
            </Box>
          </Box>
        }
      </Flex>
    </Box>
  );
});

export default AccountHolderInformation;
