import { Box, Text, useColorModeValue, } from '@chakra-ui/react';
import React from 'react';
import Coverage from "./Coverage";

export default function Vehicle(props) {
  const {
    vehicles
  } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box mt='8px'>
      <Text
        color={ textColor }
        fontWeight='600'
        fontSize='md'
      >
        Vehicles
      </Text>
      <Box>
        {
          vehicles?.map((vehicle, index) => {
            return (
              <Box key={ index }>
                <Text
                  color={ textColor }
                  fontWeight='500'
                  fontSize='sm'
                >
                  { `${vehicle?.year} ${vehicle?.make} ${vehicle?.model} (${vehicle?.series} ${vehicle?.series2 || ''})` }
                </Text>
                <Text
                  color={ textColor }
                  fontWeight='400'
                  fontSize='sm'
                >
                  { `VIN: ${vehicle?.vin}` }
                </Text>
                {
                  vehicle?.coverages?.length > 0 && <Coverage coverages={ vehicle?.coverages }/>
                }
              </Box>
            )
          })
        }
      </Box>
    </Box>
  );
}
