import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Box, Checkbox, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { CirclesWithBar } from "react-loader-spinner";
import { getFormFieldValues, updateFormFieldValues } from "../../../../../api/smart-fill-service";
import { getValueByFieldName } from "../../../../../libraries/utils";
import { StepComponentProps } from "../IRASchwabApplicationModal";

const INSTRUCTION_ONE = 'clients[0].Form[0].Section5[0].border[0].IA_Authorizations[0].AuthorizationsInstructions[0].Checkboxes[0].InstructionOne[0]';
const INSTRUCTION_TWO = 'clients[0].Form[0].Section5[0].border[0].IA_Authorizations[0].AuthorizationsInstructions[0].Checkboxes[0].InstructionTwo[0]';
const INSTRUCTION_THREE = 'clients[0].Form[0].Section5[0].border[0].IA_Authorizations[0].AuthorizationsInstructions[0].Checkboxes[0].InstructionThree[0]';

const InstructionIAAuthorization = forwardRef<any, StepComponentProps>(({userFormId, title, ...props}, ref) => {

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [isLoading, setIsLoading] = useState(false);
  const [journal, setJournal] = useState(false);
  const [isTrading, setIsTrading] = useState(false);
  const [feePayment, setFeePayment] = useState(false);

  useEffect(() => {
    fetchFormValues();
  }, []);

  const fetchFormValues = () => {
    setIsLoading(true);
    getFormFieldValues(userFormId)
      .then(({fieldValues}) => {
        const instructionOne = getValueByFieldName(fieldValues, INSTRUCTION_ONE) === "checked";
        const instructionTwo = getValueByFieldName(fieldValues, INSTRUCTION_TWO) === "checked";
        const instructionThree = getValueByFieldName(fieldValues, INSTRUCTION_THREE) === "checked";
        setJournal(instructionOne);
        setIsTrading(instructionTwo);
        setFeePayment(instructionThree);
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsLoading(false))
  }

  const updateData = async () => {
    await updateFormFieldValues([
        {
          [INSTRUCTION_ONE]: journal ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [INSTRUCTION_TWO]: isTrading ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [INSTRUCTION_THREE]: feePayment ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
      ], userFormId
    )
  }

  useImperativeHandle(ref, () => ({
    handleNext: async () => {
      try {
        await updateData();
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  }));

  return (
    <Box h='full'>
      <Text
        fontSize="2xl"
        fontWeight="500">
        { title }
      </Text>
      <Flex p={ {base: '0px', md: '32px'} } direction='column' align={ isLoading ? 'center' : 'start' }
            justify={ isLoading ? 'center' : 'start' } h='full'>
        {
          isLoading ? <CirclesWithBar
            height="100"
            width="100"
            wrapperClass=""
            visible={ true }
            ariaLabel="circles-with-bar-loading"
          /> : <Box w='full'>
            <Text>You may change or revoke these instructions at any time by contacting Schwab.</Text>
            <Flex align='start'>
              <Checkbox
                checked={ journal }
                defaultChecked={ journal }
                onChange={ () => setJournal(prev => !prev) }
                colorScheme='brandScheme'
                me='10px'
                mt='4px'
              />
              <Text
                color={ textColor }
              >
                <b>Trading and Disbursement Authorization for Checks and Journals.</b> Selecting this option indicates
                that I have authorized my IA to take the following actions, and I instruct Schwab to permit my IA: (1)
                to place trades in my account as provided under the Trading Authorization heading in the attached
                Account Application Agreement; (2) to remit checks to me at my address of record; and (3) to journal
                cash and/or securities to a Schwab brokerage account on which I am named account holder (known as a
                first-party transfer).
                <Text>
                  This Disbursement Authorization does not apply to wire disbursements; Schwab MoneyLink® distributions;
                  or direct, ongoing electronic payments of dividends, interest, and money market income, which use
                  separate forms. (Note: Trading and Disbursement Authorization for Checks and Journals is not available
                  for Estate accounts.)
                </Text>
              </Text>
            </Flex>
            <Flex align='start'>
              <Checkbox
                checked={ isTrading }
                defaultChecked={ isTrading }
                onChange={ () => setIsTrading(prev => !prev) }
                colorScheme='brandScheme'
                me='10px'
                mt='4px'
              />
              <Text
                color={ textColor }
              >
                <b>Trading Authorization.</b> Selecting this option indicates that I have authorized my IA, and I
                instruct Schwab to permit my IA, to place trades in my account as provided under the Trading
                Authorization heading in the attached Account Application Agreement.
              </Text>
            </Flex>
            <Flex align='start'>
              <Checkbox
                checked={ feePayment }
                defaultChecked={ feePayment }
                onChange={ () => setFeePayment(prev => !prev) }
                colorScheme='brandScheme'
                me='10px'
                mt='4px'
              />
              <Text
                color={ textColor }
              >
                <b>Fee Payment Authorization.</b> Selecting this option indicates that I have authorized my IA, and I
                instruct Schwab to permit my IA, to pay investment advisory and related fees to IA from my account or
                another account registered to my name, over which I have granted IA fee payment authorization in the
                amount of IA's instructions
              </Text>
            </Flex>
          </Box>
        }
      </Flex>
    </Box>
  );
});

export default InstructionIAAuthorization;
