import React, { ReactNode, useMemo, useState } from "react";
import {
	Button,
	Input,
	InputGroup,
	InputLeftElement,
	Flex,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Select,
	HStack,
	Switch,
	Badge,
	useDisclosure,
	Popover,
	PopoverTrigger as OrigPopoverTrigger,
	Portal,
	PopoverContent,
	PopoverHeader,
	Box,
} from "@chakra-ui/react";
import {
	Column,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
} from "react-table";
import moment from "moment";

// Custom components
import Card from "../../../../components/card/Card";
import { AddIcon, DeleteIcon, WarningIcon } from "@chakra-ui/icons";
import { SearchIcon } from "@chakra-ui/icons";

// Assets
import { UserTableDataProps } from "../../../auth/users/index";
import { statusOptionArray } from "../variables/variables";
import UserApi from "../../../../api/user";
import UserAddModal from "../../modals/components/userAddModal";
import TableSpinner from "../../../../components/spinner/TableSpinner";
import { UserRole } from "../../../../config/constant";

interface UserTableProps {
	columnsData: Column<UserTableDataProps>[];
	tableData: UserTableDataProps[];
	search: { field: string; value: string | null };
	loading: boolean;
	setSearch: React.Dispatch<React.SetStateAction<{
		field: string;
		value: string | null;
	}>>;
	setIsRerender: React.Dispatch<React.SetStateAction<boolean>>;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
	OrigPopoverTrigger;

const UserTable: React.FC<UserTableProps> = ({
																							 columnsData,
																							 tableData,
																							 setSearch,
																							 setIsRerender,
																							 setIsLoading,
																							 search,
																							 loading,
																						 }) => {
	const tableColumns = useMemo(() => columnsData, [columnsData]);
	const data = useMemo(() => tableData, [tableData]);
	const [emailSearch, setEmailSearch] = useState<string>("");
	const tableInstance = useTable(
		{
			columns: tableColumns,
			data,
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		tableInstance;

	const textColor = useColorModeValue("secondaryGray.900", "white");
	const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
	const trBgColor = useColorModeValue("#dddddd", "whiteAlpha.100");

	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleEnableChange = async (enabled: boolean, email: string) => {
		setIsLoading(true);
		try {
			let api = enabled === true ? UserApi.EnableUser : UserApi.DisableUser;
			await api({ email });
			setIsRerender(true);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleKeyDown = (e: string) => {
		if (e === "Enter") {
			setSearch({ field: search.field, value: emailSearch });
		}
	};

	const handleFilterChange = (e: string) => {
		setSearch({ field: e, value: null });
	};

	const handleFilterSelectChange = (field: string, value: string) => {
		setSearch({ field, value });
	};

	const handleDeleteUser = async (e: string, onClose: () => void) => {
		onClose();
		try {
			setIsLoading(true);
			await UserApi.DeleteUser({ email: e });
			setIsRerender(true);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const createUser = async (e: {
		email: string;
		name: string;
		role: string;
	}) => {
		setIsLoading(true);
		try {
			await UserApi.CreateUser({
				email: e.email,
				name: e.name,
				accountType: e.role,
			});
			setIsRerender(true);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	return (
		<Card
			direction="column"
			w="100%"
			px="0px"
			overflowX={{ sm: "scroll", lg: "hidden" }}
			opacity={loading ? 0.7 : 1}
		>
			<TableSpinner loading={loading}/>
			<Flex px="25px" justify="space-between" mb="20px" align="center">
				<HStack>
					<Select
						width="200px"
						onChange={(e) => handleFilterChange(e.target.value)}
						cursor="pointer"
					>
						<option value="name">Name</option>
						<option value="email">Email</option>
						<option value="enabled">Enabled</option>
						<option value="status">Account Status</option>
					</Select>
					{search.field === "email" || search.field === "name" ? (
						<InputGroup>
							<InputLeftElement
								pointerEvents="none"
								children={<SearchIcon color="gray.300"/>}
							/>
							<Input
								type="input"
								width="300px"
								placeholder="Search..."
								onChange={(e: { target: { value: string } }) =>
									setEmailSearch(e.target.value)
								}
								onKeyDown={(e) => handleKeyDown(e.key)}
							/>
						</InputGroup>
					) : search.field === "enabled" ? (
						<Select
							placeholder="All"
							width="300px"
							onChange={(e) =>
								handleFilterSelectChange("enabled", e.target.value)
							}
						>
							<option value="Enabled">Enabled</option>
							<option value="Disabled">Disabled</option>
						</Select>
					) : (
						<Select
							placeholder="All"
							width="300px"
							onChange={(e) =>
								handleFilterSelectChange("userStatus", e.target.value)
							}
						>
							{statusOptionArray.map((item, index) => (
								<option value={item.value} key={index}>
									{item.value}
								</option>
							))}
						</Select>
					)}
				</HStack>
				<Button
					leftIcon={<AddIcon/>}
					fontSize="sm"
					variant="brand"
					fontWeight="500"
					onClick={onOpen}
				>
					Add User
				</Button>
			</Flex>
			<Table
				{...getTableProps()}
				variant="simple"
				color="gray.500"
				mb="24px"
				size="sm"
			>
				<Thead>
					{headerGroups.map((headerGroup, index) => (
						<Tr {...headerGroup.getHeaderGroupProps()} key={index}>
							{headerGroup.headers.map((column, index) => (
								<Th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									pe="10px"
									key={index}
									borderColor={borderColor}
								>
									<Flex
										justify="space-between"
										align="center"
										fontSize={{ sm: "10px", lg: "12px" }}
										color="gray.400"
									>
										{column.render("Header")}
									</Flex>
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{rows.map((row, index) => {
						prepareRow(row);
						return (
							<Tr
								{...row.getRowProps()}
								key={index}
								_hover={{ background: trBgColor }}
							>
								{row.cells.map((cell, index) => {
									let data: ReactNode;
									if (cell.column.Header === "NAME") {
										data = (
											<Flex align="center">
												<Text color={textColor} fontSize="sm" fontWeight="700">
													{cell.value}
												</Text>
											</Flex>
										);
									} else if (cell.column.Header === "ENABLED") {
										data = (
											<Flex align="center">
												<Switch
													colorScheme="teal"
													isChecked={cell.value}
													onChange={(e) =>
														handleEnableChange(
															e.target.checked,
															row.cells.filter(
																(item) => item.column.Header === "EMAIL"
															)[0].value
														)
													}
												/>
											</Flex>
										);
									} else if (
										cell.column.Header === "UPDATED" ||
										cell.column.Header === "CREATED"
									) {
										data = (
											<Flex align="center">
												<Text color={textColor} fontSize="sm" fontWeight="700">
													{moment(cell.value).format("YYYY.MM.DD HH:mm:ss")}
												</Text>
											</Flex>
										);
									} else if (cell.column.Header === "ACCOUNT STATUS") {
										data = (
											<Flex align="center">
												<Badge
													colorScheme={
														statusOptionArray.filter(
															(item) => item.value === cell.value
														)[0].color
													}
												>
													{cell.value}
												</Badge>
											</Flex>
										);
									} else if (cell.column.Header === "ACCOUNT TYPE") {
										data = (
											<Flex align="center">
												<Badge
													colorScheme={
														cell.value === UserRole[0]
															? "blue" : "green"
													}
												>
													{cell.value}
												</Badge>
											</Flex>
										);
									} else if (cell.column.Header === "OPTION") {
										const disabled: boolean = !row.cells.filter(
											(item) => item.column.Header === "ENABLED"
										)[0].value;
										const email: string = row.cells.filter(
											(item) => item.column.Header === "EMAIL"
										)[0].value;
										data = (
											<Flex align="center">
												<Popover>
													{({ onClose }) => (
														<>
															<PopoverTrigger>
																<Button disabled={!disabled} size="sm">
																	<DeleteIcon
																		color={
																			disabled ? "red.400" : "blackAlpha.300"
																		}
																		cursor={
																			disabled ? "pointer" : "not-allowed"
																		}
																	/>
																</Button>
															</PopoverTrigger>
															<Portal>
																<PopoverContent width="270px">
																	<PopoverHeader>
																		<Box display="flex" marginBottom="8px">
																			<WarningIcon
																				color="orange"
																				marginRight="4px"
																				marginTop="4px"
																			/>
																			<Box>
																				Are you sure to delete this User?
																			</Box>
																		</Box>
																		<Box
																			display="flex"
																			justifyContent="flex-end"
																		>
																			<Button
																				size="xs"
																				margin="4px"
																				width="50px"
																				onClick={onClose}
																			>
																				No
																			</Button>
																			<Button
																				size="xs"
																				margin="4px"
																				width="50px"
																				colorScheme="red"
																				onClick={() =>
																					handleDeleteUser(email, onClose)
																				}
																			>
																				Yes
																			</Button>
																		</Box>
																	</PopoverHeader>
																</PopoverContent>
															</Portal>
														</>
													)}
												</Popover>
											</Flex>
										);
									} else {
										data = (
											<Flex align="center">
												<Text color={textColor} fontSize="sm" fontWeight="700">
													{cell.value}
												</Text>
											</Flex>
										);
									}
									return (
										<Td
											{...cell.getCellProps()}
											key={index}
											fontSize={{ sm: "14px" }}
											minW={{ sm: "150px", md: "200px", lg: "auto" }}
											borderColor="transparent"
										>
											{data}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
			<UserAddModal isOpen={isOpen} onClose={onClose} onSave={createUser}/>
		</Card>
	);
};

export default UserTable;
