import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverContent,
  PopoverHeader,
  Portal,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';

// Custom components
import Card from 'components/card/Card';

// Assets
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator
} from '@ajna/pagination';
import { mode } from '@chakra-ui/theme-tools';
import { DeleteIcon, SearchIcon, WarningIcon } from '@chakra-ui/icons';
import Select, { components } from 'react-select';
import moment from "moment";
import { formatInsuranceProviderName, formatPhoneNumber } from "../../../../libraries/utils";
import { PopoverTrigger } from "../../dataTables/components/ClientTable";
import { getPullAsAdvisor } from "../../../../api/canopy-service";
import { useAuth } from "../../../../auth-context/auth.context";
import PolicyRow from "./PolicyRow";
import Driver from "./Driver";
import DrivingRecord from "./DrivingRecord";
import Claim from "./Claim";
import Document from "./Document";
import { selectCustomStyles } from "../../../../theme/styles";

const TABLE_COLUMNS = [
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'DATE',
    accessor: 'createdAt',
  },
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
  },
  {
    Header: 'PROVIDER',
    accessor: 'insuranceProviderName',
  },
  {
    Header: '',
    accessor: 'other',
  },
];

export const colorCircle = (color) => {
  return (
    <Box
      bg={ color }
      borderRadius="50%"
      minW="20px"
      width="20px"
      height="20px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    />
  )
}

const PULL_STATUS_OPTIONS = [
  {
    label: "ALL", value: "ALL", Icon: () => colorCircle('blue.500'), color: 'blue'
  },
  {
    label: "COMPLETED", value: "SUCCESS", Icon: () => colorCircle('green.500'), color: 'green'
  },
  {
    label: "IN PROGRESS", value: "PROCESSING", Icon: () => colorCircle('orange.500'), color: 'orange'
  },
  {
    label: "BAD LOGIN", value: "NOT_AUTHENTICATED", Icon: () => colorCircle('red.500'), color: 'red'
  }
];

function getLabelAndColor(value) {

  // Iterate through the PULL_STATUS_OPTIONS to find the item with the given value
  const item = PULL_STATUS_OPTIONS.find(option => option.value === value);

  // If an item is found, get the label and color using the Icon function
  if (item) {
    const label = item.label;
    const color = item.color;

    return { label, color };
  } else {
    return null;
  }
}

const { Option } = components;

function IconOption(props) {
  const {
    data: { label, Icon },
  } = props;

  return (
    <Option { ...props }>
      <HStack>
        { Icon && <Icon/> }
        <Text fontSize='sm' fontWeight='500'>{ label }</Text>
      </HStack>
    </Option>
  );
}

export default function PullTable(props) {
  const {
    isLoading,
    pulls,
    filter,
    setFilter,
    setCurrentPage,
    pagesCount,
    currentPage,
    pages,
  } = props;

  const { colorMode } = useColorMode();
  const { selectedClient } = useAuth();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const bgPagination = useColorModeValue('brand.500', 'brand.400');
  const bgFilter = useColorModeValue('gray.100', 'whiteAlpha.100');
  const [isPullLoading, setIsPullLoading] = useState(false);
  const [visibleRow, setVisibleRow] = useState(null);

  const [pull, setPull] = useState(null);

  const getPullById = (pullId) => {
    if (selectedClient) {
      setIsPullLoading(true);
      getPullAsAdvisor(selectedClient.userId, pullId)
        .then(({ pull }) => {
          setPull(pull);
        })
        .catch(err => console.error(err.message))
        .finally(() => setIsPullLoading(false))
    }
  }

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    })
  }

  // Get Address
  const getAddress = (addresses, nature = 'PHYSICAL' | 'MAILING') => {
    const address = addresses.find(a => a.addressNature === nature && a.fullAddress);
    return address ? address.fullAddress : '';
  }

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={ { sm: 'scroll', lg: 'hidden' } }
    >

      <Box px="25px" bg={ bgFilter } pb="12px">
        <Flex mb="10px" direction={ { sm: 'column', lg: 'row' } } gap={ 4 }>
          <FormControl maxW={ { sm: 'auto', lg: '350px' } }>
            <FormLabel>Status</FormLabel>
            <Select
              value={ filter.PULL_STATUS }
              styles={ selectCustomStyles(colorMode) }
              options={ PULL_STATUS_OPTIONS }
              onChange={ (column) => {
                handleFilterChange('PULL_STATUS', column)
              } }
              components={ {
                Option: IconOption
              } }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Find a pull</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                zIndex={ 0 }
                children={ <SearchIcon color="gray.300"/> }
              />
              <Input
                type="input"
                color={ textColor }
                placeholder="Filter by email, first or last name..."
                value={ filter.SEARCH }
                onChange={ e => handleFilterChange('SEARCH', e.target.value) }
              />
            </InputGroup>
          </FormControl>
        </Flex>
      </Box>
      <Divider mb="12px"/>

      <Table variant="simple" color="gray.500">
        <Thead>
          <Tr>
            { TABLE_COLUMNS.map((column, index) => (
              <Th pe="10px" key={ index } borderColor={ borderColor }>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={ {
                    sm: '10px',
                    lg: '12px',
                  } }
                  color="gray.400"
                >
                  { column.Header }
                </Flex>
              </Th>
            )) }
          </Tr>
        </Thead>
        <Tbody>
          { isLoading ? (
            <>
              { [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                <Tr key={ item }>
                  <Td colSpan={ 8 } p={ 2 }>
                    <Skeleton h="20px"></Skeleton>
                  </Td>
                </Tr>
              )) }
            </>
          ) : (
            <>
              { pulls
                .map((item, index) => {
                  const isVisible = visibleRow === item?.pullId;
                  return (
                    <>
                      <Tr
                        key={ index }
                        cursor='pointer'
                        _hover={ { background: '#dddddd55' } }
                        mt='20px'
                        onClick={ () => {
                          setPull(null);
                          setVisibleRow(isVisible ? null : item?.pullId);
                          if (!isVisible) {
                            getPullById(item?.pullId);
                          }
                        } }
                        borderTop='1px solid #dddddd'
                        borderBottom='2px solid #dddddd'
                        borderLeft={ `4px solid ${ getLabelAndColor(item.status).color }` }
                      >
                        <Td
                          fontSize={ {
                            sm: '14px',
                          } }
                          maxH="30px !important"
                          py="8px"
                        >
                          <Text
                            color={ textColor }
                            fontSize="sm"
                            fontWeight="600"
                          >
                            <Badge
                              colorScheme={ getLabelAndColor(item?.status).color }>
                              { getLabelAndColor(item?.status).label }
                            </Badge>
                          </Text>
                        </Td>
                        <Td
                          fontSize={ {
                            sm: '14px',
                          } }
                          maxH="30px !important"
                          py="8px"
                          color={ textColor }>
                          { moment(item?.createdAt).format('YYYY-MM-DD') }
                        </Td>
                        <Td
                          fontSize={ {
                            sm: '14px',
                          } }
                          maxH="30px !important"
                          py="8px"
                          color={ textColor }>
                          { `${ item?.firstName || '' } ${ item?.middleName || '' } ${ item?.lastName || '' }` }
                        </Td>
                        <Td
                          fontSize={ {
                            sm: '14px',
                          } }
                          maxH="30px !important"
                          py="8px"
                          color={ textColor }>
                          { item?.email || item?.accountEmail || '' }
                        </Td>
                        <Td
                          fontSize={ {
                            sm: '14px',
                          } }
                          maxH="30px !important"
                          py="8px"
                          color={ textColor }>
                          { formatInsuranceProviderName(item?.insuranceProviderName || '') }
                        </Td>
                        <Td>
                          <Popover>
                            { ({ onClose }) => (
                              <>
                                <PopoverTrigger>
                                  <Button>
                                    <DeleteIcon
                                      color='red.400'
                                      cursor='pointer'/>
                                  </Button>
                                </PopoverTrigger>
                                <Portal>
                                  <PopoverContent width="270px">
                                    <PopoverHeader>
                                      <Box display='flex' marginBottom='8px'>
                                        <WarningIcon color="orange" marginRight="4px" marginTop="4px"/>
                                        <Box>Are you sure to
                                          delete <b>{ formatInsuranceProviderName(item?.insuranceProviderName) }</b>?</Box>
                                      </Box>
                                      <Box display='flex' justifyContent='flex-end'>
                                        <Button size='xs' margin="4px" width="50px" onClick={ onClose }>No</Button>
                                        <Button size='xs' margin="4px" width="50px" colorScheme='red'
                                                onClick={ () => {
                                                } }>Yes</Button>
                                      </Box>
                                    </PopoverHeader>
                                  </PopoverContent>
                                </Portal>
                              </>
                            ) }
                          </Popover>
                        </Td>
                      </Tr>
                      {
                        isVisible && (
                          isPullLoading ?
                            <Td colSpan={ 8 } p={ 2 }>
                              <Skeleton h="30px"></Skeleton>
                            </Td> : (
                              <Td colSpan={ 6 }>
                                {
                                  pull ?
                                    <Box mx='16px'>
                                      <VStack
                                        align='start'
                                        mt='16px'
                                      >
                                        <Text
                                          color={ textColor }
                                          fontWeight='bold'
                                          fontSize='lg'
                                        >
                                          Contact
                                        </Text>
                                        <Flex
                                          gap={ { base: '8px', md: '16px' } }
                                          direction={ { base: 'column', md: 'row' } }
                                        >
                                          <HStack>
                                            <Text
                                              color={ textColor }
                                              fontSize='sm'
                                            >
                                              Name:
                                            </Text>

                                            <Text
                                              color={ textColor }
                                              fontWeight='500'
                                              fontSize='sm'
                                            >
                                              { `${ pull?.firstName || '' } ${ pull?.middleName || '' } ${ pull?.lastName || '' }` }
                                            </Text>
                                          </HStack>
                                          <HStack>
                                            <Text
                                              color={ textColor }
                                              fontSize='sm'
                                            >
                                              Email:
                                            </Text>

                                            <Text
                                              color={ textColor }
                                              fontWeight='500'
                                              fontSize='sm'
                                            >
                                              { pull?.email || pull?.accountEmail || '' }
                                            </Text>
                                          </HStack>
                                          <HStack>
                                            <Text
                                              color={ textColor }
                                              fontSize='sm'
                                            >
                                              Phone:
                                            </Text>

                                            <Text
                                              color={ textColor }
                                              fontWeight='500'
                                              fontSize='sm'
                                            >
                                              { formatPhoneNumber(pull?.phone || pull?.homePhone || pull?.workPhone || '') }
                                            </Text>
                                          </HStack>
                                        </Flex>
                                      </VStack>
                                      <VStack
                                        align='start'
                                        mt='32px'
                                      >
                                        <Text
                                          color={ textColor }
                                          fontWeight='bold'
                                          fontSize='lg'
                                        >
                                          Addresses
                                        </Text>
                                        <Flex
                                          gap={ { base: '8px', md: '16px' } }
                                          direction={ { base: 'column', md: 'row' } }
                                        >
                                          <HStack>
                                            <Text
                                              color={ textColor }
                                              fontSize='sm'
                                            >
                                              Physical:
                                            </Text>

                                            <Text
                                              color={ textColor }
                                              fontWeight='500'
                                              fontSize='sm'
                                            >
                                              { getAddress(pull?.addresses, 'PHYSICAL') }
                                            </Text>
                                          </HStack>
                                          <HStack>
                                            <Text
                                              color={ textColor }
                                              fontSize='sm'
                                            >
                                              Mailing:
                                            </Text>

                                            <Text
                                              color={ textColor }
                                              fontWeight='500'
                                              fontSize='sm'
                                            >
                                              { getAddress(pull?.addresses, 'MAILING') }
                                            </Text>
                                          </HStack>
                                        </Flex>
                                      </VStack>
                                      <PolicyRow policies={ pull?.policies }/>
                                      <Driver drivers={ pull?.drivers }/>
                                      <DrivingRecord drivingRecords={ pull?.drivingRecords }/>
                                      <Claim claims={ pull?.claims }/>
                                      <Document documents={ pull?.documents }/>
                                    </Box> : <Text textAlign='center'>
                                      There is no data.
                                    </Text>
                                }
                              </Td>
                            ))
                      }
                      <Tr height='8px'/>
                    </>
                  );
                }) }

              { pulls.length === 0 && (
                <Tr>
                  <Td colSpan={ 8 } p={ 2 }>
                    <Text textAlign="center">There is no insurance data!</Text>
                  </Td>
                </Tr>
              ) }
            </>
          ) }
        </Tbody>
      </Table>
      <Pagination
        pagesCount={ pagesCount }
        currentPage={ currentPage }
        onPageChange={ setCurrentPage }
      >
        <PaginationContainer
          align="center"
          justify="center"
          p={ 4 }
          gap={ 2 }
          w="full"
        >
          <PaginationPrevious
            _hover={ {
              bg: mode('brand.600', 'brand.400'),
            } }
            bg={ bgPagination }
          > Prev
          </PaginationPrevious>
          <PaginationPageGroup
            isInline
            align="center"
            separator={
              <PaginationSeparator
                bg="transparent"
                borderColor="brand.100"
                borderWidth="1px"
                fontSize="sm"
                w={ 10 }
                jumpSize={ 5 }
              />
            }
          >
            { pages.map((page) => (
              <PaginationPage
                key={ `pagination_page_${ page }` }
                page={ page }
                w={ 10 }
                bg="transparent"
                borderColor="brand.100"
                borderWidth="1px"
                fontSize="sm"
                _hover={ {
                  bg: 'brand.600'
                } }
                _current={ {
                  bg: 'brand.600',
                  fontSize: 'sm',
                  w: 10
                } }
              />
            )) }
          </PaginationPageGroup>
          <PaginationNext
            _hover={ {
              bg: mode('brand.600', 'brand.400'),
            } }
            bg={ bgPagination }
          >Next</PaginationNext>
        </PaginationContainer>
      </Pagination>
    </Card>
  );
}
