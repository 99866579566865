import { Box, Button, Flex, HStack, Td, Text, Tr, useColorModeValue, } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdEditDocument } from "react-icons/md";
import moment from "moment";
import { convertFriendlyName } from "../../../../libraries/utils";
import { DownloadIcon } from "@chakra-ui/icons";
import { getDocumentById } from "../../../../api/canopy-service";

export default function Document(props) {
  const {
    documents,
  } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [isDownloading, setIsDownloading] = useState({});

  const downloadDocument = ({ pullId, documentId }) => {
    setIsDownloading((prevState) => ({ ...prevState, [documentId]: true }));
    getDocumentById(pullId, documentId)
      .then(({ fileUrl }) => {
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', `${ documentId }`);
            link.style.display = 'none'; // Hide the link element from the user
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Remove the link element after triggering the download
          })
          .catch((error) => console.error('Error downloading the file:', error));
        setIsDownloading((prevState) => ({ ...prevState, [documentId]: false }));
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
        setIsDownloading((prevState) => ({ ...prevState, [documentId]: false }));
      });
  }

  return (
    <Box mt='32px'>
      <Text
        color={ textColor }
        fontWeight='bold'
        fontSize='lg'
      >
        Documents
      </Text>
      <Box mt='8px'>
        {
          documents && documents.map((document, index) => {
            return (
              <HStack
                key={ index }
                px='8px'
                py='4px'
                justify='space-between'
                _hover={ { background: '#dddddd55' } }
              >
                <Flex
                  gap='8px'
                  color={ textColor }
                  fontWeight='500'
                  fontSize='sm'
                >
                  <MdEditDocument size={ 20 }/>
                  <Text>
                    { moment(document?.dateAdded).format('YYYY-MM-DD') }
                  </Text>
                  <Text>
                    { document?.policy?.name || '' }
                  </Text>
                  <Text>
                    { convertFriendlyName(document?.documentType) }
                  </Text>
                </Flex>
                <Button
                  leftIcon={ <DownloadIcon/> }
                  fontSize="sm"
                  variant="brand"
                  disabled={ isDownloading[document.documentId] }
                  isLoading={ isDownloading[document.documentId] }
                  onClick={ () => downloadDocument(document) }
                >
                  Download
                </Button>
              </HStack>
            )
          })
        }
        { (!documents || documents.length === 0) && (
          <Tr>
            <Td colSpan={ 8 } p={ 2 }>
              <Text textAlign="center">There is no document!</Text>
            </Td>
          </Tr>
        ) }
      </Box>
    </Box>
  );
}
