// Chakra imports
import {Box, Grid, useToast} from '@chakra-ui/react';

// Custom components
// Assets
import React, {useEffect, useState} from 'react';
import PossibleSecurities from './components/PossibleSecurities';
import AccountHolding from '../default/components/AccountHolding';
import {createSecurityByAdvisor, deleteSecurityByAdvisor, getAccountHoldings} from '../../../api/fasttrack-service';
import {useAuth} from '../../../auth-context/auth.context';
import Card from '../../../components/card/Card';

export default function Manage() {
	const {selectedClient} = useAuth();
	const [isSecurityAdding, setIsSecurityAdding] = useState(false);
	const [isSecurityLoading, setIsSecurityLoading] = useState(false);
	const [isSecurityDeleting, setIsSecurityDeleting] = useState(false);
	const [securities, seSecurities] = useState([]);
	const toast = useToast();

	useEffect(() => {
		fetchAccountHoldings();
	}, [])

	const fetchAccountHoldings = () => {
		setIsSecurityLoading(true);
		getAccountHoldings(selectedClient?.userId)
			.then(({securities}) => {
				seSecurities(securities);
			})
			.catch(err => console.error(err.message))
			.finally(() => setIsSecurityLoading(false))
	}

	const onRemoveHolding = (securityId, symbolName) => {
		deleteSecurityByAdvisor(selectedClient?.userId, securityId)
			.then(() => {
				fetchAccountHoldings();
				toast({
					title: 'Remove security',
					description: `${symbolName} has been successfully removed.`,
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			})
			.catch(error => {
				console.error(error.message);
				toast({
					title: 'Remove security',
					description: error.response?.data.message ?? error.message,
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			})
			.finally(() => setIsSecurityLoading(false))
	}

	const onSecuritySave = ({symbol, name}) => {
		const securityName = name.split('--')[1].trim();
		setIsSecurityAdding(true);
		createSecurityByAdvisor(selectedClient?.userId, symbol, securityName)
			.then(() => {
				fetchAccountHoldings();
				toast({
					title: 'Add security',
					description: `${name} has been successfully added.`,
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			})
			.catch((error) => {
				toast({
					title: 'Add security',
					description: error.response?.data.message ?? error.message,
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			})
			.finally(() => setIsSecurityAdding(false))
	}

	return (
		<Box pt={{base: '130px', md: '80px', xl: '80px'}}>
			<Grid
				templateColumns={{
					base: 'none',
					lg: 'repeat(2, 1fr)',
				}}
				templateRows={{
					base: 'none',
					lg: '1fr',
				}}
				gap={{base: '20px', xl: '20px'}}>
				<PossibleSecurities
					isSecurityAdding={isSecurityAdding}
					onSecuritySave={onSecuritySave}
				/>
				<Card px="0px" mb="20px">
					<AccountHolding
						isSecurityLoading={isSecurityLoading}
						securityData={securities}
						isRemovable
						isDeleting={isSecurityDeleting}
						onRemoveHolding={onRemoveHolding}
					/>
				</Card>
			</Grid>
		</Box>
	);
}
