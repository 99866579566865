import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';

// Custom components
import Card from 'components/card/Card';

// Assets
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator
} from '@ajna/pagination';
import { mode } from '@chakra-ui/theme-tools';
import { SearchIcon } from '@chakra-ui/icons';
import { MdAttachMoney } from 'react-icons/md';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import dateFilterOptions from '../../../../libraries/dateFilterOptions';
import { CUSTOM } from '../../../../libraries/transactionsFilterManager';
import { epochToDate, getPeriodDates, numberFormat } from '../../../../libraries/utils';
import Select from 'react-select';
import { selectCustomStyles } from "../../../../theme/styles";

const TABLE_COLUMNS = [
  {
    Header: 'DATE',
    accessor: 'transactionDate',
  },
  {
    Header: 'DESCRIPTION',
    accessor: 'description',
  },
  {
    Header: 'MEMO',
    accessor: 'memo',
  },
  {
    Header: 'TYPE',
    accessor: 'type',
  },
  {
    Header: 'INVESTMENT TRANSACTION TYPE',
    accessor: 'investmentTransactionType',
  },
  {
    Header: 'AMOUNT',
    accessor: 'amount',
  },
];

export default function TransactionTable(props) {
  const {
    isLoading,
    isInvestmentTypesLoading,
    transactions,
    filter,
    setFilter,
    setCurrentPage,
    pagesCount,
    currentPage,
    pages,
    isInsLoading,
    accounts,
    investmentTypes
  } = props;

  const { colorMode } = useColorMode();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const bgPagination = useColorModeValue('brand.500', 'brand.400');
  const bgFilter = useColorModeValue('gray.100', 'whiteAlpha.100');

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    })
  }

  const handleFeeChange = (key, value) => {
    setFilter({
      ...filter,
      FEE: {
        ...filter.FEE,
        [key]: value
      }
    })
  }

  const handleDateChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
      'CUSTOM_DATE': getPeriodDates(value)
    });
  }

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={ { sm: 'scroll', lg: 'hidden' } }
    >
      <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text
          color={ textColor }
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Transactions
        </Text>
        <HStack spacing={ 7 }>
          <Button
            w="140px"
            minW="140px"
            variant="brand"
            fontWeight="500"
            onClick={ () => setIsFilterVisible(prev => !prev) }
          >
            FILTERS
          </Button>
        </HStack>
      </Flex>
      {
        isFilterVisible &&
        <Box px="25px" bg={ bgFilter } pb="12px">
          <Divider mb="12px"/>
          <Flex mb="10px" direction={ { sm: 'column', lg: 'row' } } gap={ 4 }>
            <FormControl>
              <FormLabel>Date</FormLabel>
              <Select styles={ selectCustomStyles(colorMode) } options={ dateFilterOptions }
                      onChange={ ({ value }) => handleDateChange('SELECTED_OPTION', value) }/>
              {
                filter.SELECTED_OPTION === CUSTOM && <RangeDatepicker
                  name="date-input"
                  selectedDates={ filter.CUSTOM_DATE }
                  onDateChange={ date => handleFilterChange('CUSTOM_DATE', date) }
                />
              }
            </FormControl>
            <FormControl>
              <FormLabel>Amount</FormLabel>
              <Flex align="center" gap="4">
                <InputGroup>
                  <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                    <MdAttachMoney color="gray.300"/>
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ filter.FEE.MIN }
                    placeholder="0"
                    onChange={ e => handleFeeChange('MIN', e.target.value) }
                  />
                </InputGroup>
                <Text
                  color={ textColor }
                  fontSize="sm"
                  fontWeight="500"
                  lineHeight="100%"
                >
                  To
                </Text>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                    <MdAttachMoney color="gray.300"/>
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ filter.FEE.MAX }
                    placeholder="0"
                    onChange={ e => handleFeeChange('MAX', e.target.value) }
                  />
                </InputGroup>
              </Flex>
            </FormControl>
            <FormControl>
              <FormLabel>Account Name</FormLabel>
              <Select
                styles={ selectCustomStyles(colorMode) }
                isLoading={ isInsLoading }
                value={ filter.ACCOUNT_NAME }
                isMulti
                placeholder="Select an account name"
                onChange={ (account) => {
                  handleFilterChange('ACCOUNT_NAME', account)
                } }
                options={ accounts }
              />
            </FormControl>
          </Flex>
          <Flex mb="10px" align="center" justify="space-between" direction={ { sm: 'column', lg: 'row' } } gap={ 4 }>
            <FormControl minW={ { sm: '150px', md: '200px', lg: 'auto' } } maxW={ { md: 'auto', lg: '40%' } }>
              <FormLabel>Find a transaction</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  zIndex={ 0 }
                  children={ <SearchIcon color="gray.300"/> }
                />
                <Input
                  type="input"
                  color={ textColor }
                  placeholder="Search..."
                  value={ filter.SEARCH }
                  onChange={ e => handleFilterChange('SEARCH', e.target.value) }
                />
              </InputGroup>
            </FormControl>
            {
              !filter.SHOW_ALL &&
              <FormControl minW={ { sm: '150px', md: '200px', lg: 'auto' } } maxW={ { md: 'auto', lg: '30%' } }>
                <FormLabel>Investment Types</FormLabel>
                <Select
                  styles={ selectCustomStyles(colorMode) }
                  isLoading={ isInvestmentTypesLoading }
                  value={ filter.INVESTMENT_TYPE }
                  isMulti
                  placeholder="Select an investment type"
                  onChange={ (account) => {
                    handleFilterChange('INVESTMENT_TYPE', account)
                  } }
                  options={ investmentTypes }
                />
              </FormControl>
            }
            <Flex align="center">
              <Checkbox
                me="16px"
                isChecked={ filter.SHOW_ALL }
                colorScheme="brandScheme"
                onChange={ e => handleFilterChange('SHOW_ALL', e.target.checked) }
              />
              <Text
                fontWeight="500"
                color={ textColor }
                fontSize="md"
                textAlign="start">
                Show all transactions
              </Text>
            </Flex>
          </Flex>
        </Box>
      }

      <Table variant="simple" color="gray.500">
        <Thead>
          <Tr>
            { TABLE_COLUMNS.map((column, index) => (
              <Th pe="10px" key={ index } borderColor={ borderColor }>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={ {
                    sm: '10px',
                    lg: '12px',
                  } }
                  color="gray.400"
                >
                  { column.Header }
                </Flex>
              </Th>
            )) }
          </Tr>
        </Thead>
        <Tbody>
          { isLoading ? (
            <>
              { [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                <Tr key={ item }>
                  <Td colSpan={ 8 } p={ 2 }>
                    <Skeleton h="20px"></Skeleton>
                  </Td>
                </Tr>
              )) }
            </>
          ) : (
            <>
              { transactions
                .map((item, index) => {
                  return (
                    <Tr key={ index }>
                      { Object.keys(item).map((key, idx) => {
                        let value = '';
                        if (key === 'transactionId' || key === 'accountId') {
                          return;
                        }

                        if (key === 'transactionDate') {
                          value = (
                            <Text
                              color={ textColor }
                              fontSize="sm"
                              fontWeight="700"
                              minW="90px"
                            >
                              { epochToDate(item[key]) }
                            </Text>
                          );
                        } else if (key === 'amount') {
                          value = (
                            <Text
                              color={ textColor }
                              fontSize="sm"
                              fontWeight="700"
                            >
                              { numberFormat(item[key]) }
                            </Text>
                          );
                        } else {
                          value = (
                            <Text
                              color={ textColor }
                              fontSize="sm"
                              fontWeight="700"
                            >
                              { item[key] }
                            </Text>
                          );
                        }

                        return (
                          <Td
                            key={ idx }
                            fontSize={ {
                              sm: '14px',
                            } }
                            maxH="30px !important"
                            py="8px"
                            minW={ {
                              sm: '150px',
                              md: '200px',
                              lg: 'auto',
                            } }
                            borderColor="transparent"
                          >
                            { value }
                          </Td>
                        );
                      }) }
                    </Tr>
                  );
                }) }

              { transactions.length === 0 && (
                <Tr>
                  <Td colSpan={ 8 } p={ 2 }>
                    <Text textAlign="center">There is no transactions!</Text>
                  </Td>
                </Tr>
              ) }
            </>
          ) }
        </Tbody>
      </Table>
      <Pagination
        pagesCount={ pagesCount }
        currentPage={ currentPage }
        onPageChange={ setCurrentPage }
      >
        <PaginationContainer
          align="center"
          justify="center"
          p={ 4 }
          gap={ 2 }
          w="full"
        >
          <PaginationPrevious
            _hover={ {
              bg: mode('brand.600', 'brand.400'),
            } }
            bg={ bgPagination }
          > Prev
          </PaginationPrevious>
          <PaginationPageGroup
            isInline
            align="center"
            separator={
              <PaginationSeparator
                bg="transparent"
                borderColor="brand.100"
                borderWidth="1px"
                fontSize="sm"
                w={ 10 }
                jumpSize={ 5 }
              />
            }
          >
            { pages.map((page) => (
              <PaginationPage
                key={ `pagination_page_${ page }` }
                page={ page }
                w={ 10 }
                bg="transparent"
                borderColor="brand.100"
                borderWidth="1px"
                fontSize="sm"
                _hover={ {
                  bg: 'brand.600'
                } }
                _current={ {
                  bg: 'brand.600',
                  fontSize: 'sm',
                  w: 10
                } }
              />
            )) }
          </PaginationPageGroup>
          <PaginationNext
            _hover={ {
              bg: mode('brand.600', 'brand.400'),
            } }
            bg={ bgPagination }
          >Next</PaginationNext>
        </PaginationContainer>
      </Pagination>
    </Card>
  );
}
