import { Auth } from '@aws-amplify/auth';

export const signup = ({
                         firstName = '',
                         lastName = '',
                         email = '',
                         phoneNumber = '',
                         fullName = '',
                         password = '',
                         accountType = 'advisor',
                       }) =>
  Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
      given_name: firstName,
      family_name: lastName,
      phone_number: phoneNumber,
      'custom:accountType': accountType,
      'custom:fullName': fullName,
      'custom:firmRole': 'admin',
    },
  });

export const signIn = ({email, password}: any) => {
  return Auth.signIn(email.trim(), password);
};

export const signOut = (isGlobal: boolean = true) => Auth.signOut(isGlobal ? {global: true} : {});

export const getCurrentAuthenticatedUser = () => {
  return Auth.currentAuthenticatedUser();
};

export const forgotPassword = (email: string) => {
  return Auth.forgotPassword(email);
};

export const forgotPasswordSubmit = (username: string, code: string, password: string) => {
  return Auth.forgotPasswordSubmit(username, code, password);
};
export const completeNewPassword = (user: any, password: string) => {
  return Auth.completeNewPassword(user, password);
};
export const confirmSignUp = (username: string, code: string) => {
  return Auth.confirmSignUp(username, code);
};

export const resendConfirmationCode = (username: string) => {
  return Auth.resendSignUp(username);
};

export const sendCustomChallengeAnswer = (user: any, code: string) => {
  return Auth.sendCustomChallengeAnswer(user, code);
};