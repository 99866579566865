import React, { useEffect, useState } from 'react';
import { Box, Flex, SimpleGrid, Text, useColorModeValue, useToast, } from '@chakra-ui/react';
import { useAuth } from 'auth-context/auth.context';
import { COUNT_PER_PAGE } from '../../../config/constant';
import { usePagination, } from '@ajna/pagination';
import { useDebounce } from '../../../libraries/useDebounce';
import PullTable, { colorCircle } from "./components/PullTable";
import { getAllPullsAsAdvisor } from "../../../api/canopy-service";

export default function InsuranceDashboard() {
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const toast = useToast();
  const { user, selectedClient } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [pulls, setPulls] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    PULL_STATUS: {
      label: "COMPLETED", value: "SUCCESS", Icon: () => colorCircle('green.500')
    },
    SEARCH: ''
  });

  const debouncedFilter = useDebounce(filter, 1000);

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    offset,
    pageSize,
  } = usePagination({
    pagesCount: Math.ceil(total / COUNT_PER_PAGE),
    limits: {
      outer: 2,
      inner: 2
    },
    initialState: {
      currentPage: 1,
      pageSize: COUNT_PER_PAGE,
    },
  });

  useEffect(() => {
    fetchPulls();
  }, [currentPage, offset, debouncedFilter]);


  const fetchPulls = () => {
    setIsLoading(true);
    if (selectedClient) {
      getAllPullsAsAdvisor(pageSize, offset, filter, selectedClient.userId)
        .then(({ pulls, count }) => {
          setPulls(pulls);
          setTotal(count);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsLoading(false))
    }
  }


  return (
    <Box pt={ { base: '130px', md: '80px', xl: '80px' } }>
      <Flex
        mt="45px"
        mb="20px"
        justifyContent="space-between"
        direction={ { base: 'column', md: 'row' } }
        align={ { base: 'start', md: 'center' } }>
        <Text color={ textColor } fontSize="2xl" fontWeight="700">
          Pulls Overview
        </Text>
      </Flex>
      <SimpleGrid columns={ { base: 1, md: 1, xl: 1 } } gap="20px" mb="20px">
        <PullTable
          isLoading={ isLoading }
          pulls={ pulls }
          setCurrentPage={ setCurrentPage }
          pagesCount={ pagesCount }
          currentPage={ currentPage }
          filter={ filter }
          setFilter={ setFilter }
          pages={ pages }/>
      </SimpleGrid>
    </Box>
  );
}
