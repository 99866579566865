// Chakra Imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorMode,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
// Custom Components
import { SidebarResponsive } from '../../components/sidebar/Sidebar';
import React from 'react';
// Assets
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../auth-context/auth.context';
import { Auth } from 'aws-amplify';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { FaEthereum } from 'react-icons/fa';
import routes, { RoutesProps } from '../../routes';
import { AccountType } from "../../config/constant";
import { validateRole } from "../../libraries/utils";

interface HeaderLinksProps {
  variant?: string;
  fixed?: boolean;
  logoText?: string;
  secondary: boolean;
  scrolled?: boolean;
  onOpen?: () => void;
}

const HeaderLinks: React.FC<HeaderLinksProps> = ({secondary}) => {
  const history = useHistory();
  const {user, setUser, selectedClient, setSelectedClient} = useAuth();
  const {colorMode, toggleColorMode} = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const avatarBg = useColorModeValue('brand.500', 'brand.500');
  const menuBgColor = useColorModeValue('#dddddd', 'whiteAlpha.100');

  const handleProfileSettings = () => {
    history.push('/admin/settings');
  }

  const handleLogout = async () => {
    // await Auth.signOut({ global: true });
    await Auth.signOut();
    await setUser(null);
    await setSelectedClient(null);
    return history.push('/auth/login');
  };

  const customRoutes: RoutesProps[] = [];

  if (user && validateRole(user?.token) === AccountType.CLIENT) {
    customRoutes.push(
      ...routes.filter((item) => {
        return ![
          'smartfees',
          'smartleads',
          'Wealth Management',
          'Insurance Management',
          'Security Management',
          'Advisors',
          'Settings',
        ].includes(item.name);
      })
    );
  }

  if (user && validateRole(user?.token) === AccountType.ADVISOR) {
    customRoutes.push(
      ...routes.filter((item) => {
        if (
          item.name === 'Profile'
        ) {
          return false;
        }
        if (
          item.name === 'Advisors' && user?.firmRole !== 'admin'
        ) {
          return false;
        }
        if (
          item.name === 'Wealth Management' &&
          selectedClient?.management !== "financial"
        ) {
          return false;
        }
        if (
          item.name === 'Insurance Management' &&
          selectedClient?.management !== "insurance"
        ) {
          return false;
        }
        if (
          item.name === 'Security Management' &&
          selectedClient?.management !== "financial"
        ) {
          return false;
        }
        return true;
      })
    );
  }
  if (!user) {
    customRoutes.push(...routes);
  }

  return (
    <Flex
      w={ {sm: '100%', md: 'auto'} }
      alignItems="center"
      flexDirection="row"
      bg={ menuBg }
      flexWrap={ secondary ? {base: 'wrap', md: 'nowrap'} : 'unset' }
      p="10px"
      borderRadius="30px"
      boxShadow={ shadow }
      gap="10px"
    >
      <Box
        pl="1"
        pr={ 1 }
        color={ textColor }
        fontSize="16px"
        fontWeight="700"
        lineHeight="100%"
        ml={ 1 }
      >
        { validateRole(user?.token) === AccountType.ADVISOR ? `Selected: ${ selectedClient ? selectedClient.firstName : 'N/A' }` : `${ user?.firstName } ${ user?.lastName }` }
      </Box>
      {/* <SearchBar
        mb={secondary ? { base: "10px", md: "unset" } : "unset"}
        me='10px'
        borderRadius='30px'
      /> */ }
      <Flex
        bg={ ethBg }
        display={ secondary ? 'flex' : 'none' }
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
      >
        <Flex
          align="center"
          justify="center"
          bg={ ethBox }
          h="29px"
          w="29px"
          borderRadius="30px"
          me="7px"
        >
          <Icon color={ ethColor } w="9px" h="14px" as={ FaEthereum }/>
        </Flex>
        <Text
          w="max-content"
          color={ ethColor }
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={ {base: 'none', md: 'unset'} }>
            { ' ' }
            ETH
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive routes={ customRoutes }/>
      {/* <Menu>
        <MenuButton p='0px'>
          <Icon
            mt='6px'
            as={MdNotificationsNone}
            color={navbarIcon}
            w='18px'
            h='18px'
            me='10px'
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: "30px", md: "unset" }}
          minW={{ base: "unset", md: "400px", xl: "450px" }}
          maxW={{ base: "360px", md: "unset" }}
        >
          <Flex justify="space-between" w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
              Mark all read
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent info="Horizon UI Dashboard PRO" aName="Alicia" />
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <ItemContent
                info="Horizon Design System Free"
                aName="Josh Henry"
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */ }
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={ toggleColorMode }
      >
        <Icon
          h="18px"
          w="18px"
          color={ navbarIcon }
          as={ colorMode === 'light' ? IoMdMoon : IoMdSunny }
        />
      </Button>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={ {cursor: 'pointer'} }
            color="white"
            src={ user?.avatarUrl }
            name={ `${ user?.firstName } ${ user?.lastName }` }
            bg={ avatarBg }
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={ shadow }
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={ menuBg }
          border="none"
          w="50px"
        >
          <VStack>
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={ borderColor }
              fontSize="sm"
              fontWeight="700"
              color={ textColor }
            >
              { `${ user?.firstName } ${ user?.lastName }` }
              <br/>
              <Box color="#f48d9c">{ user?.fullName ?? '' }</Box>
            </Text>
          </VStack>

          {
            validateRole(user?.token) === AccountType.ADVISOR && <VStack p="10px">
              <MenuItem
                _hover={ {bg: menuBgColor} }
                color={ textColor }
                borderRadius="8px"
                px="14px"
                onClick={ handleProfileSettings }
              >
                <Text fontSize="sm">Settings</Text>
              </MenuItem>
            </VStack>
          }

          {
            validateRole(user?.token) === AccountType.ADVISOR && <VStack p="10px">
              <MenuItem
                _hover={ {bg: menuBgColor} }
                color={ textColor }
                borderRadius="8px"
                px="14px"
                onClick={() => window.open('https://share.hsforms.com/1j07_yQxAQPa5D9E3jqYUcwrgvsv', '_blank')}
              >
                <Text fontSize="sm">Support</Text>
              </MenuItem>
            </VStack>
          }

          <VStack p="10px">
            <MenuItem
              _hover={ {bg: menuBgColor} }
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={ handleLogout }
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </VStack>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default HeaderLinks;
