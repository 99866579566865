import apiRequest from "./api";
import { BASE_API_URL } from "../config/constant";

export const getAllPullsAsAdvisor = (limit: number = 20, offset: number = 0, filter: any, clientId: number | null = null) =>
  apiRequest('post', `${ BASE_API_URL }/canopy/pulls/as-advisor`, {
    body: {
      limit,
      offset,
      filter,
      clientId
    },
  }).then((res) => res.data);

export const getPullAsAdvisor = (clientId: number | null = null, pullId: string) =>
  apiRequest('post', `${ BASE_API_URL }/canopy/pulls/${ pullId }/as-advisor`, {
    body: {
      clientId
    },
  }).then((res) => res.data);

export const getDocumentById = (pullId: string, documentId: string) =>
  apiRequest('get', `${ BASE_API_URL }/canopy/pulls/${ pullId }/documents/${ documentId }/pdf`).then((res) => res.data);