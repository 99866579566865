/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from '../../../auth-context/auth.context';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useGlobalFilter, usePagination, useSortBy, useTable, } from 'react-table';
import Card from '../../../components/card/Card';
import TableSpinner from '../../../components/spinner/TableSpinner';
import moment from 'moment';
import { COUNT_PER_PAGE } from "../../../config/constant";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination as useAjnaPagination
} from "@ajna/pagination";
import { mode } from "@chakra-ui/theme-tools";
import { retrieveTaxOrders } from "../../../api/smarttax-service";
import { getBadgeColor } from "../../../libraries/utils";
import { MdMenuBook } from "react-icons/md";
import SmartTaxModal from "../modals/components/SmartTaxModal";

const ORDER_COLUMNS: any = [
  {
    Header: 'CLIENT NAME',
    accessor: 'name',
  },
  {
    Header: 'REPORT TYPE',
    accessor: 'reportType',
  },
  {
    Header: 'ADD ON',
    accessor: 'addOn',
  },
  {
    Header: 'ORDER DATE',
    accessor: 'createdAt',
  },
  {
    Header: 'COMPLETED DATE',
    accessor: 'completedDate',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'OTHER',
    accessor: 'other',
  },
];

const SmarttaxOrders: React.FC = () => {
  const { user, selectedClient } = useAuth();

  const bgPagination = useColorModeValue('brand.500', 'brand.400');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const textColorSecondary = 'secondaryGray.600';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOrderId, setSelectedOrderId] = useState(undefined);

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    offset,
    pageSize,
  } = useAjnaPagination({
    pagesCount: Math.ceil(total / COUNT_PER_PAGE),
    limits: {
      outer: 2,
      inner: 2
    },
    initialState: {
      currentPage: 1,
      pageSize: COUNT_PER_PAGE,
    },
  });

  const tableInstance = useTable(
    {
      columns: ORDER_COLUMNS,
      data: orders,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const { orders, count } = await retrieveTaxOrders(pageSize, offset, selectedClient.userId);
      setOrders(orders);
      setTotal(count);
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user && selectedClient) {
      fetchOrders();
    }
  }, [user, currentPage, offset]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <VStack>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
          opacity={isLoading ? 0.7 : 1}
        >
          <TableSpinner loading={isLoading} />
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            size="sm"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row: any, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    bgColor={row.original.userId === selectedClient?.userId ? '#dddddd' : 'transparent'}
                    _hover={{ background: '#dddddd55' }}
                  >
                    {row.cells.map((cell: any, index: number) => {
                      let data: ReactNode;
                      if (cell.column.Header === 'CLIENT NAME') {
                        data = (
                          <HStack>
                            <Avatar
                              src={cell.row.original?.avatarUrl}
                              name={`${cell.row.original.firstName} ${cell.row.original.lastName}`}
                              w="32px"
                              h="32px"
                              size='12px'
                            />
                            <Box>
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {cell.row.original.firstName} {cell.row.original.lastName}
                              </Text>
                              {
                                cell.row.original?.isCompany &&
                                <Text
                                  color={textColorSecondary}
                                  fontSize="sm"
                                  fontWeight="500"
                                >
                                  {cell.row.original.businessName}
                                </Text>
                              }
                            </Box>
                          </HStack>
                        );
                      } else if (cell.column.Header === 'ORDER DATE' || cell.column.Header === 'COMPLETED DATE') {
                        const displayValue = cell.value ? moment(cell.value).format('MMMM DD, YYYY') : '';

                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            {displayValue}
                          </Text>
                        );
                      } else if (cell.column.Header === 'STATUS') {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            <Badge colorScheme={getBadgeColor(cell.value)}>{cell.value}</Badge>
                          </Text>
                        );
                      } else if (cell.column.Header === 'OTHER') {
                        data = (
                          <Button
                            rightIcon={<MdMenuBook />}
                            size="sm"
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            disabled={cell.row.original.status !== 'COMPLETED'}
                            onClick={() => {
                              setSelectedOrderId(cell.row.original.taxOrderId);
                              onOpen();
                            }}
                          >
                            View Data
                          </Button>
                        );
                      } else {
                        data = (
                          <Flex align="center">
                            <Text color={textColor} fontSize="sm" fontWeight="600">
                              {cell.value}
                            </Text>
                          </Flex>
                        );
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer
              align="center"
              justify="center"
              p={4}
              gap={2}
              w="full"
            >
              <PaginationPrevious
                _hover={{
                  bg: mode('brand.600', 'brand.400'),
                }}
                bg={bgPagination}
              > Prev
              </PaginationPrevious>
              <PaginationPageGroup
                isInline
                align="center"
                separator={
                  <PaginationSeparator
                    bg="transparent"
                    borderColor="brand.100"
                    borderWidth="1px"
                    fontSize="sm"
                    w={10}
                    jumpSize={5}
                  />
                }
              >
                {pages.map((page) => (
                  <PaginationPage
                    key={`pagination_page_${page}`}
                    page={page}
                    w={10}
                    bg="transparent"
                    borderColor="brand.100"
                    borderWidth="1px"
                    fontSize="sm"
                    _hover={{
                      bg: 'brand.600'
                    }}
                    _current={{
                      bg: 'brand.600',
                      fontSize: 'sm',
                      w: 10
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                _hover={{
                  bg: mode('brand.600', 'brand.400'),
                }}
                bg={bgPagination}
              >Next</PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Card>
      </VStack>
      <SmartTaxModal isOpen={isOpen} onClose={onClose} orderId={selectedOrderId} />
    </Box>
  );
};

export default SmarttaxOrders;
