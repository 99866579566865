import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Text,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';
import moment from "moment";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { StepComponentProps } from "../IRASchwabApplicationModal";
import { getFormFieldValues, updateFormFieldValues } from "../../../../../api/smart-fill-service";
import { getValueByFieldName } from "../../../../../libraries/utils";
import { CirclesWithBar } from "react-loader-spinner";

const PRIMARY_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].typeOfBeneficiary[0].Primary[0]';
const CONTINGENT_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].typeOfBeneficiary[0].Contingent[0]';
const PERCENTAGE_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].typeOfBeneficiary[0].portionPercentage[0]';
const FIRST_NAME_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].fullName[0].firstNameTF[0]';
const MIDDLE_NAME_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].fullName[0].middleNameTF[0]';
const LAST_NAME_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].fullName[0].lastNameTF[0]';
const SUFFIX_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].fullName[0].SuffixTF[0]';
const TRUST_OR_ESTATE_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].trustorgEstateTF[0]';
const SPOUSE_RELATIONSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].relationshipCBL[0].checkboxesGroup[0].Spouse[0]';
const CHILD_RELATIONSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].relationshipCBL[0].checkboxesGroup[0].Child[0]';
const GRANDCHILD_RELATIONSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].relationshipCBL[0].checkboxesGroup[0].Grandchild[0]';
const PARENT_RELATIONSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].relationshipCBL[0].checkboxesGroup[0].Parent[0]';
const SIBLING_RELATIONSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].relationshipCBL[0].checkboxesGroup[0].Sibling[0]';
const OTHER_RELATIONSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].relationshipCBL[0].checkboxesGroup[0].Other[0]';
const TRUST_RELATIONSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].relationshipCBL[0].checkboxesGroup[0].Trust[0]';
const ORGANIZATION_RELATIONSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].relationshipCBL[0].checkboxesGroup[0].Organization[0]';
const ESTATE_RELATIONSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].relationshipCBL[0].checkboxesGroup[0].Estate[0]';
const SSN_TAX_ID_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].ssnTaxIDTF[0]';
const DOB_OR_TRUST_DATE_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].dobOrTrustDate[0]';
const LEGAL_ADDRESS_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].mailingAddressDetails[0].legalAddressTF[0]';
const CITY_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].mailingAddressDetails[0].cityTF[0]';
const STATE_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].mailingAddressDetails[0].stateTF[0]';
const ZIP_CODE_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].mailingAddressDetails[0].zipCodeTF[0]';
const COUNTRY_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].mailingAddressDetails[0].countryTF[0]';
const HOME_PHONE_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].phoneNumbers[0].homePhoneTF[0]';
const EMAIL_ADDRESS_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].phoneNumbers[0].emailAddressTF[0]';
const USA_CITIZENSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].USA[0]';
const OTHER_CITIZENSHIP_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].other1[0]';
const CITIZENSHIP_COUNTRY_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].country1[0]';
const USA_RESIDENCE_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].citizenshipAndResidence[0].residence[0].checkResidence[0].USA[0]';
const OTHER_RESIDENCE_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].citizenshipAndResidence[0].residence[0].checkResidence[0].Other[0]';
const CITIZENSHIP_RESIDENCE_BENEFICIARY1 = 'clients[0].Form[0].Section7[0].border[0].#subform[0].AccountOpenBeneInformation1[0].citizenshipAndResidence[0].residence[0].checkResidence[0].Country[0]';

const PRIMARY_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].typeOfBeneficiary[0].Primary[0]';
const CONTINGENT_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].typeOfBeneficiary[0].Contingent[0]';
const PORTION_PERCENTAGE_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].typeOfBeneficiary[0].portionPercentage[0]';
const FIRST_NAME_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].fullName[0].firstNameTF[0]';
const MIDDLE_NAME_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].fullName[0].middleNameTF[0]';
const LAST_NAME_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].fullName[0].lastNameTF[0]';
const SUFFIX_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].fullName[0].SuffixTF[0]';
const TRUST_OR_ESTATE_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].trustorgEstateTF[0]';
const RELATIONSHIP_SPOUSE_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].relationshipCBL[0].checkboxesGroup[0].Spouse[0]';
const RELATIONSHIP_CHILD_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].relationshipCBL[0].checkboxesGroup[0].Child[0]';
const RELATIONSHIP_GRANDCHILD_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].relationshipCBL[0].checkboxesGroup[0].Grandchild[0]';
const RELATIONSHIP_PARENT_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].relationshipCBL[0].checkboxesGroup[0].Parent[0]';
const RELATIONSHIP_SIBLING_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].relationshipCBL[0].checkboxesGroup[0].Sibling[0]';
const RELATIONSHIP_OTHER_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].relationshipCBL[0].checkboxesGroup[0].Other[0]';
const RELATIONSHIP_TRUST_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].relationshipCBL[0].checkboxesGroup[0].Trust[0]';
const RELATIONSHIP_ORGANIZATION_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].relationshipCBL[0].checkboxesGroup[0].Organization[0]';
const RELATIONSHIP_ESTATE_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].relationshipCBL[0].checkboxesGroup[0].Estate[0]';
const SSN_TAX_ID_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].ssnTaxIDTF[0]';
const DOB_OR_TRUST_DATE_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].dobOrTrustDate[0]';
const LEGAL_ADDRESS_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].mailingAddressDetails[0].legalAddressTF[0]';
const CITY_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].mailingAddressDetails[0].cityTF[0]';
const STATE_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].mailingAddressDetails[0].stateTF[0]';
const ZIP_CODE_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].mailingAddressDetails[0].zipCodeTF[0]';
const COUNTRY_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].mailingAddressDetails[0].countryTF[0]';
const HOME_PHONE_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].phoneNumbers[0].homePhoneTF[0]';
const EMAIL_ADDRESS_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].phoneNumbers[0].emailAddressTF[0]';
const CITIZENSHIP_USA_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].USA[0]';
const CITIZENSHIP_OTHER1_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].other1[0]';
const COUNTRY1_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].country1[0]';
const RESIDENCE_USA_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].citizenshipAndResidence[0].residence[0].checkResidence[0].USA[0]';
const RESIDENCE_OTHER_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].citizenshipAndResidence[0].residence[0].checkResidence[0].Other[0]';
const RESIDENCE_COUNTRY_BENEFICIARY2 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation2[0].citizenshipAndResidence[0].residence[0].checkResidence[0].Country[0]';

const PRIMARY_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].typeOfBeneficiary[0].Primary[0]';
const CONTINGENT_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].typeOfBeneficiary[0].Contingent[0]';
const PERCENTAGE_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].typeOfBeneficiary[0].portionPercentage[0]';
const FIRST_NAME_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].fullName[0].firstNameTF[0]';
const MIDDLE_NAME_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].fullName[0].middleNameTF[0]';
const LAST_NAME_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].fullName[0].lastNameTF[0]';
const SUFFIX_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].fullName[0].SuffixTF[0]';
const TRUST_OR_ESTATE_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].trustorgEstateTF[0]';
const SPOUSE_RELATIONSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].relationshipCBL[0].checkboxesGroup[0].Spouse[0]';
const CHILD_RELATIONSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].relationshipCBL[0].checkboxesGroup[0].Child[0]';
const GRANDCHILD_RELATIONSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].relationshipCBL[0].checkboxesGroup[0].Grandchild[0]';
const PARENT_RELATIONSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].relationshipCBL[0].checkboxesGroup[0].Parent[0]';
const SIBLING_RELATIONSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].relationshipCBL[0].checkboxesGroup[0].Sibling[0]';
const OTHER_RELATIONSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].relationshipCBL[0].checkboxesGroup[0].Other[0]';
const TRUST_RELATIONSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].relationshipCBL[0].checkboxesGroup[0].Trust[0]';
const ORGANIZATION_RELATIONSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].relationshipCBL[0].checkboxesGroup[0].Organization[0]';
const ESTATE_RELATIONSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].relationshipCBL[0].checkboxesGroup[0].Estate[0]';
const SSN_TAX_ID_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].ssnTaxIDTF[0]';
const DOB_OR_TRUST_DATE_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].dobOrTrustDate[0]';
const LEGAL_ADDRESS_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].mailingAddressDetails[0].legalAddressTF[0]';
const CITY_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].mailingAddressDetails[0].cityTF[0]';
const STATE_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].mailingAddressDetails[0].stateTF[0]';
const ZIP_CODE_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].mailingAddressDetails[0].zipCodeTF[0]';
const COUNTRY_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].mailingAddressDetails[0].countryTF[0]';
const HOME_PHONE_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].phoneNumbers[0].homePhoneTF[0]';
const EMAIL_ADDRESS_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].phoneNumbers[0].emailAddressTF[0]';
const USA_CITIZENSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].USA[0]';
const OTHER_CITIZENSHIP_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].other1[0]';
const CITIZENSHIP_COUNTRY_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].country1[0]';
const USA_RESIDENCE_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].citizenshipAndResidence[0].residence[0].checkResidence[0].USA[0]';
const OTHER_RESIDENCE_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].citizenshipAndResidence[0].residence[0].checkResidence[0].Other[0]';
const RESIDENCE_COUNTRY_BENEFICIARY3 = 'clients[0].Form[0].Section7[0].border[0].#subform[3].AccountOpenBeneInformation3[0].citizenshipAndResidence[0].residence[0].checkResidence[0].Country[0]';

const PRIMARY_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].typeOfBeneficiary[0].Primary[0]';
const CONTINGENT_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].typeOfBeneficiary[0].Contingent[0]';
const PORTION_PERCENTAGE_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].typeOfBeneficiary[0].portionPercentage[0]';
const FIRST_NAME_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].fullName[0].firstNameTF[0]';
const MIDDLE_NAME_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].fullName[0].middleNameTF[0]';
const LAST_NAME_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].fullName[0].lastNameTF[0]';
const SUFFIX_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].fullName[0].SuffixTF[0]';
const TRUST_OR_ESTATE_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].trustorgEstateTF[0]';
const RELATIONSHIP_SPOUSE_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].relationshipCBL[0].checkboxesGroup[0].Spouse[0]';
const RELATIONSHIP_CHILD_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].relationshipCBL[0].checkboxesGroup[0].Child[0]';
const RELATIONSHIP_GRANDCHILD_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].relationshipCBL[0].checkboxesGroup[0].Grandchild[0]';
const RELATIONSHIP_PARENT_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].relationshipCBL[0].checkboxesGroup[0].Parent[0]';
const RELATIONSHIP_SIBLING_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].relationshipCBL[0].checkboxesGroup[0].Sibling[0]';
const RELATIONSHIP_OTHER_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].relationshipCBL[0].checkboxesGroup[0].Other[0]';
const RELATIONSHIP_TRUST_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].relationshipCBL[0].checkboxesGroup[0].Trust[0]';
const RELATIONSHIP_ORGANIZATION_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].relationshipCBL[0].checkboxesGroup[0].Organization[0]';
const RELATIONSHIP_ESTATE_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].relationshipCBL[0].checkboxesGroup[0].Estate[0]';
const SSN_TAX_ID_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].ssnTaxIDTF[0]';
const DOB_OR_TRUST_DATE_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].dobOrTrustDate[0]';
const LEGAL_ADDRESS_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].mailingAddressDetails[0].legalAddressTF[0]';
const CITY_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].mailingAddressDetails[0].cityTF[0]';
const STATE_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].mailingAddressDetails[0].stateTF[0]';
const ZIP_CODE_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].mailingAddressDetails[0].zipCodeTF[0]';
const COUNTRY_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].mailingAddressDetails[0].countryTF[0]';
const HOME_PHONE_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].phoneNumbers[0].homePhoneTF[0]';
const EMAIL_ADDRESS_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].phoneNumbers[0].emailAddressTF[0]';
const CITIZENSHIP_USA_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].USA[0]';
const CITIZENSHIP_OTHER1_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].other1[0]';
const COUNTRY1_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].citizenshipAndResidence[0].citizenship[0].checkCitizenship[0].country1[0]';
const RESIDENCE_USA_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].citizenshipAndResidence[0].residence[0].checkResidence[0].USA[0]';
const RESIDENCE_OTHER_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].citizenshipAndResidence[0].residence[0].checkResidence[0].Other[0]';
const RESIDENCE_COUNTRY_BENEFICIARY4 = 'clients[0].Form[0].Section7[0].border[0].AccountOpenBeneInformation4[0].citizenshipAndResidence[0].residence[0].checkResidence[0].Country[0]';

const DesignateBeneficiary = forwardRef<any, StepComponentProps>(({userFormId, title}, ref) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [showMore, setShowMore] = useState(true);

  const initialBeneficiaryState = {
    type: '',
    portion: '',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    trustOrganizationEstate: '',
    relationship: '',
    ssn: '',
    dateOfBirth: moment().subtract(20, 'years').startOf('year').toDate(),
    mailingAddress: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    telephoneNumber: '',
    email: '',
    citizenShip: 'USA',
    otherCitizenShip: '',
    residence: 'USA',
    otherResidence: '',
  };

  // Initialize with one contact form
  const [beneficiaries, setBeneficiaries] = useState([{...initialBeneficiaryState}]);
  const handleInputChange = (beneficiaryIndex: number, fieldName: string, value: string | Date) => {
    setBeneficiaries((prevContacts) =>
      prevContacts.map((contact, index) =>
        index === beneficiaryIndex ? {...contact, [fieldName]: value} : contact
      )
    );
  };

  const addBeneficiary = (beneficiary: any) => {
    if (beneficiary.type === '' || beneficiary.firstName === '' || beneficiary.lastName === '') {
      toast({
        title: 'Add Beneficiary',
        description: `Please input required fields.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (beneficiaries.length < 4) {
      setBeneficiaries((prevBeneficiaries) => [...prevBeneficiaries, {...initialBeneficiaryState}]);
    }
  };
  const removeBeneficiary = (beneficiaryIndex: number) => {
    setBeneficiaries((prevBeneficiaries) =>
      prevBeneficiaries.filter((_beneficiary, index) => index !== beneficiaryIndex)
    );
  };

  const getBeneficiary1Data = (fieldValues: any) => {
    const primaryBeneficiary1 = getValueByFieldName(fieldValues, PRIMARY_BENEFICIARY1) === 'checked';
    const contingentBeneficiary1 = getValueByFieldName(fieldValues, CONTINGENT_BENEFICIARY1) === 'checked';
    let typeOption = '';
    if (primaryBeneficiary1) {
      typeOption = 'Primary';
    } else if (contingentBeneficiary1) {
      typeOption = 'Contingent';
    }

    const percentageBeneficiary1 = getValueByFieldName(fieldValues, PERCENTAGE_BENEFICIARY1);
    const firstNameBeneficiary1 = getValueByFieldName(fieldValues, FIRST_NAME_BENEFICIARY1);
    const middleNameBeneficiary1 = getValueByFieldName(fieldValues, MIDDLE_NAME_BENEFICIARY1);
    const lastNameBeneficiary1 = getValueByFieldName(fieldValues, LAST_NAME_BENEFICIARY1);
    const suffixBeneficiary1 = getValueByFieldName(fieldValues, SUFFIX_BENEFICIARY1);
    const trustOrEstateBeneficiary1 = getValueByFieldName(fieldValues, TRUST_OR_ESTATE_BENEFICIARY1);

    const spouseRelationshipBeneficiary1 = getValueByFieldName(fieldValues, SPOUSE_RELATIONSHIP_BENEFICIARY1) === 'checked';
    const childRelationshipBeneficiary1 = getValueByFieldName(fieldValues, CHILD_RELATIONSHIP_BENEFICIARY1) === 'checked';
    const grandchildRelationshipBeneficiary1 = getValueByFieldName(fieldValues, GRANDCHILD_RELATIONSHIP_BENEFICIARY1) === 'checked';
    const parentRelationshipBeneficiary1 = getValueByFieldName(fieldValues, PARENT_RELATIONSHIP_BENEFICIARY1) === 'checked';
    const siblingRelationshipBeneficiary1 = getValueByFieldName(fieldValues, SIBLING_RELATIONSHIP_BENEFICIARY1) === 'checked';
    const otherRelationshipBeneficiary1 = getValueByFieldName(fieldValues, OTHER_RELATIONSHIP_BENEFICIARY1) === 'checked';
    const trustRelationshipBeneficiary1 = getValueByFieldName(fieldValues, TRUST_RELATIONSHIP_BENEFICIARY1) === 'checked';
    const organizationRelationshipBeneficiary1 = getValueByFieldName(fieldValues, ORGANIZATION_RELATIONSHIP_BENEFICIARY1) === 'checked';
    const estateRelationshipBeneficiary1 = getValueByFieldName(fieldValues, ESTATE_RELATIONSHIP_BENEFICIARY1) === 'checked';

    let relationShipOption = '';
    if (spouseRelationshipBeneficiary1) {
      relationShipOption = 'Spouse';
    } else if (childRelationshipBeneficiary1) {
      relationShipOption = 'Child';
    } else if (grandchildRelationshipBeneficiary1) {
      relationShipOption = 'Grandchild';
    } else if (parentRelationshipBeneficiary1) {
      relationShipOption = 'Parent';
    } else if (siblingRelationshipBeneficiary1) {
      relationShipOption = 'Sibling';
    } else if (otherRelationshipBeneficiary1) {
      relationShipOption = 'OtherIndividual';
    } else if (trustRelationshipBeneficiary1) {
      relationShipOption = 'Trust';
    } else if (organizationRelationshipBeneficiary1) {
      relationShipOption = 'Organization';
    } else if (estateRelationshipBeneficiary1) {
      relationShipOption = 'Estate';
    }

    const ssnTaxIdBeneficiary1 = getValueByFieldName(fieldValues, SSN_TAX_ID_BENEFICIARY1);
    const dobOrTrustDateBeneficiary1 = getValueByFieldName(fieldValues, DOB_OR_TRUST_DATE_BENEFICIARY1);
    const legalAddressBeneficiary1 = getValueByFieldName(fieldValues, LEGAL_ADDRESS_BENEFICIARY1);
    const cityBeneficiary1 = getValueByFieldName(fieldValues, CITY_BENEFICIARY1);
    const stateBeneficiary1 = getValueByFieldName(fieldValues, STATE_BENEFICIARY1);
    const zipCodeBeneficiary1 = getValueByFieldName(fieldValues, ZIP_CODE_BENEFICIARY1);
    const countryBeneficiary1 = getValueByFieldName(fieldValues, COUNTRY_BENEFICIARY1);
    const homePhoneBeneficiary1 = getValueByFieldName(fieldValues, HOME_PHONE_BENEFICIARY1);
    const emailAddressBeneficiary1 = getValueByFieldName(fieldValues, EMAIL_ADDRESS_BENEFICIARY1);

    const usaCitizenshipBeneficiary1 = getValueByFieldName(fieldValues, USA_CITIZENSHIP_BENEFICIARY1) === 'checked';
    const otherCitizenshipBeneficiary1 = getValueByFieldName(fieldValues, OTHER_CITIZENSHIP_BENEFICIARY1) === 'checked';
    const citizenshipCountryBeneficiary1 = getValueByFieldName(fieldValues, CITIZENSHIP_COUNTRY_BENEFICIARY1);
    let citizenShipOption = '';
    if (usaCitizenshipBeneficiary1) {
      citizenShipOption = 'USA';
    } else if (otherCitizenshipBeneficiary1) {
      citizenShipOption = 'OTHER';
    }
    const usaResidenceBeneficiary1 = getValueByFieldName(fieldValues, USA_RESIDENCE_BENEFICIARY1) === 'checked';
    const otherResidenceBeneficiary1 = getValueByFieldName(fieldValues, OTHER_RESIDENCE_BENEFICIARY1) === 'checked';
    const citizenshipResidenceBeneficiary1 = getValueByFieldName(fieldValues, CITIZENSHIP_RESIDENCE_BENEFICIARY1);
    let residenceOption = '';
    if (usaResidenceBeneficiary1) {
      residenceOption = 'USA';
    } else if (otherResidenceBeneficiary1) {
      residenceOption = 'OTHER';
    }

    const beneficiary = {
      type: typeOption,
      portion: percentageBeneficiary1,
      firstName: firstNameBeneficiary1,
      middleName: middleNameBeneficiary1,
      lastName: lastNameBeneficiary1,
      suffix: suffixBeneficiary1,
      trustOrganizationEstate: trustOrEstateBeneficiary1,
      relationship: relationShipOption,
      ssn: ssnTaxIdBeneficiary1,
      dateOfBirth: dobOrTrustDateBeneficiary1 ? moment(dobOrTrustDateBeneficiary1).toDate() : moment().subtract(20, 'years').startOf('year').toDate(),
      mailingAddress: legalAddressBeneficiary1,
      city: cityBeneficiary1,
      state: stateBeneficiary1,
      postalCode: zipCodeBeneficiary1,
      country: countryBeneficiary1,
      telephoneNumber: homePhoneBeneficiary1,
      email: emailAddressBeneficiary1,
      citizenShip: citizenShipOption || 'USA',
      otherCitizenShip: citizenshipCountryBeneficiary1,
      residence: residenceOption || 'USA',
      otherResidence: citizenshipResidenceBeneficiary1,
    };

    setBeneficiaries([beneficiary]);
  }


  const getBeneficiary2Data = (fieldValues: any) => {
    const primaryBeneficiary2 = getValueByFieldName(fieldValues, PRIMARY_BENEFICIARY2) === 'checked';
    const contingentBeneficiary2 = getValueByFieldName(fieldValues, CONTINGENT_BENEFICIARY2) === 'checked';
    let typeOption = '';
    if (primaryBeneficiary2) {
      typeOption = 'Primary';
    } else if (contingentBeneficiary2) {
      typeOption = 'Contingent';
    }
    if (typeOption === '') return;
    const portionPercentageBeneficiary2 = getValueByFieldName(fieldValues, PORTION_PERCENTAGE_BENEFICIARY2);
    const firstNameBeneficiary2 = getValueByFieldName(fieldValues, FIRST_NAME_BENEFICIARY2);
    const middleNameBeneficiary2 = getValueByFieldName(fieldValues, MIDDLE_NAME_BENEFICIARY2);
    const lastNameBeneficiary2 = getValueByFieldName(fieldValues, LAST_NAME_BENEFICIARY2);
    const suffixBeneficiary2 = getValueByFieldName(fieldValues, SUFFIX_BENEFICIARY2);
    const trustOrEstateBeneficiary2 = getValueByFieldName(fieldValues, TRUST_OR_ESTATE_BENEFICIARY2);

    const relationshipSpouseBeneficiary2 = getValueByFieldName(fieldValues, RELATIONSHIP_SPOUSE_BENEFICIARY2) === 'checked';
    const relationshipChildBeneficiary2 = getValueByFieldName(fieldValues, RELATIONSHIP_CHILD_BENEFICIARY2) === 'checked';
    const relationshipGrandchildBeneficiary2 = getValueByFieldName(fieldValues, RELATIONSHIP_GRANDCHILD_BENEFICIARY2) === 'checked';
    const relationshipParentBeneficiary2 = getValueByFieldName(fieldValues, RELATIONSHIP_PARENT_BENEFICIARY2) === 'checked';
    const relationshipSiblingBeneficiary2 = getValueByFieldName(fieldValues, RELATIONSHIP_SIBLING_BENEFICIARY2) === 'checked';
    const relationshipOtherBeneficiary2 = getValueByFieldName(fieldValues, RELATIONSHIP_OTHER_BENEFICIARY2) === 'checked';
    const relationshipTrustBeneficiary2 = getValueByFieldName(fieldValues, RELATIONSHIP_TRUST_BENEFICIARY2) === 'checked';
    const relationshipOrganizationBeneficiary2 = getValueByFieldName(fieldValues, RELATIONSHIP_ORGANIZATION_BENEFICIARY2) === 'checked';
    const relationshipEstateBeneficiary2 = getValueByFieldName(fieldValues, RELATIONSHIP_ESTATE_BENEFICIARY2) === 'checked';

    let relationShipOption = '';
    if (relationshipSpouseBeneficiary2) {
      relationShipOption = 'Spouse';
    } else if (relationshipChildBeneficiary2) {
      relationShipOption = 'Child';
    } else if (relationshipGrandchildBeneficiary2) {
      relationShipOption = 'Grandchild';
    } else if (relationshipParentBeneficiary2) {
      relationShipOption = 'Parent';
    } else if (relationshipSiblingBeneficiary2) {
      relationShipOption = 'Sibling';
    } else if (relationshipOtherBeneficiary2) {
      relationShipOption = 'OtherIndividual';
    } else if (relationshipTrustBeneficiary2) {
      relationShipOption = 'Trust';
    } else if (relationshipOrganizationBeneficiary2) {
      relationShipOption = 'Organization';
    } else if (relationshipEstateBeneficiary2) {
      relationShipOption = 'Estate';
    }

    const ssnTaxIdBeneficiary2 = getValueByFieldName(fieldValues, SSN_TAX_ID_BENEFICIARY2);
    const dobOrTrustDateBeneficiary2 = getValueByFieldName(fieldValues, DOB_OR_TRUST_DATE_BENEFICIARY2);
    const legalAddressBeneficiary2 = getValueByFieldName(fieldValues, LEGAL_ADDRESS_BENEFICIARY2);
    const cityBeneficiary2 = getValueByFieldName(fieldValues, CITY_BENEFICIARY2);
    const stateBeneficiary2 = getValueByFieldName(fieldValues, STATE_BENEFICIARY2);
    const zipCodeBeneficiary2 = getValueByFieldName(fieldValues, ZIP_CODE_BENEFICIARY2);
    const countryBeneficiary2 = getValueByFieldName(fieldValues, COUNTRY_BENEFICIARY2);
    const homePhoneBeneficiary2 = getValueByFieldName(fieldValues, HOME_PHONE_BENEFICIARY2);
    const emailAddressBeneficiary2 = getValueByFieldName(fieldValues, EMAIL_ADDRESS_BENEFICIARY2);

    const citizenshipUsaBeneficiary2 = getValueByFieldName(fieldValues, CITIZENSHIP_USA_BENEFICIARY2) === 'checked';
    const citizenshipOther1Beneficiary2 = getValueByFieldName(fieldValues, CITIZENSHIP_OTHER1_BENEFICIARY2) === 'checked';
    const country1Beneficiary2 = getValueByFieldName(fieldValues, COUNTRY1_BENEFICIARY2);
    let citizenShipOption = '';
    if (citizenshipUsaBeneficiary2) {
      citizenShipOption = 'USA';
    } else if (citizenshipOther1Beneficiary2) {
      citizenShipOption = 'OTHER';
    }

    const residenceUsaBeneficiary2 = getValueByFieldName(fieldValues, RESIDENCE_USA_BENEFICIARY2) === 'checked';
    const residenceOtherBeneficiary2 = getValueByFieldName(fieldValues, RESIDENCE_OTHER_BENEFICIARY2) === 'checked';
    const residenceCountryBeneficiary2 = getValueByFieldName(fieldValues, RESIDENCE_COUNTRY_BENEFICIARY2);
    let residenceOption = '';
    if (residenceUsaBeneficiary2) {
      residenceOption = 'USA';
    } else if (residenceOtherBeneficiary2) {
      residenceOption = 'OTHER';
    }

    const beneficiary = {
      type: typeOption,
      portion: portionPercentageBeneficiary2,
      firstName: firstNameBeneficiary2,
      middleName: middleNameBeneficiary2,
      lastName: lastNameBeneficiary2,
      suffix: suffixBeneficiary2,
      trustOrganizationEstate: trustOrEstateBeneficiary2,
      relationship: relationShipOption,
      ssn: ssnTaxIdBeneficiary2,
      dateOfBirth: dobOrTrustDateBeneficiary2 ? moment(dobOrTrustDateBeneficiary2).toDate() : moment().subtract(20, 'years').startOf('year').toDate(),
      mailingAddress: legalAddressBeneficiary2,
      city: cityBeneficiary2,
      state: stateBeneficiary2,
      postalCode: zipCodeBeneficiary2,
      country: countryBeneficiary2,
      telephoneNumber: homePhoneBeneficiary2,
      email: emailAddressBeneficiary2,
      citizenShip: citizenShipOption || 'USA',
      otherCitizenShip: country1Beneficiary2,
      residence: residenceOption || 'USA',
      otherResidence: residenceCountryBeneficiary2,
    };
    setBeneficiaries(prev => [...prev, {...beneficiary}]);
  }

  const getBeneficiary3Data = (fieldValues: any) => {
    const primaryBeneficiary3 = getValueByFieldName(fieldValues, PRIMARY_BENEFICIARY3) === 'checked';
    const contingentBeneficiary3 = getValueByFieldName(fieldValues, CONTINGENT_BENEFICIARY3) === 'checked';
    let typeOption = '';
    if (primaryBeneficiary3) {
      typeOption = 'Primary';
    } else if (contingentBeneficiary3) {
      typeOption = 'Contingent';
    }
    if (typeOption === '') return;
    const percentageBeneficiary3 = getValueByFieldName(fieldValues, PERCENTAGE_BENEFICIARY3);
    const firstNameBeneficiary3 = getValueByFieldName(fieldValues, FIRST_NAME_BENEFICIARY3);
    const middleNameBeneficiary3 = getValueByFieldName(fieldValues, MIDDLE_NAME_BENEFICIARY3);
    const lastNameBeneficiary3 = getValueByFieldName(fieldValues, LAST_NAME_BENEFICIARY3);
    const suffixBeneficiary3 = getValueByFieldName(fieldValues, SUFFIX_BENEFICIARY3);
    const trustOrEstateBeneficiary3 = getValueByFieldName(fieldValues, TRUST_OR_ESTATE_BENEFICIARY3);

    const spouseRelationshipBeneficiary3 = getValueByFieldName(fieldValues, SPOUSE_RELATIONSHIP_BENEFICIARY3) === 'checked';
    const childRelationshipBeneficiary3 = getValueByFieldName(fieldValues, CHILD_RELATIONSHIP_BENEFICIARY3) === 'checked';
    const grandchildRelationshipBeneficiary3 = getValueByFieldName(fieldValues, GRANDCHILD_RELATIONSHIP_BENEFICIARY3) === 'checked';
    const parentRelationshipBeneficiary3 = getValueByFieldName(fieldValues, PARENT_RELATIONSHIP_BENEFICIARY3) === 'checked';
    const siblingRelationshipBeneficiary3 = getValueByFieldName(fieldValues, SIBLING_RELATIONSHIP_BENEFICIARY3) === 'checked';
    const otherRelationshipBeneficiary3 = getValueByFieldName(fieldValues, OTHER_RELATIONSHIP_BENEFICIARY3) === 'checked';
    const trustRelationshipBeneficiary3 = getValueByFieldName(fieldValues, TRUST_RELATIONSHIP_BENEFICIARY3) === 'checked';
    const organizationRelationshipBeneficiary3 = getValueByFieldName(fieldValues, ORGANIZATION_RELATIONSHIP_BENEFICIARY3) === 'checked';
    const estateRelationshipBeneficiary3 = getValueByFieldName(fieldValues, ESTATE_RELATIONSHIP_BENEFICIARY3) === 'checked';

    let relationShipOption = '';
    if (spouseRelationshipBeneficiary3) {
      relationShipOption = 'Spouse';
    } else if (childRelationshipBeneficiary3) {
      relationShipOption = 'Child';
    } else if (grandchildRelationshipBeneficiary3) {
      relationShipOption = 'Grandchild';
    } else if (parentRelationshipBeneficiary3) {
      relationShipOption = 'Parent';
    } else if (siblingRelationshipBeneficiary3) {
      relationShipOption = 'Sibling';
    } else if (otherRelationshipBeneficiary3) {
      relationShipOption = 'OtherIndividual';
    } else if (trustRelationshipBeneficiary3) {
      relationShipOption = 'Trust';
    } else if (organizationRelationshipBeneficiary3) {
      relationShipOption = 'Organization';
    } else if (estateRelationshipBeneficiary3) {
      relationShipOption = 'Estate';
    }

    const ssnTaxIdBeneficiary3 = getValueByFieldName(fieldValues, SSN_TAX_ID_BENEFICIARY3);
    const dobOrTrustDateBeneficiary3 = getValueByFieldName(fieldValues, DOB_OR_TRUST_DATE_BENEFICIARY3);
    const legalAddressBeneficiary3 = getValueByFieldName(fieldValues, LEGAL_ADDRESS_BENEFICIARY3);
    const cityBeneficiary3 = getValueByFieldName(fieldValues, CITY_BENEFICIARY3);
    const stateBeneficiary3 = getValueByFieldName(fieldValues, STATE_BENEFICIARY3);
    const zipCodeBeneficiary3 = getValueByFieldName(fieldValues, ZIP_CODE_BENEFICIARY3);
    const countryBeneficiary3 = getValueByFieldName(fieldValues, COUNTRY_BENEFICIARY3);
    const homePhoneBeneficiary3 = getValueByFieldName(fieldValues, HOME_PHONE_BENEFICIARY3);
    const emailAddressBeneficiary3 = getValueByFieldName(fieldValues, EMAIL_ADDRESS_BENEFICIARY3);

    const usaCitizenshipBeneficiary3 = getValueByFieldName(fieldValues, USA_CITIZENSHIP_BENEFICIARY3) === 'checked';
    const otherCitizenshipBeneficiary3 = getValueByFieldName(fieldValues, OTHER_CITIZENSHIP_BENEFICIARY3) === 'checked';
    const citizenshipCountryBeneficiary3 = getValueByFieldName(fieldValues, CITIZENSHIP_COUNTRY_BENEFICIARY3);
    let citizenShipOption = '';
    if (usaCitizenshipBeneficiary3) {
      citizenShipOption = 'USA';
    } else if (otherCitizenshipBeneficiary3) {
      citizenShipOption = 'OTHER';
    }
    const usaResidenceBeneficiary3 = getValueByFieldName(fieldValues, USA_RESIDENCE_BENEFICIARY3) === 'checked';
    const otherResidenceBeneficiary3 = getValueByFieldName(fieldValues, OTHER_RESIDENCE_BENEFICIARY3) === 'checked';
    const residenceCountryBeneficiary3 = getValueByFieldName(fieldValues, RESIDENCE_COUNTRY_BENEFICIARY3);
    let residenceOption = '';
    if (usaResidenceBeneficiary3) {
      residenceOption = 'USA';
    } else if (otherResidenceBeneficiary3) {
      residenceOption = 'OTHER';
    }
    const beneficiary = {
      type: typeOption,
      portion: percentageBeneficiary3,
      firstName: firstNameBeneficiary3,
      middleName: middleNameBeneficiary3,
      lastName: lastNameBeneficiary3,
      suffix: suffixBeneficiary3,
      trustOrganizationEstate: trustOrEstateBeneficiary3,
      relationship: relationShipOption,
      ssn: ssnTaxIdBeneficiary3,
      dateOfBirth: dobOrTrustDateBeneficiary3 ? moment(dobOrTrustDateBeneficiary3).toDate() : moment().subtract(20, 'years').startOf('year').toDate(),
      mailingAddress: legalAddressBeneficiary3,
      city: cityBeneficiary3,
      state: stateBeneficiary3,
      postalCode: zipCodeBeneficiary3,
      country: countryBeneficiary3,
      telephoneNumber: homePhoneBeneficiary3,
      email: emailAddressBeneficiary3,
      citizenShip: citizenShipOption || 'USA',
      otherCitizenShip: citizenshipCountryBeneficiary3,
      residence: residenceOption || 'USA',
      otherResidence: residenceCountryBeneficiary3,
    };
    setBeneficiaries(prev => [...prev, {...beneficiary}]);
  }

  const getBeneficiary4Data = (fieldValues: any) => {
    const primaryBeneficiary4 = getValueByFieldName(fieldValues, PRIMARY_BENEFICIARY4) === 'checked';
    const contingentBeneficiary4 = getValueByFieldName(fieldValues, CONTINGENT_BENEFICIARY4) === 'checked';
    let typeOption = '';
    if (primaryBeneficiary4) {
      typeOption = 'Primary';
    } else if (contingentBeneficiary4) {
      typeOption = 'Contingent';
    }
    if (typeOption === '') return;
    const portionPercentageBeneficiary4 = getValueByFieldName(fieldValues, PORTION_PERCENTAGE_BENEFICIARY4);
    const firstNameBeneficiary4 = getValueByFieldName(fieldValues, FIRST_NAME_BENEFICIARY4);
    const middleNameBeneficiary4 = getValueByFieldName(fieldValues, MIDDLE_NAME_BENEFICIARY4);
    const lastNameBeneficiary4 = getValueByFieldName(fieldValues, LAST_NAME_BENEFICIARY4);
    const suffixBeneficiary4 = getValueByFieldName(fieldValues, SUFFIX_BENEFICIARY4);
    const trustOrEstateBeneficiary4 = getValueByFieldName(fieldValues, TRUST_OR_ESTATE_BENEFICIARY4);

    const relationshipSpouseBeneficiary4 = getValueByFieldName(fieldValues, RELATIONSHIP_SPOUSE_BENEFICIARY4) === 'checked';
    const relationshipChildBeneficiary4 = getValueByFieldName(fieldValues, RELATIONSHIP_CHILD_BENEFICIARY4) === 'checked';
    const relationshipGrandchildBeneficiary4 = getValueByFieldName(fieldValues, RELATIONSHIP_GRANDCHILD_BENEFICIARY4) === 'checked';
    const relationshipParentBeneficiary4 = getValueByFieldName(fieldValues, RELATIONSHIP_PARENT_BENEFICIARY4) === 'checked';
    const relationshipSiblingBeneficiary4 = getValueByFieldName(fieldValues, RELATIONSHIP_SIBLING_BENEFICIARY4) === 'checked';
    const relationshipOtherBeneficiary4 = getValueByFieldName(fieldValues, RELATIONSHIP_OTHER_BENEFICIARY4) === 'checked';
    const relationshipTrustBeneficiary4 = getValueByFieldName(fieldValues, RELATIONSHIP_TRUST_BENEFICIARY4) === 'checked';
    const relationshipOrganizationBeneficiary4 = getValueByFieldName(fieldValues, RELATIONSHIP_ORGANIZATION_BENEFICIARY4) === 'checked';
    const relationshipEstateBeneficiary4 = getValueByFieldName(fieldValues, RELATIONSHIP_ESTATE_BENEFICIARY4) === 'checked';
    let relationShipOption = '';
    if (relationshipSpouseBeneficiary4) {
      relationShipOption = 'Spouse';
    } else if (relationshipChildBeneficiary4) {
      relationShipOption = 'Child';
    } else if (relationshipGrandchildBeneficiary4) {
      relationShipOption = 'Grandchild';
    } else if (relationshipParentBeneficiary4) {
      relationShipOption = 'Parent';
    } else if (relationshipSiblingBeneficiary4) {
      relationShipOption = 'Sibling';
    } else if (relationshipOtherBeneficiary4) {
      relationShipOption = 'OtherIndividual';
    } else if (relationshipTrustBeneficiary4) {
      relationShipOption = 'Trust';
    } else if (relationshipOrganizationBeneficiary4) {
      relationShipOption = 'Organization';
    } else if (relationshipEstateBeneficiary4) {
      relationShipOption = 'Estate';
    }

    const ssnTaxIdBeneficiary4 = getValueByFieldName(fieldValues, SSN_TAX_ID_BENEFICIARY4);
    const dobOrTrustDateBeneficiary4 = getValueByFieldName(fieldValues, DOB_OR_TRUST_DATE_BENEFICIARY4);
    const legalAddressBeneficiary4 = getValueByFieldName(fieldValues, LEGAL_ADDRESS_BENEFICIARY4);
    const cityBeneficiary4 = getValueByFieldName(fieldValues, CITY_BENEFICIARY4);
    const stateBeneficiary4 = getValueByFieldName(fieldValues, STATE_BENEFICIARY4);
    const zipCodeBeneficiary4 = getValueByFieldName(fieldValues, ZIP_CODE_BENEFICIARY4);
    const countryBeneficiary4 = getValueByFieldName(fieldValues, COUNTRY_BENEFICIARY4);
    const homePhoneBeneficiary4 = getValueByFieldName(fieldValues, HOME_PHONE_BENEFICIARY4);
    const emailAddressBeneficiary4 = getValueByFieldName(fieldValues, EMAIL_ADDRESS_BENEFICIARY4);

    const citizenshipUsaBeneficiary4 = getValueByFieldName(fieldValues, CITIZENSHIP_USA_BENEFICIARY4) === 'checked';
    const citizenshipOther1Beneficiary4 = getValueByFieldName(fieldValues, CITIZENSHIP_OTHER1_BENEFICIARY4) === 'checked';
    const country1Beneficiary4 = getValueByFieldName(fieldValues, COUNTRY1_BENEFICIARY4);
    let citizenShipOption = '';
    if (citizenshipUsaBeneficiary4) {
      citizenShipOption = 'USA';
    } else if (citizenshipOther1Beneficiary4) {
      citizenShipOption = 'OTHER';
    }
    const residenceUsaBeneficiary4 = getValueByFieldName(fieldValues, RESIDENCE_USA_BENEFICIARY4) === 'checked';
    const residenceOtherBeneficiary4 = getValueByFieldName(fieldValues, RESIDENCE_OTHER_BENEFICIARY4) === 'checked';
    const residenceCountryBeneficiary4 = getValueByFieldName(fieldValues, RESIDENCE_COUNTRY_BENEFICIARY4);
    let residenceOption = '';
    if (residenceUsaBeneficiary4) {
      residenceOption = 'USA';
    } else if (residenceOtherBeneficiary4) {
      residenceOption = 'OTHER';
    }
    const beneficiary = {
      type: typeOption,
      portion: portionPercentageBeneficiary4,
      firstName: firstNameBeneficiary4,
      middleName: middleNameBeneficiary4,
      lastName: lastNameBeneficiary4,
      suffix: suffixBeneficiary4,
      trustOrganizationEstate: trustOrEstateBeneficiary4,
      relationship: relationShipOption,
      ssn: ssnTaxIdBeneficiary4,
      dateOfBirth: dobOrTrustDateBeneficiary4 ? moment(dobOrTrustDateBeneficiary4).toDate() : moment().subtract(20, 'years').startOf('year').toDate(),
      mailingAddress: legalAddressBeneficiary4,
      city: cityBeneficiary4,
      state: stateBeneficiary4,
      postalCode: zipCodeBeneficiary4,
      country: countryBeneficiary4,
      telephoneNumber: homePhoneBeneficiary4,
      email: emailAddressBeneficiary4,
      citizenShip: citizenShipOption || 'USA',
      otherCitizenShip: country1Beneficiary4,
      residence: residenceOption || 'USA',
      otherResidence: residenceCountryBeneficiary4,
    };
    setBeneficiaries(prev => [...prev, {...beneficiary}]);
  }

  useEffect(() => {
    fetchFormValues();
  }, []);

  const fetchFormValues = () => {
    setIsLoading(true);
    getFormFieldValues(userFormId)
      .then(({fieldValues}) => {
        getBeneficiary1Data(fieldValues);
        getBeneficiary2Data(fieldValues);
        getBeneficiary3Data(fieldValues);
        getBeneficiary4Data(fieldValues);
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsLoading(false))
  }

  const updateData = async () => {
    await updateFormFieldValues([
      {
        [PRIMARY_BENEFICIARY1]: beneficiaries[0].type === 'Primary' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [CONTINGENT_BENEFICIARY1]: beneficiaries[0].type === 'Contingent' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [PERCENTAGE_BENEFICIARY1]: beneficiaries[0].portion,
        fieldType: 'PDFTextField'
      },
      {
        [FIRST_NAME_BENEFICIARY1]: beneficiaries[0].firstName,
        fieldType: 'PDFTextField'
      },
      {
        [MIDDLE_NAME_BENEFICIARY1]: beneficiaries[0].middleName,
        fieldType: 'PDFTextField'
      },
      {
        [LAST_NAME_BENEFICIARY1]: beneficiaries[0].lastName,
        fieldType: 'PDFTextField'
      },
      {
        [SUFFIX_BENEFICIARY1]: beneficiaries[0].suffix,
        fieldType: 'PDFTextField'
      },
      {
        [TRUST_OR_ESTATE_BENEFICIARY1]: beneficiaries[0].trustOrganizationEstate,
        fieldType: 'PDFTextField'
      },
      {
        [SPOUSE_RELATIONSHIP_BENEFICIARY1]: beneficiaries[0].relationship === 'Spouse' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [CHILD_RELATIONSHIP_BENEFICIARY1]: beneficiaries[0].relationship === 'Child' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [GRANDCHILD_RELATIONSHIP_BENEFICIARY1]: beneficiaries[0].relationship === 'Grandchild' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [PARENT_RELATIONSHIP_BENEFICIARY1]: beneficiaries[0].relationship === 'Parent' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [SIBLING_RELATIONSHIP_BENEFICIARY1]: beneficiaries[0].relationship === 'Sibling' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OTHER_RELATIONSHIP_BENEFICIARY1]: beneficiaries[0].relationship === 'OtherIndividual' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRUST_RELATIONSHIP_BENEFICIARY1]: beneficiaries[0].relationship === 'Trust' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [ORGANIZATION_RELATIONSHIP_BENEFICIARY1]: beneficiaries[0].relationship === 'Organization' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [ESTATE_RELATIONSHIP_BENEFICIARY1]: beneficiaries[0].relationship === 'Estate' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [SSN_TAX_ID_BENEFICIARY1]: beneficiaries[0].ssn,
        fieldType: 'PDFTextField'
      },
      {
        [DOB_OR_TRUST_DATE_BENEFICIARY1]: beneficiaries[0].dateOfBirth ? moment(beneficiaries[0].dateOfBirth).format('MM/DD/YYYY') : '',
        fieldType: 'PDFTextField'
      },
      {
        [LEGAL_ADDRESS_BENEFICIARY1]: beneficiaries[0].mailingAddress,
        fieldType: 'PDFTextField'
      },
      {
        [CITY_BENEFICIARY1]: beneficiaries[0].city,
        fieldType: 'PDFTextField'
      },
      {
        [STATE_BENEFICIARY1]: beneficiaries[0].state,
        fieldType: 'PDFTextField'
      },
      {
        [ZIP_CODE_BENEFICIARY1]: beneficiaries[0].postalCode,
        fieldType: 'PDFTextField'
      },
      {
        [COUNTRY_BENEFICIARY1]: beneficiaries[0].country,
        fieldType: 'PDFTextField'
      },
      {
        [HOME_PHONE_BENEFICIARY1]: beneficiaries[0].telephoneNumber,
        fieldType: 'PDFTextField'
      },
      {
        [EMAIL_ADDRESS_BENEFICIARY1]: beneficiaries[0].email,
        fieldType: 'PDFTextField'
      },
      {
        [USA_CITIZENSHIP_BENEFICIARY1]: beneficiaries[0].citizenShip === 'USA' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OTHER_CITIZENSHIP_BENEFICIARY1]: beneficiaries[0].citizenShip === 'OTHER' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [CITIZENSHIP_COUNTRY_BENEFICIARY1]: beneficiaries[0].citizenShip === 'OTHER' ? beneficiaries[0].otherCitizenShip : '',
        fieldType: 'PDFTextField'
      },
      {
        [USA_RESIDENCE_BENEFICIARY1]: beneficiaries[0].residence === 'USA' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [OTHER_RESIDENCE_BENEFICIARY1]: beneficiaries[0].residence === 'OTHER' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [CITIZENSHIP_RESIDENCE_BENEFICIARY1]: beneficiaries[0].residence === 'OTHER' ? beneficiaries[0].otherResidence : '',
        fieldType: 'PDFTextField'
      },
      ...(beneficiaries.length > 1 ? [
        {
          [PRIMARY_BENEFICIARY2]: beneficiaries[1].type === 'Primary' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [CONTINGENT_BENEFICIARY2]: beneficiaries[1].type === 'Contingent' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [PORTION_PERCENTAGE_BENEFICIARY2]: beneficiaries[1].portion,
          fieldType: 'PDFTextField'
        },
        {
          [FIRST_NAME_BENEFICIARY2]: beneficiaries[1].firstName,
          fieldType: 'PDFTextField'
        },
        {
          [MIDDLE_NAME_BENEFICIARY2]: beneficiaries[1].middleName,
          fieldType: 'PDFTextField'
        },
        {
          [LAST_NAME_BENEFICIARY2]: beneficiaries[1].lastName,
          fieldType: 'PDFTextField'
        },
        {
          [SUFFIX_BENEFICIARY2]: beneficiaries[1].suffix,
          fieldType: 'PDFTextField'
        },
        {
          [TRUST_OR_ESTATE_BENEFICIARY2]: beneficiaries[1].trustOrganizationEstate,
          fieldType: 'PDFTextField'
        },
        {
          [RELATIONSHIP_SPOUSE_BENEFICIARY2]: beneficiaries[1].relationship === 'Spouse' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_CHILD_BENEFICIARY2]: beneficiaries[1].relationship === 'Child' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_GRANDCHILD_BENEFICIARY2]: beneficiaries[1].relationship === 'Grandchild' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_PARENT_BENEFICIARY2]: beneficiaries[1].relationship === 'Parent' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_SIBLING_BENEFICIARY2]: beneficiaries[1].relationship === 'Sibling' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_OTHER_BENEFICIARY2]: beneficiaries[1].relationship === 'OtherIndividual' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_TRUST_BENEFICIARY2]: beneficiaries[1].relationship === 'Trust' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_ORGANIZATION_BENEFICIARY2]: beneficiaries[1].relationship === 'Organization' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_ESTATE_BENEFICIARY2]: beneficiaries[1].relationship === 'Estate' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [SSN_TAX_ID_BENEFICIARY2]: beneficiaries[1].ssn,
          fieldType: 'PDFTextField'
        },
        {
          [DOB_OR_TRUST_DATE_BENEFICIARY2]: beneficiaries[1].dateOfBirth ? moment(beneficiaries[1].dateOfBirth).format('MM/DD/YYYY') : '',
          fieldType: 'PDFTextField'
        },
        {
          [LEGAL_ADDRESS_BENEFICIARY2]: beneficiaries[1].mailingAddress,
          fieldType: 'PDFTextField'
        },
        {
          [CITY_BENEFICIARY2]: beneficiaries[1].city,
          fieldType: 'PDFTextField'
        },
        {
          [STATE_BENEFICIARY2]: beneficiaries[1].state,
          fieldType: 'PDFTextField'
        },
        {
          [ZIP_CODE_BENEFICIARY2]: beneficiaries[1].postalCode,
          fieldType: 'PDFTextField'
        },
        {
          [COUNTRY_BENEFICIARY2]: beneficiaries[1].country,
          fieldType: 'PDFTextField'
        },
        {
          [HOME_PHONE_BENEFICIARY2]: beneficiaries[1].telephoneNumber,
          fieldType: 'PDFTextField'
        },
        {
          [EMAIL_ADDRESS_BENEFICIARY2]: beneficiaries[1].email,
          fieldType: 'PDFTextField'
        },
        {
          [CITIZENSHIP_USA_BENEFICIARY2]: beneficiaries[1].citizenShip === 'USA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [CITIZENSHIP_OTHER1_BENEFICIARY2]: beneficiaries[1].citizenShip === 'OTHER' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [COUNTRY1_BENEFICIARY2]: beneficiaries[1].citizenShip === 'OTHER' ? beneficiaries[1].otherCitizenShip : '',
          fieldType: 'PDFTextField'
        },
        {
          [RESIDENCE_USA_BENEFICIARY2]: beneficiaries[1].residence === 'USA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RESIDENCE_OTHER_BENEFICIARY2]: beneficiaries[1].residence === 'OTHER' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RESIDENCE_COUNTRY_BENEFICIARY2]: beneficiaries[1].residence === 'OTHER' ? beneficiaries[1].otherResidence : '',
          fieldType: 'PDFTextField'
        },
      ] : []),
      ...(beneficiaries.length > 2 ? [
        {
          [PRIMARY_BENEFICIARY3]: beneficiaries[2].type === 'Primary' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [CONTINGENT_BENEFICIARY3]: beneficiaries[2].type === 'Contingent' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [PERCENTAGE_BENEFICIARY3]: beneficiaries[2].portion,
          fieldType: 'PDFTextField'
        },
        {
          [FIRST_NAME_BENEFICIARY3]: beneficiaries[2].firstName,
          fieldType: 'PDFTextField'
        },
        {
          [MIDDLE_NAME_BENEFICIARY3]: beneficiaries[2].middleName,
          fieldType: 'PDFTextField'
        },
        {
          [LAST_NAME_BENEFICIARY3]: beneficiaries[2].lastName,
          fieldType: 'PDFTextField'
        },
        {
          [SUFFIX_BENEFICIARY3]: beneficiaries[2].suffix,
          fieldType: 'PDFTextField'
        },
        {
          [TRUST_OR_ESTATE_BENEFICIARY3]: beneficiaries[2].trustOrganizationEstate,
          fieldType: 'PDFTextField'
        },
        {
          [SPOUSE_RELATIONSHIP_BENEFICIARY3]: beneficiaries[2].relationship === 'Spouse' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [CHILD_RELATIONSHIP_BENEFICIARY3]: beneficiaries[2].relationship === 'Child' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [GRANDCHILD_RELATIONSHIP_BENEFICIARY3]: beneficiaries[2].relationship === 'Grandchild' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [PARENT_RELATIONSHIP_BENEFICIARY3]: beneficiaries[2].relationship === 'Parent' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [SIBLING_RELATIONSHIP_BENEFICIARY3]: beneficiaries[2].relationship === 'Sibling' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [OTHER_RELATIONSHIP_BENEFICIARY3]: beneficiaries[2].relationship === 'OtherIndividual' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [TRUST_RELATIONSHIP_BENEFICIARY3]: beneficiaries[2].relationship === 'Trust' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [ORGANIZATION_RELATIONSHIP_BENEFICIARY3]: beneficiaries[2].relationship === 'Organization' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [ESTATE_RELATIONSHIP_BENEFICIARY3]: beneficiaries[2].relationship === 'Estate' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [SSN_TAX_ID_BENEFICIARY3]: beneficiaries[2].ssn,
          fieldType: 'PDFTextField'
        },
        {
          [DOB_OR_TRUST_DATE_BENEFICIARY3]: beneficiaries[2].dateOfBirth ? moment(beneficiaries[2].dateOfBirth).format('MM/DD/YYYY') : '',
          fieldType: 'PDFTextField'
        },
        {
          [LEGAL_ADDRESS_BENEFICIARY3]: beneficiaries[2].mailingAddress,
          fieldType: 'PDFTextField'
        },
        {
          [CITY_BENEFICIARY3]: beneficiaries[2].city,
          fieldType: 'PDFTextField'
        },
        {
          [STATE_BENEFICIARY3]: beneficiaries[2].state,
          fieldType: 'PDFTextField'
        },
        {
          [ZIP_CODE_BENEFICIARY3]: beneficiaries[2].postalCode,
          fieldType: 'PDFTextField'
        },
        {
          [COUNTRY_BENEFICIARY3]: beneficiaries[2].country,
          fieldType: 'PDFTextField'
        },
        {
          [HOME_PHONE_BENEFICIARY3]: beneficiaries[2].telephoneNumber,
          fieldType: 'PDFTextField'
        },
        {
          [EMAIL_ADDRESS_BENEFICIARY3]: beneficiaries[2].email,
          fieldType: 'PDFTextField'
        },
        {
          [USA_CITIZENSHIP_BENEFICIARY3]: beneficiaries[2].citizenShip === 'USA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [OTHER_CITIZENSHIP_BENEFICIARY3]: beneficiaries[2].citizenShip === 'OTHER' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [CITIZENSHIP_COUNTRY_BENEFICIARY3]: beneficiaries[2].citizenShip === 'OTHER' ? beneficiaries[2].otherCitizenShip : '',
          fieldType: 'PDFTextField'
        },
        {
          [USA_RESIDENCE_BENEFICIARY3]: beneficiaries[2].residence === 'USA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [OTHER_RESIDENCE_BENEFICIARY3]: beneficiaries[2].residence === 'OTHER' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RESIDENCE_COUNTRY_BENEFICIARY3]: beneficiaries[2].residence === 'OTHER' ? beneficiaries[2].otherResidence : '',
          fieldType: 'PDFTextField'
        },
      ] : []),
      ...(beneficiaries.length > 3 ? [
        {
          [PRIMARY_BENEFICIARY4]: beneficiaries[3].type === 'Primary' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [CONTINGENT_BENEFICIARY4]: beneficiaries[3].type === 'Contingent' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [PORTION_PERCENTAGE_BENEFICIARY4]: beneficiaries[3].portion,
          fieldType: 'PDFTextField'
        },
        {
          [FIRST_NAME_BENEFICIARY4]: beneficiaries[3].firstName,
          fieldType: 'PDFTextField'
        },
        {
          [MIDDLE_NAME_BENEFICIARY4]: beneficiaries[3].middleName,
          fieldType: 'PDFTextField'
        },
        {
          [LAST_NAME_BENEFICIARY4]: beneficiaries[3].lastName,
          fieldType: 'PDFTextField'
        },
        {
          [SUFFIX_BENEFICIARY4]: beneficiaries[3].suffix,
          fieldType: 'PDFTextField'
        },
        {
          [TRUST_OR_ESTATE_BENEFICIARY4]: beneficiaries[3].trustOrganizationEstate,
          fieldType: 'PDFTextField'
        },
        {
          [RELATIONSHIP_SPOUSE_BENEFICIARY4]: beneficiaries[3].relationship === 'Spouse' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_CHILD_BENEFICIARY4]: beneficiaries[3].relationship === 'Child' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_GRANDCHILD_BENEFICIARY4]: beneficiaries[3].relationship === 'Grandchild' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_PARENT_BENEFICIARY4]: beneficiaries[3].relationship === 'Parent' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_SIBLING_BENEFICIARY4]: beneficiaries[3].relationship === 'Sibling' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_OTHER_BENEFICIARY4]: beneficiaries[3].relationship === 'OtherIndividual' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_TRUST_BENEFICIARY4]: beneficiaries[3].relationship === 'Trust' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_ORGANIZATION_BENEFICIARY4]: beneficiaries[3].relationship === 'Organization' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RELATIONSHIP_ESTATE_BENEFICIARY4]: beneficiaries[3].relationship === 'Estate' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [SSN_TAX_ID_BENEFICIARY4]: beneficiaries[3].ssn,
          fieldType: 'PDFTextField'
        },
        {
          [DOB_OR_TRUST_DATE_BENEFICIARY4]: beneficiaries[3].dateOfBirth ? moment(beneficiaries[3].dateOfBirth).format('MM/DD/YYYY') : '',
          fieldType: 'PDFTextField'
        },
        {
          [LEGAL_ADDRESS_BENEFICIARY4]: beneficiaries[3].mailingAddress,
          fieldType: 'PDFTextField'
        },
        {
          [CITY_BENEFICIARY4]: beneficiaries[3].city,
          fieldType: 'PDFTextField'
        },
        {
          [STATE_BENEFICIARY4]: beneficiaries[3].state,
          fieldType: 'PDFTextField'
        },
        {
          [ZIP_CODE_BENEFICIARY4]: beneficiaries[3].postalCode,
          fieldType: 'PDFTextField'
        },
        {
          [COUNTRY_BENEFICIARY4]: beneficiaries[3].country,
          fieldType: 'PDFTextField'
        },
        {
          [HOME_PHONE_BENEFICIARY4]: beneficiaries[3].telephoneNumber,
          fieldType: 'PDFTextField'
        },
        {
          [EMAIL_ADDRESS_BENEFICIARY4]: beneficiaries[3].email,
          fieldType: 'PDFTextField'
        },
        {
          [CITIZENSHIP_USA_BENEFICIARY4]: beneficiaries[3].citizenShip === 'USA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [CITIZENSHIP_OTHER1_BENEFICIARY4]: beneficiaries[3].citizenShip === 'OTHER' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [COUNTRY1_BENEFICIARY4]: beneficiaries[3].citizenShip === 'OTHER' ? beneficiaries[3].otherCitizenShip : '',
          fieldType: 'PDFTextField'
        },
        {
          [RESIDENCE_USA_BENEFICIARY4]: beneficiaries[3].residence === 'USA' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RESIDENCE_OTHER_BENEFICIARY4]: beneficiaries[3].residence === 'OTHER' ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [RESIDENCE_COUNTRY_BENEFICIARY4]: beneficiaries[3].residence === 'OTHER' ? beneficiaries[3].otherResidence : '',
          fieldType: 'PDFTextField'
        }
      ] : [])
    ], userFormId)
  }

  useImperativeHandle(ref, () => ({
    handleNext: async () => {
      try {
        await updateData();
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  }));

  return (
    <Box h='full'>
      <Text
        fontSize="2xl"
        fontWeight="500">
        { title }
      </Text>
      <Flex p={ {base: '0px', md: '32px'} } direction='column' align={ isLoading ? 'center' : 'start' }
            justify={ isLoading ? 'center' : 'start' } h='full'>
        {
          isLoading ? <CirclesWithBar
            height="100"
            width="100"
            wrapperClass=""
            visible={ true }
            ariaLabel="circles-with-bar-loading"
          /> : <Box w='full'>
            <Text>
              In the event of my death, pay the full value of my account (in equal proportions, in the case of multiple
              beneficiaries, unless I indicate otherwise) to the Primary Beneficiary(ies) as designated below.
              I understand that if a Primary Beneficiary passes away before me, the remaining portion will be divided
              proportionately to
              any surviving Primary Beneficiaries in the manner provided in the Charles Schwab & Co., Inc. Individual
              Retirement Plan.
            </Text>
            <Flex hidden={ showMore } direction='column'>
              <Text>
                If no Primary Beneficiary survives me, pay the full value of my account (in equal
                proportions, in the case of multiple beneficiaries, unless I indicate otherwise) to the Contingent
                Beneficiary(ies) as designated below. I understand that if a Contingent Beneficiary passes away before
                me,
                the remaining portion will be divided proportionately among any surviving Contingent Beneficiaries in
                the
                manner
                provided in the Charles Schwab & Co., Inc. Individual Retirement Plan. Any beneficiary who does not
                survive
                me by 120 hours will be considered to have passed away before me. If I do not designate a Beneficiary,
                the
                balance of the account shall be distributed in the listed order of priority to the following named
                person(s)
                surviving me:
              </Text>
              <Text>
                In the event of my death, pay the full value of my account (in equal proportions, in the case of
                multiple
                beneficiaries, unless I indicate otherwise) to the Primary Beneficiary(ies) as designated below. I
                understand that if a Primary Beneficiary passes away before me, the remaining portion will be divided
                proportionately
                to any surviving Primary Beneficiaries in the manner provided in the Charles Schwab & Co., Inc.
                Individual
                Retirement Plan. If no Primary Beneficiary survives me, pay the full value of my account (in equal
                proportions, in the case of multiple beneficiaries, unless I indicate otherwise) to the Contingent
                Beneficiary(ies) as designated below. I understand that if a Contingent Beneficiary passes away before
                me,
                the remaining portion will be divided proportionately among any surviving Contingent Beneficiaries in
                the
                manner provided in the Charles Schwab & Co., Inc. Individual Retirement Plan. Any beneficiary who does
                not
                survive me by 120 hours will be considered to have passed away before me. If I do not designate a
                Beneficiary, the balance of the account shall be distributed in the listed order of priority to the
                following named person(s) surviving me:
              </Text>
              <Text>
                a) my spouse,
              </Text>
              <Text>
                b) my living children (natural or legally adopted) in equal shares; stepchildren are not legally defined
                as
                descendants for these purposes,
              </Text>
              <Text>
                c) my estate.
              </Text>
              <Text>
                I understand that I may change or revoke this designation at any time by completing a new Beneficiary
                Form
                with Schwab during my lifetime. It will become effective when Schwab receives it.
              </Text>
              <Text>
                I understand that if Schwab determines that my beneficiary designation is not clear with respect to the
                amount of the distribution, the date on which the distribution shall be made, or the identity of the
                party
                or parties who will receive the distribution, Schwab shall have the right, in its sole discretion, to
                consult counsel and to institute legal proceedings to determine the proper distribution of the account,
                all
                at the expense of the account, before distributing or transferring the account.
              </Text>
              <Text>
                If I live in a state with community property statutes and do not designate my spouse as the sole Primary
                Beneficiary, I represent and warrant that my spouse has consented to such designation.
              </Text>
              <Text>
                The percentage portions must add up to 100% per beneficiary type. All portions can be extended to the
                hundredths position (e.g., 33.33%). If section is left blank, portions will be evenly distributed
                amongst
                beneficiaries.
              </Text>
              <Text>
                Note: Benefits cannot be expressed in dollar amounts.
              </Text>
              <Text>
                If you wish to indicate additional designation options, including, but not limited to Per Stirpes or Per
                Capita designations, please skip this section,complete the Schwab IRA Beneficiary Designation form, and
                submit it with this application. For future beneficiary designations and changes, visit
                www.schwaballiance.com/beneficiaries.
              </Text>
            </Flex>
            <Flex justify='end'>
              <Text fontWeight='500' cursor='pointer' color='brand.500' onClick={ () => setShowMore(prev => !prev) }>
                Show { showMore ? 'more' : 'less' }...
              </Text>
            </Flex>
            {
              beneficiaries.map((beneficiary, index) => {
                return (
                  <Box
                    key={ index }
                    mt='8px'
                    borderRadius='4px'
                    p='12px'
                    borderWidth='1px'
                    w='full'
                  >
                    <Text
                      color={ textColor }
                      backgroundColor='gray.300'
                      p='4px'
                      fontWeight='400'>
                      Beneficiary { index + 1 }
                    </Text>
                    <FormControl isRequired mt='12px'>
                      <FormLabel>Type of Beneficiary</FormLabel>
                      <RadioGroup value={ beneficiary.type } onChange={ (e) => handleInputChange(index, 'type', e) }>
                        <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ {base: 2, md: 4} }
                              align={ {base: 'start', md: 'center'} }>
                          <Radio colorScheme="brandScheme" value='Primary'>Primary</Radio>
                          <Radio colorScheme="brandScheme" value='Contingent'>Contingent</Radio>
                          <FormControl>
                            <Flex align='center' gap={ 2 }>
                              <Text minW='80px'>Portion (%):</Text>
                              <Input
                                type="text"
                                color={ textColor }
                                maxW={ {base: 'full', md: 'xs'} }
                                value={ beneficiary.portion }
                                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                                  handleInputChange(index, 'portion', e.target.value);
                                } }
                              />
                            </Flex>
                          </FormControl>
                        </Flex>
                      </RadioGroup>
                    </FormControl>

                    <Grid
                      templateColumns={ {base: '1fr', md: '2fr 2fr 2fr 1fr'} }
                      gap={ {base: 4, md: 2} }
                    >
                      <FormControl isRequired mt='12px'>
                        <FormLabel>First Name</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.firstName }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'firstName', e.target.value);
                          } }
                        />
                      </FormControl>
                      <FormControl mt='12px'>
                        <FormLabel>Middle Name</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.middleName }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'middleName', e.target.value);
                          } }
                        />
                      </FormControl>
                      <FormControl isRequired mt='12px'>
                        <FormLabel>Last Name</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.lastName }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'lastName', e.target.value);
                          } }
                        />
                      </FormControl>
                      <FormControl mt='12px'>
                        <FormLabel>Suffix</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.suffix }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'suffix', e.target.value);
                          } }
                        />
                      </FormControl>
                    </Grid>
                    <FormControl mt='12px'>
                      <FormLabel>Trust/Organization/Estate (If Trust, include full Trust name.)</FormLabel>
                      <Input
                        type="text"
                        color={ textColor }
                        value={ beneficiary.trustOrganizationEstate }
                        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                          handleInputChange(index, 'trustOrganizationEstate', e.target.value);
                        } }
                      />
                    </FormControl>
                    <FormControl isRequired mt='12px'>
                      <FormLabel>Relationship (Please select only one.)</FormLabel>
                      <RadioGroup value={ beneficiary.relationship }
                                  onChange={ (e) => handleInputChange(index, 'relationship', e) }>
                        <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                          <Radio colorScheme="brandScheme" value='Spouse'>Spouse</Radio>
                          <Radio colorScheme="brandScheme" value='Child'>Child</Radio>
                          <Radio colorScheme="brandScheme" value='Grandchild'>Grandchild</Radio>
                          <Radio colorScheme="brandScheme" value='Parent'>Parent</Radio>
                          <Radio colorScheme="brandScheme" value='Sibling'>Sibling</Radio>
                          <Radio colorScheme="brandScheme" value='OtherIndividual'>Other Individual</Radio>
                          <Radio colorScheme="brandScheme" value='Trust'>Trust</Radio>
                          <Radio colorScheme="brandScheme" value='Organization'>Organization</Radio>
                          <Radio colorScheme="brandScheme" value='Estate'>Estate</Radio>
                        </Flex>
                      </RadioGroup>
                    </FormControl>

                    <Grid
                      templateColumns={ {base: '1fr', md: '1fr 1fr 2fr'} }
                      gap={ {base: 4, md: 2} }
                    >
                      <FormControl isRequired mt='12px'>
                        <FormLabel>Social Security/Tax ID Number</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.ssn }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'ssn', e.target.value);
                          } }
                        />
                      </FormControl>
                      <FormControl isRequired mt='12px'>
                        <FormLabel>Date of Birth/Trust Date</FormLabel>
                        <SingleDatepicker
                          name="date-input"
                          configs={ {
                            dateFormat: 'MM/dd/yyyy'
                          } }
                          maxDate={ new Date() }
                          date={ beneficiary.dateOfBirth }
                          onDateChange={ (date) => handleInputChange(index, 'dateOfBirth', date) }
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      templateColumns={ {base: '1fr', md: '3fr 1fr'} }
                      gap={ {base: 4, md: 2} }
                    >
                      <FormControl isRequired mt='12px'>
                        <FormLabel>Mailing Street Address (no P.O. boxes)</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.mailingAddress }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'mailingAddress', e.target.value);
                          } }
                        />
                      </FormControl>
                      <FormControl isRequired mt='12px'>
                        <FormLabel>City</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.city }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'city', e.target.value);
                          } }
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      templateColumns={ {base: '1fr', md: '3fr 2fr 1fr'} }
                      gap={ {base: 4, md: 2} }
                    >
                      <FormControl isRequired mt='12px'>
                        <FormLabel>State or Province</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.postalCode }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'postalCode', e.target.value);
                          } }
                        />
                      </FormControl>
                      <FormControl isRequired mt='12px'>
                        <FormLabel>Zip or Postal Code</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.postalCode }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'postalCode', e.target.value);
                          } }
                        />
                      </FormControl>
                      <FormControl isRequired mt='12px'>
                        <FormLabel>Country</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.country }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'country', e.target.value);
                          } }
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      templateColumns={ {base: '1fr', md: '1fr 2fr'} }
                      gap={ {base: 4, md: 2} }
                    >
                      <FormControl mt='12px'>
                        <FormLabel>Telephone Number</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.telephoneNumber }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'telephoneNumber', e.target.value);
                          } }
                        />
                      </FormControl>
                      <FormControl isRequired mt='12px'>
                        <FormLabel>Email Address</FormLabel>
                        <Input
                          type="text"
                          color={ textColor }
                          value={ beneficiary.email }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(index, 'email', e.target.value);
                          } }
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      mt="12px"
                      templateColumns={ {base: '1fr', md: 'repeat(2, 1fr)'} }
                      gap={ {base: 4, md: 2} }
                    >
                      <FormControl isRequired mt='12px'>
                        <FormLabel>Country(ies) of Citizenship</FormLabel>
                        <RadioGroup value={ beneficiary.citizenShip }
                                    onChange={ (e) => handleInputChange(index, 'citizenShip', e) }>
                          <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                            <Radio colorScheme="brandScheme" value='USA'>USA</Radio>
                            <Radio colorScheme="brandScheme" value='OTHER'>Other:</Radio>
                            <FormControl ps='24px' hidden={ beneficiary.citizenShip === 'USA' }>
                              <Input
                                type="text"
                                color={ textColor }
                                w={ {base: 'full', md: 'xs'} }
                                value={ beneficiary.otherCitizenShip }
                                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                                  handleInputChange(index, 'otherCitizenShip', e.target.value);
                                } }
                              />
                            </FormControl>
                          </Flex>
                        </RadioGroup>
                      </FormControl>
                      <FormControl isRequired mt='12px'>
                        <FormLabel>Country of Legal Residence</FormLabel>
                        <RadioGroup value={ beneficiary.residence }
                                    onChange={ (e) => handleInputChange(index, 'residence', e) }>
                          <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                            <Radio colorScheme="brandScheme" value='USA'>USA</Radio>
                            <Radio colorScheme="brandScheme" value='OTHER'>Other:</Radio>
                            <FormControl ps='24px' hidden={ beneficiary.residence === 'USA' }>
                              <Input
                                type="text"
                                color={ textColor }
                                w={ {base: 'full', md: 'xs'} }
                                value={ beneficiary.otherResidence }
                                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                                  handleInputChange(index, 'otherResidence', e.target.value);
                                } }
                              />
                            </FormControl>
                          </Flex>
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    <Flex mt='24px' justify={ {base: 'start', md: 'end'} } direction={ {base: 'column', md: 'row'} }
                          gap={ 4 }>
                      {
                        beneficiaries.length > 1 && <Button
                          leftIcon={ <CloseIcon w='12px'/> }
                          bg='gray.200'
                          onClick={ () => removeBeneficiary(index) }>
                          Remove Beneficiary
                        </Button>
                      }
                      {
                        (index === beneficiaries.length - 1 && beneficiaries.length < 4) && <Button
                          leftIcon={ <AddIcon w='12px'/> }
                          colorScheme="brandScheme"
                          onClick={ () => addBeneficiary(beneficiary) }
                        >
                          Add Beneficiary
                        </Button>
                      }
                    </Flex>
                  </Box>
                )
              })
            }
            {
              beneficiaries.length === 4 && <Text textColor={ textColor } fontWeight='500'>
                * If more than four Primary or Contingent Beneficiaries are designated, attach a separate sheet of paper,
                signed and dated as it appears on this application.
              </Text>
            }
          </Box>
        }
      </Flex>
    </Box>
  );
});

export default DesignateBeneficiary;
