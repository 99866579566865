import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Chakra imports
import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Text,
	useColorModeValue,
	useToast,
	VStack
} from '@chakra-ui/react';
// Custom components
import DefaultAuth from '../../../layouts/auth/Default';
// Assets
import { forgotPassword } from '../../../api/auth';

const ResetEmail: React.FC = () => {
	const [email, setEmail] = useState<string>('');
	const [error, setError] = useState<string | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const toast = useToast();
	const history = useHistory();

	// Chakra color mode
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
	const brandStars = useColorModeValue('brand.500', 'brand.400');
	const handleResetPassword = async (event: { preventDefault: () => void; }) => {
		if (event) {
			event.preventDefault();
		}
		setIsLoading(true);
		forgotPassword(email)
			.then(() => {
				toast({
					title: 'Forgot Password',
					description: `We have sent an email to your email account with verification code.`,
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
				history.push('/auth/confirm-reset-password');
			})
			.catch((err) => {
				console.error(err);
				toast({
					title: 'Forgot Password',
					description: err.message,
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<DefaultAuth illustrationBackground="../../../assets/img/auth/auth.png" image="../../../assets/img/auth/auth.png">
			<Flex
				maxW={{ base: '100%', md: 'max-content' }}
				w="100%"
				mx={{ base: 'auto', lg: '0px' }}
				me="auto"
				h="100%"
				alignItems="start"
				justifyContent="center"
				mb={{ base: '30px', md: '60px' }}
				px={{ base: '25px', md: '0px' }}
				flexDirection="column"
			>
				<VStack w="100%">
					<Heading color={textColor} fontSize="36px" mb="10px">
						Forgot Password
					</Heading>
				</VStack>
				<Flex
					zIndex="2"
					direction="column"
					w={{ base: '100%', md: '420px' }}
					maxW="100%"
					background="transparent"
					borderRadius="15px"
					mx={{ base: 'auto', lg: 'unset' }}
					me="auto"
					mb={{ base: '20px', md: 'auto' }}
				>
					<Flex
						zIndex="2"
						direction="column"
						w={{ base: '100%', md: '420px' }}
						maxW="100%"
						background="transparent"
						borderRadius="15px"
						mx={{ base: 'auto', lg: 'unset' }}
						me="auto"
						mb={{ base: '20px', md: 'auto' }}
					>
						<h4
							style={{
								fontSize: '.9em',
								color: 'red',
								textAlign: 'center',
								fontWeight: 400,
								transition: '.2s all',
							}}
						>
							{error}
						</h4>
						<form onSubmit={handleResetPassword}>
							<FormControl>
								<FormLabel
									display="flex"
									ms="4px"
									fontSize="sm"
									fontWeight="500"
									color={textColor}
									mb="8px"
								>
									Email<Text color={brandStars}>*</Text>
								</FormLabel>
								<Input
									isRequired={true}
									variant="auth"
									fontSize="sm"
									ms={{ base: '0px', md: '0px' }}
									type="email"
									placeholder="mail@example.com"
									mb="24px"
									defaultValue={email}
									fontWeight="500"
									size="lg"
									onChange={(event) => {
										setEmail(event.target.value);
										setError(undefined);
									}}
								/>
								<Button
									fontSize="sm"
									variant="brand"
									fontWeight="500"
									w="100%"
									h="50"
									mb="24px"
									colorScheme="brandScheme"
									isLoading={isLoading}
									type="submit"
								>
									Send Verification Code
								</Button>
								<Flex justifyContent={'center'}>
									<Text color={textColorDetails} fontWeight="400" fontSize="14px" mr="10px">Back to </Text>
									<Text color={brandStars} fontWeight="400" fontSize="14px" cursor={'pointer'}><NavLink
										to="/auth/login">Log In</NavLink></Text>
								</Flex>
							</FormControl>
						</form>
					</Flex>
				</Flex>
			</Flex>
		</DefaultAuth>
	);
}

export default ResetEmail;
