import React from "react";
import { Route, useHistory } from "react-router-dom";
import { useAuth } from "../auth-context/auth.context";
import { validateRole } from "../libraries/utils";

export const ProtectedRoute = ({...rest}) => {
  const history = useHistory();
  const {user} = useAuth();

  const type = validateRole(user?.token);

  if (!user || type === 'invalid') {
    history.push("/auth/login");
  }

  return <Route {...rest} />;
};
