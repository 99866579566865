import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  useToast,
  VStack
} from '@chakra-ui/react';
// Custom components
import DefaultAuth from '../../../layouts/auth/Default';
// Assets
import { sendCustomChallengeAnswer } from '../../../api/auth';
import { useAuth } from "../../../auth-context/auth.context";
import { Auth } from "aws-amplify";
import { getUserInfo } from "../../../api/user-service";
import { AccountType } from "../../../config/constant";

const ConfirmLogin: React.FC = () => {
  const { user, setUser } = useAuth();
  const [code, setCode] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const history = useHistory();

  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const confirmSignin = async (e: any) => {
    e.preventDefault();
    if (!code) {
      toast({
        title: 'OTP Verification',
        description: 'Please provide your OTP',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      await sendCustomChallengeAnswer(user, code);
      const { attributes } = await Auth.currentAuthenticatedUser();
      const { user: userInfo, url } = await getUserInfo();
      if (url) {
        window.location.href = url;
      }

      setUser({
        sub: attributes['sub'],
        ...userInfo,
      });

      if (attributes['custom:accountType'] === AccountType.ADVISOR) {
        history.push('/admin/clients');
      } else {
        history.push('/admin/profile');
      }
    } catch (error: any) {
      console.log(error);
      const { message, code } = error;
      if (
        code === 'NotAuthorizedException'
      ) {
        setError(message);
        toast({
          title: 'OTP Verification',
          description: 'Too many attempts. Please go back to Login page and sign in again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'OTP Verification',
          description: message || 'Invalid code. A new code has been sent.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setError('Invalid code. A new code has been sent.');
      }
    }
    setIsLoading(false);
  };

  return (
    <DefaultAuth illustrationBackground="../../../assets/img/auth/auth.png" image="../../../assets/img/auth/auth.png">
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        flexDirection="column"
      >
        <VStack w="100%">
          <Heading color={textColor} fontSize="36px" mb="10px">
            OTP Verification
          </Heading>
        </VStack>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <h4
            style={{
              fontSize: '.9em',
              color: 'red',
              textAlign: 'center',
              fontWeight: 400,
              transition: '.2s all',
            }}
          >
            {error}
          </h4>
          <form onSubmit={confirmSignin}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Verification Code<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="number"
                placeholder="OTP code"
                defaultValue={code}
                fontWeight="500"
                size="lg"
                onChange={(event) => {
                  setCode(event.target.value);
                  setError(undefined);
                }}
              />
              <Text mb="24px" color={textColorDetails}>
                We have sent the code
                to <b>{user?.challengeParam?.method === 'phone' ? user?.challengeParam?.phone_number : user?.challengeParam?.email}</b>.
                Please provide your one time passcode from your {user?.challengeParam?.method}.
              </Text>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                colorScheme="brandScheme"
                isLoading={isLoading}
                type="submit"
              >
                Confirm
              </Button>
              <Flex justifyContent={'center'}>
                <Text color={textColorDetails} fontWeight="400" fontSize="14px" mr="10px">Back to </Text>
                <Text color={brandStars} fontWeight="400" fontSize="14px" cursor={'pointer'}><NavLink
                  to="/auth/login">Log In</NavLink></Text>
              </Flex>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ConfirmLogin;
