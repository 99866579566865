/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../auth-context/auth.context';
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Radio,
  RadioGroup,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Column, useGlobalFilter, usePagination, useSortBy, useTable, } from 'react-table';
import Card from '../../../components/card/Card';
import TableSpinner from '../../../components/spinner/TableSpinner';
import { AddIcon, SearchIcon, WarningIcon } from '@chakra-ui/icons';
import { MdAttachMoney, MdLoop, MdMenuBook, MdPersonRemove, MdRemoveRedEye, MdSend, MdVideocam } from 'react-icons/md';
import AddClientModal from '../../admin/modals/components/AddClientModal';
import {
  addClient,
  deleteUserAsAdvisor,
  generateLoomJWS,
  getAllClients,
  remindInvitation,
  resetPasswordRequest,
  sendEmailWithVideo
} from '../../../api/user-service';
import { PopoverTrigger } from '../../admin/dataTables/components/ClientTable';
import {
  getAllLinkedAccountsAsAdvisor,
  getAllLinkedInsuranceAccountsAsAdvisor
} from '../../../api/institution-service';
import { formatInsuranceProviderName, getBadgeColor, getColorByFirstChar } from "../../../libraries/utils";
import { ClientType } from "../../../types/user";
import ResetPasswordModal from "../../admin/modals/components/ResetPasswordModal";
import { isSupported } from "@loomhq/record-sdk/is-supported";
import { setup } from "@loomhq/record-sdk";
import { COUNT_PER_PAGE } from "../../../config/constant";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination as useAjnaPagination
} from "@ajna/pagination";
import { mode } from "@chakra-ui/theme-tools";
import { useDebounce } from "../../../libraries/useDebounce";
import moment from "moment/moment";

const CLIENT_COLUMNS: Column<ClientType>[] | any = [
  {
    Header: 'NAME',
    accessor: 'fullName',
  },
  // {
  //   Header: 'MANAGEMENT',
  //   accessor: 'management',
  // },
  {
    Header: 'SOURCE',
    accessor: 'source',
  },
  {
    Header: 'FINANCE STATUS',
    accessor: 'accepted',
  },
  {
    Header: 'TAX STATUS',
    accessor: 'taxStatus',
  },
  {
    Header: 'VIEW DATA',
    accessor: 'options',
  },
  {
    Header: 'OTHER',
    accessor: 'other',
  },
];

const useQuery = () => {
  return new URLSearchParams(window.location.search);
}

const ClientList: React.FC = () => {
  const { user, selectedClient, setSelectedClient } = useAuth();
  const history = useHistory();
  const toast = useToast();

  const tmImage = useColorModeValue(require('../../../assets/img/TM-black.png'), require('../../../assets/img/TM-white.png'));
  const bgPagination = useColorModeValue('brand.500', 'brand.400');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [clients, setClients] = useState<ClientType[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedClientData, setSelectedClientData] = useState<any>(null);
  const [isInstitutionLoading, setIsInstitutionLoading] = useState<boolean>(false);
  const [isInsuranceInstitutionLoading, setIsInsuranceInstitutionLoading] = useState<boolean>(false);
  const [linkedInstitutions, setLinkedInstitutions] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);
  const { isOpen: isResetOpen, onOpen: onResetOpen, onClose: onResetClose } = useDisclosure();
  const { isOpen: isVideoOpen, onOpen: onVideoOpen, onClose: onVideoClose } = useDisclosure();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const loomButtonRefs = useRef(new Map());
  const [sharedVideoUrl, setSharedVideoUrl] = useState("");
  const [reportType, setReportType] = useState<'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY'>('TEN_DAY');
  const [openedPopoverId, setOpenedPopoverId] = useState<any>(null);

  const debouncedSearch = useDebounce(search, 1000);

  const query = useQuery();
  const reason = query.get("reason");
  const code = query.get("code");

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    offset,
    pageSize,
  } = useAjnaPagination({
    pagesCount: Math.ceil(total / COUNT_PER_PAGE),
    limits: {
      outer: 2,
      inner: 2
    },
    initialState: {
      currentPage: 1,
      pageSize: COUNT_PER_PAGE,
    },
  });

  // Function to create new ref if it doesn't exist for a specific row
  const getLoomButtonRef = (rowId: number) => {
    if (!loomButtonRefs.current.has(rowId)) {
      loomButtonRefs.current.set(rowId, React.createRef());
    }
    return loomButtonRefs.current.get(rowId);
  };

  const setupLoom = useCallback(async (userId: number) => {
    try {
      const { supported, error } = await isSupported();
      if (supported) {
        const buttonRef = getLoomButtonRef(userId);
        if (buttonRef.current) {
          const { jws } = await generateLoomJWS();
          const { configureButton } = await setup({
            jws,
          });
          const sdkButton = configureButton({ element: buttonRef.current });
          sdkButton.on('insert-click', async (video) => {
            setSharedVideoUrl(video.sharedUrl);
            onVideoOpen();
          });
        }
      } else {
        console.warn(`Loom is not supported: ${error}`);
      }
    } catch (e) {
      console.error(`Loom setup Error: ${e}`);
    }
  }, []);

  useEffect(() => {
    if (reason === 'wealthbox_return' && code === '200') {
      toast({
        title: 'You have been successfully connected to Wealthbox.',
        description: `We are currently retrieving your leads and initiating the invitation process.`,
        status: 'success',
        duration: 10000,
        isClosable: false,
      });
      setTimeout(() => {
        fetchClients();
        history.push(window.location.pathname);
      }, 10000);
    } else if (reason === 'redtail_return' && code === '200') {
      toast({
        title: 'You have been successfully connected to REDTAIL.',
        description: `We are currently retrieving your leads and initiating the invitation process.`,
        status: 'success',
        duration: 10000,
        isClosable: false,
      });
      setTimeout(() => {
        fetchClients();
        history.push(window.location.pathname);
      }, 10000);
    }
  }, [reason]);

  const tableInstance = useTable(
    {
      columns: CLIENT_COLUMNS,
      data: clients,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const nameColor = useColorModeValue('blue.600', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const textColorSecondary = 'secondaryGray.600';

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const { clients, count } = await getAllClients(pageSize, offset, search);
      setClients(clients);
      setTotal(count)
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const viewData = (data: any, type: 'Finance' | 'Tax' = 'Finance') => {
    const clientData: ClientType = { ...data };
    setSelectedClient(clientData);
    if (clientData.management === 'insurance') {
      history.push('/admin/insurance');
    } else if (type === 'Finance') {
      history.push('/admin/wealth');
    } else if (type === 'Tax') {
      history.push('/admin/smarttax');
    }
  };

  const fetchLinkedAccounts = (client: any) => {
    setIsInstitutionLoading(true);
    getAllLinkedAccountsAsAdvisor(client.userId)
      .then((result) => {
        setLinkedInstitutions(result);
      })
      .catch(err => console.error(err.message))
      .finally(() => setIsInstitutionLoading(false))
  }

  const fetchLinkedInsuranceAccounts = (client: any) => {
    setIsInsuranceInstitutionLoading(true);
    getAllLinkedInsuranceAccountsAsAdvisor(client.userId)
      .then((result) => {
        setLinkedInstitutions(result);
      })
      .catch(err => console.error(err.message))
      .finally(() => setIsInsuranceInstitutionLoading(false))
  }

  useEffect(() => {
    setSelectedClient(null);
    if (user) {
      fetchClients();
    }
  }, [user, currentPage, offset, debouncedSearch]);

  useEffect(() => {
    if (clients.length > 0) {
      clients.forEach(client => {
        setupLoom(client.userId);
      });
    }
  }, [clients, setupLoom]);

  useEffect(() => {
    if (selectedClientData) {
      if (selectedClientData?.management === 'insurance') {
        fetchLinkedInsuranceAccounts(selectedClientData);
      } else {
        fetchLinkedAccounts(selectedClientData);
      }
    }
  }, [selectedClientData])

  const addNewClient = async ({
                                addressLine1,
                                birthday,
                                city,
                                companyName,
                                email,
                                firstName,
                                lastName,
                                management,
                                middleName,
                                nickName,
                                phoneNumber,
                                postalCode,
                                state,
                                userRole,
                                note,
                                category,
                                reportType,
                              }: {
    firstName: string,
    middleName: string,
    lastName: string,
    nickName: string,
    birthday: string,
    email: string,
    phoneNumber: string,
    userRole: string,
    management: string,
    addressLine1: string,
    city: string,
    state: string,
    postalCode: string,
    companyName: string,
    note: string,
    category: 'Finance' | 'Tax',
    reportType?: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY'
  }) => {
    try {
      await addClient({
        addressLine1,
        birthday,
        city,
        companyName,
        receiverEmail: email,
        firstName,
        lastName,
        management,
        middleName,
        nickName,
        phoneNumber,
        postalCode,
        state,
        userRole,
        note,
        category,
        reportType,
      })
      toast({
        title: 'Add Lead',
        description: `${firstName} has been successfully added as a client.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      fetchClients();
    } catch (error: any) {
      toast({
        title: 'Add Lead',
        description: error.response?.data.message ?? error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  };

  const removeClient = (clientId: any) => {
    setIsDeleting(true);
    deleteUserAsAdvisor(clientId)
      .then(() => {
        toast({
          title: 'Remove Client',
          description: `Client has been removed.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchClients();
      })
      .catch((err) => {
        toast({
          title: 'Remove Client',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsDeleting(false);
      })
  }

  const resetPassword = async ({ isAutoGenerated, password, askToChange }: {
    isAutoGenerated: boolean,
    password?: string,
    askToChange?: boolean,
  }) => {
    await resetPasswordRequest(selectedClientData.userId, isAutoGenerated, password, askToChange)
      .then(() => {
        toast({
          title: 'Reset Password',
          description: `The password of ${selectedClientData.firstName} ${selectedClientData.lastName} has been reset.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Reset Password',
          description: err?.response?.data?.message || err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
  }

  const resendInvitationEmail = (userId: number, category: 'Finance' | 'Tax', reportType?: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY') => {
    remindInvitation(userId, category, reportType)
      .then(() => {
        toast({
          title: 'Email reminder has been sent.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Updates the clients state
        setClients(prevClients =>
          prevClients.map(client =>
            client.userId === userId
              ? { ...client, resentInvitation: true }
              : client
          )
        );
      })
      .catch((err) => {
        toast({
          title: 'Remind Invitation',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
  }

  const sendVideoEmail = async () => {
    setIsSending(true);

    let errMsg = null;

    if (!subject) {
      errMsg = 'Subject is required.';
    } else if (!message) {
      errMsg = 'Message is required.';
    }

    if (errMsg) {
      toast({
        title: errMsg,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsSending(false);
      return;
    }

    await sendEmailWithVideo(selectedClientData.userId, subject, message, sharedVideoUrl)
      .then(() => {
        toast({
          title: 'Email with video has been sent.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        handleVideoEmailClose();
      })
      .catch((err) => {
        toast({
          title: 'Email',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSending(false);
      })
  }

  const handleVideoEmailClose = () => {
    onVideoClose();
    setSubject('');
    setMessage('');
    setSelectedClientData(null);
    setIsSending(false);
  }

  const getTaxStatus = (value?: 'ACTIVE' | 'INACTIVE' | 'REJECTED' | 'CLOSED' | 'INVITE SENT') => {
    if (value === 'ACTIVE') {
      return 'ACTIVE';
    } else if (value === 'INACTIVE') {
      return 'SUBMITTED';
    } else if (value === 'REJECTED') {
      return 'REJECTED';
    } else if (value === 'CLOSED') {
      return 'CLOSED';
    } else if (value === 'INVITE SENT') {
      return 'INVITE SENT';
    } else {
      return 'NOT INVITED';
    }
  }

  const getSourceColor = (source?: 'internal' | 'smartleads' | 'Wealthbox' | 'Redtail' | 'API'): string => {
    switch (source) {
      case 'internal':
        return 'green';
      case 'smartleads':
        return 'orange';
      case 'Wealthbox':
        return 'navy';
      case 'Redtail':
        return 'red';
      case 'API':
        return 'blue';
      default:
        return 'red';
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <VStack>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX='auto'
          opacity={isLoading ? 0.7 : 1}
        >
          <TableSpinner loading={isLoading} />
          <Flex px="25px" justify="space-between" mb="20px" align="center">
            <HStack>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="gray.300" />}
                />
                <Input
                  type="input"
                  placeholder="Search Email or Name..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </HStack>
            <Button
              leftIcon={<AddIcon />}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              minW="110px"
              ml="8px"
              onClick={onOpen}
            >
              Add Lead
            </Button>
          </Flex>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            size="sm"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    bgColor={row.original.userId === selectedClient?.userId ? '#dddddd' : 'transparent'}
                    _hover={{ background: '#dddddd55' }}
                  >
                    {row.cells.map((cell, index) => {
                      let data: ReactNode;
                      if (cell.column.Header === 'NAME') {
                        data = (
                          <HStack key={index}>
                            <Avatar
                              src={cell.row.original?.avatarUrl}
                              name={`${cell.row.original.firstName} ${cell.row.original.lastName}`}
                              w="32px"
                              h="32px"
                              size='12px'
                            />
                            <VStack align='start' spacing={1}>
                              <Tooltip
                                label={`View details of ${cell.row.original.firstName} ${cell.row.original.lastName}`}
                                hasArrow placement="bottom">
                                <Link href={`/admin/clients/${cell.row.original.userId}`}>
                                  <Text
                                    color={nameColor}
                                    textDecoration="underline"
                                    textUnderlineOffset={2}
                                    fontSize="sm"
                                    fontWeight="700"
                                  >
                                    {cell.row.original.firstName} {cell.row.original.lastName}
                                  </Text>
                                </Link>
                              </Tooltip>
                              <Text>{cell.row.original.email}</Text>
                            </VStack>
                          </HStack>
                        );
                      } else if (cell.column.Header === 'SOURCE') {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            Created by <Badge ml='2' colorScheme={getSourceColor(cell.row.original?.source)}
                                              variant='outline'>
                            {cell.row.original?.source}
                          </Badge>
                          </Text>
                        );
                      } else if (cell.column.Header === 'FINANCE STATUS' || cell.column.Header === 'MANAGEMENT') {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {
                              cell.column.Header === 'MANAGEMENT' ?
                                <Badge
                                  colorScheme={cell.value === 'insurance' ? 'purple' : 'pink'}>{cell.value}
                                </Badge> :
                                <HStack>
                                  <Badge
                                    colorScheme={cell.value ? 'green' : 'red'}>{cell.value ? 'ACCEPTED' : 'NOT ACCEPTED'}
                                  </Badge>
                                  {!cell.value && (
                                    <Tooltip hasArrow placement="top"
                                             label={cell.row.original?.linkOpened ? 'Invite has been opened' : 'Invite has not been opened'}>
                                      <Box w='12px' h='12px' borderRadius='full'
                                           bg={cell.row.original?.linkOpened ? 'green.500' : 'red.500'} />
                                    </Tooltip>
                                  )}
                                </HStack>
                            }
                          </Text>
                        );
                      } else if (cell.column.Header === 'TAX STATUS') {
                        const taxStatus = cell.row.original.isTax ? 'INVITE SENT' : cell.row.original.smarttaxClients.status;

                        data = (
                          <HStack>
                            <Tooltip
                              label={cell.row.original.isTax ? moment(cell.row.original.isTaxAt).format('MMMM DD, YYYY') : ''}
                              placement='top'>
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                <Badge
                                  colorScheme={taxStatus ? getBadgeColor(taxStatus) : 'red'}>
                                  {getTaxStatus(taxStatus)}
                                </Badge>
                              </Text>
                            </Tooltip>
                          </HStack>
                        );
                      } else if (cell.column.Header === 'VIEW DATA') {
                        const disabled: boolean = !row.cells.filter(
                          (item) => item.column.Header === 'FINANCE STATUS'
                        )[0].value;

                        const taxEnabled: boolean = cell.row.original.smarttaxClients.status === 'ACTIVE';

                        data = (
                          <HStack>
                            <Button
                              rightIcon={<MdMenuBook />}
                              size="sm"
                              fontSize="sm"
                              variant="brand"
                              fontWeight="500"
                              disabled={disabled}
                              onClick={() => viewData(row.original)}
                            >
                              {cell.row.original.management === 'insurance' ? 'View Insurance Data' : 'View Finance'}
                            </Button>
                            {
                              cell.row.original?.smarttaxClients?.taxClientId ?
                                <Button
                                  rightIcon={<MdAttachMoney />}
                                  size="sm"
                                  fontSize="sm"
                                  variant="brand"
                                  fontWeight="500"
                                  disabled={!taxEnabled}
                                  onClick={() => viewData(row.original, 'Tax')}
                                >
                                  View Tax
                                </Button> :

                                <Popover placement="bottom" isOpen={openedPopoverId === row.original.userId}>
                                  <PopoverTrigger>
                                    <Button
                                      rightIcon={<MdSend />}
                                      size="sm"
                                      fontSize="sm"
                                      colorScheme="blue"
                                      fontWeight="500"
                                      onClick={() => {
                                        setSelectedClientData(row.original);
                                        setOpenedPopoverId(row.original.userId);
                                      }}
                                    >
                                      Invite Tax
                                    </Button>
                                  </PopoverTrigger>
                                  <PopoverContent w="auto">
                                    <PopoverHeader fontWeight="semibold" pr={8} maxW='240px'>
                                      Are you sure you want to
                                      invite {cell.row.original.firstName} {cell.row.original.lastName} to smarttax
                                      <Image
                                        display="inline"
                                        boxSize="18px"
                                        src={tmImage}
                                      />?
                                    </PopoverHeader>
                                    <PopoverArrow />
                                    <PopoverCloseButton onClick={() => setOpenedPopoverId(null)} />
                                    <PopoverBody>
                                      <FormLabel>Report Type</FormLabel>
                                      <RadioGroup value={reportType}
                                                  onChange={(e: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY') => setReportType(e)}>
                                        <HStack>
                                          <Radio colorScheme="brandScheme" fontSize='sm'
                                                 value='SAME_DAY'>SAME DAY</Radio>
                                          <Radio colorScheme="brandScheme" fontSize='sm'
                                                 value='NEXT_DAY'>NEXT DAY</Radio>
                                        </HStack>

                                        <Radio mt={2} colorScheme="brandScheme" fontSize='sm'
                                               value='TEN_DAY'>WITHIN 10 DAYS</Radio>
                                        <HStack w='full' justify='end'>
                                          <Button
                                            mt={2}
                                            size='sm'
                                            colorScheme='brand'
                                            onClick={() => {
                                              resendInvitationEmail(cell.row.original.userId, 'Tax', reportType)
                                              setOpenedPopoverId(null);
                                            }}
                                          >Send</Button>
                                        </HStack>
                                      </RadioGroup>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                            }
                          </HStack>
                        );
                      } else {
                        const disabled: boolean = !row.cells.filter(
                          (item) => item.column.Header === 'FINANCE STATUS'
                        )[0].value;

                        data = (
                          <HStack>
                            <Popover placement="auto-start">
                              <PopoverTrigger>
                                <Button
                                  rightIcon={<MdRemoveRedEye />}
                                  size="sm"
                                  fontSize="sm"
                                  colorScheme="green"
                                  fontWeight="500"
                                  disabled={disabled}
                                  onClick={() => {
                                    setLinkedInstitutions([]);
                                    setSelectedClientData(row.original);
                                  }}
                                >
                                  Institutions
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent w="auto" minW="280px">
                                <PopoverHeader fontWeight="semibold">Connected
                                  Institutions</PopoverHeader>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody>
                                  {
                                    (isInstitutionLoading || isInsuranceInstitutionLoading) ?
                                      <CircularProgress size={8} isIndeterminate
                                                        color="green.300" /> :
                                      (linkedInstitutions.length > 0 ? linkedInstitutions.map((ins: any, lIndex: number) =>
                                          selectedClientData?.management === 'insurance' ?
                                            <Flex align="center" mb="4px" key={lIndex}>
                                              <HStack>
                                                <Box
                                                  bg={getColorByFirstChar(ins.insuranceProviderName)}
                                                  borderRadius="50%"
                                                  minW="20px"
                                                  width="20px"
                                                  height="20px"
                                                  display="flex"
                                                  alignItems="center"
                                                  justifyContent="center"
                                                />
                                                <Text
                                                  color={textColor}
                                                  fontSize="sm"
                                                  fontWeight="600">
                                                  {formatInsuranceProviderName(ins?.insuranceProviderName)}
                                                </Text>
                                              </HStack>
                                            </Flex> :
                                            <Flex align="center" mb="4px">
                                              <Avatar
                                                src={ins?.branding?.icon || ins?.branding?.logo}
                                                w="24px"
                                                h="24px"
                                                me="8px"
                                              />
                                              <HStack>
                                                <Text
                                                  color={textColor}
                                                  fontSize="sm"
                                                  fontWeight="600">
                                                  {ins?.institutionName}
                                                </Text>
                                                <Text
                                                  fontWeight="500"> ({ins?.items[0]?.accounts.length} {ins?.items[0]?.accounts.length > 1 ? 'accounts' : 'account'} linked)
                                                </Text>
                                              </HStack>
                                            </Flex>) :
                                        <Text
                                          color={textColorSecondary}
                                          fontSize="sm"
                                          fontWeight="500">
                                          No institutions linked yet. 😔
                                        </Text>)

                                  }
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                            {
                              cell.row.original.accepted ?
                                <Tooltip hasArrow
                                         label='Once you record a video, click "Insert Video" to send a message with that recorded video.'>
                                  <IconButton
                                    aria-label="Send an email with video"
                                    bg="inherit"
                                    ms="auto"
                                    borderRadius="inherit"
                                    color="red.500"
                                    ref={getLoomButtonRef(row.original.userId)}
                                    onClick={() => {
                                      setSelectedClientData(row.original);
                                    }}
                                    _active={{
                                      bg: 'inherit',
                                      transform: 'none',
                                      borderColor: 'transparent',
                                    }}
                                    _focus={{
                                      boxShadow: 'none',
                                    }}
                                    icon={
                                      <MdVideocam />
                                    } />
                                </Tooltip> :
                                <Tooltip hasArrow label='Remind invitation'>
                                  <IconButton
                                    aria-label="Remind invitation"
                                    bg="inherit"
                                    ms="auto"
                                    disabled={cell.row.original.accepted}
                                    borderRadius="inherit"
                                    onClick={() => resendInvitationEmail(cell.row.original.userId, 'Finance')}
                                    _active={{
                                      bg: 'inherit',
                                      transform: 'none',
                                      borderColor: 'transparent',
                                    }}
                                    _focus={{
                                      boxShadow: 'none',
                                    }}
                                    icon={<MdSend color="#3965FF" />} />
                                </Tooltip>
                            }
                            <Tooltip hasArrow label='Reset password'>
                              <IconButton
                                aria-label="Reset password"
                                bg="inherit"
                                ms="auto"
                                borderRadius="inherit"
                                onClick={() => {
                                  onResetOpen();
                                  setSelectedClientData(row.original);
                                }}
                                _active={{
                                  bg: 'inherit',
                                  transform: 'none',
                                  borderColor: 'transparent',
                                }}
                                _focus={{
                                  boxShadow: 'none',
                                }}
                                icon={
                                  <MdLoop />
                                } />
                            </Tooltip>
                            <Popover placement="auto-start">
                              {({ onClose }) => (
                                <>
                                  <PopoverTrigger>
                                    <IconButton
                                      aria-label="Remove client"
                                      bg="inherit"
                                      ms="auto"
                                      borderRadius="inherit"
                                      _active={{
                                        bg: 'inherit',
                                        transform: 'none',
                                        borderColor: 'transparent',
                                      }}
                                      _focus={{
                                        boxShadow: 'none',
                                      }}
                                      icon={
                                        <MdPersonRemove
                                          color="secondaryGray.500" />
                                      }></IconButton>
                                  </PopoverTrigger>
                                  <PopoverContent w="auto" minW="280px">
                                    <PopoverHeader fontWeight="semibold">Remove
                                      Client</PopoverHeader>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                      <Box display="flex" marginBottom="8px">
                                        <WarningIcon color="orange"
                                                     marginRight="4px"
                                                     marginTop="4px" />
                                        <Box>Are you sure to
                                          remove <b>{cell.row.original.firstName} {cell.row.original.lastName}</b>?</Box>
                                      </Box>
                                      <Box display="flex"
                                           justifyContent="flex-end">
                                        <Button size="xs" margin="4px"
                                                width="50px"
                                                onClick={onClose}>No</Button>
                                        <Button isLoading={isDeleting}
                                                isDisabled={isDeleting}
                                                size="xs" margin="4px"
                                                width="50px" colorScheme="red"
                                                onClick={() => {
                                                  removeClient(row.original.userId);
                                                  onClose();
                                                }}>Yes</Button>
                                      </Box>
                                    </PopoverBody>
                                  </PopoverContent>
                                </>
                              )}
                            </Popover>
                          </HStack>
                        );
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer
              align="center"
              justify="center"
              p={4}
              gap={2}
              w="full"
            >
              <PaginationPrevious
                _hover={{
                  bg: mode('brand.600', 'brand.400'),
                }}
                bg={bgPagination}
              > Prev
              </PaginationPrevious>
              <PaginationPageGroup
                isInline
                align="center"
                separator={
                  <PaginationSeparator
                    bg="transparent"
                    borderColor="brand.100"
                    borderWidth="1px"
                    fontSize="sm"
                    w={10}
                    jumpSize={5}
                  />
                }
              >
                {pages.map((page) => (
                  <PaginationPage
                    key={`pagination_page_${page}`}
                    page={page}
                    w={10}
                    bg="transparent"
                    borderColor="brand.100"
                    borderWidth="1px"
                    fontSize="sm"
                    _hover={{
                      bg: 'brand.600'
                    }}
                    _current={{
                      bg: 'brand.600',
                      fontSize: 'sm',
                      w: 10
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                _hover={{
                  bg: mode('brand.600', 'brand.400'),
                }}
                bg={bgPagination}
              >Next</PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Card>
      </VStack>
      <AddClientModal isOpen={isOpen} onClose={onClose} onSave={addNewClient} />
      <ResetPasswordModal isOpen={isResetOpen} onClose={onResetClose} user={selectedClientData}
                          onReset={resetPassword} />
      <Modal isOpen={isVideoOpen} onClose={handleVideoEmailClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send an email to {selectedClientData?.firstName} with video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Subject</FormLabel>
              <Input colorScheme="brandScheme" value={subject}
                     type="tel"
                     placeholder="Subject"
                     onChange={(e) => setSubject(e.target.value)} />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Message</FormLabel>
              <Textarea placeholder='Please add message before the meeting...' value={message}
                        rows={5}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }} />
              <Text mt={1} color={textColor}>Note: Your <Link href={sharedVideoUrl} target='_blank' color='blue.500'
                                                              fontWeight='600'>video</Link> will be included in the
                email.</Text>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="brandScheme" variant="ghost" onClick={handleVideoEmailClose}>Cancel</Button>
            <Button colorScheme="brandScheme" loadingText='Sending...' isLoading={isSending} isDisabled={isSending}
                    onClick={sendVideoEmail}>Send</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ClientList;
