import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";

interface UserAddModalProps {
  isConnecting: boolean,
  isOpen: boolean,
  onClose: () => void,
  onLogin: (e: {
    userName: string,
    password: string,
  }) => Promise<void>
}

const RedtailOrionLoginModal: React.FC<UserAddModalProps> = ({isConnecting, isOpen, onClose, onLogin}) => {
  const textColorSecondary = 'gray.400';
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const login = async () => {
    let message = null;

    if (!userName) {
      message = 'User name is required.';
    } else if (!password) {
      message = 'Password is required.';
    }

    if (message) {
      setErrorMessage(message);
    } else {
      await onLogin({
        userName,
        password
      });
      handleClose();
    }
  }

  const handleClose = () => {
    setUserName('');
    setPassword('');
    setErrorMessage(null);
    onClose();
  }

  return (
    <Modal isOpen={ isOpen } onClose={ () => handleClose() } size="xl" isCentered>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Image src={ require('../../../../assets/img/financials/redtail-reversed-icon.png') } borderRadius="full"
                   h="32px"/>
            <Text>Connect to REDTAIL</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <FormControl>
            <FormLabel>User Name</FormLabel>
            <Input
              isRequired={ true }
              variant="auth"
              fontSize="sm"
              ms={ {base: '0px', md: '0px'} }
              type="email"
              placeholder="username"
              mb="16px"
              defaultValue={ userName }
              value={ userName }
              fontWeight="500"
              size="lg"
              onChange={ (event) => {
                setUserName(event.target.value);
                setErrorMessage(null);
              } }
            />
            <FormLabel>Password</FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={ true }
                fontSize="sm"
                placeholder="Password"
                mb="8px"
                size="lg"
                defaultValue={ password }
                type={ show ? 'text' : 'password' }
                variant="auth"
                onChange={ (event) => {
                  setPassword(event.target.value);
                  setErrorMessage(null);
                } }
              />
              <InputRightElement
                display="flex"
                alignItems="center"
                mt="4px"
              >
                <Icon
                  color={ textColorSecondary }
                  _hover={ {cursor: 'pointer'} }
                  as={ show ? RiEyeCloseLine : MdOutlineRemoveRedEye }
                  onClick={ () => setShow(prev => !prev) }
                />
              </InputRightElement>
            </InputGroup>
            { errorMessage ? (
              <FormHelperText color='red'>
                { errorMessage }
              </FormHelperText>
            ) : null }
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brandScheme" mr={ 3 } disabled={ isConnecting }
                  onClick={ () => login() }>{ isConnecting ? 'Connecting...' : 'Connect' }</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={ () => handleClose() }>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RedtailOrionLoginModal;
