import React, { useState, useEffect, Dispatch } from 'react';

const AuthContext = React.createContext<{
	user: any;
	setUser: Dispatch<any>;
	selectedClient: any;
	setSelectedClient: Dispatch<any>;
}>({
	user: null,
	setUser: () => null as unknown as Dispatch<any>,
	selectedClient: null,
	setSelectedClient: () => null as unknown as Dispatch<any>,
});

interface AuthProviderProps {
	children?: any;
}

function getInitialData(type: string) {
	let data;
	switch (type) {
		case 'user':
			const user = localStorage.getItem('user');
			try {
				data = user ? JSON.parse(user) : null;
			} catch (e) {
				data = null;
			}
			break;
		case 'selectedClient':
			try {
				const selectedClient = localStorage.getItem('selectedClient');
				data = selectedClient ? JSON.parse(selectedClient) : null;
			} catch (e) {
				data = null;
			}
	}
	return data;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
																														children,
																													}) => {
	const [user, setUser] = useState(getInitialData('user'));
	const [selectedClient, setSelectedClient] = useState(
		getInitialData('selectedClient')
	);

	useEffect(() => {
		try {
			localStorage.setItem('user', JSON.stringify(user));
		} catch (e) {
			console.log('localStorage/user/error', e);
		}
	}, [user]);

	useEffect(() => {
		try {
			localStorage.setItem('selectedClient', JSON.stringify(selectedClient));
		} catch (e) {
			console.log('localStorage/selectedClient/error', e);
		}

	}, [selectedClient]);

	return (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				selectedClient,
				setSelectedClient,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => React.useContext(AuthContext);
