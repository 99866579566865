// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  HStack,
  Image,
  Link,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import AdminNavbarLinks from "../../components/navbar/NavbarLinksAdmin";
import { startWealthboxOAuth } from "../../api/wealthbox-service";
import { useAuth } from "../../auth-context/auth.context";
import { AccountType } from "../../config/constant";
import RedtailOrionLoginModal from "../../views/admin/modals/components/RedtailOrionLoginModal";
import { connectRedtail } from "../../api/user-service";
import { useHistory } from "react-router-dom";
import WealthboxLoginConfirmModal from "../../views/admin/modals/components/WealthboxLoginConfirmModal";
import { validateRole } from "../../libraries/utils";

interface AdminNabarProps {
  brandText: string,
  variant?: string,
  secondary: boolean,
  fixed: boolean,
  logoText: string,
  message: string,
  onOpen: () => void,
}

const AdminNavbar: React.FC<AdminNabarProps> = (props) => {
  const toast = useToast();
  const history = useHistory();
  const { user } = useAuth();
  const [scrolled, setScrolled] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isWealthboxOpen, onOpen: onWealthboxOpen, onClose: onWealthboxClose } = useDisclosure();

  const useCheckProviderConnected = (providerName: 'WEALTHBOX' | 'REDTAIL') => useMemo(() => {
    return user?.accessTokens.some((token: any) => token.provider === providerName);
  }, [providerName, user]);

  const wealthboxConnected = useCheckProviderConnected('WEALTHBOX');
  const redtailConnected = useCheckProviderConnected('REDTAIL');

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, message, brandText } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const startWealthBox = async () => {
    try {
      setIsConnecting(true);
      const { authUrl } = await startWealthboxOAuth();
      window.location.href = authUrl;
    } catch (e: any) {
      console.error(e.message);
    } finally {
      setIsConnecting(false);
    }
  }

  const onLogin = async ({ userName, password }: { userName: string, password: string }) => {
    setIsConnecting(true);
    await connectRedtail(userName, password)
      .then(async () => {
        history.push('/admin/clients?reason=redtail_return&code=200');
      })
      .catch(err => {
        toast({
          title: err?.response?.data.message || err?.message || 'There was an error occurred. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setIsConnecting(false))
  }

  return (
    <Box
      position="fixed"
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition='center'
      backgroundSize='cover'
      borderRadius='16px'
      borderWidth='1.5px'
      borderStyle='solid'
      transitionDelay='0s, 0s, 0s, 0s'
      transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
      transition-property='box-shadow, background-color, filter, border'
      transitionTimingFunction='linear, linear, linear, linear'
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH='75px'
      justifyContent={{ xl: "center" }}
      lineHeight='25.6px'
      mx='auto'
      mt={secondaryMargin}
      pb='8px'
      right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt='8px'
      top={{ base: "12px", md: "16px", xl: "18px" }}
      w={{
        base: "calc(100vw - 6%)",
        md: "calc(100vw - 8%)",
        lg: "calc(100vw - 6%)",
        xl: "calc(100vw - 320px)",
        "2xl": "calc(100vw - 320px)",
      }}>
      <Flex
        w='100%'
        flexDirection={{
          sm: "column",
          md: "row",
        }}
        justify="space-between"
        alignItems={{ md: "center" }}
        gap={gap}>
        <HStack justify='space-between'>
          <Box mb={{ sm: "8px", md: "0px" }}>
            <Breadcrumb>
              <BreadcrumbItem color={secondaryText} fontSize='sm' mb='5px'>
                <BreadcrumbLink href='#' color={secondaryText}>
                  Pages
                </BreadcrumbLink>
              </BreadcrumbItem>

              <BreadcrumbItem color={secondaryText} fontSize='sm'>
                <BreadcrumbLink href='#' color={secondaryText}>
                  {brandText}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            {/* Here we create navbar brand, based on route name */}
            <Link
              color={mainText}
              href='#'
              bg='inherit'
              borderRadius='inherit'
              fontWeight='bold'
              fontSize='34px'
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}>
              {brandText}
            </Link>
          </Box>
          {
            (validateRole(user?.token) === AccountType.ADVISOR && !wealthboxConnected && !redtailConnected) && <HStack>
              <Tooltip label="Connect to Wealthbox" hasArrow placement="top">
                <Image cursor="pointer" src={require('../../assets/img/financials/wealthbox-icon.png')} h='32px' w='auto'
                       borderRadius="12px"
                       onClick={onWealthboxOpen}
                       _active={{
                         opacity: 0.8,
                       }}
                       display={{ base: "flex", md: "none" }} />
              </Tooltip>
              <Tooltip label="Connect to Redtail" hasArrow placement="top">
                <Image cursor="pointer" src={require('../../assets/img/financials/redtail-reversed-icon.png')} h='32px'
                       w='auto'
                       borderRadius="full"
                       onClick={onOpen}
                       _active={{
                         opacity: 0.8,
                       }}
                       display={{ base: "flex", md: "none" }} />
              </Tooltip>
            </HStack>
          }
        </HStack>
        {
          (validateRole(user?.token) === AccountType.ADVISOR && !wealthboxConnected && !redtailConnected) && <HStack gap="16px">
            <Tooltip label="Connect to Wealthbox" hasArrow placement="top">
              <Image cursor="pointer" src={require('../../assets/img/financials/wealthbox.png')} h='48px' w='auto'
                     borderRadius="20px"
                     p='8px'
                     bg='#3c75d130'
                     onClick={onWealthboxOpen}
                     _active={{
                       opacity: 0.8,
                     }}
                     display={{ base: "none", md: "flex" }} />
            </Tooltip>
            <Tooltip label="Connect to Redtail" hasArrow placement="top">
              <Image cursor="pointer" src={require('../../assets/img/financials/redtail-orion-logo.png')} h='48px'
                     w='auto'
                     p='8px'
                     borderRadius="20px"
                     bg='#EE5D5030'
                     onClick={onOpen}
                     _active={{
                       opacity: 0.8,
                     }}
                     display={{ base: "none", md: "flex" }} />
            </Tooltip>
          </HStack>
        }
        <Box w={{ sm: "100%", md: "unset" }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            scrolled={scrolled}
          />
        </Box>
      </Flex>
      {secondary ? <Text color='white'>{message}</Text> : null}
      <WealthboxLoginConfirmModal isConnecting={isConnecting} isOpen={isWealthboxOpen} onClose={onWealthboxClose}
                                  onLogin={startWealthBox} />
      <RedtailOrionLoginModal isConnecting={isConnecting} isOpen={isOpen} onClose={onClose} onLogin={onLogin} />
    </Box>
  );
}

export default AdminNavbar;