export const API_SERVER = process.env.REACT_APP_DASHBOARD_API_URL;
export const ENDPOINT: string = 'clarifees';
export const BASE_API_URL: string = '/api';
export const GENERAL_API_URL: string = '/general-api';
export const UserRole = ["advisor", "client"];
export const COUNT_PER_PAGE = 20;

export enum AccountType {
  ADVISOR = 'advisor',
  CLIENT = 'client'
}

export enum Management {
  FINANCIAL = 'financial',
  INSURANCE = 'insurance'
}

export const insuranceColors = [
  '#e22925',
  '#ffd000',
  '#0033a0',
  '#2480fe',
  '#005dab',
  '#e51b24',
  '#822b2f',
  '#6e27c5',
  '#466c7e',
  '#150f96',
  '#0079c1',
  '#b50a37',
  '#0d553f',
  '#97d700',
  '#007ac3',
  '#ff0083',
  '#ef2e21'
];

export const finicityAccountType = {
  "checking": { displayName: "Checking", category: "Non-Qualified" },
  "savings": { displayName: "Savings", category: "Non-Qualified" },
  "cd": { displayName: "Certificate of Deposit", category: "Non-Qualified" },
  "moneyMarket": { displayName: "Money Market", category: "Non-Qualified" },
  "creditCard": { displayName: "Credit Card", category: "Non-Qualified" },
  "lineOfCredit": { displayName: "Line of Credit", category: "Non-Qualified" },
  "investment": { displayName: "Investment", category: "Non-Qualified" },
  "investmentTaxDeferred": { displayName: "Tax-Deferred Investment", category: "Qualified" },
  "employeeStockPurchasePlan": { displayName: "Employee Stock Purchase Plan", category: "Qualified" },
  "ira": { displayName: "IRA", category: "Non-Qualified" },
  "401k": { displayName: "401(k)", category: "Qualified" },
  "roth": { displayName: "Roth IRA", category: "Non-Qualified" },
  "403b": { displayName: "403(b)", category: "Qualified" },
  "529plan": { displayName: "529 Plan", category: "Qualified" },
  "rollover": { displayName: "Rollover IRA", category: "Qualified" },
  "ugma": { displayName: "UGMA", category: "Non-Qualified" },
  "utma": { displayName: "UTMA", category: "Non-Qualified" },
  "keogh": { displayName: "Keogh Plan", category: "Qualified" },
  "457plan": { displayName: "457 Plan", category: "Non-Qualified" },
  "401a": { displayName: "401(a)", category: "Qualified" },
  "brokerageAccount": { displayName: "Brokerage Account", category: "Non-Qualified" },
  "educationSavings": { displayName: "Education Savings", category: "Qualified" },
  "healthSavingsAccount": { displayName: "Health Savings Account", category: "Non-Qualified" },
  "pension": { displayName: "Pension", category: "Non-Qualified" },
  "profitSharingPlan": { displayName: "Profit Sharing Plan", category: "Qualified" },
  "roth401k": { displayName: "Roth 401(k)", category: "Non-Qualified" },
  "sepIRA": { displayName: "SEP IRA", category: "Qualified" },
  "simpleIRA": { displayName: "SIMPLE IRA", category: "Qualified" },
  "thriftSavingsPlan": { displayName: "Thrift Savings Plan", category: "Qualified" },
  "variableAnnuity": { displayName: "Variable Annuity", category: "Qualified" },
  "cryptocurrency": { displayName: "Cryptocurrency", category: "Non-Qualified" },
  "mortgage": { displayName: "Mortgage", category: "Non-Qualified" },
  "loan": { displayName: "Loan", category: "Non-Qualified" },
  "studentLoan": { displayName: "Student Loan", category: "Non-Qualified" },
  "studentLoanGroup": { displayName: "Student Loan Group", category: "Non-Qualified" },
  "studentLoanAccount": { displayName: "Student Loan Account", category: "Non-Qualified" },
};

