import apiRequest from "./api";
import { BASE_API_URL } from "../config/constant";

export const getSubscriptionInfo = () =>
  apiRequest('get', `${BASE_API_URL}/subscription/get_subscription`).then((res) => res.data);

export const changeSubscription = (plan: string) =>
  apiRequest('post', `${BASE_API_URL}/subscription/change_subscription`, {
    body: {
      plan
    }
  }).then((res) => res.data);

export const addPaymentMethod = (paymentMethodId: string) =>
  apiRequest('post', `${BASE_API_URL}/subscription/add_payment_method`, {
    body: {
      paymentMethodId
    }
  }).then((res) => res.data);