import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { currencyFormat } from "../../../../libraries/utils";

interface TranscriptProps {
  isOpen: boolean,
  onClose: () => void,
  title: string,
  type: 'TRT' | 'W&I' | 'ACCT',
  transcript: any,
}


const TranscriptModal: React.FC<TranscriptProps> = ({
                                                      isOpen,
                                                      onClose,
                                                      title,
                                                      type,
                                                      transcript
                                                    }) => {
  // Chakra Color Mode
  const textColor = useColorModeValue("navy.700", "white");

  const isZeroValue = (value: string): boolean => {
    return value === '$0.00' || value === '$0' || value === '0';
  };

  const areAllItemsZero = (items: any[]) => {
    return items.every(item => isZeroValue(item.value));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='full'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack color={textColor} justify='center'>
            <Text fontSize='2xl'>
              {title}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
            type === 'TRT' &&
            <Box mb={4}>
              {transcript?.einProvided && <Text><b>EIN Provided:</b> {transcript?.einProvided}</Text>}
              {transcript?.ssnProvided && <Text><b>SSN Provided:</b> {transcript?.ssnProvided}</Text>}
              <Text><b>Tax Period Requested:</b> {transcript?.period}</Text>
              <Text><b>Form Number:</b> {transcript?.form}</Text>
              <Text><b>Duplicate Amendment Number:</b> {transcript?.duplicateAmendmentNumber}</Text>
              <Text><i>The following items reflect the amount as shown on the return as filed or as adjusted during
                return processing. It does not include adjustments to the account after return settlement.</i></Text>

              <Box mt={4}>
                <Text fontWeight="bold" fontSize='xl' textAlign='center'>Original Return</Text>
                {transcript?.ssn && <Text><b>SSN:</b> {transcript?.ssn}</Text>}
                {transcript?.spouseSsn && <Text><b>SPOUSE SSN:</b> {transcript?.spouseSsn}</Text>}
                <Text><b>NAME(S) SHOWN ON RETURN:</b> {transcript?.nameShownOnReturn}</Text>
                <Text><b>ADDRESS:</b> {transcript?.street} {transcript?.city} {transcript?.state} {transcript?.zip}
                </Text>
                <Text><b>CYCLE POSTED:</b> {transcript?.cycleYear}{transcript?.cycleWeek}{transcript?.cycleDay}</Text>
                <Text><b>DLN:</b> {transcript?.dln}</Text>
                <Text><b>REMITTANCE:</b> {transcript?.remittance}</Text>
                <Text><b>RECEIVED DATE:</b> {transcript?.receivedDate}</Text>

                {transcript?.filingStatus && <Text><b>FILLING STATUS:</b> {transcript?.filingStatus}</Text>}
                {transcript?.exemptionNumber && <Text><b>EXEMPTION NUMBER:</b> {transcript?.exemptionNumber}</Text>}
                {transcript?.dependent1NameCtrl &&
                  <Text><b>DEPENDENT 1 NAME CTRL:</b> {transcript?.dependent1NameCtrl}</Text>}
                {transcript?.dependent1Ssn && <Text><b>DEPENDENT 1 SSN:</b> {transcript?.dependent1Ssn}</Text>}
                {transcript?.dependent2NameCtrl &&
                  <Text><b>DEPENDENT 2 NAME CTRL:</b> {transcript?.dependent2NameCtrl}</Text>}
                {transcript?.dependent2Ssn && <Text><b>DEPENDENT 2 SSN:</b> {transcript?.dependent2Ssn}</Text>}
                {transcript?.dependent3NameCtrl &&
                  <Text><b>DEPENDENT 3 NAME CTRL:</b> {transcript?.dependent3NameCtrl}</Text>}
                {transcript?.dependent3Ssn && <Text><b>DEPENDENT 3 SSN:</b> {transcript?.dependent3Ssn}</Text>}
                {transcript?.dependent4NameCtrl &&
                  <Text><b>DEPENDENT 4 NAME CTRL:</b> {transcript?.dependent4NameCtrl}</Text>}
                {transcript?.dependent4Ssn && <Text><b>DEPENDENT 4 SSN:</b> {transcript?.dependent4Ssn}</Text>}
                {transcript?.ptin && <Text><b>PTIN:</b> {transcript?.ptin}</Text>}
                {transcript?.preparerEin && <Text><b>PREPARE EIN:</b> {transcript?.preparerEin}</Text>}
              </Box>
            </Box>
          }

          {
            type === 'W&I' &&
            <Box mb={4}>
              <Text><b>SSN Provided:</b> {transcript?.ssn}</Text>
              <Text><b>Tax Period Requested:</b> {transcript?.period}</Text>
            </Box>
          }

          {type === 'ACCT' && (
            <Box mb={4}>
              <Text><b>Taxpayer Identification Number:</b> {transcript?.taxpayerIdentificationNumber}</Text>
              <Text><b>Spouse Taxpayer Identification Number:</b> {transcript?.spouseTaxpayerIdentificationNumber}
              </Text>
              <Text><b>Account Name:</b> {transcript?.accountName1}</Text>
              {transcript?.accountName2 && <Text><b>Account Name 2:</b> {transcript?.accountName2}</Text>}
              <Text><b>Address:</b> {transcript?.accountStreet}, {transcript?.accountCity}, {transcript?.accountState} {transcript?.accountZip}
              </Text>
              <Text><b>Account Balance:</b> {transcript?.accountBalance}</Text>
              <Text><b>Accrued Interest:</b> {transcript?.accruedInterest}</Text>
              <Text><b>Accrued Interest As Of:</b> {transcript?.accruedInterestAsOf}</Text>
              <Text><b>Accrued Penalty:</b> {transcript?.accruedPenalty}</Text>
              <Text><b>Accrued Penalty As Of:</b> {transcript?.accruedPenaltyAsOf}</Text>
              <Text><b>Account Balance Plus Accruals:</b> {transcript?.accountBalancePlusAccruals}</Text>
              <Text><b>Exemptions:</b> {transcript?.exemptions}</Text>
              <Text><b>Account Filing Status:</b> {transcript?.accountFilingStatus}</Text>
              <Text><b>Adjusted Gross Income:</b> {transcript?.adjustedGrossIncome}</Text>
              <Text><b>Taxable Income:</b> {transcript?.taxableIncome}</Text>
              <Text><b>Tax Per Return:</b> {transcript?.taxPerReturn}</Text>
              <Text><b>SE Taxable Income Taxpayer:</b> {transcript?.seTaxableIncomeTaxpayer}</Text>
              <Text><b>SE Taxable Income Spouse:</b> {transcript?.seTaxableIncomeSpouse}</Text>
              <Text><b>Total Self-Employment Tax:</b> {transcript?.totalSelfEmploymentTax}</Text>
              {transcript?.netReceipts && <Text><b>Net Receipts:</b> {transcript?.netReceipts}</Text>}
              {transcript?.totalIncome && <Text><b>Total Income:</b> {transcript?.totalIncome}</Text>}
              {transcript?.totalDeductions && <Text><b>Total Deductions:</b> {transcript?.totalDeductions}</Text>}
              {transcript?.netTaxableIncome && <Text><b>Net Taxable Income:</b> {transcript?.netTaxableIncome}</Text>}
              {transcript?.minimumTaxAmount && <Text><b>Minimum Tax Amount:</b> {transcript?.minimumTaxAmount}</Text>}
              {transcript?.estimatedTaxCredits &&
                <Text><b>Estimated Tax Credits:</b> {transcript?.estimatedTaxCredits}</Text>}
              {transcript?.personalHoldingTax &&
                <Text><b>Personal Holding Tax:</b> {transcript?.personalHoldingTax}</Text>}
              {transcript?.es7004PaymentsClaimed &&
                <Text><b>ES7004 Payments Claimed:</b> {transcript?.es7004PaymentsClaimed}</Text>}
              {transcript?.naicsCd && <Text><b>NAICS Code:</b> {transcript?.naicsCd}</Text>}
              <Text><b>Return Due Date Or Return Received Date:</b> {transcript?.returnDueDateOrReturnReceivedDate}
              </Text>
              {transcript?.processedDate && <Text><b>Processed Date:</b> {transcript?.processedDate}</Text>}
              {transcript?.processingDate && <Text><b>Processing Date:</b> {transcript?.processingDate}</Text>}
              <Text><b>SSN Provided:</b> {transcript?.ssnProvided}</Text>
              <Text><b>Tax Period Ending:</b> {transcript?.taxPeriodEnding}</Text>
              <Text><b>SSN:</b> {transcript?.ssn}</Text>
              <Text><b>Spouse SSN:</b> {transcript?.spouseSsn}</Text>
              <Text><b>Names Shown On Return:</b> {transcript?.namesShownOnReturn}</Text>
              <Text><b>Return Filing Status:</b> {transcript?.returnFilingStatus}</Text>
              <Text><b>Return Form Number:</b> {transcript?.returnFormNumber}</Text>
              <Text><b>Cycle Year:</b> {transcript?.cycleYear}</Text>
              <Text><b>Cycle Week:</b> {transcript?.cycleWeek}</Text>
              <Text><b>Cycle Day:</b> {transcript?.cycleDay}</Text>
              <Text><b>Received Date:</b> {transcript?.receivedDate}</Text>
              <Text><b>Remittance:</b> {transcript?.remittance}</Text>
              <Text><b>Exemption Number:</b> {transcript?.exemptionNumber}</Text>
              {transcript?.ptin && <Text><b>PTIN:</b> {transcript?.ptin}</Text>}
              {transcript?.preparerEin && <Text><b>Preparer EIN:</b> {transcript?.preparerEin}</Text>}
              <Text><b>Return Filed Date:</b> {transcript?.returnFiledDate}</Text>
              <Text><b>Return Filed Amount:</b> {transcript?.returnFiledAmount}</Text>
              <Text><b>Return Filed:</b> {transcript?.returnFiled}</Text>
              {/* Add any additional fields if necessary... */}
            </Box>
          )}

          <Accordion allowMultiple>
            {transcript?.sections.map((section: any, index: number) => {
              const allItemsZero = areAllItemsZero(section?.items || []);
              const allSubsectionsZero =
                section.subsections?.every((subsection: any) => areAllItemsZero(subsection.items || [])) || false;
              const sectionIsZero =
                (allItemsZero && allSubsectionsZero) ||
                (section?.items?.length === 0 && section?.subsections?.length === 0);
              return (
                <AccordionItem key={index}
                               borderBottomWidth={sectionIsZero  ? "2px" : "1px"}
                               borderBottomColor={sectionIsZero  ? "gray.300" : "gray.200"}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        _expanded={{ bg: sectionIsZero  ? "gray.100" : "inherit" }}>
                        <Box flex="1" textAlign="left" fontWeight="bold"
                             color={sectionIsZero  ? "gray.500" : "inherit"}
                             opacity={sectionIsZero  ? 0.4 : 1}>
                          {section?.description}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel pb={4}
                                      opacity={sectionIsZero  ? 0.4 : 1}>
                        {
                          type === 'W&I' &&
                          <Box my={4}>
                            <Text><b>{section?.businessLabel}:</b> {section?.businessEin}</Text>
                            <Text>{section?.businessAddress1}</Text>
                            <Text>{section?.businessAddress2}</Text>
                            <Text>{section?.businessCity}, {section?.businessState} {section?.businessZip}</Text>
                            <Text mt={2}><b>{section?.individualLabel}:</b> {section?.individualSsn}</Text>
                            <Text>{section?.individualAddress1}</Text>
                            <Text>{section?.individualAddress2}</Text>
                            <Text>{section?.individualCity}, {section?.individualState} {section?.individualZip}</Text>
                          </Box>
                        }

                        <VStack align="stretch">
                          {section?.items.map((item: any, itemIndex: number) => (
                            <Box key={itemIndex}>
                              <HStack justifyContent="space-between">
                                <Text>{item.description}:</Text>
                                <Text fontWeight="semibold">{item.value}</Text>
                              </HStack>
                              <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed" />
                            </Box>
                          ))}

                          {section?.subsections && section.subsections.map((subsection: any, subsectionIndex: number) => (
                            <Box key={subsectionIndex}>
                              <Box flex="1" textAlign="left" fontWeight="bold">
                                {subsection?.description}
                              </Box>
                              {subsection?.items && subsection?.items.map((item: any, itemIndex: number) => (
                                <Box key={itemIndex}>
                                  <HStack justifyContent="space-between">
                                    <Text>{item.description}:</Text>
                                    <Text fontWeight="semibold">{item.value}</Text>
                                  </HStack>
                                  <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed" />
                                </Box>
                              ))}
                            </Box>
                          ))}
                        </VStack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              )
            })}
          </Accordion>

          {type === 'ACCT' && (
            <Box mt={4}>
              <Text fontSize="lg" fontWeight="bold" mb={2} textAlign='center'>Transactions</Text>
              <TransactionsTable transactions={transcript.transactions?.[0].items} />
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brandScheme" variant="ghost" onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
    ;
}

const TransactionsTable = ({ transactions }: any) => {
  return (
    <Box overflowX="scroll">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Code</Th>
            <Th>Description</Th>
            <Th>Cycle</Th>
            <Th>Date</Th>
            <Th isNumeric>Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transactions.map((transaction: any, index: number) => (
            <Tr key={index}>
              <Td>{transaction?.code}</Td>
              <Td>{transaction?.explanationOfTransaction}</Td>
              <Td>{transaction?.cycleYear}{transaction?.cycleWeek} {transaction?.cycleDay}</Td>
              <Td>{transaction?.date}</Td>
              <Td isNumeric>{transaction?.amount ? currencyFormat(transaction?.amount) : 0}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default TranscriptModal;
