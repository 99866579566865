import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  InputLeftElement, Text,
  useColorModeValue
} from '@chakra-ui/react';
import { CirclesWithBar } from 'react-loader-spinner';
import { MdAccountBalance, MdEmail, MdHome, MdPeople, MdPerson, MdPhone } from "react-icons/md";
import {
  getValueByFieldName,
  isValidEmail,
  isValidUSPhone,
  removePlusPhoneNumber
} from "../../../../../libraries/utils";
import { getFormFieldValues, updateFormFieldValues } from "../../../../../api/smart-fill-service";
import { StepComponentProps } from "../IRASchwabApplicationModal";

const IA_FIRM_NAME = 'clients[0].Form[0].InvestmentAdvisorInformation[0].border[0].IA-Box-01_Contact[0].IA[0].ia_firm_name[0]';
const IA_MASTER_ACCT_NUM = 'clients[0].Form[0].InvestmentAdvisorInformation[0].border[0].IA-Box-01_Contact[0].IA[0].ia_master_acct_num[0]';
const IA_SVC_TEAM = 'clients[0].Form[0].InvestmentAdvisorInformation[0].border[0].IA-Box-01_Contact[0].IA[0].ia_svc_team[0]';
const IA_CONTACT_NAME = 'clients[0].Form[0].InvestmentAdvisorInformation[0].border[0].IA-Box-01_Contact[0].IA[0].iaAvisorContactInfo[0]';
const IA_PHONE = 'clients[0].Form[0].InvestmentAdvisorInformation[0].border[0].IA-Box-01_Contact[0].IA[0].phone[0]';
const IA_EMAIL = 'clients[0].Form[0].InvestmentAdvisorInformation[0].border[0].IA-Box-01_Contact[0].IA[0].Email[0]';

const InvestmentAdvisorInformation = forwardRef<any, StepComponentProps>(({userFormId, title, ...props}, ref) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [isLoading, setIsLoading] = useState(false);
  const [firmName, setFirmName] = useState('');
  const [masterAccountNumber, setMasterAccountNumber] = useState('');
  const [serviceTeam, setServiceTeam] = useState('smartfees');
  const [contactName, setContactName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const [errors, setErrors] = useState({
    firmName: '',
    masterAccountNumber: '',
    serviceTeam: '',
    contactName: '',
    phoneNumber: '',
    email: '',
  });

  useEffect(() => {
    fetchFormValues();
  }, []);

  const fieldConstants = {
    iaFirmName: IA_FIRM_NAME,
    iaMasterAcctNum: IA_MASTER_ACCT_NUM,
    iaSvcTeam: IA_SVC_TEAM,
    iaContactName: IA_CONTACT_NAME,
    iaPhone: IA_PHONE,
    iaEmail: IA_EMAIL
  };

  const setters: any = {
    iaFirmName: setFirmName,
    iaMasterAcctNum: setMasterAccountNumber,
    iaSvcTeam: setServiceTeam,
    iaContactName: setContactName,
    iaPhone: (value: string) => setPhoneNumber(removePlusPhoneNumber(value)),
    iaEmail: setEmail
  };

  const fetchFormValues = () => {
    setIsLoading(true);
    getFormFieldValues(userFormId)
      .then(({fieldValues}) => {
        Object.entries(fieldConstants).forEach(([key, path]) => {
          const value = getValueByFieldName(fieldValues, path);
          setters[key](value);
        });
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsLoading(false))
  }

  const updateData = async () => {
    await updateFormFieldValues([
      {
        [IA_FIRM_NAME]: firmName,
        fieldType: 'PDFTextField'
      },
      {
        [IA_MASTER_ACCT_NUM]: masterAccountNumber,
        fieldType: 'PDFTextField'
      },
      {
        [IA_SVC_TEAM]: serviceTeam,
        fieldType: 'PDFTextField'
      },
      {
        [IA_CONTACT_NAME]: contactName,
        fieldType: 'PDFTextField'
      },
      {
        [IA_PHONE]: phoneNumber,
        fieldType: 'PDFTextField'
      },
      {
        [IA_EMAIL]: email,
        fieldType: 'PDFTextField'
      },
    ], userFormId);
  }

  const validateEmail = (email: string) => {
    // Add your email validation logic here
    return /^(.+)@(.+)\.(.+)$/.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: validateEmail(newEmail) ? '' : 'Invalid email address.',
    }));
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    let onlyNums = value.replace(/\D/g, '');
    let formattedNumber;
    if (onlyNums.length <= 3) {
      formattedNumber = onlyNums;
    } else if (onlyNums.length <= 6) {
      formattedNumber = `(${ onlyNums.slice(0, 3) }) ${ onlyNums.slice(3) }`;
    } else {
      formattedNumber = `(${ onlyNums.slice(0, 3) }) ${ onlyNums.slice(3, 6) }-${ onlyNums.slice(6, 10) }`;
    }

    setPhoneNumber(formattedNumber);
    setErrors((prevErrors) => ({
      ...prevErrors,
      phoneNumber: '',
    }));
  };

  useImperativeHandle(ref, () => ({
    handleNext: async () => {
      if (!firmName) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          firmName: 'Firm Name is required.',
        }));
        return Promise.reject(new Error('Firm name is required'));
      } else if (!masterAccountNumber) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          masterAccountNumber: 'Master Account Number is required.',
        }));
        return Promise.reject(new Error('Master Account Number is required.'));
      } else if (!serviceTeam) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          serviceTeam: 'Service Team is required.',
        }));
        return Promise.reject(new Error('Service Team is required.'));
      } else if (!contactName) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          contactName: 'Contact Name is required.',
        }));
        return Promise.reject(new Error('Contact Name is required.'));
      } else if (!isValidUSPhone(phoneNumber)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: 'Invalid phone number!',
        }));
        return Promise.reject(new Error('Invalid phone number!'));
      } else if (!email) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Email is required.',
        }));
        return Promise.reject(new Error('Email is required.'));
      } else if (!isValidEmail(email)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Invalid email address!',
        }));
        return Promise.reject(new Error('Invalid email address!'));
      }
      try {
        await updateData();
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  }));

  return (
    <Box h='full'>
      <Text
        fontSize="2xl"
        fontWeight="500">
        { title }
      </Text>
      <Flex p={ {base: '0px', md: '32px'} } direction='column' align={ isLoading ? 'center' : 'start' }
            justify={ isLoading ? 'center' : 'start' } h='full'>
        {
          isLoading ? <CirclesWithBar
            height="100"
            width="100"
            wrapperClass=""
            visible={ true }
            ariaLabel="circles-with-bar-loading"
          /> : <Box w='full'>
            <Grid
              mt="12px"
              gap={ {base: 4, md: 2} }
            >
              <FormControl isRequired mt='12px' isInvalid={ !!errors.firmName }>
                <FormLabel>IA Firm Name</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                    <MdHome color="gray.300"/>
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ firmName }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      setFirmName(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        firmName: '',
                      }));
                    } }
                  />
                </InputGroup>
                { errors.firmName && <FormErrorMessage>{ errors.firmName }</FormErrorMessage> }
              </FormControl>
            </Grid>
            <Grid
              mt="12px"
              templateColumns={ {base: '1fr', md: '2fr 3fr'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl isRequired mt='12px' isInvalid={ !!errors.masterAccountNumber }>
                <FormLabel>IA Master Account Number</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                    <MdAccountBalance color="gray.300"/>
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ masterAccountNumber }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      setMasterAccountNumber(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        masterAccountNumber: '',
                      }));
                    } }
                  />
                </InputGroup>
                { errors.masterAccountNumber && <FormErrorMessage>{ errors.masterAccountNumber }</FormErrorMessage> }
              </FormControl>
              <FormControl isRequired mt='12px' isInvalid={ !!errors.serviceTeam }>
                <FormLabel>Service Team</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                    <MdPeople color="gray.300"/>
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ serviceTeam }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      setServiceTeam(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        serviceTeam: '',
                      }));
                    } }
                  />
                </InputGroup>
                { errors.serviceTeam && <FormErrorMessage>{ errors.serviceTeam }</FormErrorMessage> }
              </FormControl>
            </Grid>
            <Grid
              mt="12px"
              templateColumns={ {base: '1fr', md: '2fr 1fr 2fr'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl isRequired mt='12px' isInvalid={ !!errors.contactName }>
                <FormLabel>Contact Name</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                    <MdPerson color="gray.300"/>
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ contactName }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      setContactName(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        contactName: '',
                      }));
                    } }
                  />
                </InputGroup>
                { errors.contactName && <FormErrorMessage>{ errors.contactName }</FormErrorMessage> }
              </FormControl>
              <FormControl isRequired mt='12px' isInvalid={ !!errors.phoneNumber }>
                <FormLabel>IA Telephone Number</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                    <MdPhone color="gray.300"/>
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ phoneNumber }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handlePhoneNumberChange(e) }
                  />
                </InputGroup>
                { errors.phoneNumber && <FormErrorMessage>{ errors.phoneNumber }</FormErrorMessage> }
              </FormControl>
              <FormControl isRequired mt='12px' isInvalid={ !!errors.email }>
                <FormLabel>IA Email Address</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                    <MdEmail color="gray.300"/>
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ email }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => handleEmailChange(e) }
                  />
                </InputGroup>
                { errors.email && <FormErrorMessage>{ errors.email }</FormErrorMessage> }
              </FormControl>
            </Grid>
          </Box>
        }
      </Flex>
    </Box>
  );
});

export default InvestmentAdvisorInformation;
