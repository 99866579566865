import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Box, Checkbox, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { getFormFieldValues, updateFormFieldValues } from "../../../../../api/smart-fill-service";
import { getValueByFieldName } from "../../../../../libraries/utils";
import { CirclesWithBar } from "react-loader-spinner";
import { StepComponentProps } from "../IRASchwabApplicationModal";

const DO_NOT_ENROLL = 'clients[0].Form[0].Section4[0].border[0].paperlessDocument[0].doNotEnroll[0].noText[0]';

const PaperlessDocumentEnrollment = forwardRef<any, StepComponentProps>(({userFormId, title, ...props}, ref) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [doNotEnrolled, setDoNotEnrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchFormValues();
  }, []);

  const fetchFormValues = () => {
    setIsLoading(true);
    getFormFieldValues(userFormId)
      .then(({fieldValues}) => {
        const doNotEnroll = getValueByFieldName(fieldValues, DO_NOT_ENROLL) === "checked";
        setDoNotEnrolled(doNotEnroll);
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsLoading(false))
  }

  const updateData = async () => {
    await updateFormFieldValues([
        {
          [DO_NOT_ENROLL]: doNotEnrolled ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
      ], userFormId
    )
  }

  useImperativeHandle(ref, () => ({
    handleNext: async () => {
      try {
        await updateData();
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  }));

  return (
    <Box h='full'>
      <Text
        fontSize="2xl"
        fontWeight="500">
        { title }
      </Text>
      <Flex p={ {base: '0px', md: '32px'} } direction='column' align={ isLoading ? 'center' : 'start' }
            justify={ isLoading ? 'center' : 'start' } h='full'>
        {
          isLoading ? <CirclesWithBar
            height="100"
            width="100"
            wrapperClass=""
            visible={ true }
            ariaLabel="circles-with-bar-loading"
          /> : <Box w='full'>
            <Text
              color={ textColor }
            >
              You will receive account statements, trade confirmations, shareholder materials, account agreements and
              related disclosures, and other regulatory documents, if available in paperless form, by email. For certain
              documents, including account statements, you will receive an email notification with a link to log on to
              our secure website to access your documents. For complete information, please see Important Information
              About Your Informed Consent to Receive Paperless Documents in the Appendix to this application. Enrollment
              may also qualify you for lower online equity trades-consult your Advisor or the applicable Charles Schwab
              Pricing Guide for additional details.
            </Text>
            <Text
              color={ textColor }
            >
              To opt for Paperless Documents, simply provide your email address in the "Account Holder Information"
              section. The completion of your enrollment will depend on one of the following scenarios.
            </Text>
            <Text
              color={ textColor }
              fontWeight='500'
            >
              Scenario 1:
            </Text>
            <Text
              color={ textColor }
            >
              If you already have an account enrolled in Paperless Documents using the email address provided in the
              "Account Holder Information" section, have logged on to www.schwaballiance.com in the past six months, and
              agree to the following, your paperless enrollment will be complete once your account is opened.
              <Text>
                • I have read and understood the Important Information About Your Informed Consent to Receive Paperless
                Documents in the Appendix to this application and consent to enrolling this account in Paperless
                Documents.
              </Text>
              <Text>
                • I understand that I will receive an email with my new account agreement and related disclosures.
              </Text>
            </Text>
            <Text
              color={ textColor }>
              Or
            </Text>
            <Text
              color={ textColor }
              fontWeight='500'
            >
              Scenario 2:
            </Text>
            <Text
              color={ textColor }
            >
              If you do not have an existing account enrolled in Paperless Documents, are using a different email
              address, or have not logged on to www.schwaballiance.com in the past six months, we will send you an email
              after the account is opened. To complete enrollment, you will need to click the "I Consent" button in that
              email and/or follow the instructions to access www.schwaballiance.com. If you do not click the "I Consent"
              button, this account will not be enrolled in Paperless Documents, and we will send your account agreement
              and related disclosures, as well as future regulatory documents, by postal mail. If you do not want to
              participate in Paperless Documents, please check the box below.
            </Text>
            <Flex align='start'>
              <Checkbox
                defaultChecked={ doNotEnrolled }
                checked={ doNotEnrolled }
                onChange={ () => setDoNotEnrolled(prev => !prev) }
                colorScheme='brandScheme'
                mt='4px'
                me='10px'
              />
              <Text color={ textColor }>
                <b>No, do not enroll my account in Paperless Documents.</b> Please send my regulatory documents via
                postal mail.
              </Text>
            </Flex>
          </Box>
        }
      </Flex>
    </Box>
  );
});

export default PaperlessDocumentEnrollment;
