import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Box, Checkbox, Flex, FormControl, FormLabel, Grid, Input, Text, useColorModeValue } from '@chakra-ui/react';
import { getFormFieldValues, updateFormFieldValues } from "../../../../../api/smart-fill-service";
import { getValueByFieldName } from "../../../../../libraries/utils";
import { CirclesWithBar } from "react-loader-spinner";
import { StepComponentProps } from "../IRASchwabApplicationModal";

const AUTHORIZATION_TO_OPEN_ACCOUNT = 'clients[0].Form[0].Section8[0].border[0].AuthorizationToOpenAccount[0].AccountOpenCertifyWrapperSubForm[0].Backup_witholding[0].Chk1[0].Chk1[0]';
const PRINT_NAME_FULL_ACCOUNT_HOLDER_SIGNATURE = 'clients[0].Form[0].Section8[0].border[0].Signature_Section_DocuSign[0].SIGBLK_01_AccountHolder_Generic_00[0].SignatureFields[0].PrintName[0].PrintNameFull_DocuSignIgnoreTransform[0]';

const OpenAccount = forwardRef<any, StepComponentProps>(({userFormId, title, ...props}, ref) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [isLoading, setIsLoading] = useState(false);
  const [printName, setPrintName] = useState('');
  const [isFailedTaxReturn, setIsFailedTaxReturn] = useState(false);

  useEffect(() => {
    fetchFormValues();
  }, []);

  const fetchFormValues = () => {
    setIsLoading(true);
    getFormFieldValues(userFormId)
      .then(({fieldValues}) => {
        const authorizationToOpenAccount = getValueByFieldName(fieldValues, AUTHORIZATION_TO_OPEN_ACCOUNT) === 'checked';
        const fullName = getValueByFieldName(fieldValues, PRINT_NAME_FULL_ACCOUNT_HOLDER_SIGNATURE);
        setIsFailedTaxReturn(authorizationToOpenAccount);
        setPrintName(fullName);
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsLoading(false))
  }

  const updateData = async () => {
    await updateFormFieldValues([
        {
          [AUTHORIZATION_TO_OPEN_ACCOUNT]: isFailedTaxReturn ? 'checked' : '',
          fieldType: 'PDFCheckBox'
        },
        {
          [PRINT_NAME_FULL_ACCOUNT_HOLDER_SIGNATURE]: printName,
          fieldType: 'PDFTextField'
        },
      ], userFormId
    )
  }

  useImperativeHandle(ref, () => ({
    handleSave: async () => {
      try {
        await updateData();
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  }));

  return (
    <Box h='full'>
      <Text
        fontSize="2xl"
        fontWeight="500">
        { title }
      </Text>
      <Flex p={ {base: '0px', md: '32px'} } direction='column' align={ isLoading ? 'center' : 'start' }
            justify={ isLoading ? 'center' : 'start' } h='full'>
        {
          isLoading ? <CirclesWithBar
            height="100"
            width="100"
            wrapperClass=""
            visible={ true }
            ariaLabel="circles-with-bar-loading"
          /> : <Box w='full'>
            <Text
              color={ textColor }
            >
              By signing this Application, you hereby adopt the applicable Individual Retirement Plan that names Charles
              Schwab & Co., Inc. as custodian of this account, as further explained in the Charles Schwab & Co., Inc.
              Individual Retirement Plan or the SIMPLE Individual Retirement Plan, as applicable. You acknowledge that
              you have received and read the attached Application Agreement, which contains a predispute arbitration
              provision. You acknowledge that your signature signifies and constitutes your agreement that this account
              and your relationship with Schwab will be governed by the Application Agreement and all incorporated
              agreements and disclosures, including, but not limited to, the applicable Individual Retirement Plan and
              Disclosure Statement, the Schwab IRA and ESA Account Agreement, and the applicable Charles Schwab Pricing
              Guide, each as amended from time to time (the "Agreement and Disclosures")
            </Text>
            <Text color={ textColor }>
              This account is established and effective when you receive your account number. You have the right to
              cancel the account within seven days from the date the account is established. The revocation will be
              reported to the Internal Revenue Service as a distribution. For purposes of this Account Application and
              the attached Application Agreement, the terms "I," "me," and "Account Holder" refer to each person who
              signs this Account Application. The terms "we," "us," "our," and "Schwab" refer to Charles Schwab & Co.,
              Inc.
            </Text>
            <Text color={ textColor }>
              <b>Note:</b> You may not alter, change, supplement, or amend ("modifications") the terms and conditions of
              this Account Application, Application Agreement, or the Agreement and Disclosures in any manner. You agree
              that Schwab shall not be liable for any modifications made without Schwab's prior written consent.
            </Text>
            <Flex gap={ 3 } direction='column' borderWidth='1px' p='8px' borderColor={ textColor } mt='12px'>
              <Text
                color={ textColor }
                fontWeight='500'
              >
                I certify, under penalties of perjury, that (1) the number shown on this Application is my correct
                Taxpayer Identification Number; and (2) I am not subject to backup withholding due to a failure to
                report interest and dividend income; and (3) I am a U.S. person (a U.S. citizen or U.S. resident alien);
                and (4) the FATCA code entered on this form (if any) indicating that I am exempt from FATCA reporting is
                correct.
              </Text>
              <Text
                color={ textColor }
                fontWeight='500'
              >
                • If you have been notified by the IRS that you are currently subject to backup withholding because you
                have failed to report all interest and dividends on your tax return, you must check the following box
                indicating that item (2) above does not apply:
                <Checkbox
                  ms='12px'
                  mt='5px'
                  colorScheme='brandScheme'
                  alignSelf='center'
                  defaultChecked={ isFailedTaxReturn }
                  checked={ isFailedTaxReturn }
                  onChange={ () => setIsFailedTaxReturn(prev => !prev) }
                />
              </Text>
              <Text
                color={ textColor }
                fontWeight='500'
              >
                If you are submitting this Application for an account you hold in the U.S., item (4) above does not
                apply.
              </Text>
            </Flex>
            <Grid templateColumns={ {base: '1fr', md: 'repeat(2, 1fr)'} } gap='16px' mt='12px'>
              <Flex borderWidth='1px' p='8px' borderColor={ textColor } align='center'>
                <Text
                  color={ textColor }
                  fontWeight='500'
                >
                  The Internal Revenue Service does not require your consent to any provision of this document other
                  than the certifications required to avoid backup withholding.
                </Text>
              </Flex>
              <Flex borderWidth='1px' p='8px' borderColor={ textColor } align='center'>
                <Text
                  color={ textColor }
                  fontWeight='500'
                >
                  The Agreement with Schwab includes a predispute arbitration clause. I acknowledge receipt of the
                  predispute arbitration clause contained in the Required Arbitration Disclosures and Arbitration
                  Agreement sections, pages 1 and 2, of the IRA Account Application Agreement.
                </Text>
              </Flex>
            </Grid>
            <FormControl isRequired mt='12px'>
              <FormLabel>Print Name</FormLabel>
              <Input
                type="text"
                color={ textColor }
                value={ printName }
                onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                  setPrintName(e.target.value);
                } }
              />
            </FormControl>
          </Box>
        }
      </Flex>
    </Box>
  );
});

export default OpenAccount;
