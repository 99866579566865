import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { numberFormat } from "../../../../libraries/utils";
import { PolicyIcons } from "../../../../components/icons/PolicyIcons";
import Dwelling from "./policy/Dwelling";
import Vehicle from "./policy/Vehicle";

export default function PolicyRow(props) {
  const {
    policies
  } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  function PolicyIcon({ status, ...rest }) {
    const Icon = PolicyIcons.hasOwnProperty(status)
      ? PolicyIcons[status]
      : PolicyIcons.AUTO;

    return <Icon { ...rest } />;
  }

  return (
    <Box mt='32px'>
      <Text
        color={ textColor }
        fontWeight='bold'
        fontSize='lg'
      >
        Policies
      </Text>
      <Box mt='8px'>
        {
          policies?.length > 0 ?
            policies.map(policy => (
              <Accordion me='24px' key={ policy.policyId } allowToggle>
                <AccordionItem>
                  <AccordionButton py={ 4 }>
                    <Flex justify='space-between' align='center' flex={ 1 }>
                      <PolicyIcon size={ 28 } color='green' status={ policy.policyType }/>
                      <Text
                        color={ textColor }
                        fontWeight='500'
                        fontSize='sm'
                      >
                        { policy?.name || '' }
                      </Text>
                      <Text
                        color={ textColor }
                        fontSize='sm'
                      >
                        ${ numberFormat(policy?.totalPremiumCents / 100 || 0) }
                      </Text>
                      <Text
                        color={ textColor }
                        fontSize='sm'
                      >
                        { policy?.description || '' }
                      </Text>
                    </Flex>
                    <AccordionIcon ms='8px'/>
                  </AccordionButton>
                  <AccordionPanel pb={ 4 }>
                    {
                      policy?.dwellings?.length > 0 && <Dwelling dwellings={policy.dwellings} />
                    }
                    {
                      policy?.vehicles?.length > 0 && <Vehicle vehicles={policy.vehicles} />
                    }
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )) :
            <Text textAlign='center'>
              There is no data.
            </Text>
        }
      </Box>
    </Box>
  );
}
