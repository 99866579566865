import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  Wrap
} from "@chakra-ui/react";
import { getUserByIdAsAdvisor, transferAccess, updateClient } from "../../../api/user-service";
import { ClientType } from "../../../types/user";
import moment from "moment/moment";
import UpdateClientModal from "../../admin/modals/components/UpdateClientModal";
import TransferOwnerShipModal from "../../admin/modals/components/TransferOwnershipModal";
import { getBadgeColor } from "../../../libraries/utils";

const ClientDetail = () => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<ClientType | undefined>(undefined);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isTransferOpen, onOpen: onTransferOpen, onClose: onTransferClose } = useDisclosure();

  useEffect(() => {
    fetchClient(id);
  }, [id]);

  const fetchClient = (id: string) => {
    setIsLoading(true);
    getUserByIdAsAdvisor(id)
      .then(({ user }) => {
        setUser(user);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const updateClientInfo = async ({
                                    addressLine1,
                                    birthday,
                                    city,
                                    companyName,
                                    email,
                                    firstName,
                                    lastName,
                                    management,
                                    middleName,
                                    nickName,
                                    phoneNumber,
                                    postalCode,
                                    state,
                                    userRole,
                                    note,
                                  }: {
    firstName: string,
    middleName: string,
    lastName: string,
    nickName: string,
    birthday: string,
    email: string,
    phoneNumber: string,
    userRole: string,
    management: string,
    addressLine1: string,
    city: string,
    state: string,
    postalCode: string,
    companyName: string,
    note?: string,
  }) => {
    try {
      await updateClient(id, {
        addressLine1,
        dateOfBirth: birthday,
        city,
        fullName: companyName,
        email,
        firstName,
        lastName,
        management,
        middleName,
        nickName,
        phoneNumber,
        postalCode,
        state,
        accountType: userRole,
        note,
      })
      toast({
        title: 'Update Lead',
        description: `${firstName}'s information has been updated.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchClient(id);
    } catch (error: any) {
      toast({
        title: 'Update Lead',
        description: error.response?.data.message ?? error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  };


  const transfer = async ({
                            fromUserId,
                            toUserId,
                            toUserName,
                          }: {
    fromUserId: number,
    toUserId: number,
    toUserName: string,
  }) => {
    try {
      await transferAccess(user?.userId, fromUserId, toUserId);
      toast({
        title: 'Access Transfer',
        description: `${user?.firstName}'s access transferred to ${toUserName}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

    } catch (error: any) {
      toast({
        title: 'Access Transfer',
        description: error.response?.data.message ?? error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {
        isLoading ? <Flex justify="center" align="center" height="100vh">
            <CircularProgress size={12} isIndeterminate color="green.300" />
          </Flex> :
          <Box>
            <Grid
              gap={4}
              templateRows={{
                base: 'none',
                lg: '1fr',
              }}
              templateColumns={{
                base: 'none',
                lg: 'repeat(4, 1fr)',
              }}>
              <GridItem colSpan={1}>
                <Flex align="center" direction='column' mt="20px">
                  <Tooltip label={user?.receivedInvitations?.[0].accepted ? 'Joined' : 'Not Joined'}
                           placement='right'>
                    <Avatar
                      src={user?.avatarUrl}
                      name={`${user?.firstName} ${user?.lastName}`}
                      size="xl"
                    >
                      <AvatarBadge boxSize='1em'
                                   bg={user?.receivedInvitations?.[0].accepted ? 'green.500' : 'red.500'} />
                    </Avatar>
                  </Tooltip>
                  <Text mt={4} fontWeight="500">Client
                    from: {moment(user?.receivedInvitations?.[0].createdAt).format('MMMM DD, YYYY')}</Text>
                </Flex>
              </GridItem>
              <GridItem colSpan={{ base: 1, lg: 3 }}>
                <Grid
                  templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                  gap={{ base: 4, md: 8 }}
                >
                  <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input readOnly defaultValue={user?.firstName} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Middle Name</FormLabel>
                    <Input readOnly defaultValue={user?.middleName} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input readOnly defaultValue={user?.lastName} />
                  </FormControl>
                </Grid>
                <Grid mt="16px"
                      templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                      gap={{ base: 4, md: 8 }}
                >
                  <FormControl>
                    <FormLabel>Company Name</FormLabel>
                    <Input readOnly defaultValue={user?.fullName} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Date Of Birth</FormLabel>
                    <Input readOnly defaultValue={user?.dateOfBirth} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Gender</FormLabel>
                    <Input readOnly defaultValue={user?.gender || 'Unknown'} />
                  </FormControl>
                </Grid>
                <Grid mt="16px"
                      templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                      gap={{ base: 4, md: 8 }}
                >
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input readOnly defaultValue={user?.email} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Phone Number</FormLabel>
                    <Input readOnly defaultValue={user?.phoneNumber} />
                  </FormControl>
                </Grid>
                <Grid mt="16px"
                      templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                      gap={{ base: 4, md: 8 }}
                >
                  <GridItem colSpan={{ base: 1, md: 2 }}>
                    <FormControl>
                      <FormLabel>Address</FormLabel>
                      <Input readOnly defaultValue={user?.addressLine1} />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>Address</FormLabel>
                      <Input readOnly defaultValue={user?.city} />
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid mt="16px"
                      templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                      gap={{ base: 4, md: 8 }}
                >
                  <FormControl>
                    <FormLabel>State</FormLabel>
                    <Input readOnly defaultValue={user?.state} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Zip Code</FormLabel>
                    <Input readOnly defaultValue={user?.postalCode} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Country</FormLabel>
                    <Input readOnly defaultValue={user?.country} />
                  </FormControl>
                </Grid>
                <FormControl mt='16px'>
                  <FormLabel>Note</FormLabel>
                  <Text color='gray.600'>{user?.note}</Text>
                </FormControl>
                <Button mt='16px' colorScheme="brand" variant="solid" maxW="120px" onClick={onOpen}>
                  Update
                </Button>
                <Box mt="32px" w="full">
                  <Text fontSize='xl' fontWeight='500'>Advisor Information</Text>
                  <Divider />
                </Box>
                <Flex direction={{ base: 'column', md: 'row' }} py='16px' gap={2} align='center'
                      justify={{ base: 'center', md: 'space-between' }}>
                  <Wrap>
                    <FormLabel>Name: </FormLabel>
                    <Text>{user?.receivedInvitations?.[0].sender?.firstName} {user?.receivedInvitations?.[0].sender?.lastName}</Text>
                  </Wrap>
                  <Wrap>
                    <FormLabel>Email: </FormLabel>
                    <Text>{user?.receivedInvitations?.[0].sender?.email}</Text>
                  </Wrap>
                  <Button variant="solid" size='sm' colorScheme="brand" onClick={onTransferOpen}>
                    Access Transfer
                  </Button>
                </Flex>
                {
                  user?.smarttaxClients?.length > 0 &&

                  <Box mt="32px" w="full">
                    <Text fontSize='xl' fontWeight='500'>Smarttax Client Information</Text>
                    <Divider />
                    {
                      user?.smarttaxClients.map((client: any) => (
                        <Grid mt="16px"
                              templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                              gap={{ base: 2, md: 4 }}
                              borderWidth='1px'
                              p={2}
                              borderRadius={4}
                        >
                          <Wrap>
                            <FormLabel>Org. Type: </FormLabel>
                            <Text>{client?.organizationType}</Text>
                          </Wrap>
                          <Wrap>
                            <FormLabel>Title: </FormLabel>
                            <Text>{client?.title}</Text>
                          </Wrap>
                          <Wrap>
                            <FormLabel>Status: </FormLabel>
                            <Badge colorScheme={getBadgeColor(client?.status)}>{client?.status}</Badge>
                          </Wrap>
                          <Wrap>
                            <FormLabel>EIN: </FormLabel>
                            <Text>{client?.lastFourEIN ? `** ***${client?.lastFourEIN}` : '--'}</Text>
                          </Wrap>
                          <Wrap>
                            <FormLabel>SSN: </FormLabel>
                            <Text>{client?.lastFourSSN ? `** ***${client?.lastFourSSN}` : '--'}</Text>
                          </Wrap>
                          {
                            client?.isCompany &&
                            <Wrap>
                              <FormLabel>Business Name: </FormLabel>
                              <Text>{client?.businessName}</Text>
                            </Wrap>
                          }
                        </Grid>
                      ))
                    }
                  </Box>
                }
                <Flex justify="start" w='full' mt={4}>
                  <Button colorScheme="brand" variant="outline" minW="120px" onClick={() => history.goBack()}>
                    Back
                  </Button>
                </Flex>
              </GridItem>
            </Grid>
          </Box>
      }
      {
        user && <UpdateClientModal isOpen={isOpen} onClose={onClose} onSave={updateClientInfo} user={user} />
      }
      {
        user &&
        <TransferOwnerShipModal isOpen={isTransferOpen} onClose={onTransferClose} onSave={transfer} client={user} />
      }
    </Box>
  );
};

export default ClientDetail;
