import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
// Chakra imports
import {
	Box,
	Button, Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement, Link,
	Text,
	useColorModeValue,
	VStack,
} from '@chakra-ui/react';
// Custom components
import DefaultAuth from "../../../layouts/auth/Default";
// Assets
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from "../../../auth-context/auth.context";
import { useImmer } from "use-immer";
import { AccountType } from "../../../config/constant";
import { getUserInfo } from "../../../api/user-service";

const ResetPassword: React.FC = () => {
	const [password, setPassword] = useImmer({
		current: "",
		new: "",
		confirm: "",
	});
	const [showPassword, setShowPassword] = useImmer({
		current: false,
		new: false,
		confirm: false,
	});

	const [error, setError] = useState<string>("");
	const [isSigning, setIsSigning] = useState<boolean>(false);
	const [isTermsAgreed, setIsTermsAgreed] = useState(false);

	const [isLowerCaseValid, setIsLowerCaseValid] = useState(false);
	const [isUpperCaseValid, setIsUpperCaseValid] = useState(false);
	const [isNumberValid, setIsNumberValid] = useState(false);
	const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);
	const [isMinLengthValid, setIsMinLengthValid] = useState(false);
	const [isNoLeadingTrailingSpaceValid, setIsNoLeadingTrailingSpaceValid] = useState(false);
	const [isPasswordMatched, setIsPasswordMatched] = useState(false);

	useEffect(() => {
		setIsLowerCaseValid(/[a-z]/g.test(password.new));
		setIsUpperCaseValid(/[A-Z]/g.test(password.new));
		setIsNumberValid(/[0-9]/g.test(password.new));
		setIsSpecialCharValid(/[!@#$%^&*()\-_.+=[\]{}|\\:;,.<>?/]/g.test(password.new));
		setIsMinLengthValid(password.new.length >= 8);
		setIsNoLeadingTrailingSpaceValid(password.new.trim() === password.new);
		setIsPasswordMatched(password.new === password.confirm);
	}, [password]);

	const history = useHistory();
	const { user, setUser } = useAuth();

	// Chakra color mode
	const textColor = useColorModeValue("navy.700", "white");
	const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
	const textColorSecondary = "gray.400";
	const brandStars = useColorModeValue("brand.500", "brand.400");

	const handleResetPassword = async (event: { preventDefault: () => void; }) => {
		if (event) {
			event.preventDefault();
		}

		if (password.new !== password.confirm) {
			setError("Please confirm password again");
			return;
		}

		if (!isTermsAgreed) {
			return setError('Please agree to the Terms of Service and Privacy Policy before proceeding.');
		}

		setIsSigning(true);
		try {
			console.log("user", user);

			const currentUser = await Auth.completeNewPassword(user, password.new);
			console.log("currentUser", currentUser);

			const signedUser = await Auth.signIn(currentUser.username, password.new);
			console.log("signedUser", signedUser);

			setUser(signedUser);
			if (signedUser.challengeName === "MFA_SETUP") {
				history.push("/auth/mfa/setup");
			} else if (signedUser.challengeName === "SMS_MFA" || signedUser.challengeName === "SOFTWARE_TOKEN_MFA") {
				history.push("/auth/signin");
			} else {
				const { attributes } = await Auth.currentAuthenticatedUser();
				const {user: userInfo, url} = await getUserInfo();

				if (url) {
					window.location.href = url;
				}

				setUser({
					sub: attributes['sub'],
					...userInfo,
				});

				if (attributes['custom:accountType'] === AccountType.ADVISOR) {
					history.push("/admin/clients");
				} else {
					history.push("/admin/profile");
				}
			}
		} catch (error: any) {
			console.log(error);
			const { message, code } = error;
			if (
				code === "NotAuthorizedException" ||
				code === "UserNotFoundException" ||
				code === "PasswordResetRequiredException" ||
				code === "UserNotConfirmedException" ||
				code === "TooManyRequestsException"
			) {
				setError(message);
			} else {
				setError("Login Error");
			}
		}
		setIsSigning(false);
	};

	return (
		<DefaultAuth illustrationBackground={require("../../../assets/img/auth/auth.png")}
								 image={require("../../../assets/img/auth/auth.png")}>
			<Flex
				maxW={{ base: "100%", md: "max-content" }}
				w="100%"
				mx={{ base: "auto", lg: "0px" }}
				me="auto"
				h="100%"
				alignItems="start"
				justifyContent="center"
				mb={{ base: "30px", md: "60px" }}
				px={{ base: "25px", md: "0px" }}
				flexDirection="column"
			>
				<VStack w="100%">
					<Heading color={textColor} fontSize="36px" mb="10px">
						Reset Password
					</Heading>
				</VStack>
				<Flex
					zIndex="2"
					direction="column"
					w={{ base: "100%", md: "420px" }}
					maxW="100%"
					background="transparent"
					borderRadius="15px"
					mx={{ base: "auto", lg: "unset" }}
					me="auto"
					mb={{ base: "20px", md: "auto" }}
				>
					<Flex
						zIndex="2"
						direction="column"
						w={{ base: "100%", md: "420px" }}
						maxW="100%"
						background="transparent"
						borderRadius="15px"
						mx={{ base: "auto", lg: "unset" }}
						me="auto"
						mb={{ base: "20px", md: "auto" }}
					>
						<h4
							style={{
								fontSize: ".9em",
								color: "red",
								textAlign: "center",
								fontWeight: 400,
								transition: ".2s all",
							}}
						>
							{error}
						</h4>

						<form onSubmit={handleResetPassword}>
							<FormControl>
								<FormLabel
									display="flex"
									ms="4px"
									fontSize="sm"
									fontWeight="500"
									color={textColor}
									mb="8px"
								>
									Current password<Text color={brandStars}>*</Text>
								</FormLabel>
								<InputGroup size="md">
									<Input
										isRequired={true}
										fontSize="sm"
										placeholder="Min. 8 characters"
										mb="12px"
										size="lg"
										defaultValue={password.current}
										type={showPassword.current ? "text" : "password"}
										variant="auth"
										onChange={(event) => {
											setPassword((draft) => {
												draft.current = event.target.value;
											});
											setError("");
										}}
									/>
									<InputRightElement
										display="flex"
										alignItems="center"
										mt="4px"
									>
										<Icon
											color={textColorSecondary}
											_hover={{ cursor: "pointer" }}
											as={
												showPassword.current
													? RiEyeCloseLine
													: MdOutlineRemoveRedEye
											}
											onClick={() =>
												setShowPassword((draft) => {
													draft.current = !draft.current;
												})
											}
										/>
									</InputRightElement>
								</InputGroup>

								<FormLabel
									ms="4px"
									fontSize="sm"
									fontWeight="500"
									color={textColor}
									display="flex"
								>
									New Password<Text color={brandStars}>*</Text>
								</FormLabel>
								<InputGroup size="md">
									<Input
										isRequired={true}
										fontSize="sm"
										placeholder="Min. 8 characters"
										mb="12px"
										size="lg"
										defaultValue={password.new}
										type={showPassword.new ? "text" : "password"}
										variant="auth"
										onChange={(event) => {
											setPassword((draft) => {
												draft.new = event.target.value;
											});
											setError("");
										}}
									/>
									<InputRightElement
										display="flex"
										alignItems="center"
										mt="4px"
									>
										<Icon
											color={textColorSecondary}
											_hover={{ cursor: "pointer" }}
											as={
												showPassword.new
													? RiEyeCloseLine
													: MdOutlineRemoveRedEye
											}
											onClick={() =>
												setShowPassword((draft) => {
													draft.new = !draft.new;
												})
											}
										/>
									</InputRightElement>
								</InputGroup>

								<FormLabel
									ms="4px"
									fontSize="sm"
									fontWeight="500"
									color={textColor}
									display="flex"
								>
									Confirm Password<Text color={brandStars}>*</Text>
								</FormLabel>
								<InputGroup size="md">
									<Input
										isRequired={true}
										fontSize="sm"
										placeholder="Min. 8 characters"
										mb="12px"
										size="lg"
										defaultValue={password.confirm}
										type={showPassword.confirm ? "text" : "password"}
										variant="auth"
										onChange={(event) => {
											setPassword((draft) => {
												draft.confirm = event.target.value;
											});
											setError("");
										}}
									/>
									<InputRightElement
										display="flex"
										alignItems="center"
										mt="4px"
									>
										<Icon
											color={textColorSecondary}
											_hover={{ cursor: "pointer" }}
											as={
												showPassword.confirm
													? RiEyeCloseLine
													: MdOutlineRemoveRedEye
											}
											onClick={() =>
												setShowPassword((draft) => {
													draft.confirm = !draft.confirm;
												})
											}
										/>
									</InputRightElement>
								</InputGroup>

								<Box mb={2}>
									<Text color={isLowerCaseValid ? 'green.500' : 'red.500'}>
										<Box as="span">{isLowerCaseValid ? '✓' : '✖'}</Box> Password must contain a lower case letter
									</Text>

									<Text color={isUpperCaseValid ? 'green.500' : 'red.500'}>
										<Box as="span">{isUpperCaseValid ? '✓' : '✖'}</Box> Password must contain an upper case letter
									</Text>

									<Text color={isNumberValid ? 'green.500' : 'red.500'}>
										<Box as="span">{isNumberValid ? '✓' : '✖'}</Box> Password must contain a number
									</Text>

									<Text color={isSpecialCharValid ? 'green.500' : 'red.500'}>
										<Box as="span">{isSpecialCharValid ? '✓' : '✖'}</Box> Password must contain a special character or a space
									</Text>

									<Text color={isMinLengthValid ? 'green.500' : 'red.500'}>
										<Box as="span">{isMinLengthValid ? '✓' : '✖'}</Box> Password must contain at least 8 characters
									</Text>

									<Text color={isNoLeadingTrailingSpaceValid ? 'green.500' : 'red.500'}>
										<Box as="span">{isNoLeadingTrailingSpaceValid ? '✓' : '✖'}</Box> Password must not contain a leading or trailing space
									</Text>

									{
										password.new && <Text color={isPasswordMatched ? 'green.500' : 'red.500'}>
											<Box as="span">{isPasswordMatched ? '✓' : '✖'}</Box> Password and Confirm Password should match
										</Text>
									}
								</Box>

								<Flex mb="4px">
									<Checkbox checked={isTermsAgreed} onChange={() => setIsTermsAgreed(prev => !prev)} me="16px" colorScheme="brandScheme"/>
									<Text
										color={textColor}
										fontSize="md"
										textAlign="start">
										By creating an account, you agree to our
										<Link
											mx="3px"
											color={brandStars}
											href="/terms-of-service"
											target="_blank">
											Terms of Service
										</Link> and
										<Link
											mx="3px"
											color={brandStars}
											href="/auth/privacy-policy"
											target="_blank">
											Privacy Policy
										</Link>.
									</Text>
								</Flex>

								<Button
									disabled={!(isLowerCaseValid && isUpperCaseValid && isNumberValid && isSpecialCharValid && isMinLengthValid && isNoLeadingTrailingSpaceValid && isPasswordMatched)}
									fontSize="sm"
									variant="brand"
									fontWeight="500"
									w="100%"
									h="50"
									mb="12px"
									colorScheme="brandScheme"
									isLoading={isSigning}
									type="submit"
								>
									Reset Password
								</Button>
								<Flex justifyContent={"center"}>
									<Text color={textColorDetails} fontWeight="400" fontSize="14px" mr="10px">Go back to</Text>
									<Text color={brandStars} fontWeight="400" fontSize="14px" cursor={"pointer"}><NavLink
										to="/auth/login">Login</NavLink></Text>
								</Flex>
							</FormControl>
						</form>
					</Flex>
				</Flex>
			</Flex>
		</DefaultAuth>
	);
}

export default ResetPassword;
