import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid, GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text, Textarea,
  useBreakpointValue
} from '@chakra-ui/react';
import { isValidEmail, isValidUSPhone } from "../../../../libraries/utils";
import { useAuth } from "../../../../auth-context/auth.context";

interface UserAddModalProps {
  isLoading: boolean,
  isOpen: boolean,
  onClose: () => void,
  onSave: (e: {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber?: string,
    note: string,
  }) => Promise<void>
}

const AddAdvisorModal: React.FC<UserAddModalProps> = ({ isLoading, isOpen, onClose, onSave }) => {
  const { user } = useAuth();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [note, setNote] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const save = async () => {
    let message = null;

    if (!firstName) {
      message = 'First name is required.';
    } else if (!lastName) {
      message = 'Last name is required.';
    } else if (!email) {
      message = 'Email is required.';
    } else if (!isValidEmail(email)) {
      message = 'Invalid email address.';
    } else if (phoneNumber && !isValidUSPhone(phoneNumber)) {
      message = 'Please input valid phone number.';
    }

    if (message) {
      setErrorMessage(message);
    } else {
      const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
      await onSave({
        email,
        firstName,
        lastName,
        ...(phoneNumber ? {
          phoneNumber: `+1${phoneNumberDigits}`
        } : {}),
        note,
      });
      handleClose();
    }
  }

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let onlyNums = value.replace(/\D/g, '');
    let formattedNumber;
    if (onlyNums.length <= 3) {
      formattedNumber = onlyNums;
    } else if (onlyNums.length <= 6) {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    } else {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    }

    setPhoneNumber(formattedNumber);
    setErrorMessage(null);
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setErrorMessage(null);
  }

  const handleClose = () => {
    onClose();
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setErrorMessage(null);
  }

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Advisor to {user?.fullName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid
            templateColumns={useBreakpointValue({ base: '1fr', md: 'repeat(2, 1fr)' })}
            gap={useBreakpointValue({ base: 4, md: 2 })}
          >
            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input placeholder='John' value={firstName}
                     onChange={(e) => {
                       setFirstName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input placeholder='Doe' value={lastName}
                     onChange={(e) => {
                       setLastName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input placeholder='you@example.com' type="email" colorScheme="brandScheme" value={email}
                     onChange={(e) => handleEmailChange(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number (optional)</FormLabel>
              <Input colorScheme="brandScheme" value={phoneNumber}
                     type="tel"
                     placeholder="(201)-555-0123"
                     onChange={(e) => handlePhoneNumberChange(e)} />
            </FormControl>
            <GridItem colSpan={2}>
              <FormControl>
                <FormLabel>Note</FormLabel>
                <Textarea placeholder='Internal note about advisor' value={note}
                          rows={3}
                          onChange={(e) => {
                            setNote(e.target.value);
                          }} />
              </FormControl>
            </GridItem>
          </Grid>
          <FormControl>
            {errorMessage ? (
              <FormHelperText color='red'>
                {errorMessage}
              </FormHelperText>
            ) : null}
          </FormControl>
          <Text fontSize="md" mt="8px" color='blue.500'>Your invitation will expire in 90 days.</Text>
        </ModalBody>
        <ModalFooter>
          <Button isDisabled={isLoading} isLoading={isLoading} colorScheme="brandScheme" mr={3}
                  onClick={() => save()}>Save</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddAdvisorModal;
