import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useColorModeValue
} from '@chakra-ui/react';
import Select from "react-select";
import { selectCustomStyles } from "../../../../theme/styles";
import { getSmartFillForms } from "../../../../api/smart-fill-service";
import { getAllClients } from "../../../../api/user-service";
import { Form } from "../../../../types/smart-fill/Form";

interface FiduciaryStressTestModalProps {
  isCreating: boolean;
  isOpen: boolean,
  onClose: () => void,
  onCreate: (formId: number, clientId: number) => Promise<void>,
}

const CreatePdfFormModal: React.FC<FiduciaryStressTestModalProps> = ({
                                                                       isCreating,
                                                                       onCreate,
                                                                       isOpen,
                                                                       onClose,
                                                                     }) => {
  const textColor = useColorModeValue('navy.700', 'white');
  const {colorMode} = useColorMode();
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [selectedForm, setSelectedForm] = useState<any>(undefined);
  const [forms, setForms] = useState([]);
  const [isClientLoading, setIsClientLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>(undefined);
  const [clients, setClients] = useState([]);
  const [errors, setErrors] = useState({
    form: '',
    client: '',
  });


  useEffect(() => {
    fetchForms();
    fetchClients();
  }, []);

  const fetchForms = () => {
    setIsFormLoading(true);
    getSmartFillForms()
      .then(({forms}) => {
        setForms(forms.map((form: Form) => ({
          label: form.formName,
          value: form.formId,
          ...form
        })));
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsFormLoading(false))
  }

  const fetchClients = () => {
    setIsClientLoading(true);
    getAllClients()
      .then(({clients}) => {
        setClients(clients.map((client: any) => ({
          label: `${ client.firstName } ${ client.lastName } (${ client.fullName })`,
          value: client.userId,
          ...client
        })));
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsClientLoading(false))
  }

  const handleCreate = async () => {
    if (!selectedForm) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        form: 'Form template is required!',
      }));
      return;
    }
    if (!selectedClient) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        client: 'Client is required!',
      }));
      return;
    }

    await onCreate(selectedForm.formId, selectedClient.userId);
    setSelectedForm(undefined);
    setSelectedClient(undefined);
  }

  const handleClose = () => {
    setSelectedForm(undefined);
    setSelectedClient(undefined);
    onClose();
  }
  const textColorSecondary = 'gray.500';

  return (
    <Modal isOpen={ isOpen } onClose={ () => handleClose() } isCentered>
      <ModalOverlay/>
      <ModalContent
        maxW="xl"
      >
        <ModalHeader>
          <HStack>
            <Text>Create Document</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Divider/>
          <Text mt='8px' color={ textColorSecondary } fontSize="md">We are making PDF filling automated as much as
            possible.
            Please select form template and your client.</Text>
          <FormControl isRequired mt='12px' isInvalid={ !!errors.form }>
            <FormLabel>Form</FormLabel>
            <Select
              styles={ selectCustomStyles(colorMode) }
              isLoading={ isFormLoading }
              value={ selectedForm }
              placeholder="Select a form"
              onChange={ (form) => {
                setSelectedForm(form);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  form: '',
                }));
              } }
              options={ forms }
            />
            { errors.form && <FormErrorMessage>{ errors.form }</FormErrorMessage> }
            {
              selectedForm && (
                <Box mt='8px'>
                  <HStack>
                    <Text
                      color={ textColor }
                      fontSize='sm'
                      fontWeight='500'
                    >
                      Name:
                    </Text>

                    <Text
                      color={ textColor }
                      fontSize='sm'
                    >
                      { selectedForm.formName }
                    </Text>
                  </HStack>
                  <HStack>
                    <Text
                      color={ textColor }
                      fontSize='sm'
                      fontWeight='500'
                    >
                      Description:
                    </Text>

                    <Text
                      color={ textColor }
                      fontSize='sm'
                    >
                      { selectedForm.formDescription }
                    </Text>
                  </HStack>
                </Box>
              )
            }
          </FormControl>
          <FormControl isRequired mt='12px' isInvalid={ !!errors.client }>
            <FormLabel>Client</FormLabel>
            <Select
              styles={ selectCustomStyles(colorMode) }
              isLoading={ isClientLoading }
              value={ selectedClient }
              placeholder="Select a client"
              onChange={ (client) => {
                setSelectedClient(client);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  client: '',
                }));
              } }
              options={ clients }
            />
            { errors.client && <FormErrorMessage>{ errors.client }</FormErrorMessage> }
            {
              selectedClient && (
                <Box mt='8px'>
                  <HStack>
                    <Text
                      color={ textColor }
                      fontSize='sm'
                      fontWeight='500'
                    >
                      Name:
                    </Text>

                    <Text
                      color={ textColor }
                      fontSize='sm'
                    >
                      { `${ selectedClient.firstName } ${ selectedClient?.middleName || '' } ${ selectedClient.lastName }` }
                    </Text>
                  </HStack>
                  <HStack>
                    <Text
                      color={ textColor }
                      fontSize='sm'
                      fontWeight='500'
                    >
                      Company:
                    </Text>

                    <Text
                      color={ textColor }
                      fontSize='sm'
                    >
                      { selectedClient.fullName }
                    </Text>
                  </HStack>
                  <HStack>
                    <Text
                      color={ textColor }
                      fontSize='sm'
                      fontWeight='500'
                    >
                      Email:
                    </Text>

                    <Text
                      color={ textColor }
                      fontSize='sm'
                    >
                      { selectedClient.email }
                    </Text>
                  </HStack>
                  <HStack>
                    <Text
                      color={ textColor }
                      fontWeight='500'
                      fontSize='sm'
                    >
                      Phone:
                    </Text>

                    <Text
                      color={ textColor }
                      fontSize='sm'
                    >
                      { selectedClient.phoneNumber }
                    </Text>
                  </HStack>
                </Box>
              )
            }
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brandScheme"
                  onClick={ () => handleCreate() }>{ isCreating ? 'Creating...' : 'Create' }</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={ () => handleClose() }>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreatePdfFormModal;
