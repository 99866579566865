import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Code,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useClipboard,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';
import { ArrowBackIcon, CopyIcon } from "@chakra-ui/icons";

interface SnippetModalProps {
  link: string,
  firmName: string,
  isOpen: boolean,
  onClose: () => void,
}

const SnippetModal: React.FC<SnippetModalProps> = ({ isOpen, onClose, link, firmName }) => {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const toast = useToast();
  const [snippet, setSnippet] = useState('');

  const { onCopy } = useClipboard(snippet);
  const [currentStep, setCurrentStep] = useState(0);
  const [selected, setSelected] = useState<'widget' | 'link' | null>(null);

  useEffect(() => {
    if (selected === 'widget') {
      setSnippet(`<iframe src="${link}" style="width: 100%; height: 100%;" frameBorder="0"></iframe>`);
    } else if (selected === 'link') {
      setSnippet(`<a href="${link}" target="_blank">${firmName} Connect</a>`)
    }
  }, [selected]);

  // Function to go to the next step
  const nextStep = () => {
    setCurrentStep((prev) => (prev < 2 ? prev + 1 : prev));
  };

  // Function to go to the previous step
  const prevStep = () => {
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleCopyClick = () => {
    onCopy();
    toast({
      title: 'Code is copied to clipboard.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {
      setCurrentStep(0);
      onClose()
    }} isCentered size='4xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            currentStep === 0 ? 'How would you like to integrate with your website?' : 'Integrate into your website'
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Divider mb='32px' />
          {
            currentStep === 0 ? <Grid templateColumns='repeat(2, 1fr)' gap={8} justifyItems='center'>
              <GridItem
                colSpan={{ base: 2, md: 1 }}
                color={selected === 'widget' ? textColor : textColorSecondary}
                maxW='280px'
                p='20px'
                borderWidth={1}
                borderColor={selected === 'widget' ? textColor : textColorSecondary}
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                onClick={() => setSelected('widget')}
                cursor='pointer'
              >
                <Image boxSize='240px' objectFit='cover' src='/smartlead-import-widget-snippet.svg'
                       alt='smartlead-import-widget-snippet' />
                <Text fontSize='xl' textAlign='center' fontWeight='500'>Import Widget</Text>
                <Text fontSize='sm' textAlign='center' fontWeight='500'>Embed your full smartlead Connect page on your
                  website.</Text>
              </GridItem>
              <GridItem
                colSpan={{ base: 2, md: 1 }}
                color={selected === 'link' ? textColor : textColorSecondary}
                maxW='280px'
                p='20px'
                borderWidth={1}
                borderColor={selected === 'link' ? textColor : textColorSecondary}
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                onClick={() => setSelected('link')}
                cursor='pointer'
              >
                <Image boxSize='240px' objectFit='cover' src='/smartlead-import-link-snippet.svg'
                       alt='smartlead-import-widget-snippet' />
                <Text fontSize='xl' textAlign='center' fontWeight='500'>Import Link</Text>
                <Text fontSize='sm' textAlign='center' fontWeight='500'>Embed as a link on your website.</Text>
              </GridItem>
            </Grid> : <Box>
              <Text color={textColorSecondary} mb={4}>Paste this snippet into your website</Text>
              <Code children={snippet} />
            </Box>
          }
          <Divider mt='32px' />
        </ModalBody>
        <ModalFooter>
          <Flex direction='row' justify='space-between' w='full'>
            <Button
              variant={currentStep === 0 ? 'outline' : 'solid'}
              colorScheme='brand'
              leftIcon={currentStep === 1 ? <ArrowBackIcon /> : <></>}
              onClick={() => { currentStep === 0 ? onClose() : prevStep() }}>
              {currentStep === 0 ? 'Cancel' : 'Back'}
            </Button>
            <HStack>
              {
                currentStep === 1 &&
                <Button variant='outline' colorScheme='brand' mr={3} onClick={() => {
                  setCurrentStep(0);
                  onClose();
                }}>Done</Button>
              }
              <Button
                variant='solid'
                colorScheme='brand'
                leftIcon={currentStep === 1 ? <CopyIcon /> : <></>}
                isDisabled={!selected}
                onClick={() => { currentStep === 0 ? nextStep() : handleCopyClick() }}>
                {currentStep === 0 ? 'Next' : 'Copy Code'}
              </Button>
            </HStack>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SnippetModal;
