import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, } from '@chakra-ui/react';
import React from 'react';
import { numberFormat } from "../../../../../libraries/utils";

const TABLE_COLUMNS = [
  {
    Header: 'COVERAGE',
    accessor: 'friendlyName',
  },
  {
    Header: 'PER PERSON LIMIT',
    accessor: 'perPersonLimitCents',
  },
  {
    Header: 'PER INCIDENT LIMIT',
    accessor: 'perIncidentLimitCents',
  },
  {
    Header: 'DEDUCTIBLE',
    accessor: 'deductibleCents',
  },
  {
    Header: 'PREMIUM',
    accessor: 'premiumCents',
  },
  {
    Header: 'DECLINED',
    accessor: 'isDeclined',
  },
];

export default function Coverage(props) {
  const {
    coverages
  } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <Box>
      <Table variant="simple" color="gray.500">
        <Thead>
          <Tr>
            { TABLE_COLUMNS.map((column, index) => (
              <Th pe="10px" key={ index } borderColor={ borderColor }>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={ {
                    sm: '10px',
                    lg: '12px',
                  } }
                  color="gray.400"
                >
                  { column.Header }
                </Flex>
              </Th>
            )) }
          </Tr>
        </Thead>
        <Tbody>
          {
            coverages && coverages.map((coverage, index) => {
              return (
                <Tr key={ index }
                    color={ textColor }
                    maxH='300px'
                    fontSize="sm">
                  <Td py='4px'>
                    { coverage?.friendlyName }
                  </Td>
                  <Td py='4px'>
                    ${ numberFormat(coverage?.perPersonLimitCents / 100 || 0) }
                  </Td>
                  <Td py='4px'>
                    ${ numberFormat(coverage?.perIncidentLimitCents / 100 || 0) }
                  </Td>
                  <Td py='4px'>
                    ${ numberFormat(coverage?.deductibleCents / 100 || 0) }
                  </Td>
                  <Td py='4px'>
                    ${ numberFormat(coverage?.premiumCents / 100 || 0) }
                  </Td>
                  <Td py='4px'>
                    { `${ coverage?.isDeclined }` === 'true' ? 'YES' : '' }
                  </Td>
                </Tr>
              )
            })
          }
          { (!coverages || coverages.length === 0) && (
            <Tr>
              <Td colSpan={ 8 } p={ 2 }>
                <Text textAlign="center">There is no coverage!</Text>
              </Td>
            </Tr>
          ) }
        </Tbody>
      </Table>
    </Box>
  );
}
