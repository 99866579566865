import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { AuthProvider } from "./auth-context/auth.context";
import { ProtectedRoute } from "./layouts/protected.route";
import "./aws-cognito-config";
import LandingPage from "./layouts/home";
import PrivacyPolicy from "./views/auth/terms-and-privacy/privacy-policy";
import TermsOfService from "./views/auth/terms-and-privacy/terms-of-service";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

ReactDOM.render(
  <ChakraProvider theme={theme} cssVarsRoot='#app'>
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <React.StrictMode>
          <BrowserRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <ProtectedRoute path={`/admin`} component={AdminLayout} />
              <Route path={`/privacy-policy`} component={PrivacyPolicy} />
              <Route path={`/terms-of-service`} component={TermsOfService} />
              <Route path={`/`} component={LandingPage} />
              {/*<Redirect from="/" to="/auth/login" />*/}
            </Switch>
          </BrowserRouter>
        </React.StrictMode>
      </Elements>
    </AuthProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
