import apiRequest from './api';
import { BASE_API_URL } from "../config/constant";

export const retrieveTaxOrders = (limit: number = 20, offset: number = 0, userId: string = '') =>
  apiRequest('get', `${BASE_API_URL}/smarttax/orders`, {
    queryStringParameters: {
      limit,
      offset,
      userId,
    },
  }).then((res) => res.data);

export const summaryReport = (clientId: number, orderId: number) =>
  apiRequest('get', `${BASE_API_URL}/smarttax/summary/orders/${orderId}`, {
    queryStringParameters: {
      clientId,
    },
  }).then((res) => res.data);

export const setSameDayOrder = (taxClientId: number, isSameDay: boolean) => apiRequest('post', `${BASE_API_URL}/smarttax/clients/${taxClientId}`, {
  body: {
    isSameDay
  }
}).then((res) => res.data);

export const downloadTranscript = (clientId: number, url: string) =>
  apiRequest('post', `${BASE_API_URL}/smarttax/transcript/download`, {
    body: {
      clientId,
      url,
    },
  }).then((res) => res.data);