// Chakra imports
import {
  Box,
  Button, Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Skeleton,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
// Assets
// Custom components
import Card from 'components/card/Card';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../auth-context/auth.context';
import { getPossibleSecurityNames } from '../../../../api/fasttrack-service';
import AddSecurityModal from '../../modals/components/AddSecurityModal';
import { MdContentCopy } from 'react-icons/md';
import copy from 'copy-to-clipboard';
import Select from 'react-select';

const sortByOptions = [
  {
    value: 'recent',
    label: 'MOST RECENT'
  },
  {
    value: 'oldest',
    label: 'OLDEST'
  },
  {
    value: 'az',
    label: 'A to Z'
  },
  {
    value: 'za',
    label: 'Z to A'
  }
]

const sortColumnOptions = [
  {
    value: 'description',
    label: 'DESCRIPTION'
  },
  {
    value: 'memo',
    label: 'MEMO'
  }
]

export default function PossibleSecurities(props) {
  const { onSecuritySave, isSecurityAdding } = props;
  const { selectedClient } = useAuth();
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const bgButton = useColorModeValue('secondaryGray.900', 'white');
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [securityNames, setSecurityNames] = useState([]);
  const textColor = useColorModeValue('navy.700', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filter, setFilter] = useState({
    SHOW_30_DAYS: true,
    COLUMN: {
      value: 'description',
      label: 'DESCRIPTION',
    },
    SORT_BY: {
      value: 'recent',
      label: 'MOST RECENT'
    },
  });

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    })
  }

  useEffect(() => {
    fetchSecurityNames(filter);
  }, [filter]);

  const fetchSecurityNames = (filter) => {
    setIsLoading(true);
    getPossibleSecurityNames(selectedClient?.userId, filter)
      .then(({ securityNames }) => {
        setSecurityNames(securityNames);
      })
      .catch(err => console.error(err.message))
      .finally(() => setIsLoading(false))
  }

  return (
    <Card mb={ { base: '0px', '2xl': '20px' } }>
      <Flex
        mb="8px"
        direction={ { base: 'column', md: 'row' } }
        align={ { md: 'center' } }
        justify="space-between"
        gap="4px"
      >
        <Text fontSize="xl" fontWeight="700" color={ textColorPrimary }>Securities
          for { selectedClient?.fullName }</Text>
        <Button
          variant="brand"
          fontWeight="500"
          disabled={ isLoading }
          onClick={ onOpen }
        >
          Add to Holdings
        </Button>
      </Flex>
      <Text color={ textColorSecondary } fontSize="md">
        Here are possible securities for your client. Please confirm and add to
        Portfolio Holdings to get expense ratio.
      </Text>
      <Flex justify="flex-end" align='center' direction={ { base: 'column', md: 'row' } } mt="4px" mb="12px" me="4px"
            gap={ { base: '8px', md: '20px' } }>
        <FormControl>
          <Flex align='center'>
            <Checkbox
              defaultChecked={filter.SHOW_30_DAYS}
              value={filter.SHOW_30_DAYS}
              onChange={() => handleFilterChange('SHOW_30_DAYS', !filter.SHOW_30_DAYS)}
              colorScheme='brandScheme'
              me='10px'
            />
            <Text color={ textColor } fontSize='md'>
              Show last 30 days
            </Text>
          </Flex>
        </FormControl>
        <FormControl>
          <FormLabel>COLUMN BY</FormLabel>
          <Select
            value={ filter.COLUMN }
            onChange={ (column) => {
              handleFilterChange('COLUMN', column)
            } }
            options={ sortColumnOptions }
          />
        </FormControl>
        <FormControl>
          <FormLabel>SORT BY</FormLabel>
          <Select
            value={ filter.SORT_BY }
            defaultValue="date"
            onChange={ (column) => {
              handleFilterChange('SORT_BY', column)
            } }
            options={ sortByOptions }
          />
        </FormControl>
      </Flex>
      <Box
        maxH="600px"
        overflowY="scroll"
      >
        {
          (isLoading) ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
            <Skeleton key={ index } mb="12px" h="20px"/>
          )) : securityNames.map((name, index) => (
            <Flex
              align="center"
              key={ index }>
              <Text
                color={ textColor }
                fontSize="sm"
                py="4px"
                fontWeight="600">
                { name }
              </Text>
              <Tooltip label={ name } hasArrow placement="top">
                <Button
                  ms="auto"
                  align="center"
                  justifyContent="center"
                  bg="transparent"
                  _hover="transparent"
                  _focus="transparent"
                  _active="transparent"
                  w="24px"
                  h="24px"
                  lineHeight="100%"
                  onClick={ () => {
                    copy(name);
                    toast({
                      title: 'Copied',
                      description: `${ name } is copied to the clipboard.`,
                      status: 'info',
                      duration: 3000,
                      isClosable: true,
                    });
                  } }
                >
                  <Icon as={ MdContentCopy } color={ bgButton } w="20px" h="20px"/>
                </Button>
              </Tooltip>
            </Flex>
          ))
        }
      </Box>
      <AddSecurityModal isOpen={ isOpen } onClose={ onClose } onSave={ onSecuritySave }
                        isSecurityAdding={ isSecurityAdding }/>
    </Card>
  );
}
