import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from "../../../auth-context/auth.context";

function MFASetup() {
  const [mfaMethod, setMfaMethod] = useState("TOTP");
  const [secretKey, setSecretKey] = useState("");
  const [mfaCode1, setMFACode1] = useState("");
  const [mfaCode2, setMFACode2] = useState("");

  const [error, setError] = useState(undefined);
  const [isSigning, setIsSigning] = useState(false);
  const history = useHistory();
  const {user, setUser} = useAuth();

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const handleConfirmTOTP = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSigning(true);
    try {
      console.log("user", user, mfaCode1);
      const verify = await Auth.verifyTotpToken(user, mfaCode1);
      console.log(verify);
      const totp = await Auth.setPreferredMFA(user, "TOTP");
      console.log("totp", totp, mfaCode2);
      const confirm = await Auth.confirmSignIn(
        user,
        mfaCode2,
        "SOFTWARE_TOKEN_MFA"
      );
      console.log("confirm", confirm);
    } catch (error) {
      console.log(error);
      const {message, code} = error;
      if (code === "EnableSoftwareTokenMFAException") {
        setError(message);
      } else {
        setError("Login Error");
      }
    }
    setIsSigning(false);
  };

  const handleConfirmSMS = async (event) => {
    if (event) {
      event.preventDefault();
    }

    Auth.setPreferredMFA(user, "SMS");
  };

  const generateQRCode = () => {
    const username = user.username;
    Auth.setupTOTP(user).then((code) => {
      console.log('setupTOTP/code', code);
      setSecretKey(code);
      const generatedQrCode =
        "otpauth://totp/AWSCognito:" +
        username +
        "?secret=" +
        code +
        "&issuer=Atreides";
    });
  };

  useEffect(() => {
    generateQRCode();
  }, []);

  const qrValue =
    "otpauth://totp/AWSCognito:" + user?.username ??
    "" + "?secret=" + secretKey + "&issuer=" + "Synkbooks";

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{base: "100%", md: "max-content"}}
        w="100%"
        mx={{base: "auto", lg: "0px"}}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{base: "30px", md: "60px"}}
        px={{base: "25px", md: "0px"}}
        flexDirection="column"
      >
        <VStack w="100%">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Setup MFA
          </Heading>
        </VStack>
        <Flex
          zIndex="2"
          direction="column"
          w={{base: "100%", md: "420px"}}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{base: "auto", lg: "unset"}}
          me="auto"
          mb={{base: "20px", md: "auto"}}
        >
          {!mfaMethod && (
            <VStack mb="20px">
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                colorScheme="brandScheme"
                onClick={() => setMfaMethod("TOTP")}
              >
                Authenticator App (TOTP)
              </Button>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                colorScheme="brandScheme"
                onClick={() => setMfaMethod("SMS")}
              >
                SMS
              </Button>
            </VStack>
          )}

          <Flex
            zIndex="2"
            direction="column"
            w={{base: "100%", md: "420px"}}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{base: "auto", lg: "unset"}}
            me="auto"
            mb={{base: "20px", md: "auto"}}
          >
            <h4
              style={{
                fontSize: ".9em",
                color: "red",
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all",
              }}
            >
              {error}
            </h4>

            {mfaMethod === "TOTP" && (
              <form onSubmit={handleConfirmTOTP}>
                <FormControl>
                  <HStack w="100%" mb="24px">

                    <FormControl>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        MFA Secret Key<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          isRequired={true}
                          fontSize="sm"
                          mb="24px"
                          size="lg"
                          disabled
                          defaultValue={secretKey}
                          type={show ? "text" : "password"}
                          variant="auth"
                        />
                        <InputRightElement
                          display="flex"
                          alignItems="center"
                          mt="4px"
                        >
                          <Icon
                            color={textColorSecondary}
                            _hover={{cursor: "pointer"}}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={handleClick}
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </HStack>

                  <HStack w="100%" mb="24px">
                    <FormControl>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        MFA Code 1<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        fontSize="sm"
                        size="lg"
                        defaultValue={mfaCode1}
                        variant="auth"
                        onChange={(e) => {
                          setMFACode1(e.target.value);
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        display="flex"
                      >
                        MFA Code 2<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        fontSize="sm"
                        size="lg"
                        defaultValue={mfaCode2}
                        variant="auth"
                        onChange={(e) => {
                          setMFACode2(e.target.value);
                        }}
                      />
                    </FormControl>
                  </HStack>

                  <HStack>
                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      mt="24px"
                      mb="24px"
                      colorScheme="brandScheme"
                      onClick={() => {
                        setMfaMethod(null);
                        setError(null);
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      mt="24px"
                      mb="24px"
                      colorScheme="brandScheme"
                      type="submit"
                      isLoading={isSigning}
                    >
                      Confirm
                    </Button>
                  </HStack>
                </FormControl>
              </form>
            )}

            {mfaMethod === "SMS" && (
              <form onSubmit={handleConfirmSMS}>
                <FormControl>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                    justifyContent="center"
                  >
                    Phone Number<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    fontSize="md"
                    size="md"
                    defaultValue={mfaCode1}
                    variant="auth"
                    textAlign="center"
                    onChange={(e) => {
                      setMFACode1(e.target.value);
                    }}
                  />

                  <HStack>
                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      mt="24px"
                      mb="24px"
                      colorScheme="brandScheme"
                      onClick={() => setMfaMethod(null)}
                    >
                      Back
                    </Button>
                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      w="100%"
                      h="50"
                      mt="24px"
                      mb="24px"
                      colorScheme="brandScheme"
                      type="submit"
                      isLoading={isSigning}
                    >
                      Confirm
                    </Button>
                  </HStack>
                </FormControl>
              </form>
            )}
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default MFASetup;
