import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useAuth } from 'auth-context/auth.context';
import { AccountType, COUNT_PER_PAGE } from '../../../config/constant';
import { usePagination, } from '@ajna/pagination';
import { useDebounce } from '../../../libraries/useDebounce';
import DocumentTable, { colorCircle } from "./components/DocumentTable";
import { MdAdd } from "react-icons/md";
import CreatePdfFormModal from "../modals/components/CreatePdfFormModal";
import { createUserForm, getUserForms, updateUserFormStatus } from "../../../api/smart-fill-service";
import IRASchwabApplicationModal from "./components/IRASchwabApplicationModal";
import { validateRole } from "../../../libraries/utils";

export default function SmartFillDashboard() {
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const toast = useToast();
  const { user, selectedClient } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const { isOpen: isPdfFormOpen, onOpen: onPdfFormOpen, onClose: onPdfFormClose } = useDisclosure();
  const [documents, setDocuments] = useState([]);
  const [total, setTotal] = useState(0);
  const { isOpen: isSchwabOpen, onOpen: onSchwabOpen, onClose: onSchwabClose } = useDisclosure();
  const [selectedUserFormId, setSelectedUserFormId] = useState(undefined);
  const [filter, setFilter] = useState({
    DOCUMENT_STATUS: {
      label: "ALL", value: "ALL", Icon: () => colorCircle('brand.500'), color: 'brand'
    },
    SEARCH: ''
  });
  const debouncedFilter = useDebounce(filter, 1000);

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    offset,
    pageSize,
  } = usePagination({
    pagesCount: Math.ceil(total / COUNT_PER_PAGE),
    limits: {
      outer: 2,
      inner: 2
    },
    initialState: {
      currentPage: 1,
      pageSize: COUNT_PER_PAGE,
    },
  });

  useEffect(() => {
    fetchDocuments();
  }, [currentPage, offset, debouncedFilter]);


  const fetchDocuments = () => {
    setIsLoading(true);
    getUserForms(pageSize, offset, filter)
      .then(({ documents, count }) => {
        setDocuments(documents);
        setTotal(count);
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsLoading(false))
  }

  const onCreate = async (formId, clientId) => {
    setIsCreating(true);
    createUserForm(formId, clientId)
      .then(({ userForm }) => {
        toast({
          title: 'Create Document',
          description: 'A new document is successfully created.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchDocuments();
        onPdfFormClose();
        handleSchwabOpen(userForm.userFormId);
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsCreating(false))
  }

  const handleSchwabOpen = (userFormId) => {
    setSelectedUserFormId(userFormId);
    onSchwabOpen();
  }

  const updateStatus = (status, userFormId) => {
    updateUserFormStatus(status, userFormId)
      .then(() => {
        toast({
          title: 'Document Status',
          description: `Document status has been updated to ${ status }`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchDocuments();
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => {
      })
  }

  return (
    <Box pt={ { base: '130px', md: '80px', xl: '80px' } }>
      <Flex
        mt="45px"
        mb="20px"
        justifyContent="space-between"
        direction={ { base: 'column', md: 'row' } }
        align={ { base: 'start', md: 'center' } }>
        <Text color={ textColor } fontSize="2xl" fontWeight="700">
          IRA Documents
        </Text>
        <Button
          colorScheme="brand"
          leftIcon={ <Icon as={ MdAdd } w="20px" h="20px"/> }
          fontWeight="500"
          onClick={ () => {
            if (validateRole(user?.token) === AccountType.CLIENT) {
              toast({
                title: 'Create Document',
                description: 'Please contact your advisor to create IRA document. He/She will provide details for you to make process seamless and faster. No worries, You can edit it anytime after created.',
                status: 'info',
                duration: 9000,
                isClosable: true,
              });
              return;
            }
            onPdfFormOpen();
          } }
        >
          Create Document
        </Button>
      </Flex>
      <SimpleGrid columns={ { base: 1, md: 1, xl: 1 } } gap="20px" mb="20px">
        <DocumentTable
          isLoading={ isLoading }
          documents={ documents }
          setCurrentPage={ setCurrentPage }
          pagesCount={ pagesCount }
          currentPage={ currentPage }
          filter={ filter }
          setFilter={ setFilter }
          onSchwabOpen={ handleSchwabOpen }
          updateStatus={ updateStatus }
          pages={ pages }/>
      </SimpleGrid>
      <CreatePdfFormModal isCreating={ isCreating } isOpen={ isPdfFormOpen } onClose={ onPdfFormClose }
                          onCreate={ onCreate }/>
      <IRASchwabApplicationModal isLoading={ isLoading } isOpen={ isSchwabOpen } onClose={ onSchwabClose }
                                 userFormId={ selectedUserFormId }/>
    </Box>
  );
}
