import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import Select from "react-select";
import { getAllAdvisorsByFirmId } from "../../../../api/user-service";
import { useAuth } from "../../../../auth-context/auth.context";

interface TransferOwnerShipModalProps {
  client: any,
  isOpen: boolean,
  onClose: () => void,
  onSave: (e: { fromUserId: number, toUserId: number, toUserName: string }) => Promise<void>
};

const TransferOwnerShipModal: React.FC<TransferOwnerShipModalProps> = ({ isOpen, onClose, onSave, client }) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [advisorList, setAdvisorList] = useState([]);
  const [toAdvisor, setToAdvisor] = useState<any>(null);

  useEffect(() => {
    if (!user) return;
    setIsLoading(true);
    getAllAdvisorsByFirmId(user.firmId)
      .then(({ advisors }) => {
        const list = advisors.map((item: any) => ({
          value: item.userId,
          label: `${item?.firstName} ${item?.lastName} (${item?.email})`
        }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label));
        setAdvisorList(list);
      })
      .catch((err) => {
        console.error(err.message);
        setAdvisorList([]);
      })
      .finally(() => setIsLoading(false))
  }, [user])

  const validate = async () => {
    setIsLoading(true);
    await onSave({
      fromUserId: user.userId,
      toUserId: toAdvisor.value,
      toUserName: toAdvisor.label,
    });
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{client?.firstName} {client?.lastName} - Transfer Access</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color='gray.500' mb={2}><b>
            {user?.firstName}</b> will lose access to all information
            from <b>{client?.firstName} {client?.lastName}</b> as soon as the access is transferred.
          </Text>
          <FormControl id='fromUser'>
            <FormLabel>From</FormLabel>
            <Input colorScheme="brandScheme" defaultValue={`${user?.firstName} ${user?.lastName} (${user?.email})`}
                   marginBottom='8px' />
          </FormControl>
          <FormControl id='toUser'>
            <FormLabel>To</FormLabel>
            <Select
              isLoading={isLoading}
              value={toAdvisor}
              isClearable
              placeholder="Transfer to..."
              onChange={(value: string) => setToAdvisor(value)}
              isSearchable
              options={advisorList}
            />
          </FormControl>
          <Text></Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="brandScheme" mr={3} onClick={() => validate()} isLoading={isLoading} loadingText='Saving...' isDisabled={!toAdvisor}>Save</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TransferOwnerShipModal;
