import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useToast,
  VStack
} from '@chakra-ui/react';
import { ArrowRightIcon, DownloadIcon } from "@chakra-ui/icons";
import InvestmentAdvisorInformation from "./application/InvestmentAdvisorInformation";
import IRAType from "./application/IRAType";
import AccountHolderInformation from "./application/AccountHolderInformation";
import ConsentEnroll from "./application/ConsentEnroll";
import PaperlessDocumentEnrollment from "./application/PaperlessDocumentEnrollment";
import InstructionIAAuthorization from "./application/InstructionIAAuthorization";
import IssuerCommunication from "./application/IssuerCommunication";
import DesignateBeneficiary from "./application/DesignateBeneficiary";
import OpenAccount from "./application/OpenAccount";
import { getOriginalFormTemplate } from "../../../../api/smart-fill-service";

interface IRASchwabApplicationModalModalProps {
  userFormId: number,
  isLoading: boolean;
  isOpen: boolean,
  onClose: () => void,
}


const steps = [
  {index: 0, name: 'Investment Advisor ("IA") Information (This portion to be completed by IA.)'},
  {index: 1, name: 'Select IRA Type (Select only one type of account.)'},
  {index: 2, name: 'Account Holder Information'},
  {index: 3, name: 'Your Consent to Enroll in Schwab\'s Cash Features Program (Read Only)'},
  {index: 4, name: 'Paperless Document Enrollment'},
  {index: 5, name: 'Instructions About IA Authorizations (Optional—please select all that apply.)'},
  {index: 6, name: 'Issuer Communications and Related Actions'},
  {index: 7, name: 'Designate Your Beneficiary(ies)'},
  {index: 8, name: 'Authorization to Open Account(s) (Please read and sign below.)'},
];

const stepComponents = [
  InvestmentAdvisorInformation,
  IRAType,
  AccountHolderInformation,
  ConsentEnroll,
  PaperlessDocumentEnrollment,
  InstructionIAAuthorization,
  IssuerCommunication,
  DesignateBeneficiary,
  OpenAccount
];

export interface StepComponentProps {
  userFormId: number;
  title: string;
}

const Stepper = ({currentStep}: { currentStep: number }) => {
  return (
    <Flex align="center" width="100%">
      { steps.map((step, index) => (
        <Box
          key={ index }
          flex={ 1 }
          height="5px"
          me='4px'
          borderRadius="8px"
          bg={ currentStep >= index ? 'brand.500' : 'gray.200' }
          transition="background-color 0.3s ease-in-out"
        />
      )) }
    </Flex>
  );
};

const IRASchwabApplicationModal: React.FC<IRASchwabApplicationModalModalProps> = ({
                                                                                    isLoading,
                                                                                    userFormId,
                                                                                    isOpen,
                                                                                    onClose,
                                                                                  }) => {
  const toast = useToast();
  const [currentStep, setCurrentStep] = useState(0);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  // Step 1: Create a ref
  const stepRefs = useRef<any>([]);

  useEffect(() => {
    // Initialize refs array based on the number of steps
    stepRefs.current = stepRefs.current.slice(0, steps.length);
  }, []);

  // Function to go to the next step
  const nextStep = () => {
    const currentRef = stepRefs.current[currentStep];
    if (currentRef && currentRef.handleNext) {
      setIsNextLoading(true);
      currentRef.handleNext().then(() => {
        setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
        setIsNextLoading(false);
      }).catch((error: any) => {
        console.error('Error in handleNext:', error);
        setIsNextLoading(false);
        toast({
          title: 'Form',
          description: error?.message || 'There is a problem. Please fix it.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
    } else if (currentRef && currentRef.handleSave) {
      setIsNextLoading(true);
      currentRef.handleSave().then(() => {
        setIsNextLoading(false);
        handleClose();
      }).catch((error: any) => {
        console.error('Error in handleSave:', error);
        setIsNextLoading(false);
        toast({
          title: 'Form',
          description: error?.message || 'There is a problem. Please fix it.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
    } else {
      // If there is no handleNext, just move to the next step
      setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
    }
  };

  // Function to go to the previous step
  const prevStep = () => {
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleClose = () => {
    setCurrentStep(0);
    onClose();
  }

  const previewOriginalForm = () => {
    setIsPreviewLoading(true);
    getOriginalFormTemplate(userFormId)
      .then(({url}) => {
        window.open(url, '_blank');
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsPreviewLoading(false))
  }

  return (
    <Modal isOpen={ isOpen } onClose={ () => handleClose() } scrollBehavior='inside' size='full'>
      <ModalOverlay/>
      <ModalContent h='full'>
        <ModalHeader>
          <HStack gap={ 2 } align='center'>
            <Text color='brand.500'>IRA Account Application</Text>
            <Tooltip label='Download original IRA application PDF' fontSize='md'>
              {
                isPreviewLoading ? <Text fontSize='sm'>Downloading...</Text> :
                  <DownloadIcon cursor='pointer' onClick={ previewOriginalForm }/>
              }
            </Tooltip>
          </HStack>
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Box h='full'>
            { stepComponents.map((StepComponent: React.FC<any>, index) => (
              <Box key={ index } style={ {display: index === currentStep ? 'block' : 'none'} } h='full'>
                { index === currentStep && (
                  <StepComponent ref={ (el: any) => stepRefs.current[index] = el } userFormId={ userFormId }
                                 title={ `${ currentStep > 0 ? `${ currentStep }. ` : '' } ${ steps.find((step) => step.index === currentStep)?.name }` }/>
                ) }
              </Box>
            )) }
          </Box>
        </ModalBody>
        <ModalFooter>
          <VStack w='100%'>
            <Stepper currentStep={ currentStep }/>
            <Flex direction={ {base: 'column', md: 'row'} } justify='space-between' gap={ 2 } w='100%' px='20px'>
              <Button bg='gray.200' onClick={ prevStep } isDisabled={ currentStep === 0 }>Back</Button>
              <HStack justify='space-between'>
                <Button colorScheme="brandScheme" variant="ghost"
                        isDisabled={ currentStep === steps.length - 1 }
                        onClick={ () => setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev)) }>
                  Skip for now
                  <ArrowRightIcon w='12px' h='12px' ms='4px'/>
                </Button>
                <Button colorScheme="brandScheme" onClick={ nextStep }
                        isDisabled={ isNextLoading }
                        isLoading={ isNextLoading }>
                  { currentStep === steps.length - 1 ? 'Save & Close' : 'Save & Next' }
                </Button>
              </HStack>
            </Flex>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default IRASchwabApplicationModal;
