// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useEffect, useRef, useState } from "react";
import { getImageUploadUrl, getUserClientByEmail, removeAvatar } from "../../../../api/user-service";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useAuth } from "../../../../auth-context/auth.context"
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';

export default function Banner(props) {
  const {banner, email} = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  const {user} = useAuth();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState();
  const [avatarUrl, setAvatarUrl] = useState("");
  const {isOpen, onOpen, onClose} = useDisclosure();
  const imageRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getUserClientByEmail(email)
      .then(({user: profileUser}) => {
        setUserProfile(profileUser);
        setAvatarUrl(profileUser?.avatarUrl);
      })
      .catch(err => console.error(err.message))
      .finally(() => setIsLoading(false))
  }, []);

  const handleSaveImage = async () => {
    setIsUploading(true);
    try {
      if (imageFile && imageUrl) {
        const {uploadUrl} = await getImageUploadUrl(userProfile.userId, user.sub, imageFile.type, 'avatar');

        const result = await axios.put(uploadUrl, imageFile, {
          headers: {
            "Content-Type": imageFile.type,
          },
        });

        if (result.status === 200) {
          setAvatarUrl(imageUrl);
        }
      } else {
        await removeAvatar(userProfile.userId, user.sub);
        setAvatarUrl(null);
      }

      onClose();
    } catch (error) {
      console.log(error);
      toast({
        title: `Edit image error!`,
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  }

  const handleSelectImage = async (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setImageFile(file);
    setImageUrl(url);
  };


  const handelRemoveAvatar = async () => {
    setImageUrl(null);
  };

  return (
    <Card mb={{base: "0px", lg: "20px"}} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Box position="relative">
        <Avatar
          mx="auto"
          src={avatarUrl}
          h="100px" // updated to 100px
          w="100px" // updated to 100px
          mt="-70px"
          border="4px solid"
          borderColor={borderColor}
        />

        <Tooltip label="Edit" placement="top">
          <IconButton
            pos="absolute"
            top="-60px"
            left="calc(50% - 50px)" // Updated to account for smaller size of Avatar
            isRound
            colorScheme="brand"
            icon={<EditIcon />}
            size="sm"
            onClick={() => {
              setImageUrl(avatarUrl);
              onOpen();
            }}
            aria-label='avatar' />
        </Tooltip>
      </Box>
      {
        !userProfile || isLoading ? (<>
          {[0, 1, 2, 3, 4].map((item) => (
            <Skeleton key={item} mt="12px" h="20px" />
          ))}
        </>) : (<Box mt='10px'>
          <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px' textAlign='start'>
            Advisor Details
          </Text>
          <Flex direction={{base: 'column', md: 'row'}}>
            <VStack flex={0.3}>
              <Tooltip label="Click to view full screen" placement="bottom">
                <Flex
                  justify='center'
                  align='center'
                  mt={4}>
                  <PhotoProvider>
                    <PhotoView src={userProfile?.advisor?.avatarUrl}>
                      <Avatar
                        cursor="pointer"
                        src={userProfile?.advisor?.avatarUrl}
                        size='xl'
                        border="4px solid"
                        color={'brand.500'}
                      />
                    </PhotoView>
                  </PhotoProvider>
                </Flex>
              </Tooltip>
            </VStack>
            <VStack flex={0.7} align='start'>
              <HStack>
                <Text color={textColorSecondary} fontSize='lg' fontWeight='600'>
                  Company:
                </Text>
                <Text color={textColorPrimary} fontSize='lg' fontWeight='500' isTruncated
                      title={userProfile?.advisor?.fullName || ''}>
                  {userProfile?.advisor?.fullName || ''}
                </Text>
              </HStack>
              <HStack>
                <Text color={textColorSecondary} fontSize='lg' fontWeight='600'>
                  Name:
                </Text>
                <Text color={textColorPrimary} fontSize='lg' fontWeight='500' isTruncated
                      title={`${userProfile.advisor.firstName} ${userProfile.advisor.lastName}`}>
                  {userProfile.advisor.firstName} {userProfile.advisor.lastName}
                </Text>
              </HStack>
              <HStack wrap='wrap'>
                <Text color={textColorSecondary} fontSize='lg' fontWeight='600'>
                  Email:
                </Text>
                <Text color={textColorPrimary} fontSize='lg' fontWeight='500' isTruncated
                      title={userProfile.advisor.email}>
                  <a href={`mailto:${userProfile.advisor.email}`}>{userProfile.advisor.email}</a>
                </Text>
              </HStack>
              <HStack>
                <Text color={textColorSecondary} fontSize='lg' fontWeight='600'>
                  Phone Number:
                </Text>
                <Text color={textColorPrimary} fontSize='lg' fontWeight='500'>
                  <a href={`tel:${userProfile.advisor.phoneNumber}`}>{userProfile.advisor.phoneNumber}</a>
                </Text>
              </HStack>
            </VStack>
          </Flex>
          <Divider my='8px' />
          <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' textAlign='center'>
            {userProfile.firstName} {userProfile.lastName}
          </Text>
          <Text color="brand.500" fontSize='lg' fontWeight='500' textAlign='center'>
            {userProfile?.fullName}
          </Text>
          <Text color={textColorSecondary} fontSize='lg' fontWeight='500' title={userProfile?.email}
                textAlign='center'>
            {userProfile?.email}
          </Text>
        </Box>)
      }
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Avatar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box textAlign="center">
              <Tooltip label="Select image" placement="top">
                <Avatar
                  mx="auto"
                  src={imageUrl}
                  h="170px"
                  w="170px"
                  border="4px solid"
                  borderColor={borderColor}
                  _hover={{
                    opacity: 0.7,
                  }}
                  onClick={() => imageRef.current.click()}
                />
              </Tooltip>
            </Box>
            <input
              type="file"
              accept="image/*"
              hidden
              ref={imageRef}
              onChange={handleSelectImage}
            />
            <Tooltip label="Remove" placement="top">
              <IconButton
                float="right"
                variant="ghost"
                colorScheme="brand"
                icon={<DeleteIcon />}
                onClick={handelRemoveAvatar}
              />
            </Tooltip>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="outline"
              colorScheme="brand"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              colorScheme="brand"
              onClick={handleSaveImage}
              isLoading={isUploading}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
