// Chakra imports
import { Button, Flex, Skeleton, Text, useColorModeValue, useToast } from '@chakra-ui/react';
// Assets
// Custom components
import Card from 'components/card/Card';
import React, { useEffect, useState } from 'react';
import { getAllLinkedInsuranceAccounts, removeInsuranceInstitution } from '../../../../api/institution-service';
import { useAuth } from "../../../../auth-context/auth.context";
import CanopyAccount from "./CanopyAccount";
import { formatInsuranceProviderName } from "../../../../libraries/utils";

export default function LinkedCanopyAccounts(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';

  const { user } = useAuth();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [linkedInstitutions, setLinkedInstitutions] = useState([]);
  const [timerId, setTimerId] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);


  useEffect(() => {
    fetchLinkedAccounts();
  }, []);

  const fetchLinkedAccounts = () => {
    setIsLoading(true);
    getAllLinkedInsuranceAccounts()
      .then((result) => {
        setLinkedInstitutions(result);
      })
      .catch(err => console.error(err.message))
      .finally(() => setIsLoading(false))
  }

  const onRemoveItem = (pullId) => {
    setIsDeleting(true);
    removeInsuranceInstitution(pullId)
      .then(() => {
        toast({
          title: 'Remove Account',
          description: `Your account has been removed.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchLinkedAccounts();
      })
      .catch((err) => {
        toast({
          title: 'Remove Account',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsDeleting(false);
      })
  }

  const CanopyConnectBtn = ({ publicAlias, consentToken, metaData }) => {
    const [handler, setHandler] = React.useState(null);
    useEffect(() => {
      if (!publicAlias) {
        return; // Don't do anything - will keep showing the loading spinner until a publicAlias is set
      }
      const canopyHandler = window.CanopyConnect.create({
        publicAlias,
        consentToken,
        pullMetaData: metaData,
        onAuthenticationSuccess: () => {
          setTimeout(() => {
            toast({
              title: 'Add Insurance account',
              description: `You have added an account successfully. Please wait a moment while we are pulling your data.`,
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
          }, 3000);
        },
        onExit: () => {
          fetchLinkedAccounts();
        }
      });
      setHandler(canopyHandler);

      return () => {
        setHandler(null);
        canopyHandler.destroy();
      };
    }, [publicAlias, consentToken, metaData]); // Reload SDK when props change

    return (
      <Button
        minW="200px"
        variant="brand"
        fontWeight="500"
        onClick={ () => handler.open() }
        disabled={ !handler || isLoading || isProcessing }
      >
        Add Insurance Account
      </Button>
    );
  };

  return (
    <Card mb={ { base: '0px', '2xl': '20px' } }>
      <Flex
        mb="20px"
        direction={ { base: 'column', md: 'row' } }
        align={ { md: 'center' } }
        justify="space-between"
      >
        <Text
          color={ textColorPrimary }
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px">
          Linked Accounts
        </Text>
        <CanopyConnectBtn publicAlias='smartdata-llc' metaData={ {
          sub: user?.sub,
          email: user?.email,
          fullName: user?.fullName,
        } }/>
      </Flex>
      {
        (isLoading || isProcessing) ? [0, 1, 2, 3].map((index) => (
          <Skeleton key={ index } mb="20px" h="80px"/>
        )) : linkedInstitutions.map((institution) => (
          <CanopyAccount
            key={ institution.pullId }
            pullId={ institution.pullId }
            onRemoveItem={ onRemoveItem }
            insuranceProviderName={ formatInsuranceProviderName(institution.insuranceProviderName) }
            status={ institution.status }
          />
        ))
      }
    </Card>
  );
}
