import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { MdAttachMoney, MdPercent, MdPerson } from "react-icons/md";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { getFormFieldValues, updateFormFieldValues } from "../../../../../api/smart-fill-service";
import { CirclesWithBar } from "react-loader-spinner";
import { getValueByFieldName } from "../../../../../libraries/utils";
import moment from "moment";
import { StepComponentProps } from "../IRASchwabApplicationModal";

const TRADITIONAL_CONTRIBUTORY = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].traditional[0].Checkboxes[0].contributory[0]';
const TRADITIONAL_ROLLOVER = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].traditional[0].Checkboxes[0].rollover[0]';
const TRADITIONAL_ROLL_OVER_FROM_EMPLOYEE = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].traditional[0].Checkboxes[0].rolloverFromEmployee[0]';
const TRADITIONAL_NAME_OF_EMPLOYER = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].traditional[0].planDetails[0].nameOfEmployer[0]';
const TRADITIONAL_APP_TOTAL_VALUE = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].traditional[0].planDetails[0].appTotalValue[0]';
const TRADITIONAL_DISTRIBUTION_DATE = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].traditional[0].planDetails[0].distributionDate[0]';
const ROTH_CONTRIBUTORY = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].roth[0].Checkboxes[0].contributory[0]';
const ROTH_CONVERSION = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].roth[0].Checkboxes[0].conversion[0]';
const ROTH_IRA_TO_CONVERT = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].roth[0].IRAToConvert[0]';
const ROTH_ROTH_CONV = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].roth[0].row3[0].rothConv[0]';
const ROTH_IRA_TO_CONVERT_1 = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].roth[0].IRAToConvert1[0]';
const ROTH_FULL_CONVERSION = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].roth[0].insideCheck[0].fullConversion[0]';
const ROTH_PARTIAL_CONVERSION = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].roth[0].insideCheck[0].partialConversion[0]';
const ROTH_CASH_AMT = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].roth[0].cashAmt[0]';
const TAX_WITHHOLDING_ELECTION_PERCENTAGE_FIELD = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].TaxWithholdingElection[0].Subform[0].PercentageField[0]';
const TAX_WITHHOLDING_ELECTION_I_DO_NOT_WANT = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].TaxWithholdingElection[0].CheckboxListForB[0].Checkboxes[0].I_Do_Not_Want[0]';
const TAX_WITHHOLDING_ELECTION_I_WANT = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].TaxWithholdingElection[0].CheckboxListForB[0].Checkboxes[0].I_Want[0]';
const TAX_WITHHOLDING_ELECTION_PERCENTAGE_FIELD_CHECKBOX = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].TaxWithholdingElection[0].CheckboxListForB[0].Checkboxes[0].PercentageField[0]';
const SIMPLE_IRA_CONTRIBUTORY = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].simpleIRA[0].Checkboxes[0].contributory[0]';
const SIMPLE_IRA_NAME_OF_EMPLOYER = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].simpleIRA[0].nameOfEmployer[0]';
const SIMPLE_IRA_NAME_OF_EMPLOYER_1 = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].simpleIRA[0].nameOfEmployer1[0]';
const PENSION_IRA_SAP_IRA = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].pensionIRA[0].Checkboxes[0].SAP-IRA[0]';
const PENSION_IRA_SARSEP_IRA = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].pensionIRA[0].Checkboxes[0].SARSEP-IRA[0]';
const PENSION_IRA_NAME_OF_EMPLOYER = 'clients[0].Form[0].Section1[0].border[0].SelectIRAType[0].pensionIRA[0].nameOfEmployer[0]';

const IRAType = forwardRef<any, StepComponentProps>(({userFormId, title, ...props}, ref) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const secondaryTextColor = useColorModeValue("secondaryGray.700", "white");

  const [isLoading, setIsLoading] = useState(false);
  const [traditionalIRA, setTraditionalIRA] = useState('');
  const [nameOfEmployerSponsoringPlan, setNameOfEmployerSponsoringPlan] = useState('');
  const [totalDistributionValue, setTotalDistributionValue] = useState('');
  const [expectedDistributionDate, setExpectedDistributionDate] = useState(new Date());
  const [rothIRA, setRothIRA] = useState('');
  const [currentSchwabQualifiedPlanToConvert, setCurrentSchwabQualifiedPlanToConvert] = useState('');
  const [currentSchwabTraditionalIRAToConvert, setCurrentSchwabTraditionalIRAToConvert] = useState('');
  const [conversionAmountToConvertToRothIRA, setConversionAmountToConvertToRothIRA] = useState('');
  const [convertPartialAmount, setConvertPartialAmount] = useState('');
  const [incomeWholeTaxWithholdingRate, setIncomeWholeTaxWithholdingRate] = useState('');
  const [taxWithholdingElection, setTaxWithholdingElection] = useState('');
  const [incomeMinimumTaxWithholdingRate, setIncomeMinimumTaxWithholdingRate] = useState('');
  const [simpleIRA, setSimpleIRA] = useState(false);
  const [simpleIRABusinessName, setSimpleIRABusinessName] = useState('');
  const [simpleIRAEmployerGroupPlanNumber, setSimpleIRAEmployerGroupPlanNumber] = useState('');
  const [simplifiedEmployeePensionIRA, setSimplifiedEmployeePensionIRA] = useState('');
  const [simplifiedEmployeePensionIRABusinessName, setSimplifiedEmployeePensionIRABusinessName] = useState('');

  const [errors, setErrors] = useState({
    traditionalIRA: '',
  });


  useEffect(() => {
    fetchFormValues();
  }, []);

  const fetchFormValues = () => {
    setIsLoading(true);
    getFormFieldValues(userFormId)
      .then(({fieldValues}) => {
        const traditionalContributoryChecked = getValueByFieldName(fieldValues, TRADITIONAL_CONTRIBUTORY) === "checked";
        const traditionalRollOverChecked = getValueByFieldName(fieldValues, TRADITIONAL_ROLLOVER) === "checked";
        const traditionalRollOverFromEmployeeChecked = getValueByFieldName(fieldValues, TRADITIONAL_ROLL_OVER_FROM_EMPLOYEE) === "checked";
        const traditionalNameOfEmployer = getValueByFieldName(fieldValues, TRADITIONAL_NAME_OF_EMPLOYER);
        const traditionalAppTotalValue = getValueByFieldName(fieldValues, TRADITIONAL_APP_TOTAL_VALUE);
        const traditionalDistributionDate = getValueByFieldName(fieldValues, TRADITIONAL_DISTRIBUTION_DATE);
        const rothContributoryChecked = getValueByFieldName(fieldValues, ROTH_CONTRIBUTORY) === "checked";
        const rothConversionChecked = getValueByFieldName(fieldValues, ROTH_CONVERSION) === "checked";
        const rothIraToConvert = getValueByFieldName(fieldValues, ROTH_IRA_TO_CONVERT);
        const rothRothConvChecked = getValueByFieldName(fieldValues, ROTH_ROTH_CONV) === "checked";
        const rothIraToConvert1 = getValueByFieldName(fieldValues, ROTH_IRA_TO_CONVERT_1);
        const rothFullConversionChecked = getValueByFieldName(fieldValues, ROTH_FULL_CONVERSION) === "checked";
        const rothPartialConversionChecked = getValueByFieldName(fieldValues, ROTH_PARTIAL_CONVERSION) === "checked";
        const rothCashAmt = getValueByFieldName(fieldValues, ROTH_CASH_AMT);
        const taxWithholdingElectionPercentageField = getValueByFieldName(fieldValues, TAX_WITHHOLDING_ELECTION_PERCENTAGE_FIELD);
        const taxWithholdingElectionIDoNotWantChecked = getValueByFieldName(fieldValues, TAX_WITHHOLDING_ELECTION_I_DO_NOT_WANT) === "checked";
        const taxWithholdingElectionIWantChecked = getValueByFieldName(fieldValues, TAX_WITHHOLDING_ELECTION_I_WANT) === "checked";
        const taxWithholdingElectionPercentageFieldCheckbox = getValueByFieldName(fieldValues, TAX_WITHHOLDING_ELECTION_PERCENTAGE_FIELD_CHECKBOX);
        const simpleIraContributory = getValueByFieldName(fieldValues, SIMPLE_IRA_CONTRIBUTORY);
        const simpleIraNameOfEmployer = getValueByFieldName(fieldValues, SIMPLE_IRA_NAME_OF_EMPLOYER);
        const simpleIraNameOfEmployer1 = getValueByFieldName(fieldValues, SIMPLE_IRA_NAME_OF_EMPLOYER_1);
        const pensionIraSapIraChecked = getValueByFieldName(fieldValues, PENSION_IRA_SAP_IRA) === "checked";
        const pensionIraSarsepIraChecked = getValueByFieldName(fieldValues, PENSION_IRA_SARSEP_IRA) === "checked";
        const pensionIraNameOfEmployer = getValueByFieldName(fieldValues, PENSION_IRA_NAME_OF_EMPLOYER);


        let traditionalIRAOption = '';
        if (traditionalContributoryChecked) {
          traditionalIRAOption = 'contributory';
        } else if (traditionalRollOverChecked) {
          traditionalIRAOption = 'rollover';
        } else if (traditionalRollOverFromEmployeeChecked) {
          traditionalIRAOption = 'rolloverFromEmployee';
        }

        setTraditionalIRA(traditionalIRAOption);
        setNameOfEmployerSponsoringPlan(traditionalNameOfEmployer);
        setTotalDistributionValue(traditionalAppTotalValue);
        setExpectedDistributionDate(traditionalDistributionDate ? moment(traditionalDistributionDate).toDate() : new Date());
        let rothIRAOption = '';
        if (rothContributoryChecked) {
          rothIRAOption = 'contributory';
        } else if (rothConversionChecked) {
          rothIRAOption = 'conversion';
        } else if (rothRothConvChecked) {
          rothIRAOption = 'rothConv';
        }
        setRothIRA(rothIRAOption);
        setCurrentSchwabQualifiedPlanToConvert(rothIraToConvert);
        setCurrentSchwabTraditionalIRAToConvert(rothIraToConvert1);

        let conversionAmountToConvertToRothIRAOption = '';
        if (rothFullConversionChecked) {
          conversionAmountToConvertToRothIRAOption = 'full';
        } else if (rothPartialConversionChecked) {
          conversionAmountToConvertToRothIRAOption = 'partial';
        }
        setConversionAmountToConvertToRothIRA(conversionAmountToConvertToRothIRAOption);
        setConvertPartialAmount(rothCashAmt);
        setIncomeWholeTaxWithholdingRate(taxWithholdingElectionPercentageField);

        let taxWithholdingElectionOption = '';
        if (taxWithholdingElectionIDoNotWantChecked) {
          taxWithholdingElectionOption = 'IDoNotWant';
        } else if (taxWithholdingElectionIWantChecked) {
          taxWithholdingElectionOption = 'IWant';
        }

        setTaxWithholdingElection(taxWithholdingElectionOption);
        setIncomeMinimumTaxWithholdingRate(taxWithholdingElectionPercentageFieldCheckbox);
        setSimpleIRA(simpleIraContributory === 'checked');
        setSimpleIRABusinessName(simpleIraNameOfEmployer);
        setSimpleIRAEmployerGroupPlanNumber(simpleIraNameOfEmployer1);

        let simplifiedEmployeePensionIRAOption = '';
        if (pensionIraSapIraChecked) {
          simplifiedEmployeePensionIRAOption = 'SEP';
        } else if (pensionIraSarsepIraChecked) {
          simplifiedEmployeePensionIRAOption = 'SARSEP';
        }

        setSimplifiedEmployeePensionIRA(simplifiedEmployeePensionIRAOption);
        setSimplifiedEmployeePensionIRABusinessName(pensionIraNameOfEmployer);
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => setIsLoading(false))
  }

  const updateData = async () => {
    await updateFormFieldValues([
      {
        [TRADITIONAL_CONTRIBUTORY]: traditionalIRA === 'contributory' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRADITIONAL_ROLLOVER]: traditionalIRA === 'rollover' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRADITIONAL_ROLL_OVER_FROM_EMPLOYEE]: traditionalIRA === 'rolloverFromEmployee' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TRADITIONAL_NAME_OF_EMPLOYER]: nameOfEmployerSponsoringPlan,
        fieldType: 'PDFTextField'
      },
      {
        [TRADITIONAL_APP_TOTAL_VALUE]: totalDistributionValue,
        fieldType: 'PDFTextField'
      },
      {
        [TRADITIONAL_DISTRIBUTION_DATE]: expectedDistributionDate ? moment(expectedDistributionDate).format('MM/DD/YYYY') : '',
        fieldType: 'PDFTextField'
      },
      {
        [ROTH_CONTRIBUTORY]: rothIRA === 'contributory' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [ROTH_CONVERSION]: rothIRA === 'conversion' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [ROTH_IRA_TO_CONVERT]: rothIRA === 'conversion' ? currentSchwabQualifiedPlanToConvert : '',
        fieldType: 'PDFTextField'
      },
      {
        [ROTH_ROTH_CONV]: rothIRA === 'rothConv' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [ROTH_IRA_TO_CONVERT_1]: rothIRA === 'rothConv' ? currentSchwabTraditionalIRAToConvert : '',
        fieldType: 'PDFTextField'
      },
      {
        [ROTH_FULL_CONVERSION]: conversionAmountToConvertToRothIRA === 'full' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [ROTH_PARTIAL_CONVERSION]: conversionAmountToConvertToRothIRA === 'partial' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [ROTH_CASH_AMT]: conversionAmountToConvertToRothIRA === 'partial' ? convertPartialAmount : '',
        fieldType: 'PDFTextField'
      },
      {
        [TAX_WITHHOLDING_ELECTION_PERCENTAGE_FIELD]: incomeWholeTaxWithholdingRate,
        fieldType: 'PDFTextField'
      },
      {
        [TAX_WITHHOLDING_ELECTION_I_DO_NOT_WANT]: taxWithholdingElection === 'IDoNotWant' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TAX_WITHHOLDING_ELECTION_I_WANT]: taxWithholdingElection === 'IWant' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [TAX_WITHHOLDING_ELECTION_PERCENTAGE_FIELD_CHECKBOX]: taxWithholdingElection === 'IWant' ? incomeMinimumTaxWithholdingRate : '',
        fieldType: 'PDFTextField'
      },
      {
        [SIMPLE_IRA_CONTRIBUTORY]: simpleIRA ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [SIMPLE_IRA_NAME_OF_EMPLOYER]: simpleIRA ? simpleIRABusinessName : '',
        fieldType: 'PDFTextField'
      },
      {
        [SIMPLE_IRA_NAME_OF_EMPLOYER_1]: simpleIRA ? simpleIRAEmployerGroupPlanNumber : '',
        fieldType: 'PDFTextField'
      },
      {
        [PENSION_IRA_SAP_IRA]: simplifiedEmployeePensionIRA === 'SEP' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [PENSION_IRA_SARSEP_IRA]: simplifiedEmployeePensionIRA === 'SARSEP' ? 'checked' : '',
        fieldType: 'PDFCheckBox'
      },
      {
        [PENSION_IRA_NAME_OF_EMPLOYER]: simplifiedEmployeePensionIRABusinessName,
        fieldType: 'PDFTextField'
      },
    ], userFormId);
  }

  useImperativeHandle(ref, () => ({
    handleNext: async () => {
      try {
        await updateData();
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  }));

  return (
    <Box h='full'>
      <Text
        fontSize="2xl"
        fontWeight="500">
        { title }
      </Text>
      <Flex p={ {base: '0px', md: '32px'} } direction='column' align={ isLoading ? 'center' : 'start' }
            justify={ isLoading ? 'center' : 'start' } h='full'>
        {
          isLoading ? <CirclesWithBar
            height="100"
            width="100"
            wrapperClass=""
            visible={ true }
            ariaLabel="circles-with-bar-loading"
          /> : <Box w='full'>
            <Grid
              mt="12px"
              gap={ {base: 4, md: 2} }
            >
              <FormControl isRequired mt='12px' isInvalid={ !!errors.traditionalIRA }>
                <FormLabel>Traditional IRA. (Select only one.)</FormLabel>
                <RadioGroup value={ traditionalIRA } onChange={ (e) => {
                  setTraditionalIRA(e);
                  setRothIRA('');
                  setCurrentSchwabQualifiedPlanToConvert('');
                  setCurrentSchwabTraditionalIRAToConvert('');
                  setConversionAmountToConvertToRothIRA('');
                  setConvertPartialAmount('');
                } }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ 2 }>
                    <Radio colorScheme="brandScheme" value='contributory'>Contributory IRA</Radio>
                    <Radio colorScheme="brandScheme" value='rollover'>Rollover IRA</Radio>
                    <Radio colorScheme="brandScheme" value='rolloverFromEmployee'>Rollover from an employer retirement
                      plan</Radio>
                  </Flex>
                </RadioGroup>
                { errors.traditionalIRA && <FormErrorMessage>{ errors.traditionalIRA }</FormErrorMessage> }
              </FormControl>
            </Grid>
            <Grid
              mt="12px"
              templateColumns={ {base: '1fr', md: '2fr 1fr 1fr'} }
              gap={ {base: 4, md: 2} }
            >
              <FormControl mt='12px'>
                <FormLabel>Name of Employer Sponsoring the Plan</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                    <MdPerson color="gray.300"/>
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ nameOfEmployerSponsoringPlan }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      setNameOfEmployerSponsoringPlan(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        nameOfEmployerSponsoringPlan: '',
                      }));
                    } }
                  />
                </InputGroup>
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Approximate Total Value of Distribution</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                    <MdAttachMoney color="gray.300"/>
                  </InputLeftElement>
                  <Input
                    type="text"
                    color={ textColor }
                    value={ totalDistributionValue }
                    onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                      setTotalDistributionValue(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        totalDistributionValue: '',
                      }));
                    } }
                  />
                </InputGroup>
              </FormControl>
              <FormControl mt='12px'>
                <FormLabel>Expected Distribution Date</FormLabel>
                <InputGroup>

                  <SingleDatepicker
                    name="date-input"
                    minDate={ new Date() }
                    configs={ {
                      dateFormat: 'MM/dd/yyyy'
                    } }
                    date={ expectedDistributionDate }
                    onDateChange={ (date) => {
                      setExpectedDistributionDate(date);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        totalDistributionValue: '',
                      }));
                    } }
                  />
                </InputGroup>
              </FormControl>
            </Grid>
            <Grid
              mt="12px"
              gap={ {base: 4, md: 2} }
            >
              <FormControl isRequired mt='12px' isInvalid={ !!errors.traditionalIRA }>
                <FormLabel>Roth IRA. (Select only one.)</FormLabel>
                <RadioGroup value={ rothIRA } onChange={ (e) => {
                  setRothIRA(e);
                  setTraditionalIRA('');
                  setNameOfEmployerSponsoringPlan('');
                  setTotalDistributionValue('');
                  setExpectedDistributionDate(new Date());
                } }>
                  <Flex flexDirection='column' gap={ 2 }>
                    <Radio colorScheme="brandScheme" value='contributory'>Roth Contributory</Radio>
                    <Radio colorScheme="brandScheme" value='conversion'>Roth Conversion
                      (from a Schwab Qualified Plan*)</Radio>
                    <FormControl ps='24px' hidden={ rothIRA !== 'conversion' }>
                      <FormLabel>Current Schwab Qualified Plan to convert</FormLabel>
                      <Input
                        type="text"
                        color={ textColor }
                        w={ {base: 'full', md: 'xs'} }
                        value={ currentSchwabQualifiedPlanToConvert }
                        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                          setCurrentSchwabQualifiedPlanToConvert(e.target.value);
                        } }
                      />
                    </FormControl>
                    <Radio colorScheme="brandScheme" value='rothConv'>Roth Conversion (from a Schwab Traditional
                      IRA)</Radio>
                    <FormControl ps='24px' hidden={ rothIRA !== 'rothConv' }>
                      <FormLabel>Current Schwab Traditional IRA to convert</FormLabel>
                      <Input
                        type="text"
                        color={ textColor }
                        w={ {base: 'full', md: 'xs'} }
                        value={ currentSchwabTraditionalIRAToConvert }
                        onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                          setCurrentSchwabTraditionalIRAToConvert(e.target.value);
                        } }
                      />
                      <FormControl mt='12px' hidden={ rothIRA !== 'rothConv' }>
                        <FormLabel>Amount to convert to Roth IRA:</FormLabel>
                        <RadioGroup value={ conversionAmountToConvertToRothIRA }
                                    onChange={ (e) => setConversionAmountToConvertToRothIRA(e) }>
                          <Flex flexDirection='column' gap={ 2 }>
                            <Radio colorScheme="brandScheme" value='full'>Full conversion</Radio>
                            <Radio colorScheme="brandScheme" value='partial'>Partial conversion</Radio>
                            <FormControl ps='24px'
                                         isRequired={ conversionAmountToConvertToRothIRA === 'partial' }
                                         hidden={ conversionAmountToConvertToRothIRA !== 'partial' }>
                              <FormLabel>Cash amount to be converted</FormLabel>
                              <InputGroup>
                                <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                                  <MdAttachMoney color="gray.300"/>
                                </InputLeftElement>
                                <Input
                                  type="text"
                                  color={ textColor }
                                  w={ {base: 'full', md: 'xs'} }
                                  value={ convertPartialAmount }
                                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                                    setConvertPartialAmount(e.target.value);
                                  } }
                                />
                              </InputGroup>
                            </FormControl>
                          </Flex>
                        </RadioGroup>
                      </FormControl>
                    </FormControl>
                  </Flex>
                </RadioGroup>
                { errors.traditionalIRA && <FormErrorMessage>{ errors.traditionalIRA }</FormErrorMessage> }
              </FormControl>
            </Grid>
            <Grid
              mt="12px"
              gap={ {base: 4, md: 2} }
            >
              <Text
                fontWeight='500'
              >Tax Withholding Election</Text>
              <Box ps='12px'>
                <Text color={ secondaryTextColor }>(* Please check details from original pdf file.)</Text>
                <Text>
                  • Distributions from an IRA that are payable on demand are treated as nonperiodic payments.
                </Text>
                <Text>
                  • Nonresident aliens are subject to a 30% tax withholding rate and must submit a valid IRS Form W-8BEN
                  to obtain an available reduced tax treaty rate.
                </Text>
                <Text>
                  • Generally, Roth IRA distributions are not taxable. Unless you have an existing election on file or
                  elect to withhold on this form, we will not withhold taxes from your Roth IRA distribution
                </Text>
              </Box>
              <Box ps='12px'>
                <Text fontWeight='600'>A. Federal Income Tax Withholding</Text>
                <Text>Your withholding rate is determined by the type of payment you will receive</Text>
                <FormControl>
                  <FormLabel>Enter the rate as a whole number (no decimals)</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                      <MdPercent color="gray.300"/>
                    </InputLeftElement>
                    <Input
                      type="text"
                      color={ textColor }
                      w={ {base: 'full', md: 'xs'} }
                      value={ incomeWholeTaxWithholdingRate }
                      onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                        setIncomeWholeTaxWithholdingRate(e.target.value);
                      } }
                    />
                  </InputGroup>
                </FormControl>
                <Text>Note: The attached IRS Form W-4R is for information only. You are not required to complete the IRS
                  Form
                  W-4R.</Text>

                <Text fontWeight='600'>B. State Income Tax Withholding</Text>
                <Text>If you do not make an election, Schwab will apply withholding (if required) at the minimum rate
                  based on
                  the laws for your state of residency as determined by the legal address of record on your
                  account.</Text>
              </Box>
              <FormControl isRequired>
                <FormLabel>Choose one</FormLabel>
                <RadioGroup value={ taxWithholdingElection } onChange={ (e) => setTaxWithholdingElection(e) }>
                  <Flex flexDirection='column' gap={ 2 }>
                    <Radio colorScheme="brandScheme" value='IDoNotWant'><b>I do not want</b> state income tax
                      withheld.</Radio>
                    <Radio colorScheme="brandScheme" value='IWant'><b>I want</b> state income tax withheld at the rate
                      of</Radio>
                    <FormControl ps='16px' isRequired={ taxWithholdingElection === 'IWant' }
                                 hidden={ taxWithholdingElection !== 'IWant' }>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none" zIndex={ 0 }>
                          <MdPercent color="gray.300"/>
                        </InputLeftElement>
                        <Input
                          type="text"
                          color={ textColor }
                          w={ {base: 'full', md: 'xs'} }
                          value={ incomeMinimumTaxWithholdingRate }
                          onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                            setIncomeMinimumTaxWithholdingRate(e.target.value);
                          } }
                        />
                      </InputGroup>
                    </FormControl>
                  </Flex>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid mt="12px">
              <FormLabel>SIMPLE IRA</FormLabel>
              <FormControl>
                <Checkbox defaultChecked={ simpleIRA } checked={ simpleIRA }
                          onChange={ () => setSimpleIRA(prev => !prev) } me='16px'
                          colorScheme='brandScheme'>SIMPLE IRA</Checkbox>
                <Flex ms='20px' flexDirection={ {base: 'column', md: 'row'} } gap={ {base: 2, md: 4} }
                      hidden={ !simpleIRA }>
                  <FormControl>
                    <FormLabel>Name of Business</FormLabel>
                    <Input
                      type="text"
                      color={ textColor }
                      value={ simpleIRABusinessName }
                      onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                        setSimpleIRABusinessName(e.target.value);
                      } }
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Employer's Group Plan Number</FormLabel>
                    <Input
                      type="text"
                      color={ textColor }
                      value={ simpleIRAEmployerGroupPlanNumber }
                      onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                        setSimpleIRAEmployerGroupPlanNumber(e.target.value);
                      } }
                    />
                  </FormControl>
                </Flex>
              </FormControl>

              <FormLabel mt='12px'>Simplified Employee Pension IRA (SEP-IRA). (Select only one)</FormLabel>
              <FormControl>
                <RadioGroup value={ simplifiedEmployeePensionIRA }
                            onChange={ (e) => setSimplifiedEmployeePensionIRA(e) }>
                  <Flex flexDirection={ {base: 'column', md: 'row'} } gap={ {base: 2, md: 4} }>
                    <Radio value='SEP' colorScheme='brandScheme'>SEP-IRA</Radio>
                    <Radio value='SARSEP' colorScheme='brandScheme'>SARSEP-IRA</Radio>
                  </Flex>
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel>Name of Business</FormLabel>
                <Input
                  type="text"
                  color={ textColor }
                  value={ simplifiedEmployeePensionIRABusinessName }
                  onChange={ (e: React.ChangeEvent<HTMLInputElement>) => {
                    setSimplifiedEmployeePensionIRABusinessName(e.target.value);
                  } }
                />
              </FormControl>
            </Grid>
          </Box>
        }
      </Flex>
    </Box>
  );
});

export default IRAType;
