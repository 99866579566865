import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, } from '@chakra-ui/react';
import React from 'react';
import moment from "moment";
import { convertFriendlyName } from "../../../../libraries/utils";

const TABLE_COLUMNS = [
  {
    Header: 'DATE',
    accessor: 'incidentDate',
  },
  {
    Header: 'TYPE',
    accessor: 'incidentType',
  },
  {
    Header: 'DRIVER',
    accessor: 'driver',
  },
  {
    Header: 'FAULT',
    accessor: 'isAtFault',
  },
];

export default function DrivingRecord(props) {
  const {
    drivingRecords
  } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <Box mt='32px'>
      <Text
        color={ textColor }
        fontWeight='bold'
        fontSize='lg'
      >
        Driving Records
      </Text>
      <Box mt='8px'>
        <Table variant="simple" color="gray.500">
          <Thead>
            <Tr>
              { TABLE_COLUMNS.map((column, index) => (
                <Th pe="10px" key={ index } borderColor={ borderColor }>
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={ {
                      sm: '10px',
                      lg: '12px',
                    } }
                    color="gray.400"
                  >
                    { column.Header }
                  </Flex>
                </Th>
              )) }
            </Tr>
          </Thead>
          <Tbody>
            {
              drivingRecords && drivingRecords.map((drivingRecord, index) => {
                return (
                  <Tr key={ index }
                      color={ textColor }
                      fontSize="sm">
                    <Td>
                      { moment(drivingRecord['incidentDate']).format('MMM DD, YYYY') }
                    </Td>
                    <Td>
                      { `${ convertFriendlyName(drivingRecord['incidentType']) } ${ drivingRecord['incidentType'] === 'VIOLATION' ? (`(${ convertFriendlyName(drivingRecord['violationType'] || '') })`) : '' }` }
                    </Td>
                    <Td>
                      {
                        drivingRecord?.driver && (`${ drivingRecord.driver?.firstName } ${ drivingRecord.driver?.middleName || '' } ${ drivingRecord.driver?.lastName || '' }`)
                      }
                    </Td>
                    <Td>
                      {`${drivingRecord?.isAtFault}` === 'false' ? 'NO' : 'YES'}
                    </Td>
                  </Tr>
                )
              })
            }
            { (!drivingRecords || drivingRecords.length === 0) && (
              <Tr>
                <Td colSpan={ 8 } p={ 2 }>
                  <Text textAlign="center">There is no driving record!</Text>
                </Td>
              </Tr>
            ) }
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
