import { Box, Text, useColorModeValue, } from '@chakra-ui/react';
import React from 'react';
import Coverage from "./Coverage";
import ProperyData from "./ProperyData";

export default function Dwelling(props) {
  const {
    dwellings
  } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box mt='8px'>
      <Text
        color={ textColor }
        fontWeight='600'
        fontSize='md'
      >
        Dwellings
      </Text>
      <Box>
        {
          dwellings?.map((dwelling, index) => {
            return (
              <Box key={ index }>
                <Text
                  color={ textColor }
                  fontWeight='500'
                  fontSize='sm'
                >
                  { dwelling?.address?.fullAddress || '' }
                </Text>
                {
                  dwelling?.coverages?.length > 0 && <Coverage coverages={ dwelling?.coverages }/>
                }
                {
                  dwelling?.propertyData && <ProperyData propertyData={ dwelling?.propertyData }/>
                }
              </Box>
            )
          })
        }
      </Box>
    </Box>
  );
}
