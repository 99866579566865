import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import {ENDPOINT} from "./config/constant";

const {
	REACT_APP_COGNITO_REGION,
	REACT_APP_COGNITO_USER_POOL_ID,
	REACT_APP_COGNITO_APPCLIENT_ID,
	REACT_APP_API_URL
} = process.env;

const customHeaders = async () => Auth.currentSession().then(
	session => ({
		Authorization: session.idToken.jwtToken,
	}),
	noValidSessionError => ({}),
);

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: REACT_APP_COGNITO_REGION,
		userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
		userPoolWebClientId: REACT_APP_COGNITO_APPCLIENT_ID,
		authenticationFlowType: "CUSTOM_AUTH",
	},
	API: {
		endpoints: [
			{
				name: ENDPOINT,
				endpoint: REACT_APP_API_URL,
				custom_header: customHeaders,
			},
		],
	},
});
