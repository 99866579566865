import React, { forwardRef } from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { StepComponentProps } from "../IRASchwabApplicationModal";

const ConsentEnroll = forwardRef<any, StepComponentProps>(({userFormId, title, ...props}, ref) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box h='full'>
      <Text
        fontSize="2xl"
        fontWeight="500">
        { title }
      </Text>
      <Flex p={ {base: '0px', md: '32px'} } gap={ 4 } direction='column'>
        <Text
          color={ textColor }
        >
          The Cash Features Program is a service that offers the Bank Sweep feature (a "Cash Feature") to permit your
          uninvested cash (the "Free Credit Balance") to earn income while you decide how those funds should be invested
          longer term.
        </Text>
        <Text
          color={ textColor }
        >
          You understand that additional information about the Cash Features Program and each Cash Feature is available
          in the Cash Features Disclosure Statement
        </Text>
        <Text
          color={ textColor }
        >
          By signing this Application, you consent to having the Free Credit Balance in your brokerage account included
          in the Cash Features Program, as described in the Cash Features Disclosure Statement.
        </Text>
        <Text
          color={ textColor }
        >
          The Bank Sweep feature is designated as the Cash Feature for brokerage accounts of account holders residing in
          the U.S. Through the Bank Sweep feature, Schwab automatically makes deposits to and withdrawals from deposit
          accounts at one or more banks affiliated with Schwab ("Sweep Banks"). Your deposits at each Sweep Bank are
          insured by the Federal Deposit Insurance Corporation (FDIC) up to $250,000 (including principal and accrued
          interest) when aggregated with all other deposits held by you in the same insurable capacity at that bank.
          Your funds may be placed in a Sweep Bank in excess of the FDIC insurance limit. In certain limited
          circumstances, Schwab may place your funds in a sweep money market fund. Please see the Cash Features
          Disclosure Statement for additional information.
        </Text>
        <Text
          color={ textColor }
        >
          You understand and agree that Schwab may (1) make changes to the terms and conditions of the Cash Features
          Program; (2) make changes to the terms and conditions of any Cash Feature; (3) change, add, or discontinue any
          Cash Feature; (4) change your investment from one Cash Feature to another if you become ineligible for your
          current Cash Feature or your current Cash Feature is discontinued; and (5) make any other changes to the Cash
          Features Program or Cash Feature as allowed by law. Schwab will notify you in writing of changes to the terms
          of the Cash Features, changes to the Cash Features we make available, or changes to the Cash Features Program
          prior to the effective date of the proposed change.
        </Text>
      </Flex>
    </Box>
  );
});

export default ConsentEnroll;
