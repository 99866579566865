import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverContent,
  PopoverHeader,
  Portal,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';

// Custom components
import Card from 'components/card/Card';

// Assets
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator
} from '@ajna/pagination';
import { mode } from '@chakra-ui/theme-tools';
import { CheckCircleIcon, DeleteIcon, DownloadIcon, EditIcon, SearchIcon, WarningIcon } from '@chakra-ui/icons';
import Select, { components } from 'react-select';
import moment from "moment";
import { PopoverTrigger } from "../../dataTables/components/ClientTable";
import { selectCustomStyles } from "../../../../theme/styles";
import { downloadFilledUserForm } from "../../../../api/smart-fill-service";
import { useAuth } from "../../../../auth-context/auth.context";
import { validateRole } from "../../../../libraries/utils";
import { AccountType } from "../../../../config/constant";

const TABLE_COLUMNS = [
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'INVESTOR',
    accessor: 'client',
  },
  {
    Header: 'ADVISOR',
    accessor: 'advisor',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    Header: 'LAST MODIFIED',
    accessor: 'lastModified',
  },
  {
    Header: 'OTHER',
    accessor: 'other',
  },
];

export const colorCircle = (color) => {
  return (
    <Box
      bg={ color }
      borderRadius="50%"
      minW="20px"
      width="20px"
      height="20px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    />
  )
}

const DOCUMENT_STATUS_OPTIONS = [
  {
    label: "ALL", value: "ALL", Icon: () => colorCircle('brand.500'), color: 'brand'
  },
  {
    label: "COMPLETED", value: "completed", Icon: () => colorCircle('green.500'), color: 'green'
  },
  {
    label: "IN PROGRESS", value: "in_progress", Icon: () => colorCircle('blue.500'), color: 'blue'
  },
  {
    label: "WAITING FOR CLIENT",
    value: "waiting_for_investor",
    Icon: () => colorCircle('orange.500'),
    color: 'orange'
  }
];

function getLabelAndColor(value) {

  // Iterate through the DOCUMENT_STATUS_OPTIONS to find the item with the given value
  const item = DOCUMENT_STATUS_OPTIONS.find(option => option.value === value);

  // If an item is found, get the label and color using the Icon function
  if (item) {
    const label = item.label;
    const color = item.color;

    return { label, color };
  } else {
    return null;
  }
}

const { Option } = components;

function IconOption(props) {
  const {
    data: { label, Icon },
  } = props;

  return (
    <Option { ...props }>
      <HStack>
        { Icon && <Icon/> }
        <Text fontSize='sm' fontWeight='500'>{ label }</Text>
      </HStack>
    </Option>
  );
}

export default function DocumentTable(props) {
  const {
    isLoading,
    documents,
    filter,
    setFilter,
    setCurrentPage,
    pagesCount,
    currentPage,
    pages,
    onSchwabOpen,
    updateStatus
  } = props;

  const { user } = useAuth();
  const { colorMode } = useColorMode();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const bgPagination = useColorModeValue('brand.500', 'brand.400');
  const bgFilter = useColorModeValue('gray.100', 'whiteAlpha.100');

  const [downloadingStatus, setDownloadingStatus] = useState({});

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    })
  }

  const downloadUserForm = (userFormId) => {
    setDownloadingStatus((prevStatus) => ({
      ...prevStatus,
      [userFormId]: true,
    }));
    downloadFilledUserForm(userFormId)
      .then(({ fileUrl }) => {
        window.open(fileUrl)
      })
      .catch(err => {
        console.error(err.message);
      })
      .finally(() => {
        setDownloadingStatus((prevStatus) => ({
          ...prevStatus,
          [userFormId]: false,
        }));
      })
  }

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={ { sm: 'scroll', lg: 'hidden' } }
    >

      <Box px="25px" bg={ bgFilter } pb="12px">
        <Flex mb="10px" direction={ { sm: 'column', lg: 'row' } } gap={ 4 }>
          <FormControl maxW={ { sm: 'auto', lg: '350px' } }>
            <FormLabel>Status</FormLabel>
            <Select
              value={ filter.DOCUMENT_STATUS }
              styles={ selectCustomStyles(colorMode) }
              options={ DOCUMENT_STATUS_OPTIONS }
              onChange={ (column) => {
                handleFilterChange('DOCUMENT_STATUS', column)
              } }
              components={ {
                Option: IconOption
              } }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Find a document</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                zIndex={ 0 }
                children={ <SearchIcon color="gray.300"/> }
              />
              <Input
                type="input"
                color={ textColor }
                placeholder="Filter by form name..."
                value={ filter.SEARCH }
                onChange={ e => handleFilterChange('SEARCH', e.target.value) }
              />
            </InputGroup>
          </FormControl>
        </Flex>
      </Box>
      <Divider mb="12px"/>

      <Table variant="simple" color="gray.500">
        <Thead>
          <Tr>
            { TABLE_COLUMNS.map((column, index) => (
              <Th pe="10px" key={ index } borderColor={ borderColor }>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={ {
                    sm: '10px',
                    lg: '12px',
                  } }
                  color="gray.400"
                >
                  { column.Header }
                </Flex>
              </Th>
            )) }
          </Tr>
        </Thead>
        <Tbody>
          { isLoading ? (
            <>
              { [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                <Tr key={ item }>
                  <Td colSpan={ 8 } p={ 2 }>
                    <Skeleton h="20px"></Skeleton>
                  </Td>
                </Tr>
              )) }
            </>
          ) : (
            <>
              { documents
                .map((item, index) => {
                  return (
                    <>
                      <Tr
                        key={ index }
                        _hover={ { background: '#dddddd55' } }
                      >
                        <Td
                          fontSize='md'
                          color={ textColor }>
                          <Text>
                            { item?.form?.formName }
                          </Text>
                        </Td>
                        <Td
                          fontSize='sm'
                          color={ textColor }>
                          <Box>
                            <Text>
                              { `${ item?.assignedUser?.firstName } ${ item?.assignedUser?.lastName }` }
                            </Text>
                            <Text>
                              { item?.assignedUser?.email }
                            </Text>
                          </Box>
                        </Td>
                        <Td
                          fontSize='sm'
                          color={ textColor }>
                          <Box>
                            <Text>
                              { `${ item?.user?.firstName } ${ item?.user?.lastName }` }
                            </Text>
                            <Text>
                              { item?.user?.email }
                            </Text>
                          </Box>
                        </Td>
                        <Td
                        >
                          <Popover>
                            { ({ onClose }) => (
                              <>
                                {
                                  validateRole(user?.token) === AccountType.CLIENT ? <PopoverTrigger>
                                    <Text
                                      cursor={ validateRole(user?.token) === AccountType.CLIENT ? 'pointer' : 'default' }
                                      color={ textColor }
                                      fontSize="sm"
                                      fontWeight="600"
                                    >
                                      <Badge
                                        colorScheme={ getLabelAndColor(item?.status).color }>
                                        { getLabelAndColor(item?.status).label }
                                      </Badge>
                                    </Text>
                                  </PopoverTrigger> : <Text
                                    color={ textColor }
                                    fontSize="sm"
                                    fontWeight="600"
                                  >
                                    <Badge
                                      colorScheme={ getLabelAndColor(item?.status).color }>
                                      { getLabelAndColor(item?.status).label }
                                    </Badge>
                                  </Text>
                                }
                                <Portal>
                                  <PopoverContent>
                                    <PopoverHeader>
                                      <Box display='flex' marginBottom='8px'>
                                        <CheckCircleIcon color="brand.500"
                                                         marginRight="4px"
                                                         marginTop="4px"/>
                                        <Box>Are you sure to change <b>status</b> as
                                          <Badge
                                            colorScheme={ getLabelAndColor(item?.status === 'in_progress' ? 'completed' : 'in_progress').color }>
                                            { getLabelAndColor(item?.status === 'in_progress' ? 'completed' : 'in_progress').label }
                                          </Badge> ?
                                        </Box>
                                      </Box>
                                      <Box display='flex'
                                           justifyContent='flex-end'>
                                        <Button size='xs' margin="4px"
                                                width="50px"
                                                onClick={ onClose }>No</Button>
                                        <Button size='xs' margin="4px"
                                                width="50px" colorScheme='red'
                                                onClick={ () => {
                                                  updateStatus(item?.status === 'in_progress' ? 'completed' : 'in_progress', item?.userFormId)
                                                  onClose();
                                                } }>Yes</Button>
                                      </Box>
                                    </PopoverHeader>
                                  </PopoverContent>
                                </Portal>
                              </>
                            ) }
                          </Popover>
                        </Td>
                        <Td
                          fontSize={ {
                            sm: '14px',
                          } }
                          color={ textColor }>
                          { moment(item?.lastModified).format('YYYY.MM.DD HH:mm:ss') }
                        </Td>
                        <Td>
                          <HStack>
                            <Button
                              title='download'
                              variant="ghost"
                              isLoading={ downloadingStatus[item?.userFormId] }
                              isDisabled={ downloadingStatus[item?.userFormId] }
                              onClick={ () => downloadUserForm(item?.userFormId) }
                            >
                              <DownloadIcon
                                color='blue.400'
                                cursor='pointer'/>
                            </Button>
                            <Button
                              onClick={ () => onSchwabOpen(item.userFormId) }
                              title='edit'
                              variant="ghost">
                              <EditIcon
                                color='orange.400'
                                cursor='pointer'/>
                            </Button>
                            <Popover>
                              { ({ onClose }) => (
                                <>
                                  <PopoverTrigger>
                                    <Button
                                      title='delete'
                                      variant="ghost">
                                      <DeleteIcon
                                        color='red.400'
                                        cursor='pointer'/>
                                    </Button>
                                  </PopoverTrigger>
                                  <Portal>
                                    <PopoverContent width="270px">
                                      <PopoverHeader>
                                        <Box display='flex' marginBottom='8px'>
                                          <WarningIcon color="orange"
                                                       marginRight="4px"
                                                       marginTop="4px"/>
                                          <Box>Are you sure to
                                            delete <b>{ item?.form?.formName }</b>?</Box>
                                        </Box>
                                        <Box display='flex'
                                             justifyContent='flex-end'>
                                          <Button size='xs' margin="4px"
                                                  width="50px"
                                                  onClick={ onClose }>No</Button>
                                          <Button size='xs' margin="4px"
                                                  width="50px" colorScheme='red'
                                                  onClick={ () => {
                                                  } }>Yes</Button>
                                        </Box>
                                      </PopoverHeader>
                                    </PopoverContent>
                                  </Portal>
                                </>
                              ) }
                            </Popover>
                          </HStack>
                        </Td>
                      </Tr>
                    </>
                  );
                }) }

              { documents.length === 0 && (
                <Tr>
                  <Td colSpan={ 8 } p={ 2 }>
                    <Text textAlign="center">There is no document!</Text>
                  </Td>
                </Tr>
              ) }
            </>
          ) }
        </Tbody>
      </Table>
      <Pagination
        pagesCount={ pagesCount }
        currentPage={ currentPage }
        onPageChange={ setCurrentPage }
      >
        <PaginationContainer
          align="center"
          justify="center"
          p={ 4 }
          gap={ 2 }
          w="full"
        >
          <PaginationPrevious
            _hover={ {
              bg: mode('brand.600', 'brand.400'),
            } }
            bg={ bgPagination }
          > Prev
          </PaginationPrevious>
          <PaginationPageGroup
            isInline
            align="center"
            separator={
              <PaginationSeparator
                bg="transparent"
                borderColor="brand.100"
                borderWidth="1px"
                fontSize="sm"
                w={ 10 }
                jumpSize={ 5 }
              />
            }
          >
            { pages.map((page) => (
              <PaginationPage
                key={ `pagination_page_${ page }` }
                page={ page }
                w={ 10 }
                bg="transparent"
                borderColor="brand.100"
                borderWidth="1px"
                fontSize="sm"
                _hover={ {
                  bg: 'brand.600'
                } }
                _current={ {
                  bg: 'brand.600',
                  fontSize: 'sm',
                  w: 10
                } }
              />
            )) }
          </PaginationPageGroup>
          <PaginationNext
            _hover={ {
              bg: mode('brand.600', 'brand.400'),
            } }
            bg={ bgPagination }
          >Next</PaginationNext>
        </PaginationContainer>
      </Pagination>
    </Card>
  );
}
