import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, } from '@chakra-ui/react';
import React from 'react';
import moment from "moment";
import { convertFriendlyName } from "../../../../libraries/utils";

const TABLE_COLUMNS = [
  {
    Header: 'NAME',
    accessor: 'firstName',
  },
  {
    Header: 'DATE OF BIRTH',
    accessor: 'dateOfBirthStr',
  },
  {
    Header: 'AGE',
    accessor: 'age',
  },
  {
    Header: 'DRIVERS LICENSE',
    accessor: 'driversLicense',
  },
  {
    Header: 'RELATIONSHIP',
    accessor: 'relationshipToInsured',
  },
  {
    Header: 'MARITAL STATUS',
    accessor: 'maritalStatus',
  },
];

function getAge(birth) {
  if (!birth) return '';
  const birthDate = moment(birth);
  const today = moment();
  return today.diff(birthDate, 'years')
}

export default function Driver(props) {
  const {
    drivers
  } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <Box mt='32px'>
      <Text
        color={ textColor }
        fontWeight='bold'
        fontSize='lg'
      >
        Drivers
      </Text>
      <Box mt='8px'>
        <Table variant="simple" color="gray.500">
          <Thead>
            <Tr>
              { TABLE_COLUMNS.map((column, index) => (
                <Th pe="10px" key={ index } borderColor={ borderColor }>
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={ {
                      sm: '10px',
                      lg: '12px',
                    } }
                    color="gray.400"
                  >
                    { column.Header }
                  </Flex>
                </Th>
              )) }
            </Tr>
          </Thead>
          <Tbody>
            {
              drivers && drivers.map((driver, index) => {
                return (
                  <Tr key={ index }
                      color={ textColor }
                      fontSize="sm">
                    <Td>
                      { `${ driver['firstName'] } ${ driver['lastName'] } (${ driver['gender'].charAt(0) })` }
                    </Td>
                    <Td>
                      { moment(driver['dateOfBirthStr']).format('MMM DD, YYYY') }
                    </Td>
                    <Td>
                      { driver['age'] || getAge(driver['dateOfBirthStr']) }
                    </Td>
                    <Td>
                      { driver['driversLicense'] || '' } ({ driver['driversLicenseState'] || '' })
                    </Td>
                    <Td>
                      { convertFriendlyName(driver['relationshipToInsured'] || '') }
                    </Td>
                    <Td>
                      { convertFriendlyName(driver['maritalStatus'] || '') }
                    </Td>
                  </Tr>
                )
              })
            }
            { (!drivers || drivers.length === 0) && (
              <Tr>
                <Td colSpan={ 8 } p={ 2 }>
                  <Text textAlign="center">There is no driver data!</Text>
                </Td>
              </Tr>
            ) }
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
