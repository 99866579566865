// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverHeader,
  Portal,
  Progress,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import React from 'react';
// Assets
import { MdCheckCircleOutline, MdDownloading, MdLogin, MdOutlineErrorOutline } from 'react-icons/md';
import { WarningIcon } from '@chakra-ui/icons';
import { PopoverTrigger } from '../../dataTables/components/ClientTable';
import { IoMdRemoveCircleOutline } from 'react-icons/io';

export default function Account(props) {
  const {
    institutionId,
    institutionName,
    items,
    branding,
    onStartSync,
    onRemoveItem,
    isDeleting,
    connectFixFinicity,
    isSyncing,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const brandColor = useColorModeValue(branding?.primaryColor || 'secondaryGray.900', 'white');
  const bg = useColorModeValue('white', 'navy.700');
  const bgItem = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

  return (
    <Card bg={ bg } { ...rest } p="14px">
      <Flex align="start">
        <Image h="40px" w="40px" src={ branding?.icon || branding?.logo } borderRadius="8px" me="20px"/>
        <Box mt={ { base: '10px', md: '0' } }
             w="100%">
          {
            items.map(({ itemId, accounts, historicalSyncStatus, hasError }) => (
              <Box>
                <Flex justify="space-between" align="center" marginBottom="8px">
                  <Text
                    color={ brandColor }
                    fontSize="lg"
                    fontWeight="semibold"
                    mb="4px">
                    { institutionName }
                  </Text>
                  {
                    accounts.some(account => account.aggregationStatusCode !== null && account.aggregationStatusCode !== 0) ?
                      <Button
                        isLoading={ isSyncing }
                        fontSize="md"
                        fontWeight="500"
                        colorScheme="red"
                        color="white"
                        minW="100px"
                        disabled={ hasError || historicalSyncStatus === 'running' }
                        onClick={ () => connectFixFinicity(itemId) }
                      >
                        Re-AUTH
                      </Button> :
                      null
                    // <Button
                    //   isLoading={ isSyncing }
                    //   fontSize="md"
                    //   fontWeight="500"
                    //   colorScheme="green"
                    //   color="white"
                    //   minW="100px"
                    //   disabled={ hasError || historicalSyncStatus === 'running' }
                    //   onClick={ () => onStartSync(institutionId, itemId) }
                    // >
                    //   Sync Now
                    // </Button>
                  }
                </Flex>
                <Flex
                  justify="space-between"
                  mb="10px"
                  p="10px"
                  key={ itemId }
                  bg={ bgItem }>
                  <Box w='100%'>
                    {
                      accounts.map(account => (
                        <Flex align="center" marginBottom="4px">
                          <Text
                            key={ account.accountId }
                            fontWeight="500"
                            color={ textColorPrimary }
                            fontSize="sm"
                            me="4px">
                            { `${ account.accountName } (**** **** **** ${ account.accountNumberDisplay })` }
                          </Text>
                          {
                            account.aggregationStatusCode === null ?
                              <Tooltip label="Account is not ready to sync, please wait" hasArrow placement="top">
                                <Box border="2px solid"
                                     borderColor="orange"
                                     borderRadius="4px"
                                     px="2px"
                                >
                                  <Text color="orange" fontSize="xs">Pending</Text>
                                </Box>
                              </Tooltip> : account.aggregationStatusCode !== 0 ?
                                <Tooltip label="There is an issue with your account, Re-authentication is required."
                                         hasArrow placement="top">
                                  <Box border="2px solid"
                                       borderColor="red"
                                       borderRadius="4px"
                                       px="2px"
                                  >
                                    <Text color="red" fontSize="xs">ERROR</Text>
                                  </Box>
                                </Tooltip> : null
                          }
                        </Flex>
                      ))
                    }
                    {
                      hasError ? <Flex
                          w="100%"
                          align="center"
                          gap={ 1 }
                          me="4px"
                          cursor="pointer"
                          color="red.500"
                          onClick={ () => console.log('clicked') }
                        >
                          <Icon
                            as={ MdLogin }
                            w="18px"
                            h="18px"
                          />
                          <Text
                            fontWeight="500"
                            fontSize="sm"
                            me="4px">
                            RE-AUTHENTICATION REQUIRED
                          </Text>
                        </Flex>
                        : historicalSyncStatus === 'waiting_historical_hook' ? <Flex
                          align="center"
                          gap={ 1 }
                          color="brand.500"
                          me="4px"
                        >
                          <Icon
                            as={ MdDownloading }
                            w="18px"
                            h="18px"
                          />
                          <Text
                            fontWeight="500"
                            fontSize="sm">
                            NOT SYNCED YET
                          </Text>
                        </Flex> : historicalSyncStatus === 'running' ? <Box
                          width="100%"
                          zIndex="1"
                        >
                          <Progress colorScheme="brand" size="sm" w="100%" isIndeterminate/>
                        </Box> : historicalSyncStatus === 'complete' ? <Flex
                          align="center"
                          gap={ 1 }
                          color="green.500"
                          me="4px"
                        >
                          <Icon
                            as={ MdCheckCircleOutline }
                            w="18px"
                            h="18px"
                          />
                          <Text
                            fontWeight="500"
                            fontSize="sm">
                            SYNC COMPLETED
                          </Text>
                        </Flex> : historicalSyncStatus === 'failed' ? <Flex
                          align="center"
                          gap={ 1 }
                          color="brand.500"
                          me="4px"
                        >
                          <Icon
                            as={ MdOutlineErrorOutline }
                            w="18px"
                            h="18px"
                          />
                          <Text
                            fontWeight="500"
                            fontSize="sm">
                            SYNC FAILED
                          </Text>
                        </Flex> : null
                    }
                  </Box>
                  <Popover placement="bottom-start">
                    { ({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <IconButton
                            bg="inherit"
                            ms="auto"
                            borderRadius="inherit"
                            _hover="none"
                            _active={ {
                              bg: 'inherit',
                              transform: 'none',
                              borderColor: 'transparent',
                            } }
                            _focus={ {
                              boxShadow: 'none',
                            } }
                            disabled={ isDeleting || historicalSyncStatus === 'running' }
                            icon={
                              <IoMdRemoveCircleOutline color="secondaryGray.500" w="20px" h="20px"/>
                            }></IconButton>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent width="270px">
                            <PopoverHeader>
                              <Box display="flex" marginBottom="8px">
                                <WarningIcon color="orange" marginRight="4px" marginTop="4px"/>
                                <Box>Are you sure to remove this account?</Box>
                              </Box>
                              <Box display="flex" justifyContent="flex-end">
                                <Button size="xs" margin="4px" width="50px" onClick={ onClose }>No</Button>
                                <Button size="xs" margin="4px" width="50px" colorScheme="red"
                                        onClick={ () => {
                                          onRemoveItem(itemId);
                                          onClose();
                                        } }>Yes</Button>
                              </Box>
                            </PopoverHeader>
                          </PopoverContent>
                        </Portal>
                      </>
                    ) }
                  </Popover>
                </Flex>
              </Box>
            ))
          }
        </Box>
      </Flex>
    </Card>
  );
}
