import { mode } from "@chakra-ui/theme-tools";
import {Select} from "chakra-react-select";
export const selectStyles = {
  components: {
    Select: {
      // baseStyle: {
      //   thumb: {
      //     fontWeight: 400,
      //   },
      //   track: {
      //     display: "flex",
      //   },
      // },

      variants: {
        main: (props) => ({
          thumb: {
            bg: mode("brand.500", "brand.400")(props),
          },
        }),
      },
    },
  },
};
