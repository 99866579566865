import axios from "./index";

class UserApi {
  static GetUsers = (data: any) => {
    return axios.get(`${base}?${data}`);
  };

  static CreateUser = (data: any) => {
    return axios.post(`${base}`, data);
  };

  static EnableUser = (data: any) => {
    return axios.put(`${base}/enable`, data);
  };

  static DisableUser = (data: any) => {
    return axios.put(`${base}/disable`, data);
  };

  static DeleteUser = (data: any) => {
    return axios.delete(`${base}`, { data: data });
  };

  static GetClients = (email: string) => {
    return axios.post(`${base}/clients`, { email });
  };

  static GetActiveUserCount = () => {
    return axios.post(`${base}/client/count`);
  };

  static GetInvitations = () => {
    return axios.get(`${base}/invitations`);
  };

  static GetInvitationById = (customerId: number) => {
    return axios.get(`${base}/invitation/${customerId}`);
  };

  static AddInvitation = (data: any) => {
    return axios.post(`${base}/invitation`, data);
  };

  static RemoveInvitation = (data: any) => {
    return axios.delete(`${base}/invitation`, {
      data,
    });
  };

  static GetBookkeepers = () => {
    return axios.get(`${base}/bookkeepers`);
  };

  static GetDisconnectedBankAccounts = (email?: string) => {
    return axios.post(`${base}/banks/disconnected`, {
      email,
    });
  };

  static GetNewClients = (email: string) => {
    return axios.post(`${base}/clients/recent`, { email });
  };

  static UpdateClientStatus = (clientId: number, status: string) => {
    return axios.put(`${base}/client/status`, { customerId: clientId, status });
  };
}

let base = "user";

export default UserApi;
