import React from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const wbImageStep1 = require('../../../../assets/img/financials/wealthbox/step-1.png');
const wbImageStep2 = require('../../../../assets/img/financials/wealthbox/step-2.png');
const wbImageStep3 = require('../../../../assets/img/financials/wealthbox/step-3.png');

interface UserAddModalProps {
  isConnecting: boolean,
  isOpen: boolean,
  onClose: () => void,
  onLogin: () => Promise<void>
}

const WealthboxLoginConfirmModal: React.FC<UserAddModalProps> = ({isConnecting, isOpen, onClose, onLogin}) => {

  const wbImages = [
    wbImageStep1,
    wbImageStep2,
    wbImageStep3
  ]

  const login = async () => {
    await onLogin();
    handleClose();
  }

  const handleClose = () => {
    onClose();
  }

  return (
    <Modal isOpen={ isOpen } onClose={ () => handleClose() } size="xl" isCentered>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>
          <HStack>
            <Image src={ require('../../../../assets/img/financials/wealthbox-icon.png') } h="32px"
                   borderRadius="full"/>
            <Text>Connect Smartdata to Wealthbox</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Text fontSize="sm" mt="8px">
            1. As soon as your Wealthbox account is connected, Smartdata will initiate auto pulling
            your <span style={ {fontWeight: 500} }>Prospect</span>s and <span style={ {fontWeight: 500} }>Client</span>s
            from your contact list.
          </Text>
          <Text fontSize="sm" mt="8px">
            2. To get invited by Smartdata, your contacts should have <span
            style={ {fontWeight: 600} }>smartdata</span> (lowercase) tag. - Under Contacts, click Manage Tags to
            add <span
            style={ {fontWeight: 600} }>smartdata</span> to your project or client.
          </Text>
          <Text fontSize="sm" mt="8px">
            3. Invites are sent within 2 hours of adding tag. Just add the <span
            style={ {fontWeight: 600} }>smartdata</span> tag, we will send invites.
          </Text>
          <Text fontSize="sm" mt="8px" fontWeight='500'>
            How to add <span style={ {fontWeight: 600} }>smartdata</span> tag?
          </Text>
          <PhotoProvider>
            <Flex direction={ {base: 'column', md: 'row'} } justify='center' align='center' gap={ 2 } p={ 2 }>
              { wbImages.map((item, index) => (
                <PhotoView key={ index } src={ item }>
                  <Box borderWidth="2px" borderColor="brand.400" borderRadius='2px'>
                    <Image cursor="pointer" src={ item } width='140px'/>
                  </Box>
                </PhotoView>
              )) }
            </Flex>
          </PhotoProvider>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brandScheme" mr={ 3 }
                  disabled={ isConnecting }
                  onClick={ () => login() }>{ isConnecting ? 'Connecting...' : 'Connect' }</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={ () => handleClose() }>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default WealthboxLoginConfirmModal;
