import apiRequest from './api';
import { BASE_API_URL } from '../config/constant';


export const getSmartFillForms = () => {
  return apiRequest('get', `${ BASE_API_URL }/smart-fill/forms`).then(res => res.data);
};

export const getUserForms = (limit: number = 20, offset: number = 0, filter: any) => {
  return apiRequest('post', `${ BASE_API_URL }/smart-fill/documents`, {
    body: {
      limit,
      offset,
      filter,
    }
  }).then(res => res.data);
};

export const createUserForm = (formId: number, clientId: number) => {
  return apiRequest('post', `${ BASE_API_URL }/smart-fill/form-documents`, {
    body: {
      formId,
      clientId
    }
  }).then(res => res.data);
};

export const getFormFieldValues = (userFormId: number) => {
  return apiRequest('get', `${ BASE_API_URL }/smart-fill/forms/values/${ userFormId }`).then(res => res.data);
};

export const updateFormFieldValues = (data: any, userFormId: number) => {
  return apiRequest('put', `${ BASE_API_URL }/smart-fill/forms/values/${ userFormId }`, {
    body: {
      data
    }
  }).then(res => res.data);
};

export const getOriginalFormTemplate = (userFormId: number) => {
  return apiRequest('get', `${ BASE_API_URL }/smart-fill/user-forms/${ userFormId }/original-pdf`).then(res => res.data);
};

export const downloadFilledUserForm = (userFormId: number) => {
  return apiRequest('get', `${ BASE_API_URL }/smart-fill/forms/download/${ userFormId }`).then(res => res.data);
};

export const updateUserFormStatus = (status: 'in_progress' | 'completed' | 'waiting_for_advisor' | 'waiting_for_investor' | 'approved' | 'rejected', userFormId: number) => {
  return apiRequest('post', `${ BASE_API_URL }/smart-fill/user-forms/${ userFormId }/status`, {
    body: {
      status
    }
  }).then(res => res.data);
};