import apiRequest from './api';
import { BASE_API_URL } from '../config/constant';

export const getAllTransactions = (limit: number = 20, offset: number = 0, filter: any) =>
	apiRequest('post', `${BASE_API_URL}/transactions`, {
		body: {
			limit,
			offset,
			filter,
		},
	}).then((res) => res.data);

export const getAllTransactionsAsAdvisor = (limit: number = 20, offset: number = 0, filter: any, clientId: number | null = null) =>
	apiRequest('post', `${BASE_API_URL}/transactions/as-advisor`, {
		body: {
			limit,
			offset,
			filter,
			clientId
		},
	}).then((res) => res.data);

export const getInvestmentTypes = () => {
	return apiRequest('get', `${BASE_API_URL}/transactions/investment_transaction_type`).then((res) => res.data);
};

export const getInvestmentTypesAsAdvisor = (clientId: number | null = null) => {
	return apiRequest('post', `${BASE_API_URL}/transactions/investment_transaction_type/as-advisor`, {
		body: {
			clientId,
		}
	}).then((res) => res.data);
};