// Chakra imports
import React from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
  VStack,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import FixedPlugin from '../../components/fixedPlugin/FixedPlugin';
import {
  AmeriPrise,
  BankOfAmerica,
  CharlesSchewab,
  EdwardJones,
  Lpl,
  Merrill,
  MorganStanley,
  NetXInvestor,
  RaymondJames,
  Ubs
} from '../../components/icons/Icons';
import { useHistory } from "react-router-dom";

// Assets

interface AuthIllustrationProps {
  illustrationBackground: string;
  image: string;
  children?: React.ReactNode;
}

const AuthIllustration = ({children}: AuthIllustrationProps) => {
  const history = useHistory();
  const logo = useColorModeValue(
    require("../../assets/img/logo.png"),
    require("../../assets/img/logo-white.png")
  );

  const tmImage = useColorModeValue(require('../../assets/img/TM-black.png'), require('../../assets/img/TM-white.png'));
  const bgButton = useColorModeValue('secondaryGray.300', 'navy.700');

  // Chakra color mode
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  return (
    <Flex position="relative" h="max-content" direction="column">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset'
        }}
        w="100%"
        mx="auto"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <VStack w="fit-content" marginTop="40px" marginBottom="12px">
          <Image
            src={logo}
            w="400px"
            mt="40px"
            mb="20px"
          />
          <Text color={textColorDetails} fontWeight="400" fontSize="xl" textAlign="center" maxW={{base: '100%', md: 'md'}}>
            Give us 60 seconds we could save 6 years off your retirement{' '}
            <Image
              display="inline"
              boxSize="18px"
              src={tmImage}
            />
          </Text>
        </VStack>
        {children}
      </Flex>
      <Flex mb="20px" overflowX="auto" gap="8px" zIndex={10}>
        <RaymondJames w={{base: '160px', md: '200px'}} h="auto"/>
        {/*<Image src={ require('../../assets/img/insurance/state-farm.png') } w={ {base: '160px', md: '200px'} } h="auto"/>*/}
        <EdwardJones w={{base: '160px', md: '200px'}} h="auto"/>
        {/*<Image src={ require('../../assets/img/insurance/famers-insurance.png') } w={ {base: '160px', md: '200px'} } h="auto"/>*/}
        <MorganStanley w={{base: '160px', md: '200px'}} h="auto"/>
        {/*<Image src={ require('../../assets/img/insurance/american-family-insurance.png') } w={ {base: '160px', md: '200px'} } h="auto"/>*/}
        <NetXInvestor w={{base: '160px', md: '200px'}} h="auto"/>
        {/*<Image src={ require('../../assets/img/insurance/american-family-insurance-blue.png') } w={ {base: '160px', md: '200px'} } h="auto"/>*/}
        <Image src={require('../../assets/img/financials/fidelity.png')} w={{base: '160px', md: '200px'}}
               h="auto"/>
        {/*<Image src={ require('../../assets/img/insurance/allstate.png') } w={ {base: '160px', md: '200px'} } h="auto"/>*/}
        <Image src={require('../../assets/img/financials/chase.png')} w={{base: '160px', md: '200px'}}
               h="auto"/>
        {/*<Image src={ require('../../assets/img/insurance/progressive.png') } w={ {base: '160px', md: '200px'} } h="auto"/>*/}
        <Merrill w={{base: '160px', md: '200px'}} h="auto"/>
        {/*<Image src={ require('../../assets/img/insurance/nationwide.png') } w={ {base: '160px', md: '200px'} } h="auto"/>*/}
        <BankOfAmerica w={{base: '160px', md: '200px'}} h="auto"/>
        {/*<Image src={ require('../../assets/img/insurance/usaa.png') } w={ {base: '160px', md: '200px'} } h="auto"/>*/}
        <Lpl w={{base: '160px', md: '200px'}} h="auto"/>
        {/*<Image src={ require('../../assets/img/insurance/geico.png') } w={ {base: '160px', md: '200px'} } h="auto"/>*/}
        <Image src={require('../../assets/img/financials/goldman-sachs.png')}
               w={{base: '160px', md: '200px'}}
               h="auto"/>
        <Ubs w={{base: '160px', md: '200px'}} h="auto"/>
        {/*<Image src={ require('../../assets/img/insurance/northwestern-mutual.png') } w={ {base: '160px', md: '200px'} } h="auto"/>*/}
        <AmeriPrise w={{base: '160px', md: '200px'}} h="auto"/>
        <CharlesSchewab w={{base: '160px', md: '200px'}} h="auto"/>
      </Flex>
      <Button
        zIndex='99'
        position='fixed'
        variant='outline'
        colorScheme='brand'
        top='32px'
        right='32px'
        border='1px solid'
        borderColor='brand.500'
        bg={bgButton}
        onClick={() => history.push('/')}
      >
        Main Page
      </Button>
      <FixedPlugin/>
    </Flex>
  );
}
// PROPS

export default AuthIllustration;