import apiRequest from './api';
import { BASE_API_URL, GENERAL_API_URL } from "../config/constant";

export const createAdvisor = (advisor: {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  fullName: string,
  password: string,
  accountType: 'advisor',
  plan: 'free_monthly' | 'basic_monthly'
}) => apiRequest('post', `${BASE_API_URL}/users/advisor/signup-intent`, {
  body: advisor
}).then((res) => res.data);

export const getAllUsers = (limit: number = 20, offset: number = 0, query: string = '') =>
  apiRequest('get', `${BASE_API_URL}/users`, {
    queryStringParameters: {
      limit,
      offset,
      query: query,
    },
  }).then((res) => res.data);

// Get client detail by userId
export const getUserClientByEmail = (email: string) => apiRequest('post', `${BASE_API_URL}/users/client-by-email`, {
  body: { email }
}).then((res) => res.data);

export const updateUser = (user: any, userId: any) =>
  apiRequest('put', `${BASE_API_URL}/users/${userId}`, {
    body: { user },
  }).then((res) => res.data);

export const deleteUser = (userId: string) =>
  apiRequest('del', `${BASE_API_URL}/users/${userId}`).then((res) => res.data);

export const addClient = (client: {
  firstName: string,
  middleName: string,
  lastName: string,
  nickName: string,
  birthday: string,
  receiverEmail: string,
  phoneNumber: string,
  userRole: string,
  management: string,
  addressLine1: string,
  city: string,
  state: string,
  postalCode: string,
  companyName: string,
  note: string,
  category: 'Finance' | 'Tax',
  reportType?: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY',
}) => apiRequest('post', `${BASE_API_URL}/invitations/clients`, {
  body: client
}).then((res) => res.data);


export const updateClient = (clientId: string, client: {
  firstName: string,
  middleName: string,
  lastName: string,
  nickName: string,
  dateOfBirth: string,
  email: string,
  phoneNumber: string,
  accountType: string,
  management: string,
  addressLine1: string,
  city: string,
  state: string,
  postalCode: string,
  fullName: string,
  note?: string,
}) => apiRequest('put', `${BASE_API_URL}/users/clients/${clientId}/as-advisor`, {
  body: client
}).then((res) => res.data);

export const updateAdvisor = (advisorId: string, advisor: {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  fullName: string,
  calendarLink: string,
}) => apiRequest('put', `${BASE_API_URL}/users/advisors/${advisorId}`, {
  body: advisor
}).then((res) => res.data);

export const getAllClients = (limit: number = 20, offset: number = 0, query: string = '') => apiRequest('get', `${BASE_API_URL}/invitations/clients`, {
  queryStringParameters: {
    limit,
    offset,
    query,
  },
}).then((res) => res.data);

export const getUserInfo = () => apiRequest('get', `${BASE_API_URL}/users/info`).then((res) => res.data);

export const getUserByIdAsAdvisor = (userId: string) =>
  apiRequest('get', `${BASE_API_URL}/users/${userId}/as-advisor`).then((res) => res.data);

export const deleteUserAsAdvisor = (userId: string) =>
  apiRequest('del', `${BASE_API_URL}/users/${userId}/as-advisor`).then((res) => res.data);

export const getImageUploadUrl = (userId: number, sub: string, type: string, category: 'avatar' | 'icon' | 'logo' | 'intro_video' = 'avatar') => apiRequest('post', `${BASE_API_URL}/users/${userId}/image/upload-url`, {
  body: {
    type,
    sub,
    category,
  }
}).then((res) => res.data);

export const removeAvatar = (userId: string, sub: string) =>
  apiRequest('del', `${BASE_API_URL}/users/${userId}/avatar/${sub}`).then((res) => res.data);

export const remindInvitation = (clientId: number, category: 'Finance' | 'Tax' = 'Finance', reportType?: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY') => apiRequest('post', `${BASE_API_URL}/invitations/clients/${clientId}/remind`, {
  body: {
    category,
    reportType,
  }
}).then((res) => res.data);

export const remindInvitationAdvisor = (advisorId: number) => apiRequest('post', `${BASE_API_URL}/invitations/advisors/${advisorId}/remind`).then((res) => res.data);

export const connectRedtail = (userName: string, password: string) => apiRequest('post', `${BASE_API_URL}/redtail/login/`, {
  body: {
    username: userName,
    password
  }
}).then((res) => res.data);

export const getAdvisorsByFirmId = (firmId: string, limit: number = 20, offset: number = 0, query: string = '') =>
  apiRequest('get', `${BASE_API_URL}/firms/${firmId}/advisors/list`, {
    queryStringParameters: {
      limit,
      offset,
      query,
    },
  }).then((res) => res.data);

export const getAllAdvisorsByFirmId = (firmId: string) =>
  apiRequest('get', `${BASE_API_URL}/firms/${firmId}/advisors`).then((res) => res.data);

export const addAdvisor = (firmId: number, advisor: {
  firstName: string,
  lastName: string,
  receiverEmail: string,
  phoneNumber?: string,
  companyName: string,
  note?: string,
}) => apiRequest('post', `${BASE_API_URL}/firms/${firmId}/advisor/invite`, {
  body: advisor
}).then((res) => res.data);

export const isValidLinkSlug = (slug: string) =>
  apiRequest('get', `${BASE_API_URL}/smartlead/valid-slug?slug=${slug}`).then((res) => res.data);

export const generateQrCode = (label: string, slug: string, eyeColor: string, eyeBallColor: string) =>
  apiRequest('post', `${BASE_API_URL}/smartlead/generate-qrcode`, {
    body: {
      label,
      slug,
      eyeColor,
      eyeBallColor,
    }
  }).then((res) => res.data);

export const getSmartleadInfo = () =>
  apiRequest('get', `${BASE_API_URL}/smartlead/info`).then((res) => res.data);

export const updateBrandColor = (firmId: string, brandColor: string) =>
  apiRequest('put', `${BASE_API_URL}/firms/brand-color`, {
    body: {
      firmId,
      brandColor,
    }
  }).then((res) => res.data);

export const downLoadQrCode = () =>
  apiRequest('get', `${BASE_API_URL}/smartlead/download-qrcode`).then((res) => res.data);

export const sendMessage = (name: string, email: string, message: string) =>
  apiRequest('post', `${GENERAL_API_URL}/contact-us`, {
    body: {
      name,
      email,
      message
    }
  }).then((res) => res.data);

export const transferAccess = (clientId: any, fromAdvisorId: number, toAdvisorId: number) =>
  apiRequest('post', `${BASE_API_URL}/users/transfer-access/clients/${clientId}`, {
    body: {
      fromAdvisorId,
      toAdvisorId,
    }
  }).then((res) => res.data);

export const deleteAdvisor = (advisorId: string, toAdvisorId: number) =>
  apiRequest('del', `${BASE_API_URL}/users/advisors/${advisorId}`, {
    body: {
      toAdvisorId,
    }
  }).then((res) => res.data);

export const resetPasswordRequest = (userId: number, isAutoGenerated: boolean, password?: string, askToChange?: boolean) => apiRequest('post', `${BASE_API_URL}/users/${userId}/reset-password`, {
  body: {
    isAutoGenerated,
    password,
    askToChange,
  }
}).then((res) => res.data);

export const setSmarttaxStatus = (status: 'ON' | 'OFF') => apiRequest('get', `${BASE_API_URL}/taxstatus/config-to-advisor`, {
  queryStringParameters: {
    status
  },
}).then((res) => res.data);

export const updateIntroVideoUrl = (advisorId: string, introVideoUrl: string) => apiRequest('put', `${BASE_API_URL}/users/advisors/${advisorId}`, {
  body: { introVideoUrl }
}).then((res) => res.data);

export const generateLoomJWS = () => apiRequest('get', `${BASE_API_URL}/users/loom/generate-token`).then((res) => res.data);

export const sendEmailWithVideo = (userId: number, subject: string, message: string, videoUrl: string) => apiRequest('post', `${BASE_API_URL}/users/${userId}/send-email-with-video`, {
  body: {
    subject,
    message,
    videoUrl
  }
}).then((res) => res.data);

export const generateAPICreds = () => apiRequest('post', `${BASE_API_URL}/users/generate-api-credentials`).then((res) => res.data);