import { mode } from "@chakra-ui/theme-tools";
export const globalStyles = {
  colors: {
    brand: {
      100: "#d4f1b9",
      200: "#b9e692",
      300: "#9eda6b",
      400: "#84cf45",
      500: "#70ad45",
      600: "#5c8b36",
      700: "#486927",
      800: "#345419",
      900: "#203000",
    },
    brandScheme: {
      100: "#d4f1b9",
      200: "#b9e692",
      300: "#9eda6b",
      400: "#84cf45",
      500: "#70ad45",
      600: "#5c8b36",
      700: "#486927",
      800: "#345419",
      900: "#203000",
    },
    brandTabs: {
      100: "#d4f1b9",
      200: "#b9e692",
      300: "#9eda6b",
      400: "#84cf45",
      500: "#70ad45",
      600: "#5c8b36",
      700: "#486927",
      800: "#345419",
      900: "#203000",
    },
    secondaryGray: {
      100: "#E0E5F2",
      200: "#E1E9F8",
      300: "#F4F7FE",
      400: "#E9EDF7",
      500: "#8F9BBA",
      600: "#A3AED0",
      700: "#707EAE",
      800: "#707EAE",
      900: "#1B2559",
    },
    red: {
      100: "#FEEFEE",
      400: '#FF4560',
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      700: "#1B254B",
      800: "#111c44",
      900: "#0b1437",
    },
    gray: {
      100: "#FAFCFE",
    },
    logo: {
      yellow: '#FFE239',
      green: '#3A9E3E',
    }
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("secondaryGray.300", "navy.900")(props),
        fontFamily: "DM Sans",
        letterSpacing: "-0.5px",
      },
      input: {
        color: "gray.700",
      },
      html: {
        fontFamily: "DM Sans",
      },
    }),
  },
};

export const selectCustomStyles = (colorMode) => {
  return {
    option: (base, state) => ({
      ...base,
      color: colorMode === 'dark' && 'white',
      backgroundColor: colorMode === 'light' ? 'white' : '#1B2559',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colorMode === 'light' ? '#E9EDF7' : '#707EAE',
      }
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided, opacity, transition,
        color: colorMode === 'light' ? '#1B2559' : 'white',
      };
    },
    control: (base) => ({
      ...base,
      backgroundColor: 'inherit',
      borderColor: 'inherit',
    }),
    input: (base) => ({
      ...base,
      color: colorMode === 'light' ? '#1B2559' : 'white',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#8F9BBA'
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: '#1B2559',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: colorMode === 'light' ? 'white' : '#1B2559',
    }),
  }
}