import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useBreakpointValue
} from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useHistory } from "react-router-dom";
import { useState } from "react";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const isMobile = useBreakpointValue({base: true, md: false});
  const history = useHistory();

  return (
    <Box>
      <Flex
        bg='transparent'
        color='white'
        minH='60px'
        py={{base: 8, md: 12}}
        px={{base: 4, md: 16, xl: 48}}
        align='center'
        justify='space-between'
      >

        {/* Logo */}
        <Flex justify={{base: 'start', md: 'start'}}>
          <Link href="/">
            <Image src={require("../../assets/img/logo.png")} alt='Logo' w={{base: '240px', md: '320px'}}/>
          </Link>
        </Flex>

        {/* Two versions of menu, for desktop and mobile */}
        {isMobile ? (
          <>
            <Menu onClose={() => setIsOpen(false)}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                transition='all 0.2s'
                bg='brand.500'
                _hover={{bg: 'brand.500'}}
                _expanded={{bg: 'brand.400'}}
                _active={{bg: 'brand.500'}}
                borderColor='brand.500'
                icon={isOpen ? <CloseIcon/> : <HamburgerIcon/>}
                variant="outline"
                onClick={handleToggle}
              ></MenuButton>
              <MenuList color='gray.700'>
                <MenuItem as='a' _hover={{color: 'brand.500'}} href='#about-us'>ABOUT US</MenuItem>
                <MenuDivider/>
                <MenuItem as='a' _hover={{color: 'brand.500'}} href='#product'>PRODUCT</MenuItem>
                <MenuDivider/>
                <MenuItem as='a' _hover={{color: 'brand.500'}} href='#pricing'>PRICING</MenuItem>
                <MenuDivider/>
                <MenuItem as='a' _hover={{color: 'brand.500'}} href='#security'>SECURITY</MenuItem>
                <MenuDivider/>
                <MenuItem as='a' _hover={{color: 'brand.500'}} href='#contact'>CONTACT US</MenuItem>
                <MenuDivider/>
                <MenuItem as='a' _hover={{color: 'brand.500'}} href='/auth/login' fontWeight='600'>LOG IN</MenuItem>
              </MenuList>
            </Menu>
          </>
        ) : (
          <Flex
            justifyContent='center'
            fontWeight='700'
            fontSize="lg"
          >
            <Link px={6} py={2} _hover={{color: 'brand.500'}} href='#about-us' style={{whiteSpace: "nowrap"}}>ABOUT US</Link>
            <Link px={6} py={2} _hover={{color: 'brand.500'}} href='#product'>PRODUCT</Link>
            <Link px={6} py={2} _hover={{color: 'brand.500'}} href='#pricing'>PRICING</Link>
            <Link px={6} py={2} _hover={{color: 'brand.500'}} href='#security'>SECURITY</Link>
            <Link px={6} py={2} _hover={{color: 'brand.500'}} href='#contact' whiteSpace='nowrap'>CONTACT US</Link>
          </Flex>
        )}

        {!isMobile && (
          <Flex
            justify={{base: 'center', md: 'end'}}
          >
            <Button
              display='inline-flex'
              fontSize='xl'
              fontWeight={700}
              textDecoration='none'
              color='gray.700'
              transition='.3s'
              lineHeight='1'
              border='2px solid white'
              size='lg'
              px='40px'
              py='28px'
              letterSpacing={.6}
              borderRadius='full'
              bg='white'
              _hover={{
                color: 'white',
                bg: 'transparent',
                border: '2px',
                borderColor: 'white',
                variant: 'outline',
              }}
              onClick={() => history.push('/auth/login')}
            >
              LOG IN
            </Button>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

export default Header;
