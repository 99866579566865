import React from "react";

// Chakra imports
import { Flex, Image, Link, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "../../separator/Separator";

const SidebarBrand: React.FC = () => {
  const logo = useColorModeValue(
    require("../../../assets/img/logo.png"),
    require("../../../assets/img/logo-white.png")
  );

  return (
    <Flex align='center' direction='column'>
      <Link href="/">
        <Image
          src={logo}
          px={2}
        />
      </Link>
      <HSeparator mt='20px' mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
