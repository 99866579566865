import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue, } from '@chakra-ui/react';
import React from 'react';
import { convertFriendlyName, numberFormat } from "../../../../../libraries/utils";
import moment from "moment";

const TABLE_COLUMNS = [
  {
    Header: 'PROPERTY',
    accessor: 'property',
  },
  {
    Header: '',
    accessor: 'property',
  },
  {
    Header: 'BUILDING',
    accessor: 'building',
  },
  {
    Header: '',
    accessor: 'property',
  },
  {
    Header: 'VALUE',
    accessor: 'value',
  },
  {
    Header: '',
    accessor: 'property',
  },
];

export default function ProperyData(props) {
  const {
    propertyData
  } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <Box mt='16px'>
      <Table variant="simple" color="gray.500">
        <Thead>
          <Tr>
            { TABLE_COLUMNS.map((column, index) => (
              <Th pe="10px" key={ index } borderColor={ borderColor }>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={ {
                    sm: '10px',
                    lg: '12px',
                  } }
                  color="gray.400"
                >
                  { column.Header }
                </Flex>
              </Th>
            )) }
          </Tr>
        </Thead>
        <Tbody
          fontSize='sm'
          color={ textColor }>
          <Tr>
            <Td fontWeight='600' py='4px'>Year Built</Td>
            <Td py='4px'>{ propertyData?.yearBuilt || '' }</Td>
            <Td fontWeight='600' py='4px'>Construction Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.constructionType) }</Td>
            <Td fontWeight='600' py='4px'>Assessed Improvement Value</Td>
            <Td py='4px'>${ numberFormat(propertyData?.assessedImprovementValueCents / 100 || 0) }</Td>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Property Class</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.class) }</Td>
            <Td fontWeight='600' py='4px'>Wall Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.wallType) }</Td>
            <Td fontWeight='600' py='4px'>Assessed Land Value</Td>
            <Td py='4px'>${ numberFormat(propertyData?.assessedLandValueCents / 100 || 0) }</Td>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Property Sub-Class</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.subType) }</Td>
            <Td fontWeight='600' py='4px'>Foundation Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.foundationType) }</Td>
            <Td fontWeight='600' py='4px'>Assessed Total Value</Td>
            <Td py='4px'>${ numberFormat(propertyData?.assessedTotalValueCents / 100 || 0) }</Td>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Square Feet</Td>
            <Td py='4px'>{ propertyData?.squareFt }</Td>
            <Td fontWeight='600' py='4px'>Frame Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.frameType) }</Td>
            <Td fontWeight='600' py='4px'>Market Improvement Value</Td>
            <Td py='4px'>${ numberFormat(propertyData?.marketImprovementValueCents / 100 || 0) }</Td>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Number of Bedrooms </Td>
            <Td py='4px'>{ propertyData?.numBeds }</Td>
            <Td fontWeight='600' py='4px'>Roof Cover</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.roofCover) }</Td>
            <Td fontWeight='600' py='4px'>Market Land Value</Td>
            <Td py='4px'>${ numberFormat(propertyData?.marketLandValueCents / 100 || 0) }</Td>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Number of Full Bathrooms</Td>
            <Td py='4px'>{ propertyData?.numBathsFull }</Td>
            <Td fontWeight='600' py='4px'>Roof Shape</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.roofShape) }</Td>
            <Td fontWeight='600' py='4px'>Market Total Value</Td>
            <Td py='4px'>${ numberFormat(propertyData?.marketTotalValueCents / 100 || 0) }</Td>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Number of Partial Bathrooms</Td>
            <Td py='4px'>{ propertyData?.numBathsPartial }</Td>
            <Td fontWeight='600' py='4px'>Cooling Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.coolingType) }</Td>
            <Td fontWeight='600' py='4px'>Owner 1 First Name</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.owner1FirstName) }</Td>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Number of Stories</Td>
            <Td py='4px'>{ propertyData?.numStories }</Td>
            <Td fontWeight='600' py='4px'>Heating Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.heatingType) }</Td>
            <Td fontWeight='600' py='4px'>Owner 1 Last Name</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.owner1LastName) }</Td>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Number of Units</Td>
            <Td py='4px'>{ propertyData?.numUnits }</Td>
            <Td fontWeight='600' py='4px'>Heating Fuel</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.heatingFuel) }</Td>
            <Td fontWeight='600' py='4px'>Owner 2 First Name</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.owner2FirstName) }</Td>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Purchase Date</Td>
            <Td py='4px'>{ moment(propertyData?.purchaseDate).format('MM/DD/YYYY') }</Td>
            <Td fontWeight='600' py='4px'>Energy Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.energyType) }</Td>
            <Td fontWeight='600' py='4px'>Owner 2 Last Name</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.owner2LastName) }</Td>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Purchase Price</Td>
            <Td py='4px'>${ numberFormat(propertyData?.purchasePriceCents / 100 || 0) }</Td>
            <Td fontWeight='600' py='4px'>Sewer Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.sewerType) }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
          <Tr>
            <Td fontWeight='600' py='4px'>Last Updated</Td>
            <Td py='4px'>{ moment(propertyData?.lastUpdateDate).format('MM/DD/YYYY') }</Td>
            <Td fontWeight='600' py='4px'>Building Shape</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.buildingShape) }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
          <Tr>
            <Td py='4px' colSpan={ 2 }/>
            <Td fontWeight='600' py='4px'>Construction Quality</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.constructionQuality) }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
          <Tr>
            <Td py='4px' colSpan={ 2 }/>
            <Td fontWeight='600' py='4px'>Has Fireplace</Td>
            <Td py='4px'>{ propertyData?.hasFireplace ? 'YES' : 'NO' }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
          <Tr>
            <Td py='4px' colSpan={ 2 }/>
            <Td fontWeight='600' py='4px'>Number of Fireplaces</Td>
            <Td py='4px'>{ propertyData?.numFireplaces }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
          <Tr>
            <Td py='4px' colSpan={ 2 }/>
            <Td fontWeight='600' py='4px'>Fireplace Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.fireplaceType) }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
          <Tr>
            <Td py='4px' colSpan={ 2 }/>
            <Td fontWeight='600' py='4px'>Has Pool</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.hasPool) }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
          <Tr>
            <Td py='4px' colSpan={ 2 }/>
            <Td fontWeight='600' py='4px'>Pool Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.poolType) }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
          <Tr>
            <Td py='4px' colSpan={ 2 }/>
            <Td fontWeight='600' py='4px'>Garage Type</Td>
            <Td py='4px'>{ convertFriendlyName(propertyData?.garageType) }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
          <Tr>
            <Td py='4px' colSpan={ 2 }/>
            <Td fontWeight='600' py='4px'>Garage Square Feet </Td>
            <Td py='4px'>{ propertyData?.garageSquareFt }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
          <Tr>
            <Td py='4px' colSpan={ 2 }/>
            <Td fontWeight='600' py='4px'>Number of Parking Spaces </Td>
            <Td py='4px'>{ propertyData?.numParkingSpaces }</Td>
            <Td py='4px' colSpan={ 2 }/>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}
