import apiRequest from './api';
import { BASE_API_URL } from '../config/constant';

export const getInstitutionById = (insId: string) => {
  return apiRequest('get', `${ BASE_API_URL }/institutions/${ insId }`);
};

export const exchangePublicToken = (publicAccessToken: string, accounts: string[]) => {
  return apiRequest('post', `${ BASE_API_URL }/institutions/set_access_token`, {
    body: {
      publicAccessToken,
      accounts
    }
  });
};

export const getAllLinkedAccounts = () => {
  return apiRequest('get', `${ BASE_API_URL }/institutions/connected_institutions`)
    .then(res => res.data.institutions)
    .then(res =>
      res.sort((a: any, b: any) => {
        const prev = a.institutionName.toLowerCase();
        const next = b.institutionName.toLowerCase();
        if (prev < next) return -1;
        if (prev > next) return 1;
        return 0;
      }))
};

export const getAllLinkedInsuranceAccounts = () => {
  return apiRequest('get', `${ BASE_API_URL }/institutions/connected_insurance_institutions`)
    .then(res => res.data.institutions)
    .then(res =>
      res.sort((a: any, b: any) => {
        const prev = a.insuranceProviderName.toLowerCase();
        const next = b.insuranceProviderName.toLowerCase();
        if (prev < next) return -1;
        if (prev > next) return 1;
        return 0;
      }))
};

export const getAllLinkedAccountsAsAdvisor = (clientId: number | null = null) => {
  return apiRequest('post', `${ BASE_API_URL }/institutions/connected_institutions/as-advisor`, {
    body: {
      clientId
    }
  })
    .then(res => res.data.institutions)
    .then(res =>
      res.sort((a: any, b: any) => {
        const prev = a.institutionName.toLowerCase();
        const next = b.institutionName.toLowerCase();
        if (prev < next) return -1;
        if (prev > next) return 1;
        return 0;
      }))
};

export const getAllLinkedInsuranceAccountsAsAdvisor = (clientId: number | null = null) => {
  return apiRequest('post', `${ BASE_API_URL }/institutions/connected_insurance_institutions/as-advisor`, {
    body: {
      clientId
    }
  })
    .then(res => res.data.institutions)
    .then(res =>
      res.sort((a: any, b: any) => {
        const prev = a.insuranceProviderName.toLowerCase();
        const next = b.insuranceProviderName.toLowerCase();
        if (prev < next) return -1;
        if (prev > next) return 1;
        return 0;
      }))
};

export const startHistoricalSyncProcess = (institutionLoginId?: string) => apiRequest('post', `${ BASE_API_URL }/institutions/historical-sync`, {
  body: {
    institutionLoginId
  }
}).then((res) => res.data);

export const getHistoricalSyncStatus = (institutionLoginId?: string) => apiRequest('post', `${ BASE_API_URL }/institutions/historical-sync-status`, {
  body: {
    institutionLoginId
  }
}).then((res) => res.data);

export const removeInstitution = (institutionLoginId: string) => apiRequest('del', `${ BASE_API_URL }/institutions/unlink_institution`, {body: {institutionLoginId}});

export const removeInsuranceInstitution = (pullId: string) => apiRequest('del', `${ BASE_API_URL }/institutions/remove_insurance_institution`, {body: {pullId}});

export const refreshInstitution = (institutionLoginId: string) => {
  return apiRequest('get', `${ BASE_API_URL }/institutions/refresh_institution/${ institutionLoginId }`);
};