import {
  MdAccessibility,
  MdAccountBox,
  MdAllInclusive,
  MdApartment,
  MdBuild,
  MdBusiness,
  MdComputer,
  MdDataset,
  MdDirectionsBoat,
  MdDirectionsCar,
  MdDomain,
  MdEco,
  MdEmojiNature,
  MdErrorOutline,
  MdFavorite,
  MdFireExtinguisher,
  MdGarage,
  MdGavel,
  MdGolfCourse,
  MdGroup,
  MdHome,
  MdHourglassFull,
  MdInventory,
  MdInventory2,
  MdLayers,
  MdLocalShipping,
  MdMobileFriendly,
  MdNature,
  MdPets,
  MdPolicy,
  MdPortrait,
  MdRvHookup,
  MdSensorWindow,
  MdSnowmobile,
  MdSportsMotorsports,
  MdStore,
  MdSupervisorAccount,
  MdUmbrella,
  MdWaves,
  MdWidgets,
  MdWork,
} from "react-icons/md";


export const PolicyIcons = {
  AUTO: MdDirectionsCar,
  NAMED_NON_OWNER: MdPortrait,
  CLASSIC_CAR: MdDirectionsCar,
  BOAT: MdDirectionsBoat,
  CONDO: MdApartment,
  FARMOWNER: MdNature,
  FLOOD: MdWaves,
  HOMEOWNERS: MdHome,
  LANDLORD: MdDomain,
  LIFE: MdFavorite,
  MOTORCYCLE: MdSportsMotorsports,
  TERM_LIFE: MdHourglassFull,
  UNIVERSAL_LIFE: MdAllInclusive,
  WHOLE_LIFE: MdAccessibility,
  RENTERS: MdGroup,
  SNOWMOBILE: MdSnowmobile,
  UMBRELLA: MdUmbrella,
  RECREATIONAL_VEHICLE: MdRvHookup,
  FIRE: MdFireExtinguisher,
  TRAILER: MdDataset,
  PERSONAL_ARTICLES: MdInventory,
  EARTHQUAKE: MdSensorWindow,
  BUSINESS_OWNERS: MdBusiness,
  COMMERCIAL_UMBRELLA: MdStore,
  COMMERCIAL_AUTO: MdLocalShipping,
  COMMERCIAL_FIRE: MdFireExtinguisher,
  COMBO: MdWidgets,
  OFFROAD_VEHICLE: MdInventory2,
  WORKERS_COMPENSATION: MdWork,
  MOBILE_HOME: MdMobileFriendly,
  PET: MdPets,
  PERSONAL_LIABILITY: MdGavel,
  GENERAL_LIABILITY: MdGavel,
  ERRORS_AND_OMISSIONS: MdErrorOutline,
  INLAND_MARINE: MdWaves,
  GARAGE: MdGarage,
  EXCESS_LIABILITY: MdLayers,
  CYBER: MdComputer,
  IDENTITY_THEFT: MdAccountBox,
  COMMERCIAL_CRIME: MdPolicy,
  ARTISAN_AND_SERVICE_CONTRACTORS: MdBuild,
  FARM_POLLUTION_LIABILITY: MdEco,
  FARM_UMBRELLA: MdEmojiNature,
  MANAGEMENT_LIABILITY: MdSupervisorAccount,
  GOLF_CART: MdGolfCourse,
};