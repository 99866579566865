import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import TransactionTable from 'views/admin/default/components/TransactionTable';
import { useAuth } from 'auth-context/auth.context';
import {
  getAllTransactions,
  getAllTransactionsAsAdvisor,
  getInvestmentTypes,
  getInvestmentTypesAsAdvisor
} from '../../../api/transaction-service';
import { COUNT_PER_PAGE } from '../../../config/constant';
import { usePagination, } from '@ajna/pagination';
import { FILTERS, YEAR_1 } from '../../../libraries/transactionsFilterManager';
import { useDebounce } from '../../../libraries/useDebounce';
import { getAllLinkedAccounts, getAllLinkedAccountsAsAdvisor } from '../../../api/institution-service';
import InvestmentStatementModal from '../modals/components/InvestmentStatementModal';
import { downloadBankStatement } from '../../../api/finicity-service';
import moment from 'moment';
import { DownloadIcon } from '@chakra-ui/icons';
import Card from '../../../components/card/Card';
import AccountBalance from './components/AccountBalance';
import AccountHolding from './components/AccountHolding';
import ExpenseRatio from './components/ExpenseRatio';
import { getAccountHoldings, getPriceRangeData } from '../../../api/fasttrack-service';
import Select from "react-select";
import { selectCustomStyles } from "../../../theme/styles";
import MarketDataModal from "../modals/components/MarketDataModal";
import { getPeriodDates } from "../../../libraries/utils";

export default function MainDashboard() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const toast = useToast();
  const {user, selectedClient} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isInstitutionLoading, setIsInstitutionLoading] = useState(false);
  const [isInvestmentTypesLoading, setIsInvestmentTypesLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(FILTERS);
  const [accounts, setAccounts] = useState([]);
  const [balanceData, setBalanceData] = useState([]);
  const [investmentTypes, setInvestmentTypes] = useState([]);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isSecurityLoading, setIsSecurityLoading] = useState(false);
  const [securities, seSecurities] = useState([]);

  const {isOpen: isHoldingOpen, onOpen: onHoldingOpen, onClose: onHoldingClose} = useDisclosure();
  const [selectedSecurity, setSelectedSecurity] = useState(undefined);
  const [isPriceDataLoading, setIsPriceDataLoading] = useState(false);
  const [marketData, setMarketData] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState({
    value: YEAR_1,
    label: '1 year',
  });
  const debouncedFilter = useDebounce(filter, 1000);

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    offset,
    pageSize,
  } = usePagination({
    pagesCount: Math.ceil(total / COUNT_PER_PAGE),
    limits: {
      outer: 2,
      inner: 2
    },
    initialState: {
      currentPage: 1,
      pageSize: COUNT_PER_PAGE,
    },
  });

  useEffect(() => {
    fetchInstitutions();
    fetchInvestmentTypes();
    fetchAccountHoldings();
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, offset, debouncedFilter]);


  useEffect(() => {
    fetchPriceRangeData();
  }, [selectedSecurity, selectedDate]);

  const fetchPriceRangeData = () => {
    if (!selectedSecurity || !selectedClient) return;
    const [start, end] = getPeriodDates(selectedDate?.value);
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).format('YYYY-MM-DD');
    setIsPriceDataLoading(true);
    getPriceRangeData(selectedClient.userId, selectedSecurity.securityId, startDate, endDate)
      .then(data => setMarketData(data))
      .catch(err => {
        setMarketData(undefined);
        toast({
          title: err.response?.data.message ?? err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
      .finally(() => setIsPriceDataLoading(false))
  }

  const fetchAccountHoldings = () => {
    setIsSecurityLoading(true);
    getAccountHoldings(selectedClient?.userId)
      .then(({securities}) => {
        seSecurities(securities);
      })
      .catch(err => console.error(err.message))
      .finally(() => setIsSecurityLoading(false))
  }

  const setInstitutionList = (response) => {
    let accounts = [];
    let balanceAccounts = [];
    response.forEach((linkedAccount) => {
      for (const item of linkedAccount.items) {
        item.accounts
          .map((account) => {
            const accountName = `${linkedAccount.institutionName} # ${account.accountNumberDisplay}`;
            if (!accounts.some((acc) => acc.value === accountName)) {
              accounts.push({
                value: account.accountId,
                label: accountName,
                isStatementAvailable: item.isStatementAvailable,
              });
            }
            balanceAccounts.push({
              accountId: account.accountId,
              icon: linkedAccount?.branding?.icon || '',
              primaryColor: linkedAccount?.branding?.primaryColor || '',
              name: accountName,
              title: account.accountName,
              type: account.type,
              balance: account.balance,
            })
          });
      }
    });

    let sortedAccounts = accounts.sort((acc1, acc2) => {
      if (acc1.label < acc2.label) return -1;
      if (acc1.label > acc2.label) return 1;
      return 0;
    });
    setAccounts(sortedAccounts);
    let sortedBalanceAccounts = balanceAccounts.sort((acc1, acc2) => {
      if (acc1.name < acc2.name) return -1;
      if (acc1.name > acc2.name) return 1;
      return 0;
    });
    setBalanceData(sortedBalanceAccounts);
  }

  const fetchInstitutions = () => {
    setIsInstitutionLoading(true)
    if (selectedClient) {
      getAllLinkedAccountsAsAdvisor(selectedClient.userId)
        .then((response) => {
          setInstitutionList(response);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsInstitutionLoading(false))
    } else {
      getAllLinkedAccounts()
        .then((response) => {
          setInstitutionList(response);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsInstitutionLoading(false))
    }
  }

  const fetchInvestmentTypes = () => {
    setIsInvestmentTypesLoading(true)
    if (selectedClient) {
      getInvestmentTypesAsAdvisor(selectedClient.userId)
        .then(({investmentTypes}) => {
          const types = investmentTypes.filter(type => type !== null).map(type => ({
            value: type,
            label: `${type}`.toUpperCase(),
          }));
          setInvestmentTypes(types);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsInvestmentTypesLoading(false))
    } else {
      getInvestmentTypes()
        .then(({investmentTypes}) => {
          const types = investmentTypes.filter(type => type !== null).map(type => ({
            value: type,
            label: `${type}`.toUpperCase(),
          }));
          setInvestmentTypes(types);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsInvestmentTypesLoading(false))
    }
  }

  const fetchTransactions = () => {
    setIsLoading(true);
    if (selectedClient) {
      getAllTransactionsAsAdvisor(pageSize, offset, filter, selectedClient.userId)
        .then((response) => {
          setResult(response);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsLoading(false))
    } else {
      getAllTransactions(pageSize, offset, filter)
        .then((response) => {
          setResult(response);
        })
        .catch(err => {
          console.error(err.message);
        })
        .finally(() => setIsLoading(false))
    }
  }

  const setResult = (response) => {
    const {transactions, count} = response;
    setTransactions(transactions);
    setTotal(count);
  }

  function downloadFile(url, filename) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        link.style.display = 'none'; // Hide the link element from the user
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Remove the link element after triggering the download
      })
      .catch((error) => console.error('Error downloading the file:', error));
  }

  const onDownload = async (accountId, index) => {
    setIsDownloading(true);
    await downloadBankStatement(selectedClient ? selectedClient.email : user.email, accountId, '', index)
      .then(({fileUrl}) => {
        const filename = `Investment_Statement_${moment().subtract(index, 'months').format('YYYY-MM')}.pdf`
        downloadFile(fileUrl, filename);
      })
      .catch(error => {
        console.error(error.message);
        toast({
          title: 'Download investment statement',
          description: error.response?.data.message ?? error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
      .finally(() => setIsDownloading(false))
  }

  const {colorMode} = useColorMode();

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    })
  }

  return (
    <Box pt={{base: '130px', md: '80px', xl: '80px'}}>
      <Flex
        mt="45px"
        mb="20px"
        justifyContent="space-between"
        direction={{base: 'column', md: 'row'}}
        align={{base: 'start', md: 'center'}}>
        <Flex ms="24px"
              me="12px"
              gap="8px"
              direction={{base: 'column', md: 'row'}}
        >
          <Text color={textColor} fontSize="2xl" fontWeight="700">
            Account Overview
          </Text>
          <Select
            styles={selectCustomStyles(colorMode)}
            isLoading={isInstitutionLoading}
            value={filter.ACCOUNT_NAME}
            isMulti
            placeholder="Select an account name"
            onChange={(account) => {
              handleFilterChange('ACCOUNT_NAME', account)
            }}
            options={accounts}
          />
        </Flex>
        <Flex
          align={{base: 'flex-end', md: 'center'}}
          gap="8px"
          width={{base: '100%', md: 'auto'}}
          direction={{base: 'column', md: 'row'}}
          pe={{base: '24px', md: '0px'}}
          ms={{base: '24px', md: '0px'}}
          mt={{base: '20px', md: '0px'}}>
          <Button
            disabled={isDownloading}
            minW="140px"
            colorScheme="brand"
            leftIcon={<DownloadIcon />}
            fontWeight="500"
            onClick={onOpen}
          >
            INVESTMENT STATEMENT
          </Button>
        </Flex>
      </Flex>
      <SimpleGrid columns={{base: 1, md: 1, xl: 3}} gap="20px" mb="20px">
        <Card px="0px" mb="20px">
          <AccountHolding
            isSecurityLoading={isSecurityLoading}
            securityData={securities}
            setSelectedSecurityId={id => {
              const security = securities.find(sec => sec.securityId === id);
              setSelectedSecurity(security);
            }}
            onOpen={onHoldingOpen}
          />
        </Card>
        <Card px="0px" mb="20px">
          <AccountBalance
            balanceLoading={isInstitutionLoading}
            balanceData={balanceData}
            setSelectedSecurityId={id => {
              const security = securities.find(sec => sec.securityId === id);
              setSelectedSecurity(security);
            }}
            onMarketDataOpen={onHoldingOpen}
          />
        </Card>
        <Card px="0px" mb="20px">
          <ExpenseRatio
            isSecurityLoading={isSecurityLoading}
            securityData={securities}
            setSelectedSecurityId={id => {
              const security = securities.find(sec => sec.securityId === id);
              setSelectedSecurity(security);
            }}
            onOpen={onHoldingOpen}
          />
        </Card>
      </SimpleGrid>
      <SimpleGrid columns={{base: 1, md: 1, xl: 1}} gap="20px" mb="20px">
        <TransactionTable
          isLoading={isLoading}
          isInvestmentTypesLoading={isInvestmentTypesLoading}
          investmentTypes={investmentTypes}
          transactions={transactions}
          setCurrentPage={setCurrentPage}
          pagesCount={pagesCount}
          currentPage={currentPage}
          filter={filter}
          setFilter={setFilter}
          isInsLoading={isInstitutionLoading}
          accounts={accounts}
          pages={pages} />
      </SimpleGrid>
      <InvestmentStatementModal isLoading={isLoading || isDownloading} isOpen={isOpen} onClose={onClose} accounts={accounts}
                                onDownload={onDownload} />
      {
        selectedSecurity &&
        <MarketDataModal isLoading={isPriceDataLoading} isOpen={isHoldingOpen} onClose={onHoldingClose}
                         selectedDate={selectedDate}
                         security={selectedSecurity}
                         handleDateChange={setSelectedDate}
                         marketData={marketData} />
      }
    </Box>
  );
}
