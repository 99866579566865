// Chakra imports
import {
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverContent,
  PopoverHeader,
  Portal,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import React from 'react';
// Assets
import { WarningIcon } from '@chakra-ui/icons';
import { PopoverTrigger } from '../../dataTables/components/ClientTable';
import { IoMdRemoveCircleOutline } from 'react-icons/io';
import { getColorByFirstChar } from "../../../../libraries/utils";

export default function CanopyAccount(props) {
  const {
    pullId,
    insuranceProviderName,
    status,
    onStartSync,
    onRemoveItem,
    isDeleting,
    ...rest
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const bg = useColorModeValue('white', 'navy.700');

  return (
    <Card bg={ bg } { ...rest } p="14px">
      <Flex align="center" justify="space-between" gap="8px">
        <Box
          bg={ getColorByFirstChar(insuranceProviderName) }
          borderRadius="50%"
          minW="40px"
          width="40px"
          height="40px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        />
        <Flex align="center" marginBottom="4px" wrap='wrap'>
          <Text
            fontWeight="semibold"
            color={ textColorPrimary }
            fontSize="lg"
            me="8px">
            { insuranceProviderName.toUpperCase() }
          </Text>
          <Text color={ status === "SUCCESS" ? "green" : "red" } fontWeight="400" fontSize="sm">{ status }</Text>
        </Flex>
        <Popover placement="bottom-start">
          { ({ onClose }) => (
            <>
              <PopoverTrigger>
                <IconButton
                  bg="inherit"
                  ms="auto"
                  borderRadius="inherit"
                  _hover="none"
                  _active={ {
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent',
                  } }
                  _focus={ {
                    boxShadow: 'none',
                  } }
                  disabled={ isDeleting }
                  icon={
                    <IoMdRemoveCircleOutline color="secondaryGray.500" w="20px" h="20px"/>
                  }></IconButton>
              </PopoverTrigger>
              <Portal>
                <PopoverContent width="270px">
                  <PopoverHeader>
                    <Box display="flex" marginBottom="8px">
                      <WarningIcon color="orange" marginRight="4px" marginTop="4px"/>
                      <Box>Are you sure to remove this account?</Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                      <Button size="xs" margin="4px" width="50px" onClick={ onClose }>No</Button>
                      <Button size="xs" margin="4px" width="50px" colorScheme="red"
                              onClick={ () => {
                                onRemoveItem(pullId);
                                onClose();
                              } }>Yes</Button>
                    </Box>
                  </PopoverHeader>
                </PopoverContent>
              </Portal>
            </>
          ) }
        </Popover>
      </Flex>
      {/*<Box*/ }
      {/*  width="100%"*/ }
      {/*  zIndex="1"*/ }
      {/*>*/ }
      {/*  <Text*/ }
      {/*    color={ textColorPrimary }*/ }
      {/*    fontWeight="500"*/ }
      {/*    fontSize="sm"*/ }
      {/*    mt="10px"*/ }
      {/*    mb="4px">*/ }
      {/*    We are fetching transactions from your account. Please wait for a moment...*/ }
      {/*  </Text>*/ }
      {/*  <Progress size="sm" w="100%" isIndeterminate/>*/ }
      {/*</Box>*/ }
    </Card>
  );
}
