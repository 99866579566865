import {
	YEAR, CURRENT_MONTH, PREVIOUS_MONTH, Q1, Q2, Q3, Q4, CUSTOM, YEAR_1,
} from './transactionsFilterManager';
import moment from 'moment';

const options = [
	{
		value: YEAR_1,
		label: '1 year',
	}, {
		value: YEAR,
		label: 'Year to date',
	}, {
		value: CURRENT_MONTH,
		label: 'This month',
	}, {
		value: Q1,
		label: 'Q1 - current year',
	}, {
		value: Q2,
		label: 'Q2 - current year',
	}, {
		value: Q3,
		label: 'Q3 - current year',
	}, {
		value: Q4,
		label: 'Q4 - current year',
	}, {
		value: CUSTOM,
		label: 'Custom',
	},
];

export default options.reduce((prevOptions, option, idx) => {
	if (option.value.match(/(Q1|Q2|Q3|Q4)/)) {
		const currentQuarter = moment().quarter();
		const optionQuarter = Number(option.value[1]);
		if (optionQuarter > currentQuarter) return prevOptions;
	}
	return [
		...prevOptions,
		{
			key: idx,
			...option,
		},
	];
}, []);

export function getPastMonthsList(numOfYears) {
	const numMonths = numOfYears * 12;
	const monthsRange = Array.from({length: numMonths}, (_, index) => index);

	return monthsRange.map(index => {
		const currentMonth = moment().subtract(index + 1, 'months');
		return {value: index + 1, label: currentMonth.format('MM/YYYY')};
	});
}